// 常规巡检记录相关请求
import req from './index'

//获取对比图片
export const compareImages = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.InsImageList_Compare', params)
}

export const cilckImg = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.FaultCheckList_Set', params)
}

//上传图片
export const submitPictureFun = (params) => {
  return req.file('api/admin.php?s=Admin.Cruise.Upload_Pic', params)
}

