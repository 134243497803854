// 权限设置
import req from './index'
// 获取用户列表信息
export const getUserInfoListApi = (params) => {
  return req.post('api/admin.php?service=Admin.Limitation.GetUserList', params)
}

// 获取权限信息
export const getPurviewInfo = (params) => {
  return req.get('api/admin.php?service=Admin.Limitation.GetUserLimitation', params)
}
// 提交权限信息
export const setPurviewInfo = (params) => {
  return req.post('api/admin.php?service=Admin.Limitation.SetUserLimitation', params)
}


// 提交用户信息
export const submitUserInfoApi = (params) => {
  return req.post('api/admin.php?service=Admin.Limitation.SetUser', params)
}

// 获取用户详细信息
export const getUserInfoApi = (params) => {
  return req.post('api/admin.php?service=Admin.Limitation.GetUserInfo', params)
}

// 删除用户
export const delUserApi = (params) => {
  return req.post('api/admin.php?service=Admin.Limitation.DelUser', params)
}




