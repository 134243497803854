// 单元段
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { PlusOutlined, ArrowLeftOutlined, VerticalAlignBottomOutlined, ExclamationCircleOutlined, UnlockOutlined, LockFilled, SettingOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Pagination, Spin, message, Drawer, Popconfirm } from 'antd';
import { Table } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom' //路由跳转使用 useNavigate react-router v6已经把usehistory替换成了useNavigate
import OperationBuilder from '@/components/TableBuilder/OperationBuilder';
import { GetRoutesList, GetRoutesInfo, AddRoutes, EditRoutes, ImportPoint, IdentificationStake, DelRoutes, exportWaypoints, downloadRouteApi, ImportAirwayAPI, lockListData } from '@/services/shipping_line'
import { basicPath } from '@/router/config.js'
import local from '@/utils/local'
// import 'antd/dist/antd.dark.css';

const { TextArea } = Input;

function FixedPointChild () {
  const navigate = useNavigate();
  const location = useLocation();//接受传值数据
  const limitsAuthorityName = useRef()//权限列表name
  // 创建一个ref来引用<a>标签  
  const fileLinkRef = useRef(null);


  const btnsize = 'default';
  const token = local.get('cruise_m30_token');
  const [dataArr, setDataArr] = useState([]);//表格数据
  const [loading, setLoading] = useState(false);
  const [showModle, setShowModel] = useState(false);//是否显示弹窗
  const [modelTitle, setModelTitle] = useState('');//弹窗标题
  const [choose_id, setChooseId] = useState('');//当前选中的id
  const [title, setTitle] = useState('');//单元名称
  const [des, setDes] = useState('');//单元备注
  const [showMasked, setShowMasked] = useState(false);//显示蒙层

  const importPointRef = useRef();//上传管线、标识桩input

  const [modelInfo, setModelInfo] = useState({
    type: '',//1 管线  2 标识桩
    coordinate: '',//WGS84 GCJ
    isOpen: false,
    title: '',
    btnLoading: false
  })

  const indexData = JSON.parse(localStorage.getItem('cruise_m30_fixed_point_index'))
  const childData = JSON.parse(localStorage.getItem('cruise_m30_fixed_point_child'))
  const [search, setSearch] = useState({//查询
    PID: indexData?.PID || null,
    name: indexData?.name || null,
    urlType: indexData?.urlType || null,
    access_token: token,
    page: childData?.page || 1,
    total: 0,
    perpage: 10,
  })

  //下载文件弹窗参数
  const [downloadModel, setDownloadModel] = useState({
    isOpen: false,
    title: '',
    content: '',
    key: '',
    id: '',
    url: '',//下载的链接
    list: {},
  });

  const columns = [ //表格头
    {
      title: 'ID',
      width: 80,
      dataIndex: 'id',
    },
    {
      title: '单元名称',
      dataIndex: 'title',
    },
    {
      title: '编辑人',
      dataIndex: 'username',
      render: (text, record) => {
        return (
          text
            ? <div style={{ color: 'rgba(255,255,255,0.45)' }}>{text}</div>
            : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
        )
      }
    },
    {
      title: '编辑时间',
      dataIndex: 'update_time',
      render: (text, record) => {
        return (
          text
            ? <div style={{ color: 'rgba(255,255,255,0.45)' }}>{text}</div>
            : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
        )
      }
    },
    {
      title: '锁定',
      dataIndex: 'is_lock',
      width: 80,
      align: 'center',
      render: (text, record) => {
        return (
          text
            ? (
              <div className='col_primary pointer flex jcc align_center'>
                <Popconfirm
                  title="是否锁定?"
                  description="Are you sure to delete this task?"
                  onConfirm={async () => {
                    let obj = {
                      access_token: token,
                      RID: record.id,
                      is_lock: 0
                    }
                    let res = await lockListData(obj)
                    if (res.ret !== 200) { return message.warning(res.msg || '数据跑丢了') }
                    getData(search, false);
                  }}
                  okText="锁定"
                  cancelText="取消"
                >
                  <UnlockOutlined className='col_primary pointer' />
                </Popconfirm>
              </div>
            )
            : (
              <div className='col_primary pointer flex jcc align_center'>

                <Popconfirm
                  title="是否解锁?"
                  description=""
                  onConfirm={async () => {
                    let obj = {
                      access_token: token,
                      RID: record.id,
                      is_lock: 1
                    }
                    let res = await lockListData(obj)
                    if (res.ret !== 200) { return message.warning(res.msg || '数据跑丢了') }
                    getData(search, false);
                  }}
                  okText="解锁"
                  cancelText="取消"
                >

                  <LockFilled className='col_primary pointer' />
                </Popconfirm>
              </div>
            )
        )
      }
    },
    {
      title: '风险设置',
      dataIndex: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (limitsAuthorityName.current.indexOf(`Risk_setting_${search.urlType}`) > -1) {
          return (
            <div
              style={{ display: (Number(record.state) === 1 ? '' : 'none') }}
              onClick={() => {
                let obj = {
                  ...search,
                  PID: record.id,
                  name: record.title,
                }
                localStorage.setItem('cruise_m30_fixed_point_child', JSON.stringify(obj))
                navigate('/fixed_point/risk_set')
              }}
            >
              <SettingOutlined className='pointer col_primary' style={{ width: '16px', height: '16px' }} />
            </div>
          )
        } else {
          return (<SettingOutlined style={{ width: '16px', height: '16px' }} />)
        }
      },
    },
    {
      title: '操作',
      dataIndex: 'op',
      width: '100px',
      align: 'center',
      render: (_, record, index) => {
        if (record.is_lock === 1) {

          //操作按钮集合
          const ButtonList = [
            {
              key: '0',
              type: 'SetUp',
              label: "修改",
              purview: limitsAuthorityName.current?.indexOf(`RoutesInfo_Edit_${search.urlType}`) > -1,
            },
            {
              key: "1",
              type: 'NodeCollapseOutlined',
              label: "上传管线",
              purview: limitsAuthorityName.current?.indexOf(`RoutesPoint_Import_${search.urlType}`) > -1,
            },
            {
              key: "2",
              type: 'RotateLeftOutlined',
              label: "上传标识桩",
              purview: limitsAuthorityName.current?.indexOf(`RoutesMark_Import_${search.urlType}`) > -1,
            },
            {
              key: "4",
              type: 'NodeExpandOutlined',
              label: "导出管线",
              purview: limitsAuthorityName.current?.indexOf(`Routes_PointExport_${search.urlType}`) > -1,
            },
            {
              key: "5",
              type: 'NodeIndexOutlined',
              label: "导出航点",
              purview: limitsAuthorityName.current?.indexOf(`Routes_PointExport_${search.urlType}`) > -1,
            },
            {
              key: "6",
              type: 'RotateRightOutlined',
              label: "导出标识桩",
              purview: limitsAuthorityName.current?.indexOf(`Routes_PointExport_${search.urlType}`) > -1,
            },
            {
              key: "7",

              type: 'NodeCollapseOutlined',
              label: "上传航线文件",
              purview: limitsAuthorityName.current?.indexOf(`RoutesRoute_Import_${search.urlType}`) > -1,
            },
            {
              key: "8",
              type: 'VerticalAlignBottomOutlined',
              label: "下载航线文件",
              purview: limitsAuthorityName.current?.indexOf(`Wayfile_Export_${search.urlType}`) > -1,
            },
            {
              key: 'divider',
              type: 'Divider',
              purview: true,
            },
            {
              key: "3",
              type: 'Delete',
              label: '删除',
              purview: limitsAuthorityName.current?.indexOf(`RoutesInfo_Del_${search.urlType}`) > -1,
            },
          ]

          return OperationBuilder(record, index, 'actions_drop', ButtonList, Operation)
        } else {
          return <div className='opa25'>-</div>
        }
      }
    },
  ];

  // 操作按钮 回调
  const Operation = (record, index, type, key) => {
    let info = { ...modelInfo };
    switch (key) {
      case '0':
        getInfor(record.id);
        break;
      case '1':
        setChooseId(record.id);
        info.type = 1;
        info.isOpen = true;
        info.title = '上传管线';
        setModelInfo(info);
        break;
      case '2':
        setChooseId(record.id);
        info.type = 2;
        info.isOpen = true;
        info.title = '上传标识桩';
        setModelInfo(info);
        break;
      case '3':
        delData(record.id);
        break;
      case '4':
        DCHD(key, record.id)
        break;
      case '5':
        DCHD(key, record.id)
        break;
      case '6':
        DCHD(key, record.id)
        break;
      case '7':
        setChooseId(record.id);
        document.getElementById('importRoute').click();
        break;
      case '8':
        downloadRoute(key, record)
        break;
      default:
        break;
    }

  };

  // 下载航线文件
  const downloadRoute = async (key, list) => {
    let dow = downloadModel
    let res = await downloadRouteApi({
      access_token: token,
      id: list.id,
      type: search.urlType,
    })
    if (res.ret !== 200) {
      dow.isOpen = false
      setDownloadModel({ ...dow })
      message.warning(res.msg || '下载航线文件失败，请稍后重试~')
      return
    }
    dow.isOpen = true
    dow.title = '下载航线文件'
    dow.content = '航线文件已准备，点击按钮下载'
    dow.key = key
    dow.id = list.id
    dow.url = res.data
    dow.list = list
    setDownloadModel({ ...dow })
  }

  //关闭下载弹窗
  const closeDownloadModel = () => {
    let info = { ...downloadModel };
    info.isOpen = false;
    setDownloadModel(info);
  }

  // //下载标识桩模板 type:下载方式 1、下载 2、另存为
  const downloadStakeTemplate = (type = 1) => {
    if (type === 1) {
      const x = new window.XMLHttpRequest();
      x.open('GET', downloadModel.url, true);
      x.responseType = 'blob';
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${downloadModel.list.title}.kmz`
        a.click();
        URL.revokeObjectURL(a.href)
      };
      x.send();

      let dow = downloadModel
      dow.isOpen = false
      setDownloadModel({ ...dow })
    }

    if (type === 2) {
      urlToFile(downloadModel.url).then(file => {
        saveAs(file, downloadModel.list.title)
      }).catch(error => {
        message.warning('获取文件地址失败，请联系管理员')
      })
    }
  }

  //另存为
  const saveAs = async (file, fileName) => {
    try {
      const opts = {
        types: [
          {
            description: '文件',
            accept: {
              'application/vnd.google-earth.kmz': ['.kmz']
            },
          }
        ],
        suggestedName: fileName,
        excludeAcceptAllOption: true
      };
      const handle = await window.showSaveFilePicker(opts); // 打开保存文件对话框
      const writable = await handle.createWritable(); // 创建可写入的文件对象

      // 在这里写入文件内容
      await writable.write(file)
      await writable.close()
      let dow = downloadModel
      dow.isOpen = false
      setDownloadModel({ ...dow })
      message.success('文件保存成功')
    } catch (error) {
      console.log(error);
      let text = JSON.stringify(error);
      if (text.indexOf('The user aborted a request') === -1) {//无法使用另存为
        message.warning('当前浏览器暂不支持另存为操作，请直接下载 或更换以下浏览器：谷歌、edge、360');
      }
    }
  }

  //地址转文件
  const urlToFile = (url) => {
    return new Promise((resolve, reject) => {
      var blob = null;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader('Accept', 'application/msword');
      xhr.responseType = "blob";
      // 加载时处理
      xhr.onload = () => {
        // 获取返回结果
        blob = xhr.response;
        let file = new File([blob], 'xxx', { type: 'application/msword' });
        // 返回结果
        resolve(file);
      };
      xhr.onerror = (e) => {
        message.warning('文件转换失败');
        reject(e)
      };
      // 发送
      xhr.send();
    });
  }


  //获取表格数据
  const getData = async (data, isloding = true) => {
    let sea = search
    if (isloding) { setLoading(true); }
    let obj = {
      PID: data.PID,
      access_token: token,
      page: data.page,
      perpage: data.perpage,
      type: data.urlType
    }
    let res = await GetRoutesList(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    sea.total = res?.data?.total
    setSearch({ ...sea })
    setDataArr([...res.data.items]);
    setLoading(false);
  };

  // 导出航点
  const DCHD = async (key, id) => {
    let res = await exportWaypoints({
      access_token: token,
      id: id,
      type: key === '4' ? '1' : key === '5' ? '2' : key === '6' ? '3' : undefined
    })

    if (res.ret === 200) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = res.data
      document.body.appendChild(link);
      link.click();
      // 释放的 URL 对象以及移除 a 标签
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
      message.success('导出成功~')
    } else {
      message.warning(res.msg || '导出失败，请稍后重试~')
    }
  }

  //删除表格数据
  const delData = async (id) => {
    let res = await DelRoutes({
      access_token: token,
      id: id
    })
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success('删除成功');
    getData(search);
  };

  //修改表格数据
  const updateData = async () => {
    if (!title) {
      message.warning('请输入单元名称');
      return;
    }
    if (!des) {
      message.warning('请输入备注');
      return;
    }
    let obj = {
      access_token: token,
      title: title,
      des: des,
      // length: length,
    }
    let res;
    if (choose_id) {
      obj.id = choose_id;
      res = await EditRoutes(obj);
    } else {
      obj.RID = search.PID;
      obj.type = search.urlType;
      res = await AddRoutes(obj);
    }
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success(choose_id ? '编辑成功' : '新增成功');
    setTitle('');
    setDes('');
    // setLength('')
    setChooseId('');
    setShowModel(false);
    getData(search);
  };

  //获取管线详细信息
  const getInfor = async (id) => {
    let params = {
      access_token: token,
      id: id
    }
    let res = await GetRoutesInfo(params);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return
    }
    let val = res.data;
    setTitle(val.title);
    setDes(val.des);
    // setLength(val.length);
    setChooseId(id);
    setShowModel(true);
    setModelTitle('编辑单元');
  };

  //新增管线
  const addPipe = async () => {
    setChooseId('');
    setShowModel(true);
    setModelTitle('新增单元');
  }

  //上传管线
  const importData = async (val) => {
    let param = {
      access_token: token,
      id: val.id,
      file: val.file,
      type: val.type
    }
    let info = { ...modelInfo };
    info.btnLoading = true;
    setModelInfo(info);
    let res = await ImportPoint(param);
    info.isOpen = false;
    info.btnLoading = false;
    setModelInfo(info);
    importPointRef.current.value = '';
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
    } else {
      message.success('上传成功');
      getData(search);
    }

  };

  //上传航线文件
  const importRoute = async (val) => {
    setShowMasked(true)
    message.loading({
      content: '正在上传航线文件...',
      key: 'updatable',
      duration: 0
    });
    try {
      var data = new FormData();
      // 创建一个表单数据
      data.append("access_token", token);
      data.append("id", val.id);
      data.append("file", val.file);
      data.append("type", search.urlType)
      let res = await ImportAirwayAPI(data);
      document.getElementById('importRoute').value = '';
      if (res.ret !== 200) {
        message.warning({
          content: res.msg || '系统出错了',
          key: 'updatable',
        });
        return;
      }
      message.success({
        content: `${res.msg || '上传成功'}`,
        key: 'updatable',
      });
      setShowMasked(false)
      getData(search);
    } catch (error) {
      setShowMasked(false)
      message.warning({
        content: '执行失败',
        key: 'updatable',
      });
    }

  };

  //上传标识桩
  const identificationStake = async (val) => {
    let param = {
      access_token: token,
      id: val.id,
      file: val.file,
      type: val.type
    }
    let info = { ...modelInfo };
    info.btnLoading = true;
    setModelInfo(info);
    let res = await IdentificationStake(param);
    importPointRef.current.value = '';
    info.isOpen = false;
    info.btnLoading = false;
    setModelInfo(info);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success('上传成功');
    getData(search);
  };

  useEffect(() => {
    limitsAuthorityName.current = JSON.parse(localStorage.getItem('cruise_m30_permissionsArrName'))
    getData(search)

    //eslint-disable-next-line
  }, [])

  return (
    <div className="flex-col hei100 tablebackcol" style={{ overflowY: 'auto' }}>
      <div className="disFlex flexDirCol">
        <div className='flex-row aliItemsCen paddL24' style={{ width: '100% ', padding: '16px 24px' }}>
          <span
            className='curPointer'
            onClick={() => {
              setTimeout(() => {
                localStorage.removeItem('cruise_m30_fixed_point_index')
                localStorage.removeItem('cruise_m30_fixed_point_child')
              }, 1000);
              switch (search.urlType) {
                case 8:
                  navigate('/fixed_point/electric_towers', { state: 'child' })
                  break;
                case 9:
                  navigate('/fixed_point/stations', { state: 'child' })
                  break;
                default:
                  break;
              }
            }}
          >
            <ArrowLeftOutlined />
          </span>
          <span className='fSize20 fWei500 margR12 margL18'>{search.name}</span>
        </div>
      </div>
      <div className="flex-col margRL24 paddRL24 backcol" style={{ marginBottom: '24px' }}>
        {/* 头部 */}
        <div className="h64 flex-row aliItemsCen min-h64">
          <div className="fSize16 fWei600">单元列表</div>
          <div className="flex-auto"></div>
          <Button type="primary" icon={<PlusOutlined />} size={btnsize}
            onClick={() => {
              if (limitsAuthorityName.current?.indexOf(`RoutesInfo_Add_${search.urlType}`) > -1) {
                addPipe()
              } else {
                message.warning('暂无权限！')
              }
            }}
          >
            新增
          </Button>
        </div>


        {/* 主体 */}
        <div className="borbox">

          <Spin spinning={loading}>
            <Table
              rowKey='id'
              columns={columns}
              dataSource={dataArr}
              pagination={false}
            />
          </Spin>


          <>{/* 页码 */}
            <div className="min-h64 tt_a_r disFlex aliItemsCen">
              <Pagination
                total={search.total}
                showSizeChanger
                showQuickJumper={false}
                pageSize={search.perpage}
                showTotal={total => `共 ${total} 条数据`}
                style={{ marginLeft: 'auto' }}
                current={search.page}
                onChange={
                  (current, pageSize) => { //选择当前页后执行
                    let sea = search
                    sea.page = current;
                    sea.perpage = pageSize;
                    setSearch({ ...sea })
                    getData(sea);
                  }
                }
              />
            </div>
          </>

        </div>

      </div>

      <>{/* 弹窗 */}
        <Modal title={modelTitle} open={showModle} maskClosable={false} cancelText='取消' okText="确定"
          onOk={() => { updateData() }}
          onCancel={() => {
            setTitle('');
            setDes('');
            setChooseId('');
            // setLength()
            setShowModel(false);
          }}>
          <div className="">
            <Input placeholder="单元名称" value={title} onChange={(e) => {
              setTitle(e.target.value)
            }} />
          </div>
          {/* <div className="margB24">
          <InputNumber style={{ width: '100%' }} placeholder="单元长度" value={length} onChange={(e) => {
            setLength(e)
          }} />
        </div> */}
          <div>
            <TextArea id="" className="margT12 h54" value={des} rows={4} placeholder="单元描述"
              onChange={(e) => { setDes(e.currentTarget.value); }
              } />
          </div>
        </Modal>
      </>

      <>{/* 下载航线文件 */}
        <Modal title="" width={416} open={downloadModel.isOpen}
          footer={false} closable={false} maskClosable={true}
          onCancel={() => { closeDownloadModel() }}
        >

          <div className='marB8 flex_row align_center'>
            <span className='fs22 marR16 col_primary'><ExclamationCircleOutlined /></span>
            <span style={{ fontWeight: 500, fontSize: 16, color: 'rgba(255, 255, 255, 0.85)' }}>
              {downloadModel.title}
            </span>
          </div>
          <div className='fs14 marL38' style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.85)' }}>
            {downloadModel.content}
          </div>

          <div className='marT24 tr'>
            <Button className='marR8' onClick={() => {
              closeDownloadModel()
            }}>取消</Button>

            <Button className='marR8' type="primary" onClick={() => {
              downloadStakeTemplate(1);
            }}>下载</Button>

            <Button type="primary" onClick={() => {
              downloadStakeTemplate(2);
            }}>另存为</Button>
          </div>
        </Modal>
      </>


      <>{/* 上传管线、上传标识桩 */}
        <input ref={importPointRef}
          type="file"
          accept=".xls,.xlsx"
          hidden={true}
          onChange={(e) => {
            switch (modelInfo.type) {
              //管线
              case 1: importData({ id: choose_id, file: e.target.files[0], type: modelInfo.coordinate })
                break;
              //标识桩
              case 2: identificationStake({ id: choose_id, file: e.target.files[0], type: modelInfo.coordinate })
                break;
              default: return
            }
          }}
        />

      </>

      <>{/* 上传航线 */}
        <input id="importRoute"
          type="file"
          accept=".kmz"
          hidden={true}
          onChange={(e) => {
            importRoute({ id: choose_id, file: e.target.files[0] })
          }}
        />
      </>

      <>{/* 上传管线标识桩时，选择坐标系 */}
        <Modal title="" width={416} open={modelInfo.isOpen} footer={false} closable={false}>
          <div className='marB8 flex_row align_center'>
            <span className='fs22 marR16 col_primary'><ExclamationCircleOutlined /></span>
            <span style={{ fontWeight: 500, fontSize: 16, color: 'rgba(255, 255, 255, 0.85)' }}>
              {modelInfo.title || '上传文件'}
            </span>
          </div>
          <div className='fs14 marL38' style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.85)' }}>
            请选择对应的坐标系文件
          </div>

          <div className='marT24 tr'>
            <Button className='marR8' onClick={() => {
              let info = { ...modelInfo };
              info.isOpen = false;
              setModelInfo(info);
            }}>取消</Button>

            <Button className='marR8' loading={modelInfo.btnLoading} type="primary" onClick={() => {
              let info = { ...modelInfo };
              info.coordinate = 'WGS84';
              setModelInfo(info);
              importPointRef.current.click();
            }}>WGS84</Button>

            <Button type="primary" loading={modelInfo.btnLoading} onClick={() => {
              let info = { ...modelInfo };
              info.coordinate = 'GCJ';
              setModelInfo(info);
              importPointRef.current.click();

            }}>GCJ</Button>
          </div>
        </Modal>
      </>

      <>{/* 蒙层 */}
        <Drawer
          open={showMasked}
          width={0}
          footer={null}
          maskClosable={false}>
        </Drawer>
      </>

    </div>
  );
}
export default FixedPointChild