//转换日期格式
export const changeDataType = (text) => {
  // 2022-07-18 09:53
  let year = text.substring(0, 4);
  let mon = text.substring(5, 7);
  let day = text.substring(8, 10);
  let time = text.substring(11);
  let date = year + '年' + mon + '月' + day + '日';
  return { date: date, time: time };
}

export const drawImgLine = (value, type, c) => {
  //type 1  AI识别的  2原图
  let amuXArr = [];
  let amuYArr = [];
  for (const item of value.point_latlng) {
    if (item) {
      amuXArr.push(
        item["latitude"]
      )
      amuYArr.push(
        -1 * item["longitude"]
      )
    }
  }

  let droneamuXArr = [];
  let droneamuYArr = [];
  for (const item of value.lnglats) {
    if (item) {
      droneamuXArr.push(
        item["latitude"]
      )
      droneamuYArr.push(
        -1 * item["longitude"]
      )
    }
  }

  const canvasWidth = 960;
  const canvasHeight = 720;

  // 拿到方向轴的最大最小值
  let xMax = Math.max(...droneamuXArr);
  let xMin = Math.min(...droneamuXArr);
  let yMax = Math.max(...droneamuYArr);
  let yMin = Math.min(...droneamuYArr);


  // 根据canvas宽高计算缩放级别
  let size = value['size'];
  let idx = value['idx'];
  let angle = value['yaw'];
  let localpic = value['localpic'];
  let xScale = canvasWidth / (xMax - xMin);
  let yScale = canvasHeight / (yMax - yMin);
  let scale1 = xScale < yScale ? xScale * size : yScale * size;

  ////console.log("-" + size + "_" + angle);

  // 计算偏移量
  let xoffset = (canvasWidth - (xMax - xMin) * scale1) / 2;
  let yoffset = (canvasHeight - (yMax - yMin) * scale1) / 2;

  ////console.log(xoffset + "|" + yoffset + "|" + idx);

  if (type === 1) {
    // let canvas = document.getElementById('posiToShape');

    let canvas = document.createElement('canvas');
    canvas.width = "960"
    canvas.height = "720"
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);//清空画布
    let img = document.createElement("img");
    img.src = value.AipicUrl;
    // let pic = require( '../img/pic.jpg');
    // img.src = pic;
    img.onload = function () {
      ctx.beginPath();
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
      ctx.stroke();
      ctx.save();//保存状态

      ctx.strokeStyle = "#00b5ff";
      ctx.lineWidth = 5;

      //焦点移动到图像中心
      ctx.translate(((droneamuXArr[4] - xMin) * scale1 + xoffset), ((yMax - droneamuYArr[4]) * scale1 + yoffset));
      //旋转到对应的角度
      ctx.rotate(((90) + (360 - angle) - 180) * Math.PI / 180);

      //绘制管线
      for (let i = 0; i < amuXArr.length; i++) {
        ctx.lineTo(
          (((amuXArr[i] - xMin) * scale1 + xoffset) - ((droneamuXArr[4] - xMin) * scale1 + xoffset)),
          (((yMax - amuYArr[i]) * scale1 + yoffset) - ((yMax - droneamuYArr[4]) * scale1 + yoffset))
        );
      }
      ctx.stroke();
      ctx.closePath();
      return c(canvas.toDataURL());
    }
  } else {
    let canvas = document.createElement('canvas');
    canvas.width = "960"
    canvas.height = "720"
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);//清空画布
    let img = document.createElement("img");
    img.src = value.picUrl;
    // let pic = require( '../img/pic.jpg');
    // img.src = pic;
    img.onload = function () {
      //console.log("xxxx");
      ctx.beginPath();
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
      ctx.stroke();
      ctx.save();//保存状态

      ctx.strokeStyle = "#00b5ff";
      ctx.lineWidth = 5;

      //焦点移动到图像中心
      ctx.translate(((droneamuXArr[4] - xMin) * scale1 + xoffset), ((yMax - droneamuYArr[4]) * scale1 + yoffset));
      //旋转到对应的角度
      ctx.rotate(((90) + (360 - angle) - 180) * Math.PI / 180);

      //绘制管线
      for (let i = 0; i < amuXArr.length; i++) {
        ctx.lineTo(
          (((amuXArr[i] - xMin) * scale1 + xoffset) - ((droneamuXArr[4] - xMin) * scale1 + xoffset)),
          (((yMax - amuYArr[i]) * scale1 + yoffset) - ((yMax - droneamuYArr[4]) * scale1 + yoffset))
        );
      }
      ctx.stroke();
      ctx.closePath();
      return c(canvas.toDataURL());
    }
  }

}

const PI = 3.14159265358979324;
const a = 6378245.0;
const ee = 0.00669342162296594323;

function delta (lat, lon) {
  let a = 6378245.0 //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
  let ee = 0.00669342162296594323 //  ee: 椭球的偏心率。
  let dLat = transformLat(lon - 105.0, lat - 35.0)
  let dLon = transformLon(lon - 105.0, lat - 35.0)
  let radLat = lat / 180.0 * PI
  let magic = Math.sin(radLat)
  magic = 1 - ee * magic * magic
  let sqrtMagic = Math.sqrt(magic)
  dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * PI)
  dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * PI)
  return {
    'lat': dLat,
    'lon': dLon
  }
}

function transformLat (x, y) {
  let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x))
  ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0
  ret += (20.0 * Math.sin(y * PI) + 40.0 * Math.sin(y / 3.0 * PI)) * 2.0 / 3.0
  ret += (160.0 * Math.sin(y / 12.0 * PI) + 320 * Math.sin(y * PI / 30.0)) * 2.0 / 3.0
  return ret
}


function transformLon (x, y) {
  let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x))
  ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0
  ret += (20.0 * Math.sin(x * PI) + 40.0 * Math.sin(x / 3.0 * PI)) * 2.0 / 3.0
  ret += (150.0 * Math.sin(x / 12.0 * PI) + 300.0 * Math.sin(x / 30.0 * PI)) * 2.0 / 3.0
  return ret
}

/*判断是否在国内，不在国内则不做偏移*/
function outOfChina (lon, lat) {
  if ((lon < 72.004 || lon > 137.8347) && (lat < 0.8293 || lat > 55.8271)) {
    return true;
  } else {
    return false;
  }
}


//GCJ02坐 标转 WGS84坐标
export const transformGCJ2WGS = (value, c) => {
  let d = delta(Number(value.lat), Number(value.lon))
  return {
    'lat': Number(value.lat) - Number(d.lat),
    'lon': Number(value.lon) - Number(d.lon)
  }
}


// WGS84坐标 转 GCJ02坐标 
export const transformWGSGCJ2 = (wgLat, wgLon) => {
  var mars_point = { lon: 0, lat: 0 };
  // if (outOfChina(wgLat, wgLon))
  // {
  //     mars_point.lat = wgLat;
  //     mars_point.lon = wgLon;
  //     return;
  // }
  var dLat = transformLat(wgLon - 105.0, wgLat - 35.0);
  var dLon = transformLon(wgLon - 105.0, wgLat - 35.0);
  var radLat = wgLat / 180.0 * PI;
  var magic = Math.sin(radLat);
  magic = 1 - ee * magic * magic;
  var sqrtMagic = Math.sqrt(magic);
  dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * PI);
  dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * PI);
  mars_point.lat = wgLat + dLat;
  mars_point.lon = wgLon + dLon;
  //console.log();
  return mars_point
}

//计算两个经纬度之间的距离 单位 m
export const distance = (point1, point2, radius = 6378.137) => {
  const rad = Number(Math.PI / 180.0);
  const lat1 = Number(point1[1]) * rad;
  const lon1 = Number(point1[0]) * rad;
  const lat2 = Number(point2[1]) * rad;
  const lon2 = Number(point2[0]) * rad;
  const theta = lon2 - lon1;

  let dist = Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(theta));
  if (dist < 0) {
    dist += Math.PI;
  }
  const val = dist * radius * 1000;
  return val
}


// 将扁平化数据修改为树结构
/**
 * 
 * @param {Array} data  扁平化数组
 * @param {String} pid  父id
 * @param {String} cid  当前id
 * @returns 
 */
export const getTreeDataByDelayeringData = (data, pid, cid) => {
  let arr = JSON.parse(JSON.stringify(data).replaceAll(pid, 'parentId').replaceAll(cid, 'currentId'))
  arr.forEach(function (item) {
    delete item.children;
  });
  let map = {};
  arr.forEach(i => {
    map[i.currentId] = i;
  });
  let treeData = [];
  arr.forEach(child => {
    const mapItem = map[child.parentId];
    if (mapItem) {
      (mapItem.children || (mapItem.children = [])).push(child);
    } else {
      treeData.push(child);
    }
  });
  return treeData;
}



// 替换所有字符串
/**
* 
* @param {String} f 需要替换的字符
* @param {String} e 替换后的字符
* @returns 
*/
// eslint-disable-next-line
String.prototype.replaceAll = function (f, e) { //吧f替换成e
  var reg = new RegExp(f, "g"); //创建正则RegExp对象   
  return this.replace(reg, e);
}

// 防抖
export const debounce = (func, delay) => {
  let timer = null;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
// 生成指定个数的随机数
export const getRandomNumberByCount = (count) => {
  const randomNumbers = [];
  for (let i = 0; i < count; i++) {
    randomNumbers.push(Math.floor(Math.random() * 100)); // 生成0到99之间的随机整数
  }
  return randomNumbers

}

