/**
 *
 * describe: ant表格表头生成器
 * author:MXC
 * Date:2022/6/9
 * 
 * */

/**
 *columns内配置字段
 *是否隐藏列  hideInColumn
 *是否开启搜索  issearch
 * */
import { useState } from 'react';
import {
    Button,
    Input,
} from 'antd';
// import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
} from '@ant-design/icons';

const HeaderBuilder = (columns) => {
    const [searchedColumn, _searchedColumn] = useState({}); //搜索
    const ColumnOld = columns || []; //接收的列描述数据对象
    const newColumn = [];  //生成完成后的
    const getColumnSearchProps = (title, dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder={`搜索 ${title}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <div className='disFlex jusConCenSB'>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            style={{ width: 90 }}
                        >
                            搜索
                        </Button>
                        <Button
                            onClick={() => {
                                handleReset(clearFilters, dataIndex);
                                confirm({ closeDropdown: false });
                            }}
                            style={{ width: 90 }}
                        >
                            重置
                        </Button>
                    </div>
                </div>
            );
        },
        filterIcon: (filtered) => {
            return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;
        },
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        searchedColumn[dataIndex] = selectedKeys[0]
        _searchedColumn(searchedColumn)
    };

    const handleReset = (clearFilters, dataIndex) => {
        clearFilters();
        searchedColumn[dataIndex] = ""
        _searchedColumn(searchedColumn)
    };
    ColumnOld.forEach((item) => {
        if (item.hideInColumn !== true) {
            if (item.issearch) {
                item = {
                    ...item,
                    ...getColumnSearchProps(item.title, item.dataIndex),
                };
            }
            newColumn.push(item);
        }
    });
    return newColumn;
}
export default HeaderBuilder;

