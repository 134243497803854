// 封装通用的ajax函数
// 引入axios和qs
import axios from 'axios'
import Qs from 'qs'
import { message } from 'antd'
import { startPath, basicPath } from '@/router/config'

axios.defaults.baseURL = '/'

// API请求异常统一报错提示
export function API_FAILED (msg) {
  message.warning(msg || '当前网络有所波动，请稍后重试', 1.5);
}

// 添加请求拦截器，在请求头中加token,Cookie
axios.interceptors.request.use(
  (config) => {
    // if (local.get('cruise_m30_sfh')) {
    //   // this.$router.push('/home');
    // }
    // if (localStorage.getItem('Authorization')) {
    //   config.headers.Authorization = localStorage.getItem('Authorization');
    // }
    // if (localStorage.getItem('Cookies')) {
    //   config.headers.Cookies = localStorage.getItem('Cookies');
    // }
    return config;
  }, error => {
    return Promise.reject(error);
  });

// 这里拦截错误
axios.interceptors.response.use(
  response => {
    if (response.data && !response.data.ret) {
      API_FAILED(null);
      return
    }
    else if (response.data && (response.data.ret === -1 || response.data.ret === 408 || response.data.ret === 406)) {
      API_FAILED(response.data.msg || null);
      localStorage.removeItem('cruise_m30_isLogin');
      setTimeout(() => {
        window.location.replace(basicPath + startPath);
      }, 1500)
    } else {
      return response
    }
  }, error => {
    return Promise.reject(error.response);
  }
);

// 通用的get请求 和 post请求
const req = {
  get (url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params,
        })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            return
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  post (url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, Qs.stringify(params))
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            return
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  file (url, params = {}, time = 300000) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: time })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            return
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //拼接字符串
  SplicString (list) {
    let text = '';
    for (let i in list) {
      if (list[i]) {
        text = text + '&' + i + '=' + list[i]
      }
    }
    return text
  }
}

// 暴露请求对象
export default req