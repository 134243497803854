//风险设置
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect } from 'react'
import { Input, Pagination, Drawer, Image, Space, Button, Form, Card, message, InputNumber, Tree, Switch, Spin, Empty } from 'antd';
import { ArrowLeftOutlined, QuestionCircleOutlined, SettingOutlined, PlusOutlined } from '@ant-design/icons'
import ImageCom from "@/components/ImageCom/index.js"//图片显示组件
import { getRiskSetListAPI, getRiskSwitchInfoFun, setRiskSwitchInfoFun, submitPictureApi } from '@/services/shipping_line'


function FixedPointRistSet (props) {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数
  const params = JSON.parse(localStorage.getItem('cruise_m30_fixed_point_child'))

  console.log('params', params)

  // 上传图片相关-------------------------
  const inputRef = useRef();
  const curIDRef = useRef();
  const curRIDRef = useRef();
  const currIdxRef = useRef();
  // 上传图片相关-------------------------


  // const {
  //   token: { colorText, colorPrimary },
  // } = theme.useToken(); //获取主题颜色
  // const { message } = App.useApp();
  const [similarityForm] = Form.useForm();//相似度
  const [form] = Form.useForm();//航点名称

  const token = localStorage.getItem('cruise_m30_token');
  const [loading, setLoading] = useState(false)//加载中···
  const [pictureData, setPictureData] = useState([]) //获取的数据

  //异常类型
  const [treeData, setTreeData] = useState([]);
  const [abnormalType, setAbnormalType] = useState([]);//异常框选列表
  const [expandedKeys, setExpandedKeys] = useState([]);//展开的父节点

  const [imgWidth, setImgWidth] = useState(0);//图片宽度

  const [similarityList, setSimilarityList] = useState({//相似度设置
    isOpen: false,
    title: '相似度',
    id: params.PID,//当前id
    similarity: '',//相似度
  })
  const [similarityLoading, setSimilarityLoading] = useState(false);//加载

  const [riskInfo, setRiskInfo] = useState({ //风险信息
    isOpen: false,
    id: '',
  });
  const [riskLoading, setRiskLoading] = useState(false);//加载

  const [searchList, setSearchList] = useState({//搜索列表
    check: 0,
    type: -1,
    page: 1,
    total: 0,
    perpage: 12,
    similarity: 0,
  })

  const [seeImgList, setSeeImgList] = useState({
    open: false,
    url: '',
  })
  const [visible, setVisible] = useState(false)

  const getListData = async (data) => {//获取图片列表
    setPictureData([])
    setLoading(true)
    let sea = searchList
    let obj = {
      access_token: token,
      id: params.PID,
      page: data.page,
      perpage: data.perpage,
    }

    let res = await getRiskSetListAPI(obj)
    if (res.ret !== 200) {
      message.warning(res.msg || '服务器开了小差，请重试');
      setLoading(false)
      return;
    }
    sea.total = res.data.list.total
    sea.similarity = res.data.threshold
    setSearchList({ ...sea })
    // 在数据中加入 isDeletion（是否缺失  字段）
    let arr = res.data?.list?.items.map((res) => {
      return { ...res, isDeletion: false }
    })
    setPictureData([...arr])
    let similarity = { ...similarityList };
    similarity.similarity = parseInt(res.data.threshold * 100);
    setSimilarityList(similarity);
    setLoading(false)
  }

  //获取风险开关信息
  const getRiskSwitchInfo = async (id) => {
    setRiskLoading(true);
    let res = await getRiskSwitchInfoFun({
      access_token: token,
      id: id
    });
    if (res.ret !== 200) {
      setRiskLoading(false);
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let choose = res.data.data.risk_cid ? res.data.data.risk_cid.split(',') : [];
    let expanded = []; //父级 展开
    let items = [];//树
    for (let i = 0; i < res.data.risk_cid.length; i++) {
      let val = res.data.risk_cid[i];
      let idx = expanded.indexOf('r-' + val.risk_id);
      if (idx < 0) { //不存在
        idx = expanded.length;
        expanded.push('r-' + val.risk_id);
        items.push({
          title: val.risk_name,
          key: 'r-' + val.risk_id,
          checkable: false,
          children: [
            {
              checkable: false,
              title: val.name, key: Number(val.risk_cid), parentKey: Number(val.risk_id),
              parentIdx: idx, idx: 0, checked: choose.indexOf(val.risk_cid.toString()) >= 0 ? true : false
            }
          ],
        })
      } else {//存在
        items[idx].children.push({
          checkable: false,
          title: val.name, key: Number(val.risk_cid), parentKey: Number(val.risk_id),
          parentIdx: idx, idx: items[idx].children.length, checked: choose.indexOf(val.risk_cid.toString()) >= 0 ? true : false
        });
      }
    }
    setTreeData(items);
    setAbnormalType(res.data);
    setExpandedKeys(expanded);
    setRiskLoading(false);
  }

  const submitSimilarityListForm = async (a, b) => { //提交相似度
    let info = { ...similarityList };
    let obj = {
      access_token: token,
      RID: info.id,
      threshold: (similarityList.similarity / 100).toFixed(2)
    }
    setSimilarityLoading(true);
    let res = await setRiskSwitchInfoFun(obj);
    setSimilarityLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    message.success('编辑成功');
    let sea = { ...searchList };
    sea.similarity = obj.threshold;
    setSearchList(sea);
    info.isOpen = false;
    setSimilarityList(info);
  }

  //获取图片父级宽度 以及 对应图片宽度
  const getImgFatherWidth = () => {
    setTimeout(() => {
      let dom = document.getElementById('imgFather');
      let width = dom.clientWidth;
      setImgWidth(Number((width * 0.24).toFixed(2)));
    }, 200);
  }

  //航点名称 提交
  const submit = async (e) => {
    let info = { ...riskInfo };
    let arr = [];
    for (let i = 0; i < treeData.length; i++) {
      for (let n = 0; n < treeData[i].children.length; n++) {
        let val = treeData[i].children[n]
        if (val.checked) {
          arr.push(val.key);
        }
      }
    }
    let obj = {
      access_token: token,
      id: info.id,
      risk_cid: arr.join(','),
      name: e.name
    };
    setRiskLoading(true);
    let res = await setRiskSwitchInfoFun(obj);
    setRiskLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }

    let pic = pictureData
    pic.find(res => res.id === info.id).name = e.name
    setPictureData([...pic])

    info.isOpen = false;
    info.id = '';
    setRiskInfo(info)
    message.success('编辑成功');

    // getListData(searchList);
  }

  //上传图片
  const submitPicture = async (file) => {
    let key = 'submitImg'
    message.loading({
      content: '上传中...',
      key,
    });
    let res = await submitPictureApi({
      access_token: token,
      id: curIDRef.current,
      RID: curRIDRef.current,
      file: file
    });
    inputRef.current.value = '';
    if (res.ret !== 200) {
      message.warning({
        content: res.msg || '数据跑丢了~',
        key,
      });
      return;
    }
    message.loading({
      content: '上传成功',
      key,
    });
    let pic = pictureData
    pic.find(res => res.id === curIDRef.current).pic = res.data || 'xxxxxxxxxxxxx'
    pic.find(res => res.id === curIDRef.current).handler_status = 0
    setPictureData([...pic])
  }

  useEffect(() => {
    getListData(searchList);
    getImgFatherWidth();
    window.addEventListener("keydown", onkeydown);
    window.addEventListener('resize', getImgFatherWidth);
    return () => {
      window.removeEventListener('keydown', onkeydown); // 销毁
      window.addEventListener('resize', getImgFatherWidth);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div id='main_div' className="disFlex flexDirCol posRel tablebackcol" style={{ height: '100%', overflow: 'auto', }}>
      {/* 头部 */}
      <div className="flex jcsb marTB16  marRL24 align_center">
        <div className='flex jcl align_center'>
          {/* 返回按钮 */}
          <ArrowLeftOutlined
            className='pointer'
            onClick={() => {
              navigate('/fixed_point/child', { state: 'set' })
            }}
          />
          {/* 航线名称 */}
          <span className='fSize20 fWei500 margR12 margL18'>{params.name}</span>
        </div>
        {/* 相似度 */}
        <div className='flex jcr  align_center'>
          <span className='marR8'>识别相似度：{searchList.similarity}</span>
          <SettingOutlined
            className='pointer'
            style={{ width: '16px', height: '16px', color: '#177DDC' }}
            onClick={() => {
              let sim = similarityList
              sim.isOpen = true
              setSimilarityList({ ...sim })
            }}
          />
        </div>
      </div>

      <Card
        className='marRL24'
        style={{ border: 0 }}
      >
        <Spin spinning={loading}>
          <div className={"flex flex_wrap " + (pictureData && pictureData.length > 0 ? '' : 'justify_center')} id='imgFather' style={{ flexDirection: 'row', maxHeight: '100%', overflowY: 'auto', alignContent: 'flex-start' }}>
            {
              pictureData && pictureData.length > 0
                ?
                pictureData.map((item, index) => {
                  return (
                    <div key={index} className="flex_col pos_rel"
                      style={{ width: '24%', height: 'auto', marginBottom: '24px', marginRight: '1%' }}>
                      {/* 图片 */}
                      {
                        item.pic && pictureData &&
                        <div
                          className={`${!item.isDeletion ? 'pointer' : ''}  radius8  `}
                          onClick={() => {
                            if (!item.isDeletion) {
                              let see = seeImgList
                              see.open = true
                              see.url = item.pic
                              setSeeImgList({ ...see })
                              setVisible(true)
                            }
                          }}
                        >
                          <ImageCom
                            alt='index'
                            height={Number((imgWidth * 3 / 4).toFixed(2))}
                            borderRadius={8}
                            src={item.pic}
                            errorBackgrounds='#000000'
                            deletion={(e) => {
                              let pic = pictureData
                              pic.find(res => res.id === item.id).isDeletion = e
                              setPictureData([...pic])
                            }}
                          />
                        </div>

                      }

                      <div className='marT4 flex_row justify_between align_center'>
                        {/* 航线名称 */}
                        <div>{`${item.order}#${item.name}`}</div>
                        <div>
                          <Space>
                            {
                              item.isDeletion &&
                              <PlusOutlined
                                className='pointer'
                                style={{ width: '16px', height: '16px', color: '#177DDC' }}
                                onClick={() => {
                                  curIDRef.current = item.id
                                  curRIDRef.current = item.RID
                                  currIdxRef.current = item.index
                                  inputRef.current.click();
                                }}
                              />
                            }

                            <SettingOutlined
                              className='pointer'
                              style={{ width: '16px', height: '16px', color: '#177DDC' }}
                              onClick={() => {
                                let info = { ...riskInfo };
                                info.isOpen = true;
                                info.id = item.id;
                                setRiskInfo(info);
                                form.setFieldsValue({
                                  name: item.name
                                });
                                getRiskSwitchInfo(item.id);
                              }}
                            />
                          </Space>

                        </div>
                      </div>
                    </div>
                  )
                })
                :
                <div>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            }
          </div>

          <div className='disFlex jusConEnd'>
            <Pagination
              total={searchList.total}
              current={searchList.page}
              pageSize={searchList.perpage}
              pageSizeOptions={['12', '24', '48', '96']}
              showTotal={(total) => `共 ${total} 条数据 `}
              onChange={(page, pageSize) => {
                let sea = searchList
                sea.page = page;
                sea.perpage = pageSize;
                setSearchList({ ...sea })
                getListData(sea);
              }}
            />
          </div>
        </Spin>


      </Card>


      {/* 相似度 */}
      <Drawer title="相似度" width={256} destroyOnClose={true} open={similarityList.isOpen}
        onClose={() => {
          let sim = similarityList
          sim.isOpen = false
          setSimilarityList({ ...sim })
        }}
        extra={
          <Space>
            <Button
              type="primary"
              loading={similarityLoading}
              onClick={submitSimilarityListForm}
            >
              提交
            </Button>
          </Space>
        }
      >

        <Form layout='vertical' form={similarityForm} >
          <Form.Item
            label={`识别-相似度`}
            tooltip={{
              title: 'AI识别风险时，相似度大于该值才会报警，范围30~100',
              icon: <QuestionCircleOutlined />,
              placement: 'left',
            }}
          >
            <InputNumber
              value={similarityList.similarity}
              addonAfter="%"
              placeholder='-'
              min='30'
              max='100'
              className='wid100'
              onChange={(e) => {
                let sim = similarityList
                if (e > 0) {
                  sim.similarity = e
                } else {
                  sim.similarity = null
                }
                setSimilarityList({ ...sim })
              }}
            />
          </Form.Item>
        </Form>

      </Drawer>

      {/* 航点名称 */}
      <Drawer title="航点名称" width={256} destroyOnClose={true} open={riskInfo.isOpen}
        afterOpenChange={() => {
          document.getElementsByClassName('ant-tree')[0].style.background = '#1F1F1F';
        }}
        onClose={() => {
          let info = { ...riskInfo };
          info.isOpen = false;
          info.id = '';
          setRiskInfo(info);
        }}
        extra={
          <Space>
            <Button
              type="primary"
              loading={riskLoading}
              onClick={() => {
                form.submit()
              }}
            >
              提交
            </Button>
          </Space>
        }
      >
        <div id='risk_set' className='flex_col'>
          <Form layout='vertical' form={form} onFinish={submit}>
            <Form.Item label='航点名称' name='name' rules={[
              {
                required: true,
                message: '航点名称不能为空',
              },
            ]} >
              <Input placeholder='-' />
            </Form.Item>
          </Form>

          <div className='flex_auto' style={{ overflow: 'auto', background: '#1f1f1f' }}>
            <Tree
              showLine={{ showLeafIcon: <div></div> }}
              blockNode
              expandedKeys={expandedKeys}
              // showIcon={true}
              autoExpandParent={true}
              defaultExpandAll='true'
              defaultExpandParent={true}
              onSelect={() => { }}
              onExpand={(keys) => {
                setExpandedKeys(keys);
              }}
              titleRender={(item) => {
                let key = item.key.toString();
                if (key.indexOf('r') >= 0) {// 父节点
                  return <span>{item.title}</span>
                }
                return <div className='flex_row jcsb align_center'>
                  <div className='flex_auto marR4' style={{ width: 0 }}>
                    <div className='text_omitted1 col_error_text'>{item.title}</div>
                  </div>
                  <div className='flex_shrink0'>
                    <Switch size="small" checked={item.checked} onChange={(val) => {
                      let arr = [...treeData];
                      arr[item.parentIdx].children[item.idx].checked = val;
                      setTreeData(arr);
                    }} />
                  </div>
                </div>
              }}
              treeData={treeData}
            />
          </div>
        </div>

      </Drawer>



      <>{/* 上传 缺失的图片 */}
        <div style={{ display: 'none' }}>
          <input ref={inputRef} type='file' accept="image/png, image/jpeg" onChange={(e) => {

            let file = e.target.files[0];
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
              inputRef.current.value = '';
              return message.error('只能上传jpeg/png格式的图片');
            }
            if (file.size > 50 * 1024 * 1024) {
              inputRef.current.value = '';
              return message.error('图片不能超过50M');
            }
            submitPicture(file);
          }} />
        </div>

      </>


      <>{/* 图片查看 */}
        <Image
          width='100%'
          style={{
            display: 'none',
          }}
          src={seeImgList.url}
          preview={{
            visible,
            src: seeImgList.url,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      </>

    </div>
  )
}
export default FixedPointRistSet;