import React, { useState, useEffect, useRef } from 'react'
import { Modal, Spin, Tabs, Pagination, Image, Tag, Button, message, Checkbox, Drawer, Skeleton, Switch } from 'antd';
import { ArrowLeftOutlined, EnvironmentFilled, UserSwitchOutlined, PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { GetPointList, GetPointListTab, GetPointInfo } from "@/services/leakage_risk.js"; // 引入Ajax
import './regular_inspections_record_picture.less'
import local from '@/utils/local'
import hsbg from '@/common/img/hsbg.svg'
import loading_error from '@/common/img/loading_error.svg'
import { basicPath } from '@/router/config'
import { compareImages, cilckImg, submitPictureFun } from '@/services/regular_inspections_record_picture.js'
import { debounce, getRandomNumberByCount } from '@/common/js/common.js'

const { TabPane } = Tabs;

function RegularInspectionsRecordPicture(props) {
  const navigate = useNavigate()
  const Stateparams = useLocation();
  const params = Stateparams.state;
  if (!params) {
    window.location.replace(`${basicPath}/PatrolResult`)
  }
  const transfer = params.transfer;
  const type = params.type
  const limitsAuthority = useRef()//权限列表
  const limitsAuthorityName = useRef()//权限列表name
  /**
   * data
   * */
  const token = local.get('cruise_m30_token') || ''
  const [spinningLoading, _spinningLoading] = useState(false)
  const [pictureData, setPictureData] = useState([]) //获取的数据
  const [pictureInfo, setPictureInfo] = useState([]);//图片信息
  const [total, _total] = useState(0);
  const [searchCriteria, _searchCriteria] = useState({
    check: 0,
    page: 1,
    perpage: 12,
  });
  const searchCriteriaRef = useRef({
    check: 0,
    page: 1,
    perpage: 12,
  });
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [titleData, _titleData] = useState([])

  const [idx, SetIdx] = useState(-1);
  const time1 = useRef()
  const visible = useRef(false)//是否显示大图
  const [visibleImg, setVisibleImg] = useState(false)//是否显示大图
  const seeOriginalImage = useRef(false)//查看原图

  const curImgPointId = useRef()//
  const currPointIdRef = useRef();//
  const currInsIDRef = useRef();
  const currIdxRef = useRef();
  const inputRef = useRef();

  const [imgLoading, setImgLoading] = useState(false)//图片加载

  const randomNumber = useRef(transfer.pic_count)//随机数

  const [contrastData, setContrastData] = useState({
    isOpen: false,
    name: '',
    img1: '',
    img2: '',
    time1: '',
    time2: '',
  })//对比图片

  const dq_data = useRef([]); //当前图片数据

  const currIdx = useRef(-1);//查看图片 当前下标
  const pictureTotal = useRef(0);//查看图片 总数
  const totalRef = useRef(0);//总数
  const [showPictureData, setShowPictureData] = useState([]);//查看图片 图片信息
  const pictureDataRef = useRef([]);//表格数据
  const [loading, setLoading] = useState(false);//
  const [pageLoading, setPageLoading] = useState(false);
  const totalPageRef = useRef(1);//总页数
  const newPAGE = useRef(1)//当前页数
  const newType = useRef(0)//当前tab类型

  const [adviceDrawer, setAdviceDrawer] = useState({ //处理意见弹窗
    isOpen: false,//是否打开
    address: '',//地址
    pointOrder: '',//序号
    date: '',//日期
    divrisk: [],//风险
    userName: [],//处理人员
    advice: [],//处理意见

  });
  const [showImgInfo, setShowImgInfo] = useState({ //显示处理意见图片信息
    visible: false,
    current: 1,
    url1: '',
    url2: '',
    url3: '',
  });

  useEffect(() => {
    limitsAuthority.current = JSON.parse(local.get('cruise_m30_permissionsArr'));
    limitsAuthorityName.current = JSON.parse(local.get('cruise_m30_permissionsArrName'))
    // _spinningLoading(true);
    GetPointListTabFun((val) => {
      GetPointListFun(val, searchCriteria);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //自动刷新改变执行
  const refreshOnChange = (e) => {
    if (e) {
      time1.current = setInterval(() => {
        GetPointListTabFun((val) => {
          GetPointListFun(undefined, searchCriteriaRef.current, 999);
        });
      }, 3000);
    } else {
      clearInterval(time1.current);
    }
  }

  //获取图片tab列表
  const GetPointListTabFun = async (c) => {
    let res = await GetPointListTab({
      access_token: token,
      companyId: transfer.companyId,
      statid: transfer.statid,
      insIds: transfer.insIds,
    });
    if (res.ret === 200) {
      const data = []
      if (res.data.length > 0) {
        res.data.forEach(item => {
          item.is_point = false;
          if (Number(item.total) >= 0) { data.push(item) }
        })
      }
      _titleData(data || []);
      return c(data[0].value);
    } else {
      message.warning(res.msg || '系统出错了，请稍后再试！')
    }
  }

  //获取图片列表
  const GetPointListFun = async (value, search, next = 0) => {
    if (next === 0) {
      setPageLoading(true);
    }
    setLoading(true);
    setPictureInfo([]);
    let params = {
      access_token: token,
      companyId: transfer.companyId,
      statid: transfer.statid,
      insIds: transfer.insIds,
      point_status: value || search.check,
      page: search.page,
      perpage: search.perpage,
    }
    let res = await GetPointList(params);
    console.log('数据', res);
    setLoading(false);
    _spinningLoading(false);
    let sea = { ...searchCriteria };
    sea.page = search.page;
    sea.perpage = search.perpage;
    sea.check = params.point_status;
    _searchCriteria(sea);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    totalPageRef.current = Math.ceil(Number(res.data.total) / Number(search.perpage));//总页数

    let pic = []; //显示的图片
    let list = []; //表格数据
    let list2 = []; //已经处理好的数据
    let idx = 0;
    for (let i = 0; i < res.data.items.length; i++) {
      let val = res.data.items[i];
      if (Number(val.handler_status) !== 0) {
        list.push({
          ...val, key: i, idx: idx,
          divrisk: [], userName: [], adviceDiv: [], adviceState: null
        });
        list2.push({ ...val, key: i, idx: idx });
        pic.push({
          original_picUrl: val.original_picUrl,
          picUrl: val.picUrl
        });
        idx += 1;
      } else {
        list.push({
          ...val, key: i, idx: -1,
          divrisk: [], userName: [], adviceDiv: [], adviceState: null
        });
      }
    }
    setShowPictureData(pic);
    if (newPAGE.current === res.data.page && (value || search.check) === Number(res.data.point_status)) {
      setPictureData([...list])
      setPageLoading(false)
      _total(res.data.total || 0);
    }
    totalRef.current = list.length;
    pictureDataRef.current = [...list2];
    dq_data.current = res.data.items;

    if (next === 1) {
      currIdx.current = 0;
      pictureTotal.current = list2.length;

      let dom = document.getElementsByClassName('ant-image-preview-operations')[document.getElementsByClassName('ant-image-preview-operations').length - 1]
      if (document.getElementById('point_info')) {
        dom.removeChild(document.getElementById('point_info'));
      }
      let dataInfo = list[0];
      let newDom = document.createElement('div');
      newDom.id = 'point_info';
      newDom.innerHTML = dataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;' + dataInfo.addtime || '';
      newDom.style = 'text-align:left;top:0;left:0';
      newDom.onclick = debounce(async () => {
        let idx = undefined
        let res = await cilckImg({
          access_token: token,
          pointId: curImgPointId.current,
        })
        if (res.ret === 200) {
          pictureData.forEach((res, index) => {
            if (res.pointId === curImgPointId.current) { idx = index }
          })
          pictureData[idx].handler_status = 0;
        }
      }, 800);
      newDom.className = 'fs20 pointer pos_abs h46 flex align_center';
      dom.appendChild(newDom);
    }

    if (next === 2) {
      currIdx.current = list2.length - 1;
      pictureTotal.current = list2.length;
      let dom = document.getElementsByClassName('ant-image-preview-operations')[document.getElementsByClassName('ant-image-preview-operations').length - 1]
      if (document.getElementById('point_info')) {
        dom.removeChild(document.getElementById('point_info'));
      }
      let dataInfo = list2[list2.length - 1];
      let newDom = document.createElement('div');
      newDom.id = 'point_info';
      newDom.innerHTML = dataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;' + dataInfo.addtime || '';
      newDom.style = 'text-align:left;top:0;left:0';
      newDom.onclick = debounce(async () => {
        let idx = undefined
        let res = await cilckImg({
          access_token: token,
          pointId: curImgPointId.current,
        })
        if (res.ret === 200) {
          pictureData.forEach((res, index) => {
            if (res.pointId === curImgPointId.current) { idx = index }
          })
          pictureData[idx].handler_status = 0;
        }
      }, 800);
      newDom.className = 'fs20 pointer pos_abs h46 flex align_center';
      dom.appendChild(newDom);
    }
  }

  //图片查看页
  const onPatrolMap = (item) => {
    const coordinate = {
      picUrl: item.picUrl,
      longitude: item.longitude, //经度
      latitude: item.latitude, //纬度
    }
    // return;
    window.sessionStorage.setItem('viewpictureid=vp' + item.key, JSON.stringify(transfer))
    window.sessionStorage.setItem('patrol_map?id=vp' + item.key, JSON.stringify(coordinate))
    window.open(basicPath + '/patrol_map?id=vp' + item.key, '_blank')
  }

  //获取图片对比数据
  const getCompareImages = async (pid) => {
    let con = contrastData
    let res = await compareImages({ access_token: token, pointId: pid })
    if (res.ret === 200) {
      if (res.data && res.data.oldPic && res.data.newPic) {
        con.img1 = res.data.oldPic
        con.img2 = res.data.newPic
        con.time1 = res.data.oldtime
        con.time2 = res.data.newtime
        con.isOpen = true
        setContrastData({ ...con })
      } else {
        message.warning('暂无对比图片！')
      }
    } else {
      message.warning(res.msg || '系统错误~')
    }
  }

  //获取节点处理详细信息
  const GetPointInfoFun = async (val1, val2, val3, idx) => {
    let paramdata = {
      access_token: token,
      companyId: val1,
      statid: val2,
      pointId: val3,
    }
    let res = await GetPointInfo(paramdata);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let val = res.data;

    let info = {
      divrisk: [],//风险
      userName: [],//处理人名称
      advice: false,//处理意见是否显示 查看详情
      adviceList: [],//处理意见
    }
    let arr = val.abnormal_type_str ? val.abnormal_type_str.split(",") : [];
    let divrisk = arr.map((item, i) => {
      return <div key={i}>{item}</div>
    });
    info.divrisk = divrisk.length > 0 ? divrisk : <div className='opa25'>-</div>;
    info.userName = val.handler_user_name ? val.handler_user_name.split(",") : [];;

    if (val.list.length > 0) {
      info.advice = true;
      info.adviceList = val.list.map((item, i) => {
        let imgs = item.detail_pic ? item.detail_pic : [];
        let detailList = item.detail ? item.detail.split('\n') : [];
        return (
          <div className='margB12' key={i}>
            <div className='flex-row'>
              <Tag style={{
                background: "#262626", borderRadius: 3,
                display: (item.status === 1) ? 'block' : 'none'
              }} className='marB4'>处理完毕</Tag>
            </div>
            <div className='flex_row' style={{ display: imgs.length > 0 ? '' : 'none' }}>
              <img alt='处理拍照1' src={imgs[0] ? imgs[0] : ''} className='w64 h64 marR8 pointer radius8' style={{ objectFit: 'cover', display: imgs[0] ? '' : 'none' }}
                onClick={() => { showAdviceImg(0, item) }}>
              </img>
              <img alt='处理拍照2' src={imgs[1] ? imgs[1] : ''} className='w64 h64 marR8 pointer radius8' style={{ objectFit: 'cover', display: imgs[1] ? '' : 'none' }}
                onClick={() => { showAdviceImg(1, item) }}>
              </img>
              <img alt='处理拍照3' src={imgs[2] ? imgs[2] : ''} className='w64 h64  pointer radius8' style={{ objectFit: 'cover', display: imgs[2] ? '' : 'none' }}
                onClick={() => { showAdviceImg(2, item) }}>
              </img>
            </div>
            {
              detailList.map((detailItem, detailIndex) => {
                return <div key={detailIndex} style={{ wordBreak: 'break-word' }}>{detailItem}</div>
              })
            }
            <div className='col_secondary_text'>{item.addtime + ' ' + item.realname}</div>
          </div>
        )
      })
    } else {
      if (val.handler_status === 3) {
        info.adviceList = <div>忽略</div>;
      } else {
        info.adviceList = <div className='margB12 opa25'>-</div>;
      }
    }
    let pic = [...pictureInfo];
    pic[idx] = true;
    setPictureInfo(pic);
    let picData = [...pictureData];
    picData[idx].divrisk = info.divrisk;
    picData[idx].userName = info.userName;
    picData[idx].adviceDiv = info.adviceList;
    picData[idx].adviceState = info.advice;
    setPictureData(picData);
  }

  //处理显示的图片
  const hangdleShowImg = (data_idx) => {
    let pic = []; //显示的图片
    let list = []; //已经处理好的数据
    let idx = 0;
    let data = [...pictureData]
    for (let i = 0; i < data.length; i++) {
      let val = data[i];
      val.idx = idx;
      if (Number(val.handler_status) !== 0) {
        list.push({ ...val, key: i, idx: idx });
        pic.push({
          original_picUrl: val.original_picUrl,
          picUrl: val.picUrl
        });
        idx += 1;
      }
    }
    setPictureData(data);
    setShowPictureData(pic);
    pictureDataRef.current = [...list];
    currIdx.current = data_idx;
    pictureTotal.current = pic.length;
  }

  // 操作dom来获取查看大图中的信息、以及上下翻页
  const showImg = () => {
    // 图片标题
    let dom = document.getElementsByClassName('ant-image-preview-operations')[document.getElementsByClassName('ant-image-preview-operations').length - 1]
    if (document.getElementById('point_info')) {
      dom.removeChild(document.getElementById('point_info'));
    }
    let dataInfo = pictureDataRef.current[currIdx.current];
    let newDom = document.createElement('div');
    newDom.id = 'point_info';
    newDom.innerHTML = dataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;' + dataInfo.addtime || '';
    newDom.style = 'text-align:left;top:0;left:0';
    newDom.onclick = debounce(async () => {
      let idx = undefined
      let res = await cilckImg({
        access_token: token,
        pointId: curImgPointId.current,
      })
      if (res.ret === 200) {
        pictureData.forEach((res, index) => {
          if (res.pointId === curImgPointId.current) { idx = index }
        })
        pictureData[idx].handler_status = 0;
      }
    }, 800);
    newDom.className = 'fs20 pointer pos_abs h46 flex align_center';
    dom.appendChild(newDom);
    if (pictureTotal.current > 1) {
      // 下一页
      let rightPhoto = document.getElementsByClassName('ant-image-preview-switch-right')[0];
      let leftPhoto = document.getElementsByClassName('ant-image-preview-switch-left')[0];
      if (currIdx.current === pictureTotal.current - 1 && (totalRef.current < searchCriteriaRef.current.perpage || totalPageRef.current === searchCriteriaRef.current.page)) {
        rightPhoto.style.color = 'rgba(255,255,255,0.2)';
        rightPhoto.style.cursor = 'not-allowed';
      } else {
        rightPhoto.style.color = 'white';
        rightPhoto.style.cursor = 'pointer';
      }

      if (currIdx.current === 0 && searchCriteriaRef.current.page === 1) {
        leftPhoto.style.color = 'rgba(255,255,255,0.2)';
        leftPhoto.style.cursor = 'not-allowed';
      } else {
        leftPhoto.style.color = 'white';
        leftPhoto.style.cursor = 'pointer';
      }

      let rightDiv = document.createElement('div')
      rightDiv.id = 'rightDiv'
      rightDiv.onclick = () => {
        if (loading) { return; }
        if (currIdx.current === pictureTotal.current - 1 && (totalRef.current < searchCriteriaRef.current.perpage || totalPageRef.current === searchCriteriaRef.current.page)) {
          return;
        }
        if (document.getElementById('point_info')) {
          dom.removeChild(document.getElementById('point_info'));
        }
        currIdx.current = currIdx.current + 1;
        if (pictureTotal.current - 1 >= currIdx.current) {
          let newDataInfo = pictureDataRef.current[currIdx.current];
          newDom.innerHTML = newDataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;' + newDataInfo.addtime || '';
          dom.appendChild(newDom);
          curImgPointId.current = newDataInfo.pointId;
        }

        let sea = { ...searchCriteriaRef.current };
        if (currIdx.current === pictureTotal.current) {
          sea.page = sea.page + 1;
          searchCriteriaRef.current = sea;
          newPAGE.current = sea.page
          GetPointListFun('', sea, 1);

        }
        if (currIdx.current === pictureTotal.current - 1 && (totalRef.current < searchCriteriaRef.current.perpage || totalPageRef.current === searchCriteriaRef.current.page)) {
          rightPhoto.style.color = 'rgba(255,255,255,0.2)';
          rightPhoto.style.cursor = 'not-allowed';
        } else {
          rightPhoto.style.color = 'white';
          rightPhoto.style.cursor = 'pointer';

          leftPhoto.style.color = 'white';
          leftPhoto.style.cursor = 'pointer';
        }

      }
      rightDiv.className = 'pos_abs wid100 hei100 radius50'
      rightPhoto.appendChild(rightDiv);

      // 上一页
      let leftDiv = document.createElement('div')
      leftDiv.id = 'leftDiv'
      leftDiv.onclick = () => {
        if (loading) { return; }
        if (currIdx.current === 0 && searchCriteriaRef.current.page === 1) {
          return;
        }
        if (document.getElementById('point_info')) {
          dom.removeChild(document.getElementById('point_info'));
        }
        currIdx.current = currIdx.current - 1;
        let sea = { ...searchCriteriaRef.current };
        if (pictureTotal.current > currIdx.current && currIdx.current >= 0) {
          let newDataInfo = pictureDataRef.current[currIdx.current];
          newDom.innerHTML = newDataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;' + newDataInfo.addtime || '';
          dom.appendChild(newDom);
          curImgPointId.current = newDataInfo.pointId;
        }


        if (currIdx.current === -1) {
          if (sea.page > 1) {
            sea.page = sea.page - 1;
            searchCriteriaRef.current = sea;
            newPAGE.current = sea.page
            GetPointListFun('', sea, 2);
          }
        }

        if (currIdx.current === 0 && sea.page === 1) {
          leftPhoto.style.color = 'rgba(255,255,255,0.2)';
          leftPhoto.style.cursor = 'not-allowed';
        } else {
          leftPhoto.style.color = 'white';
          leftPhoto.style.cursor = 'pointer';

          rightPhoto.style.color = 'white';
          rightPhoto.style.cursor = 'pointer';
        }
      }
      leftDiv.className = 'pos_abs wid100 hei100 radius50'
      leftPhoto.appendChild(leftDiv)
    }

  }

  //显示处理意见图片
  const showAdviceImg = (cur, data = '') => {
    let info = { ...showImgInfo };
    info.visible = true;
    if (data) { //多个图片
      info.current = cur;
      let imgs = data.detail_pic ? data.detail_pic : [];
      info.url1 = imgs[0] ? imgs[0] : '';
      info.url2 = imgs[1] ? imgs[1] : '';
      info.url3 = imgs[2] ? imgs[2] : '';

    } else { //单个图片
      info.url1 = cur;
      info.url2 = '';
      info.url3 = '';
      info.current = 0;
    }
    setShowImgInfo(info);
  }

  // 监听键盘事件来展示图片上下页
  const onkeydown = (e) => {
    if (e.code === 'ArrowLeft' && visible.current) {
      document.getElementById('leftDiv').click()
    }
    if (e.code === 'ArrowRight' && visible.current) {
      document.getElementById('rightDiv').click()
    }
  }

  //上传图片
  const submitPicture = async (file) => {
    let key = 'submitImg'
    message.loading({
      content: '上传中...',
      key,
    });
    let res = await submitPictureFun({
      access_token: token,
      insID: currInsIDRef.current,
      pointID: currPointIdRef.current,
      file: file
    });
    inputRef.current.value = '';
    if (res.ret !== 200) {
      message.warning({
        content: res.msg || '数据跑丢了~',
        key,
      });
      return;
    }
    message.loading({
      content: '上传成功',
      key,
    });
    let list = [...pictureData];
    list[currIdxRef.current].picUrl = res.data.picUrl || 'xxxxxxxxxxxxx';
    list[currIdxRef.current].handler_status = 0;
    setPictureData(list);
  }

  useEffect(() => {
    window.addEventListener("keydown", onkeydown);
    return () => {
      clearInterval(time1.current);
      window.removeEventListener('keydown', onkeydown); // 销毁
    }
    // eslint-disable-next-line
  }, [])


  // 分页
  const onPagination = (page, pageSize) => {
    clearInterval(time1.current);
    newPAGE.current = page
    const search = searchCriteria;
    search.page = page;
    search.perpage = pageSize;
    searchCriteriaRef.current = { ...search };
    GetPointListFun('', search);
    refreshOnChange(autoRefresh);
    _spinningLoading(true);

    if ((total / pageSize) < page) {
      randomNumber.current = (total % pageSize)
    } else {
      randomNumber.current = pageSize
    }

  }

  return (
    <div id='main_div' className="disFlex flexDirCol posRel tablebackcol" style={{ height: '100%', overflow: 'auto' }}>
      <div className="flex jcsb">
        <div className='flex-row aliItemsCen paddL24' style={{ width: '100% ', padding: '16px 24px' }}>
          <span onClick={() => {
            // window.history.back()
            if (transfer.search) {
              switch (transfer.search.urlType) {
                case 1:
                  navigate('/conduit_inspections_record', { state: { list: transfer.search } })
                  break;
                case 7:
                  navigate('/powerGrid_inspections_record', { state: { list: transfer.search } })
                  break;
                default:
                  break;
              }
            } else {
              navigate(-1)
            }
          }} className='curPointer'><ArrowLeftOutlined /> </span>
          <span className='fSize20 fWei500 margR12 margL18'>{transfer.pTitle}{transfer.title ? ' - ' + transfer.title : ''}</span>
          <span className='fSize14 fWei400 opacity0_45'>巡检时间：{transfer.start_time} ~ <span>{transfer.end_time !== '' ? transfer.end_time : '未结束'}</span></span>
        </div>
        <div
          className=' marTB16  marR24 align_center flex'
          style={{ display: limitsAuthorityName.current?.indexOf(`Auto_Refresh_${transfer.search.urlType}`) > -1 ? `` : `none` }}>
          <Checkbox
            checked={autoRefresh}
            className='w110'
            onChange={(e) => {
              setAutoRefresh(e.target.checked)
              refreshOnChange(e.target.checked)
            }}
          >
            自动刷新/3s
          </Checkbox>

        </div>
      </div>
      <div className="padd24 " style={{ flex: 'auto', padding: '0 24px 24px 24px', }}>
        <div className="disFlex flexDirCol hei100 padd24 backcol">
          <Tabs
            defaultActiveKey={searchCriteria.check}
            onTabClick={(key) => {
              newType.current = titleData[Number(key)].value
            }}
          >
            {
              titleData.map((item, index) => {
                return <TabPane
                  tab={
                    <div
                      className='disFlex aliItemsCen'
                      key={index}
                      onClick={() => {
                        _spinningLoading(true)
                        const search = searchCriteria;
                        search.page = 1;
                        search.check = item.value;
                        searchCriteriaRef.current = { ...search };
                        newPAGE.current = 1
                        GetPointListFun('', search);
                        randomNumber.current = item.total
                        if ((item.total / search.perpage) < search.page) {
                          randomNumber.current = (item.total % search.perpage)
                        } else {
                          randomNumber.current = search.perpage
                        }
                        _total(item.total)
                      }}>
                      <div className='margR4'>
                        {item.label}
                      </div>
                      <div className='TabPane' style={{
                        // backgroundColor: (searchCriteria.check === item.value) ? '#E6F7FF' : '#F0F0F0',
                      }}>
                        {item.total}
                      </div>
                    </div>
                  }
                  key={index}
                >
                </TabPane>
              })
            }

          </Tabs>

          {/* <Spin spinning={pageLoading} className=' pos_abs' style={{ height: 'calc(100vh - 334px)' }}> */}
          {/* 图片集合 */}
          <div >
            {
              !spinningLoading && !pageLoading && !imgLoading
                ? (
                  <div className="disFlex flexDirCol"
                    style={{ flex: 'auto', paddingLeft: '24px', }}
                  >
                    <div className="disFlex flex_wrap  "
                      style={{ flexDirection: 'row', maxHeight: '100%', overflowY: 'auto', alignContent: 'flex-start' }}>
                      {pictureData.length > 0
                        ? pictureData.map((item, item_idx) => {
                          return (
                            item.handler_status === 0
                              ? !item.compress_pic
                                ?
                                //没有图片
                                <div key={item_idx} className="disFlex flexDirCol"
                                  style={{ width: '24%', height: 'auto', marginBottom: '24px', marginRight: '1%', }}>
                                  <div className='pos_rel radius8' style={{ backgroundColor: '#000' }}>
                                    <img className='radius8' style={{ width: '100%' }} src={hsbg} alt="人工复核中..." />
                                    {/* 覆盖图层  显示想要显示的文字 */}
                                    <div
                                      className='pos_abs wid100 hei100 flex_col jcc align_center radius8 pointer'
                                      style={{ backgroundColor: '#000', top: 0, left: 0 }} onClick={() => {
                                        if (limitsAuthority.current.indexOf(129) < 0) {
                                          return;
                                        }
                                        currPointIdRef.current = item.pointId;
                                        currInsIDRef.current = item.insIds;
                                        currIdxRef.current = item.key;
                                        inputRef.current.click();
                                      }}>
                                      <img width={40} height={40} src={loading_error} alt='加载失败' />
                                      <div className='marT4 fs12 fw400'>加载失败</div>
                                    </div>
                                  </div>
                                  <div className="flex_row justify_between align_center marT8"
                                    style={{ flexDirection: 'row', flex: 'auto', }}>
                                    <div className='coLF0_85' style={{ fontSize: '14px', fontWeight: '600' }}>
                                      {/* {(item.point_order < 10) ? '0' + item.point_order : item.point_order} */}
                                      {item.point_order}
                                    </div>

                                    <Button size='small' className='marR8' style={{ display: limitsAuthorityName.current?.indexOf(`Upload_Pic_${transfer.search.urlType}`) > -1 ? '' : 'none' }}
                                      onClick={() => {
                                        currPointIdRef.current = item.pointId;
                                        currInsIDRef.current = item.insIds;
                                        currIdxRef.current = item.key;
                                        inputRef.current.click();
                                      }}
                                    >
                                      上传
                                    </Button>
                                  </div>
                                </div>
                                :
                                //有图片但是在异常排查里面
                                <div key={item_idx} className="disFlex flexDirCol"
                                  style={{ width: '24%', height: 'auto', marginBottom: '24px', marginRight: '1%', }}>
                                  <div className='pos_rel radius8' style={{ backgroundColor: '#000' }}>
                                    <img className='radius8' style={{ width: '100%' }} src={hsbg} alt="人工复核中..." />
                                    {/* 覆盖图层  显示想要显示的文字 */}
                                    <div
                                      className=' pos_abs wid100 hei100 flex jcc align_center radius8'
                                      style={{ backgroundColor: '#000', top: 0, left: 0 }}>
                                      · · · · · ·
                                    </div>
                                  </div>
                                  <div className="flex_row justify_between align_center marT8"
                                    style={{ flexDirection: 'row', flex: 'auto', }}>
                                    <div className='coLF0_85' style={{ fontSize: '14px', fontWeight: '600' }}>
                                      {/* {(item.point_order < 10) ? '0' + item.point_order : item.point_order} */}
                                      {item.point_order}
                                    </div>
                                  </div>
                                </div>
                              :
                              <div key={item_idx}
                                className="flex_col"
                                style={{ width: '24%', height: 'auto', marginBottom: '24px', marginRight: '1%' }}>
                                <div className='pos_rel'
                                  onMouseLeave={() => SetIdx(-1)}
                                  onMouseEnter={() => {
                                    if (!pictureInfo[item_idx]) {
                                      GetPointInfoFun(item.CompanyID, item.statid, item.pointId, item_idx);
                                    }
                                    SetIdx(item_idx);
                                  }}
                                >
                                  {
                                    imgLoading
                                      ? (
                                        <>
                                          <img className='radius8' style={{ width: '100%' }} src={hsbg} alt="人工复核中..." />
                                          <div
                                            className='pos_abs wid100 hei100 flex jcc align_center radius8 pointer'
                                            style={{ backgroundColor: '#000', top: 0, left: 0 }} onClick={() => {
                                              if (limitsAuthority.current.indexOf(129) < 0) {
                                                return;
                                              }
                                              currPointIdRef.current = item.pointId;
                                              currInsIDRef.current = item.insIds;
                                              currIdxRef.current = item.key;
                                              inputRef.current.click();
                                            }}>
                                            <Spin spinning={imgLoading}></Spin>
                                          </div>
                                        </>
                                      )
                                      : (
                                        <>
                                          <img
                                            className='radius8'
                                            alt='index'
                                            width='100%'
                                            src={item.compress_pic || item.picUrl}
                                            onClick={() => {
                                              visible.current = true;
                                              setVisibleImg(true);
                                              curImgPointId.current = item.pointId;
                                              SetIdx(-1);

                                              hangdleShowImg(item.idx);//处理显示图片
                                              setTimeout(() => {
                                                showImg()
                                              }, 300)
                                            }}
                                          />
                                          <div className='pos_abs radius8 wid100 hei100 paddRL24 paddTB16'
                                            style={{ background: 'rgba(0, 0, 0, 0.45)', top: 0, left: 0, overflowY: 'auto', display: item_idx === idx ? '' : 'none' }}
                                            onClick={() => {
                                              visible.current = true;
                                              setVisibleImg(true);
                                              curImgPointId.current = item.pointId;
                                              SetIdx(-1);

                                              hangdleShowImg(item.idx);//处理显示图片
                                              setTimeout(() => {
                                                showImg()
                                              }, 300)
                                            }}
                                          >
                                            <div className='fSize14 fWei400 margB8 flex-row'>
                                              <div className='flex_shrink0'>
                                                位置：
                                              </div>
                                              <div>
                                                {item.location}
                                              </div>
                                            </div>
                                            <div className='fSize14 fWei400 margB8 flex-row'>
                                              <div className='flex_shrink0'>
                                                时间：
                                              </div>
                                              <div className=''>
                                                {item.addtime}
                                              </div>
                                            </div>
                                            <div className='fSize14 fWei400 margB8 flex-row' style={{ display: item.abnormal_type === '1' ? 'none' : '' }}>
                                              <div className='flex_shrink0'>
                                                处理：
                                              </div>
                                              <>
                                                {
                                                  item.adviceState === null ? <div></div>
                                                    : item.adviceState ?
                                                      <div style={{ color: '#69c0ff' }} className='pointer' onClick={(e) => {
                                                        e.stopPropagation();
                                                        let info = { ...adviceDrawer };
                                                        info.isOpen = true;
                                                        info.address = item.location;
                                                        info.pointOrder = item.point_order;
                                                        info.date = item.addtime;
                                                        info.divrisk = item.divrisk;
                                                        info.userName = item.userName;
                                                        info.advice = item.adviceDiv;
                                                        setAdviceDrawer(info);
                                                      }}>
                                                        详情 </div>
                                                      : <div className='opa25'>-</div>
                                                }
                                              </>
                                            </div>
                                          </div>
                                        </>

                                      )

                                  }



                                </div>

                                <div className="disFlex jusConCenSB marT8"
                                  style={{ flexDirection: 'row', flex: 'auto', }}>
                                  {/* 下标 */}
                                  <div className='coLF0_85' style={{ fontSize: '14px', fontWeight: '600' }}>
                                    {
                                      type === 6 && searchCriteria.check === 7
                                        ? item.addtime
                                        // : (item.point_order < 10) ? '0' + item.point_order : item.point_order
                                        : (
                                          <div className='coLF0_85' style={{ fontSize: '14px', fontWeight: '600' }}>
                                            {item.point_order}
                                          </div>
                                        )
                                    }
                                  </div>
                                  <div className='flex'>
                                    <Button size='small' className='marR8' style={{ display: limitsAuthority.current.indexOf(129) >= 0 ? '' : 'none' }}
                                      onClick={() => {
                                        currPointIdRef.current = item.pointId;
                                        currInsIDRef.current = item.insIds;
                                        currIdxRef.current = item.key;
                                        inputRef.current.click();
                                      }}
                                    >
                                      上传
                                    </Button>
                                    <Button
                                      style={{ display: item.is_contrast === 1 ? '' : 'none' }}
                                      size='small'
                                      className='marR8'
                                      onClick={() => {
                                        let con = contrastData
                                        con.name = item.point_order
                                        setContrastData({ ...con })
                                        getCompareImages(item.pointId)

                                      }}
                                    >
                                      对比
                                    </Button>
                                    {
                                      item.original_picUrl
                                        ? (
                                          <Tag
                                            color="processing"
                                            className='pointer h24'
                                            onClick={() => {
                                              visible.current = true;
                                              setVisibleImg(true);
                                              seeOriginalImage.current = true
                                              SetIdx(-1);

                                              hangdleShowImg(item.idx);//处理显示图片
                                              setTimeout(() => {
                                                showImg()
                                              }, 300)
                                            }}
                                          >
                                            原图
                                          </Tag>
                                        )
                                        : ''

                                    }
                                    <div style={{ fontSize: '14px', fontWeight: '400', color: '#1890FF', cursor: 'pointer' }}
                                      onClick={() => { onPatrolMap(item) }}>
                                      <EnvironmentFilled style={{ color: '#177DDC' }} />
                                    </div>
                                  </div>
                                </div>

                                {/* 甲烷值 */}
                                <div className='coLF0_85 backColorD32029 borRad2' style={{ position: 'absolute', bottom: '32px', right: '8px', padding: '1px 8px', display: (item.methane_con > -1 ? 'none' : 'none') }}>
                                  CH4：{item.methane_con} ppm·m
                                </div>
                              </div>
                          )
                        })
                        : ''
                      }
                    </div>
                  </div>
                )
                : (
                  <div className="disFlex flexDirCol"
                    style={{ flex: 'auto', paddingLeft: '24px', }}
                  >
                    <div className="disFlex flex_wrap  "
                      style={{ flexDirection: 'row', maxHeight: '100%', overflowY: 'auto', alignContent: 'flex-start' }}>
                      {
                        getRandomNumberByCount(randomNumber.current).map((item, item_idx) => {
                          return (
                            <div key={item_idx}
                              className="flex_col"
                              style={{ width: '24%', height: 'auto', marginBottom: '24px', marginRight: '1%' }}>
                              <div className='pos_rel'>
                                <img className='radius8' style={{ width: '100%' }} src={hsbg} alt="人工复核中..." />
                                <div
                                  className='pos_abs wid100 hei100 flex jcc align_center radius8 pointer'
                                  style={{ backgroundColor: '#000', top: 0, left: 0 }} >
                                  <Spin spinning={pageLoading}></Spin>
                                </div>
                              </div>

                              <div className="disFlex jusConCenSB marT8"
                                style={{ flexDirection: 'row', flex: 'auto', }}>
                                {/* 下标 */}
                                <div className='h24'></div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>



                )
            }
          </div>

          {/* 页脚 */}
          <div className='disFlex jusConEnd Pagination' >
            <Pagination
              total={total}
              // current={searchCriteria.page}
              current={newPAGE.current}
              pageSize={searchCriteria.perpage}
              showQuickJumper
              showSizeChanger={true}
              pageSizeOptions={['12', '24', '48', '96',]}
              showTotal={(total) => `Total ${total} items`}
              onChange={onPagination}
            />
          </div>

          {/* </Spin> */}
        </div>

      </div>

      {/* 对比图片 */}
      <Modal width='90vw' wrapClassName='dd_modal' open={contrastData.isOpen} bodyStyle={{ backgroundColor: '#141414' }}
        destroyOnClose={true} title={contrastData.name} footer={null}
        onCancel={() => {
          let con = contrastData
          con.isOpen = false
          setContrastData({ ...con })
        }}
      >
        <div className='flex jcsb' style={{ color: 'rgba(255, 255, 255, 0.85)' }}>
          <div style={{ width: 'calc(50% - 12px)' }}>
            <img alt='图片对比1' className='wid100' src={contrastData.img1} />
            <div>
              <span>{contrastData.time1}</span>
            </div>
          </div>
          <div className='w24'></div>
          <div style={{ width: 'calc(50% - 12px)' }}>
            <img alt='图片对比2' className='wid100' src={contrastData.img2} />
            <div>
              <span>{contrastData.time2}</span>
            </div>
          </div>
        </div>
      </Modal>

      <div style={{ display: 'none', }} >
        <Image.PreviewGroup
          preview={{
            visible: visibleImg,
            current: currIdx.current,
            onVisibleChange: (vis) => {
              visible.current = vis;
              setVisibleImg(vis);
              seeOriginalImage.current = false
            },
            countRender: (cur, tol) => {
              return <div>{(pictureDataRef.current[currIdx.current] ? (pictureDataRef.current[currIdx.current].key + 1) + ' / ' + totalRef.current : '')}</div>
            }
          }}
        >
          {seeOriginalImage.current
            ? (
              showPictureData && showPictureData.map((item, index) => {
                return (
                  <Image key={index} src={item.original_picUrl} />
                )
              })
            )
            : (
              showPictureData && showPictureData.map((item, index) => {
                return (
                  <Image key={index} src={item.picUrl} />
                )
              })
            )
          }
        </Image.PreviewGroup>
      </div>

      <div style={{ display: 'none' }}>
        <input ref={inputRef} type='file' accept="image/png, image/jpeg" onChange={(e) => {

          let file = e.target.files[0];
          if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            inputRef.current.value = '';
            return message.error('只能上传jpeg/png格式的图片');
          }
          if (file.size > 50 * 1024 * 1024) {
            inputRef.current.value = '';
            return message.error('图片不能超过50M');
          }
          submitPicture(file);
        }} />
      </div>

      {/* 风险处理详情抽屉 */}
      <Drawer title='处理详情' width={256} placement="right" onClose={() => {
        let info = { ...adviceDrawer };
        info.isOpen = false;
        setAdviceDrawer(info);
      }} open={adviceDrawer.isOpen}>
        {/* 信息 */}
        <div className='flex_col'>
          <div className='pdB16'>
            <div className='col_primary fw500'>{adviceDrawer.address}</div>
            <div className='col_secondary_text'>{adviceDrawer.pointOrder}</div>
            <div className='col_secondary_text'>{adviceDrawer.date}</div>
          </div>
          {/* 风险初判 */}
          <div className='flex_col pdB16 col_divider_w_top'>
            <div className='marTB12 col_divider_w_btm'>
              <div className='col_secondary_text'> 风险初判 </div>
              <div className='flex_row flex_wrap marB4'>
                {
                  adviceDrawer.divrisk.length > 0 ? adviceDrawer.divrisk.map((item, idx) => {
                    return <Tag key={idx} color="error" className='marR8 marB8'>{item}</Tag>
                  }) : <div className='opa25 marB8'>-</div>
                }
              </div>
            </div>

            {/* 处理人员 */}
            <div className='marB12 col_divider_w_btm'>
              <div className='col_secondary_text'>处理人员</div>
              <div className='flex_row flex_wrap marB12'>
                {
                  adviceDrawer.userName.length > 0 ?
                    <div>{adviceDrawer.userName.join('、')}</div>
                    : <div className='opa25'>-</div>
                }
              </div>
            </div>

            {/* 处理意见 */}
            <div>
              <div className='col_secondary_text marB12'> 处理意见 </div>
              <div className='flex_col'>
                {adviceDrawer.advice}
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      {/* 图片放大 */}
      <Image.PreviewGroup
        preview={{
          visible: showImgInfo.visible,
          current: showImgInfo.current,
          onVisibleChange: (value) => {
            let info = { ...showImgInfo };
            info.visible = false;
            info.url1 = '';
            info.url2 = '';
            info.url3 = '';
            info.current = 0;
            setShowImgInfo(info);
          },
        }}
      >
        {showImgInfo.url1 && <Image style={{ display: 'none', }} src={showImgInfo.url1} />}
        {showImgInfo.url2 && <Image style={{ display: 'none', }} src={showImgInfo.url2} />}
        {showImgInfo.url3 && <Image style={{ display: 'none', }} src={showImgInfo.url3} />}
      </Image.PreviewGroup>
    </div >
  );
}
export default RegularInspectionsRecordPicture