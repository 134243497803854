//定点类型 (电塔巡检、场站巡检)
import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import { PlusOutlined, FilePdfOutlined, DownloadOutlined, ExclamationCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Pagination, Spin, message, InputNumber, Layout, Select, Table } from 'antd';
//路由跳转使用 useNavigate react-router v6已经把usehistory替换成了useNavigate
import { useNavigate, useLocation, useSearchParams, useParams } from 'react-router-dom'

import OperationBuilder from '@/components/TableBuilder/OperationBuilder';
import { GetRoutesList, GetRoutesInfo, AddRoutes, EditRoutes, DelRoutes } from '@/services/shipping_line'
import local from '@/utils/local'
import { basicPath } from '@/router/config.js'
import CheckPdf from '@/components/CheckPdf.js'
// import flie from "@/common/line_node.pdf"
import ExportJsonExcel from "js-export-excel";//生成|导出excel
// import 'antd/dist/antd.dark.css';
const { TextArea } = Input;
const { Content } = Layout;

function FixedPoint () {
  const navigate = useNavigate();
  const location = useLocation();//接受传值数据
  const path_val = useParams();//获取动态路由的参数 /:key

  const indexData = JSON.parse(localStorage.getItem('cruise_m30_fixed_point_index')) || null

  const limitsAuthorityName = useRef()
  // const [limitsAuthorityName.current, setLimitsAuthorityName] = useState([])
  const btnsize = 'default';
  const token = local.get('cruise_m30_token');
  const [val_in, setValIn] = useState('');
  const [current, setCurrent] = useState(1);//第几页
  const curPage = useRef(1)
  const [data, setData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//总共多少条
  const [loading, setLoading] = useState(false);
  const [showModle, setShowModel] = useState(false);//是否显示弹窗
  const [modelTitle, setModelTitle] = useState('');//弹窗标题
  const [choose_id, setChooseId] = useState('');//当前选中的id
  const [pipelineList, setPipelineList] = useState({
    title: '',//航线名称
    inspectionCycle: '1',//巡检周期
    inspectionCycleDictionary: '',//巡检周期  字典
    des: '',//航线描述
  });

  const [search, setSearch] = useState({
    urlType: indexData ? indexData.urlType : -1,
    page: indexData ? indexData.page : 1,
    perpage: indexData ? indexData.perpage : 10,
  });//查询

  //下载文件弹窗参数
  const [downloadModel, setDownloadModel] = useState({
    isOpen: false,
    title: '',
    content: '',
  });

  //获取表格数据
  const getTableData = async (data) => {
    setLoading(true);
    let obj = {
      access_token: token,
      type: data.urlType,
      page: data.page,
      perpage: data.perpage,
    }
    let res = await GetRoutesList(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    let pip = pipelineList
    pip.inspectionCycleDictionary = res.data.sjzd
    setPipelineList({ ...pip })
    setData([...res.data.items]);
    setTotal(res.data.total);
    setLoading(false);
  };

  //删除表格数据
  const delData = async (id) => {
    let res = await DelRoutes({
      access_token: token,
      id: id
    })
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success('删除成功');
    let obj = { ...search }
    if (data.length === 1) {
      if (obj.page !== 1) {
        obj.page = obj.page - 1;
        setCurrent(obj.page);
        curPage.current = obj.page
      }
    }
    setSearch({ ...obj });
    getTableData(obj);
  };

  //修改表格数据
  const updateData = async () => {
    let pip = pipelineList
    if (!pip.title) {
      message.warning('请输入航线名称');
      return;
    }
    if (!pip.inspectionCycle) {
      message.warning('请输入巡检周期');
      return;
    }
    if (!pip.des) {
      message.warning('请输入备注');
      return;
    }
    let obj = {
      access_token: token,
      title: pip.title,
      cycle: pip.inspectionCycle,
      des: pip.des,
    }
    let res;
    if (choose_id) {
      obj.id = choose_id;
      res = await EditRoutes(obj);
    } else {
      obj.type = search.urlType;
      res = await AddRoutes(obj);
    }
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success(choose_id ? '编辑成功' : '新增成功');
    setPipelineList({})
    setChooseId('');
    setShowModel(false);
    getTableData(search);
  };

  //获取管线详细信息
  const getInfor = async (id) => {
    let pip = pipelineList
    let params = {
      access_token: token,
      id: id
    }
    let res = await GetRoutesInfo(params);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return
    }
    let val = res.data;

    pip.title = val.title
    pip.inspectionCycle = val.cycle
    pip.des = val.des
    setPipelineList({ ...pip })
    setChooseId(id);
    setShowModel(true);
    setModelTitle('编辑航线');
  };

  //新增航线
  const addPipeline = async () => {
    let pip = pipelineList
    pip.title = null
    pip.inspectionCycle = 1
    pip.des = null
    setPipelineList({ ...pip })
    setShowModel(true);
    setModelTitle('新增航线');
  }

  const [columns, setColumns] = useState([]);

  //操作按钮 回调
  const Operation = (record, index, type, key) => {
    switch (key) {
      case '0':
        getInfor(record.id);
        break;
      case '3':
        delData(record.id);
        break;
      default:
        break;
    }
  };

  // //下载标识桩模板 type:下载方式 1、下载 2、另存为
  const downloadStakeTemplate = (type = 1) => {
    let option = {};
    option.fileName = '标识桩模板';
    if (type === 2) { //是否将excel转为文件
      option.saveAsBlob = true;
    }
    option.datas = [
      {
        sheetData: [
          { number: '序号', longitude: '经度', latitude: '纬度', name: '名称' },
          { number: '1', longitude: '104.2108974', latitude: '30.8545486', name: '测试1' },
          { number: '2', longitude: '104.2098443', latitude: '30.8546942', name: '测试2' },
          { number: '3', longitude: '104.2084056', latitude: '30.8549502', name: '测试3' },
        ],
        sheetName: 'Sheet1',
        sheetFilter: ['number', 'longitude', 'latitude', 'name'],
        columnWidths: [10, 10, 10, 10],
      },
    ];
    let toExcel = new ExportJsonExcel(option); //new
    if (type === 1) {
      toExcel.saveExcel();
      let info = { ...downloadModel };
      info.isOpen = false;
      setDownloadModel(info);
    } else {
      let file = toExcel.saveExcel(); //保存
      saveAs(file, '标识桩模板');
    }

  }

  //另存为
  const saveAs = async (file, fileName) => {
    try {
      const opts = {
        types: [
          {
            description: '文件',
            accept: {
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls']
            },
          }
        ],
        suggestedName: fileName,
        excludeAcceptAllOption: true
      };
      const handle = await window.showSaveFilePicker(opts); // 打开保存文件对话框
      const writable = await handle.createWritable(); // 创建可写入的文件对象

      // 在这里写入文件内容
      await writable.write(file);
      await writable.close();
      message.success('文件保存成功');
      let info = { ...downloadModel };
      info.isOpen = false;
      setDownloadModel(info);
    } catch (error) {
      let text = JSON.stringify(error);
      if (text.indexOf('The user aborted a request') === -1) {//无法使用另存为
        message.warning('当前浏览器暂不支持另存为操作，请直接下载 或更换以下浏览器：谷歌、edge、360');
      }
    }
  }

  //关闭下载弹窗
  const closeDownloadModel = () => {
    let info = { ...downloadModel };
    info.isOpen = false;
    setDownloadModel(info);
  }

  useEffect(() => {

    limitsAuthorityName.current = JSON.parse(localStorage.getItem('cruise_m30_permissionsArrName'))
    let sea = search

    switch (path_val.type) {
      //定点-电塔
      case 'electric_towers':
        sea.urlType = 8;
        local.set('cruise_m30_type', 8);
        break;
      //定点-场站
      case 'stations':
        sea.urlType = 9;
        local.set('cruise_m30_type', 9);
        break;

      default:
        break;
    }

    const arr = [ //表格头
      {
        title: 'ID',
        width: 80,
        dataIndex: 'id',
      },
      {
        title: '航线名称',
        dataIndex: 'title',
        render: (text, record) => {
          return (
            <span style={{ color: '#1890FF', cursor: 'pointer' }}

              onClick={() => {
                let obj = {
                  page: curPage.current,
                  urlType: sea.urlType,
                  perpage: sea.perpage,
                  type: 'index',
                  PID: record.id,
                  name: record.title,
                }
                localStorage.setItem('cruise_m30_fixed_point_index', JSON.stringify(obj))
                navigate('/fixed_point/child', { state: 'index' })
              }
              }
            >
              {text}
            </span>
          )
        }
      },
      {
        title: '巡检周期',
        dataIndex: 'cycle',
        render: (text, record) => {
          return (
            text
              ? <div>{text}</div>
              : <div style={{ color: 'rgba(255,255,255,0.45)' }}>-</div>
          )
        }
      },
      {
        title: '编辑人',
        dataIndex: 'username',
        render: (text, record) => {
          return (
            text
              ? <div style={{ color: 'rgba(255,255,255,0.45)' }}>{text}</div>
              : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
          )
        }
      },
      {
        title: '编辑时间',
        dataIndex: 'update_time',
        render: (text, record) => {
          return (
            text
              ? <div style={{ color: 'rgba(255,255,255,0.45)' }}>{text}</div>
              : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
          )
        }
      },
      {
        title: '操作',
        dataIndex: 'op',
        width: 100,
        align: 'center',
        render: (_, record, index) => {
          //操作按钮
          let ButtonList = [
            {
              key: '0',
              type: 'SetUp',
              label: "修改",
              purview: false,
            },
            {
              key: 'divider',
              type: 'Divider',
              purview: true,
            },
            {
              key: '3',
              type: 'Delete',
              label: '删除',
              purview: false,
            },
          ]
          if (limitsAuthorityName.current.indexOf(`RoutesInfo_Edit_${sea.urlType}`) > -1) { ButtonList[0].purview = true }
          if (limitsAuthorityName.current.indexOf(`RoutesInfo_Edit_${sea.urlType}`) > -1 && limitsAuthorityName.current.indexOf(`RoutesInfo_Del_${sea.urlType}`) > -1) { ButtonList[1].purview = true }
          if (limitsAuthorityName.current.indexOf(`RoutesInfo_Del_${sea.urlType}`) > -1 && record.is_lock === 1) { ButtonList[2].purview = true }

          return OperationBuilder(record, index, 'actions_drop', ButtonList, Operation)
        }
      },
    ];

    setSearch({ ...sea });
    getTableData(sea);
    setColumns(arr);

    //eslint-disable-next-line
  }, [path_val.type])

  return (
    <div className="hei100 padd_24 borbox tablebackcol" style={{ overflowY: 'auto' }}>
      <Layout style={{ display: 'none' }}>
        <Content style={{ background: '#141414', color: 'rgba(255, 255, 255, 0.85)', overflowY: 'auto' }}>
          <InputNumber placeholder="航点速度" value={val_in} className='' addonAfter="m/s"
            onChange={(e) => { setValIn(e) }}
          ></InputNumber>
        </Content>
      </Layout>

      <div className="flex-col paddRL24 backcol">
        <div className="h64 flex-row aliItemsCen min-h64">
          <div className="fSize16 fWei600 marR12">
            {search.urlType === 8 ? '电塔巡检' : search.urlType === 9 ? '场站巡检' : '航线巡检'}
          </div>
          <div className="fSize14 col_primary pointer" style={{ display: search.urlType === 1 ? '' : 'none' }}
            onClick={() => {
              window.open(basicPath + '/shipping_line_overview');
            }}
          >航线总览</div>
          <div className="flex-auto"></div>
          <Button className='marR8' icon={<DownloadOutlined />} size={btnsize}
            onClick={() => {
              let info = { ...downloadModel }
              info.isOpen = true;
              info.title = '下载标识桩模板';
              info.content = '标识桩模板已准备，点击按钮下载'
              setDownloadModel(info);
            }}
          >
            标识桩模板
          </Button>

          {/* <Button className='marR8' type="primary" icon={<FilePdfOutlined />} size={btnsize}
            onClick={() => {
              setBrochureShow(true);
            }}
          >
            操作手册
          </Button> */}

          <Button type="primary" icon={<PlusOutlined />} size={btnsize}
            onClick={() => {
              if (limitsAuthorityName.current.indexOf(`RoutesInfo_Add_${search.urlType}`) > -1) {
                addPipeline()
              } else {
                message.warning('暂无权限！')
              }
            }}
          >
            新增
          </Button>
        </div>
        <div className="borbox">
          <Spin spinning={loading}>
            <Table
              rowKey='id'
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </Spin>
        </div>
        <div className="min-h64 tt_a_r disFlex aliItemsCen">
          <Pagination
            total={total}
            showSizeChanger
            showQuickJumper={false}
            pageSize={search.perpage}
            showTotal={total => `共 ${total} 条数据`}
            style={{ marginLeft: 'auto' }}
            current={search.page}
            onChange={
              (current, pageSize) => { //选择当前页后执行
                let sea = search
                sea.page = current
                sea.perpage = pageSize
                setCurrent(current)
                curPage.current = current
                setSearch({ ...sea })
                getTableData(sea)
              }
            }
          />
        </div>
        <Modal title={modelTitle} open={showModle} maskClosable={false} cancelText='取消' okText="确定"
          onOk={() => { updateData() }}
          onCancel={() => {
            let pip = pipelineList
            pip.title = ''
            pip.des = ''
            pip.inspectionCycle = 1
            setPipelineList({ ...pip })
            setChooseId('');
            setShowModel(false);
          }}>
          <div className="margB24">
            <Input placeholder="航线名称" value={pipelineList.title}
              onChange={(e) => {
                let pip = pipelineList
                pip.title = e.target.value
                setPipelineList({ ...pip })
              }} />
          </div>
          <div className="margB24 wid100">
            <Select
              style={{ width: '100%' }}
              placeholder='巡检周期'
              allowClear
              options={pipelineList.inspectionCycleDictionary}
              value={Number(pipelineList.inspectionCycle) ? Number(pipelineList.inspectionCycle) : null}
              onChange={(e) => {
                let pip = pipelineList
                pip.inspectionCycle = e ? e : null
                setPipelineList({ ...pip })
              }}
            />
          </div>
          <div>
            <TextArea id="" className="h54" value={pipelineList.des} rows={4} placeholder="航线描述"
              onChange={(e) => {
                let pip = pipelineList
                pip.des = e.currentTarget.value
                setPipelineList({ ...pip })
              }
              } />
          </div>
        </Modal>

        {/* <Modal title='操作手册' width={1400} open={brochureShow} maskClosable={true} footer={false} onCancel={() => {
          setBrochureShow(false);
        }}>
          <div className='tc' style={{ width: '100%', height: 700, overflowY: 'auto' }}>
            <CheckPdf file={flie} width={900}></CheckPdf>
          </div>
        </Modal> */}
      </div>

      <Modal title="" width={416} open={downloadModel.isOpen}
        footer={false} closable={false} maskClosable={true}
        onCancel={() => { closeDownloadModel() }}
      >

        <div className='marB8 flex_row align_center'>
          <span className='fs22 marR16 col_primary'><ExclamationCircleOutlined /></span>
          <span style={{ fontWeight: 500, fontSize: 16, color: 'rgba(255, 255, 255, 0.85)' }}>
            {downloadModel.title}
          </span>
        </div>
        <div className='fs14 marL38' style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.85)' }}>
          {downloadModel.content}
        </div>

        <div className='marT24 tr'>
          <Button className='marR8' onClick={() => {
            closeDownloadModel()
          }}>取消</Button>

          <Button className='marR8' type="primary" onClick={() => {
            downloadStakeTemplate(1);
          }}>下载</Button>

          <Button type="primary" onClick={() => {
            downloadStakeTemplate(2);
          }}>另存为</Button>
        </div>
      </Modal>
    </div >
  );
}
export default FixedPoint