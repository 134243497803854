import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import { Menu, Spin, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'
import './menu.less'
import local from '@/utils/local'
import { getAllPromptQuantities } from '@/services/navigationBar.js'
import TextInfo from './messagePrompt.js'
import Mp3 from '@/common/mp3/TS.mp3'
import { basicPath } from '@/router/config.js'
import { getAllPermissions } from '@/services/home.js'
import { MenuList as constMenuList } from './menu_list.js';

const Menus = (props) => {
  const { isShowMenu } = props
  const navigate = useNavigate()
  const Stateparams = useLocation();
  const menuListRef = useRef([]);

  const [defaultMenuKeys, _defaultMenuKeys] = useState(local.get('cruise_m30_cruiseMenuKeys') || '/');
  // const defaultOpenKeys = local.get('cruise_m30_defaultOpenKeys') || '';
  const [defaultOpenKeys, setDefaultOpenKeys] = useState(local.get('cruise_m30_defaultOpenKeys'));
  const [loading, setLoading] = useState(false)
  const token = local.get('cruise_m30_token') || '';
  const muted = useRef(false)//是否静音
  const num = useRef(0)//是否静音
  const permissionsListArr = useRef() //所有权限
  const [showMenu, setShowMenu] = useState([])

  const intervalRef = useRef(null);//

  //获取所有提示数量
  const getAllCont = () => {
    getAllPromptQuantities({ access_token: token }).then((res) => {
      let arr = [...menuListRef.current];
      if (local.get('cruise_m30_memberLevel') !== '255') {
        let ano = ['/data_monitor', '/',]
        let newArr = []
        arr.forEach((item) => {
          if (ano.indexOf(item.key) > -1) {
            newArr.push(item)
          }
        })
        arr = arr.filter((item) => !newArr.some((ele) => ele.key === item.key))
      }
      if (permissionsListArr.current) {
        if (permissionsListArr.current?.indexOf(19) > -1) { arr.find((item) => item.label.props?.type === '2').label = <TextInfo type='2' count={res.data.conventionalcheck_count} /> }
        // if (permissionsListArr.current?.indexOf(90) > -1) { arr.find((item) => item.label.props?.type === '3').label = <TextInfo type='3' count={res.data.leakcheck_count} /> }
        if (permissionsListArr.current?.indexOf(14) > -1) { arr.find((item) => item.label.props?.type === '4').label = <TextInfo type='4' count={res.data.risk_count} /> }
        if (permissionsListArr.current?.indexOf('') > -1 || true) { arr.find((item) => item.label.props?.type === '7').label = <TextInfo type='7' count={res.data.powergrid_count} /> }
        if (permissionsListArr.current?.indexOf('') > -1 || true) { arr.find((item) => item.label.props?.type === '8').label = <TextInfo type='8' count={res.data.tower_count} /> }
        if (permissionsListArr.current?.indexOf('') > -1 || true) { arr.find((item) => item.label.props?.type === '9').label = <TextInfo type='9' count={res.data.station_count} /> }
      }

      if (res.data.leakcheck_count > num.current) {
        openMyAudio(false)
        num.current = res.data.leakcheck_count
      } else {
        openMyAudio(true)
        num.current = res.data.leakcheck_count
      }
      setShowMenu(addNewList(arr))
      isShowMenu(true)
      setLoading(false)
    })
  }

  const openMyAudio = (has) => {
    const myAudio = document.getElementById('newAudio')
    if (local.get('cruise_m30_token') && myAudio) {
      myAudio.preload = true
      myAudio.controls = true
      myAudio.autoplay = true
      myAudio.loop = false
      myAudio.muted = has
      myAudio.src = Mp3
      document.getElementById('audioBox').appendChild(myAudio)
    }
  }

  // 菜单选择事件
  const onSelect = (item) => {
    navigate(item.key);
    local.set('cruise_m30_cruiseMenuKeys', item.key);
  }

  // 获取menu权限
  const getMenuPermissions = async () => {
    setShowMenu([])
    isShowMenu(false)
    setLoading(true)
    let res = await getAllPermissions({ access_token: token, member_level: local.get('cruise_m30_memberLevel') })
    if (res.ret !== 200) { message.warning(res.msg); return }
    if (res.data.menu && res.data.menu.length) {
      permissionsListArr.current = res.data.menu
      window.localStorage.setItem('cruise_m30_permissionsArr', JSON.stringify(res.data.menuids));
      window.localStorage.setItem('cruise_m30_permissionsArrName', JSON.stringify(res.data.menuName));
    }
    getAllCont();
    intervalRef.current = setInterval(() => {
      getAllCont()
    }, 5000)
  }

  // 遍历menu  并重新渲染
  const addNewList = (list) => {
    let menuArr = []
    for (let i = 0; i < list.length; i++) {
      if (list[i].children && list[i].children.length > 0) {
        let arr = addNewList(list[i].children)
        if (arr.length) {
          let sho = list[i]
          sho.children = arr
          menuArr.push(sho)
        }
      } else {
        if (permissionsListArr.current?.indexOf(list[i].permissionkey) >= 0 || list[i].permissionkey === '') {
          menuArr.push(list[i])
        }
      }
    }
    return menuArr
  }

  useEffect(() => {
    if (local.get('cruise_m30_token')) {
      let path = ''
      if (local.get('cruise_m30_cruiseMenuKeys').indexOf('"') > -1) {
        path = JSON.parse(local.get('cruise_m30_cruiseMenuKeys'))
      } else {
        path = local.get('cruise_m30_cruiseMenuKeys')
      }
      if (path) {
        _defaultMenuKeys(path)
        if (path != Stateparams.pathname) { // eslint-disable-line
          window.location.replace(basicPath + path)
        }
      }
    }
  }, [local.get('cruise_m30_cruiseMenuKeys')]); // eslint-disable-line

  //在函数组件中，组件加载完成后执行
  useEffect(() => {
    let arr = constMenuList.map((item) => {
      return { ...item }
    });
    menuListRef.current = arr;
    if (local.get('cruise_m30_token')) {
      getMenuPermissions()
    }
    return () => {
      clearInterval(intervalRef.current);
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  // 当前点击的页面 url设置
  useEffect(() => {
    let path = ''
    if (local.get('cruise_m30_defaultOpenKeys').indexOf('"') > -1) {
      path = JSON.parse(local.get('cruise_m30_defaultOpenKeys'))
    } else {
      path = local.get('cruise_m30_defaultOpenKeys')
    }
    setDefaultOpenKeys(path);
  }, [local.get('cruise_m30_defaultOpenKeys')]); // eslint-disable-line


  return (
    <div
      className='menu'
      style={{ backgroundColor: 'rgba(20,20,20)', }}
    >
      <Spin style={{ height: '100vh' }} spinning={loading} >
        <div id='audioBox' />
        <audio id='newAudio' style={{ display: 'none' }} ></audio>
        <Menu
          style={{
            height: '100%',
            borderRight: 0,
          }}
          mode="inline"
          selectedKeys={[defaultMenuKeys]}
          defaultOpenKeys={[defaultOpenKeys]}
          items={showMenu.length > 0 ? showMenu : []}
          onSelect={onSelect}
          onClick={(e) => {
            local.set('cruise_m30_defaultOpenKeys', e.keyPath[e.keyPath.length - 1]);
          }}
          theme="light"
        />
      </Spin>
    </div >
  )
}



export default React.memo(Menus)