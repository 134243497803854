import React from 'react'
import { Button, Modal, Form, Input, Select, Spin, message, InputNumber } from 'antd';
import { GetHandlerUserInfo, SetHandlerUser } from "@/services/risk_handlers.js"; // 引入Ajax
import { useState, useEffect } from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import local from '@/utils/local'

const AddOrSetUp = (record) => {
  /**
   * data
   * */
  const [form] = Form.useForm();
  const { Option } = Select;
  const { AddOrSetUpVal, visible, Operation } = record
  const [spinningLoading, _spinningLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [sexList, setSexList] = useState([])
  const [sexVal, setSexVal] = useState(null)
  const [CompanyList, setCompanyList] = useState([])
  const [CompanyVal, setCompanyVal] = useState(null)
  const [pw_dis, setPwDis] = useState(true);//重复密码输入框是否可用
  const [pw_value, setPwValue] = useState('');//密码

  useEffect(() => {
    if (visible) {
      GetUserInfoFun()
    }
  }, [visible]);// eslint-disable-line

  useEffect(() => {
    setCompanyVal(null)
    setSexVal(null)
  }, [sexVal]);

  /**
   * methods
   * */
  // 用户详情
  const GetUserInfoFun = async () => {
    _spinningLoading(true)
    let res = await GetHandlerUserInfo({
      access_token: local.get('cruise_m30_token'),
      id: AddOrSetUpVal.id,
    })
    _spinningLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    setCompanyList(res.data.sjzd.company)
    setSexList(res.data.sjzd.sex)
    let gettype = Object.prototype.toString
    if (gettype.call(res.data.info) == '[object Object]') {// eslint-disable-line
      setSexVal(res.data.info.sex)
      setCompanyVal(res.data.info.CompanyID)
      form.setFieldsValue({
        CompanyID: res.data.info.CompanyID,
        sex: res.data.info.sex,
        realname: res.data.info.realname,
        age: Number(res.data.info.age),
        phone: res.data.info.phone,
        username: res.data.info.phone,
        department: res.data.info.department
      });
    }
  };
  //巡检员更新或添加
  const onUserInfo_Set = async (values) => {
    _spinningLoading(true)
    setloading(true)
    let res = await SetHandlerUser({
      access_token: local.get('cruise_m30_token'),
      id: AddOrSetUpVal.id,
      CompanyID: values.CompanyID,
      sex: values.sex,
      realname: values.realname,
      age: values.age,
      phone: values.phone,
      username: values.phone,
      password: values.password,
      verify_password: values.verify_password,
      department: values.department
    })
    _spinningLoading(false)
    setloading(false)
    if (res.ret == 200) { // eslint-disable-line
      message.success(res.msg || '操作成功', 1.5);
      Operation(true)
    } else {
      message.warning(res.msg || '数据跑丢了')
    }
  };
  const handleOk = (e) => {
    form.validateFields()
      .then((values) => {
        if (values) onUserInfo_Set(values)
      })
      .catch(() => {
      });
  };
  const handleCancel = (e) => {
    form.resetFields()
    Operation(false)
  };

  const handleChange = (value) => {
    //console.log(`selected ${value}`);
  };

  return (
    <>
      <Modal
        title={AddOrSetUpVal.title}
        width={432}
        open={visible}
        maskClosable={false}
        onOk={handleOk}
        destroyOnClose={true}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            确定
          </Button>,
        ]}
      >
        <Spin spinning={spinningLoading}>
          <Form
            form={form}
            layout="vertical"
            name="userForm"
            initialValues={{
              sex: sexVal,
              CompanyID: CompanyVal,
            }}
          >
            <div className='disFlex jusConCenSB'>
              <Form.Item
                name="realname"
                rules={[
                  {
                    required: true,
                    message: '请填写姓名',
                  },
                ]}
              >
                <Input maxLength={6} style={{ width: '112px' }} placeholder="姓名" />
              </Form.Item>
              <Form.Item
                name="sex"
                rules={[
                  {
                    required: true,
                    message: '请选择性别',
                  },
                ]}
              >
                <Select
                  placeholder="性别"
                  style={{
                    width: 112,
                  }}
                >
                  {
                    sexList.map((item, index) => {
                      return <Option value={item.value} key={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name="age"
                rules={[
                  {
                    required: true,
                    message: '请填写年龄',
                  },
                ]}
              >
                <InputNumber min={1} max={120} style={{ width: '112px' }} placeholder="年龄" />
              </Form.Item>
            </div>
            <div className='disFlex jusConCenSB'>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: '请填写电话',
                  }, {
                    pattern: /^1[0-9]{10}$/,
                    message: '电话格式不正确',
                  },
                ]}
              >
                <Input maxLength={11} style={{ width: '248px' }} placeholder="电话" />
              </Form.Item>
              <Form.Item
                name="CompanyID"
                rules={[
                  {
                    required: true,
                    message: '请选择公司',
                  },
                ]}
              >
                <Select
                  placeholder="公司"
                  style={{
                    width: 112,
                  }}
                >
                  {
                    CompanyList.map((item, index) => {
                      return <Option value={item.value} key={item.value} disabled={item.disabled}>{item.label}</Option>
                    })
                  }
                </Select>
              </Form.Item>
            </div>

            <div className='disFlex jusConCenSB'>
              {/* <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: '请填写手机号',
                                    }, {
                                        pattern: /^1[0-9]{10}$/,
                                        message: '电话格式不正确',
                                    }
                                ]}
                            >
                                <Input
                                    maxLength={20}
                                    placeholder="手机号"
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    style={{ width: '248px' }}
                                />
                            </Form.Item> */}
              <Form.Item
                name="department"
                rules={[
                  {
                    required: true,
                    message: '请填写部门',
                  }
                ]}
              >
                <Input
                  maxLength={20}
                  placeholder="部门"
                  style={{ width: '384px' }}
                />
              </Form.Item>
            </div>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '请填写密码', //AddOrSetUpVal.id
                },
                {
                  pattern: /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9 _]{8,16}$/,
                  message: '请输入8-16位包含数字和字母的密码',
                },
              ]}
            >
              <Input.Password maxLength={30}
                placeholder={AddOrSetUpVal.id ? "新密码由字母、数字组成不少于8位密码" : "由字母、数字组成不少于8位密码"}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                onBlur={(value) => {
                  //console.log('密码');
                  let regular = /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9 _]{8,16}$/;
                  if (regular.test(value.target.value)) {
                    setPwValue(value.target.value);
                    setPwDis(false);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="verify_password"
              rules={[{
                validator: (rules, value, callback) => {
                  if (value == '') { // eslint-disable-line
                    return Promise.reject('请重复填写密码');
                  }
                  let regular = /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9 _]{8,16}$/;
                  if (!regular.test(value)) {
                    return Promise.reject('请输入8-16位包含数字和字母的密码');
                  }
                  if (value != pw_value) { // eslint-disable-line
                    return Promise.reject('两个密码必须一致');
                  }
                  callback();
                }
              }
              ]}
            >
              <Input.Password maxLength={30}
                placeholder="重复密码"
                disabled={pw_dis}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

export default React.memo(AddOrSetUp)