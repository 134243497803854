import React from 'react'

import AvoidLogin from '@/pages/avoid_login'
import Login from '@/pages/login'
import Menu from '@/pages/menu/index.js'
import DataMonitor from '@/pages/data_monitor/index.js' //大数据
import PatrolMap from '@/pages/patrol_map/index.js' //巡检地图
import InspectionLiveBroadcast from '@/pages/inspection_live_broadcast/index.js' //巡检直播
import ShippingLine from '@/pages/shipping_line/index.js' //巡检
import ShippingLineSon from '@/pages/shipping_line/shipping_line_son.js' //巡检详情
import ShippingLineEdit from '@/pages/shipping_line/shipping_line_edit.js' //管线编辑
import ShippingLineBuildSegment from '@/pages/shipping_line/shipping_line_build_segment.js' //生成单元段
import ShippingLineOverview from '@/pages/shipping_line/shipping_line_overview.js' //生成单元段




// 常规巡检
import Routine from '@/pages/routine/index.js'
import RoutineChild from '@/pages/routine/child.js'

// 定点巡检
import FixedPoint from '@/pages/fixed_point/index.js'
import FixedPointChild from '@/pages/fixed_point/child.js'
import FixedPointRiskSet from '@/pages/fixed_point/risk_set.js'


import AbnormalTroubleshooting from '@/pages/abnormal_troubleshooting/index.js' //异常排查
import AbnormalTroubleshootingSteps from '@/pages/abnormal_troubleshooting/abnormal_troubleshooting_steps.js' //异常排查步骤

import AbnormalTroubleshootingShows from '@/pages/abnormal_troubleshooting_shows/index.js' //异常排查 - 演示
import AbnormalTroubleshootingStepsShows from '@/pages/abnormal_troubleshooting_shows/abnormal_troubleshooting_steps.js' //异常排查步骤 - 演示

import ConventionalRisks from '@/pages/conventional_risks/index.js' //异常排查步骤
import ConventionalRisksHandle from '@/pages/conventional_risks/conventional_risks_handle.js' //异常排查步骤
import RegularInspectionsRecord from '@/pages/regular_inspections_record/index.js' //常规巡检记录
import ComprehensiveInspectionsRecord from '@/pages/comprehensive_inspections_record/index.js' //综合巡检记录
import DesignatedInspectionsRecord from '@/pages/designated_inspections_record/index.js' //定点巡检记录
import DesignatedInspectionsRecordPicture from '@/pages/designated_inspections_record/designated_inspections_record_picture.js' //定点图像对比
import LeakInspectionsRecord from '@/pages/leak_inspections_record/index.js' //定点巡检记录
import InspectionReport from '@/pages/inspection_report/index.js' //巡检报告
import InspectionPilots from '@/pages/inspection_pilots/index.js' //巡检飞手
import RiskHandlers from '@/pages/risk_handlers/index.js' //风险处理员 - 人员
import Team from '@/pages/team/index.js' //风险处理员 - 组队
import PipelineGadMap from '@/pages/pipeline_gad_map/index.js' //管道上帝图
import PipelineGadMapInfo from '@/pages/pipeline_gad_map/pipeline_gad_map_info.js' //管道上帝图详情
import PipelineGadMapShow from '@/pages/pipeline_gad_map/pipeline_gad_map_show.js' //管道上帝图展示
// import UserManual from '@/pages/user_manual/index.js' //用户手册
import PermissionSettings from "@/pages/permission_settings/index.js"//设置权限
import SMSNotification from "@/pages/SMS_notification/index.js"//短信通知
import RiskIdentification from '@/pages/risk_identification/index.js';//风险识别
import LeakageRisk from '@/pages/leakage_risk/index.js' //高危风险
import LeakHandle from '@/pages/leakage_risk/leak_handle.js' //高危风险处理
import RegularInspectionsRecordPicture from '@/pages/regular_inspections_record/regular_inspections_record_picture.js' //常规查看图像
import LeakInspectionsRecordPicture from '@/pages/leak_inspections_record/leak_inspections_record_picture.js' //高危查看图像


export const basicPath = '/cruise_m30';//基础路径
export const redirectPath = '/data_monitor';//主页重定向路径
export const startPath = '/login';//初始路径1
export const StartComp = Login;
export const startPath2 = '/avoid_login';//初始路径2
export const StartComp2 = AvoidLogin;


export const RouterConfig = [
  // { path: "/login", title: "登录", exact: true, component: Login },
  // { path: "/avoid_login", title: "免登录", exact: true, component: AvoidLogin },
  {
    path: "/", title: "框架", exact: true, component: Menu,
    children: [
      { path: "/data_monitor", title: "数据监控", exact: true, component: DataMonitor },
      { path: "/inspection_live_broadcast", title: "巡检直播", exact: true, component: InspectionLiveBroadcast },

      { path: "/shipping_line/:type", title: "巡检", exact: true, component: ShippingLine },



      { path: "/shipping_line_routine", title: "常规巡检", exact: true, component: ShippingLine },
      { path: "/shipping_line_leak", title: "高危巡检", exact: true, component: ShippingLine },
      { path: "/shipping_line_precise", title: "定点巡检", exact: true, component: ShippingLine },
      { path: "/shipping_line_comprehensive", title: "综合巡检", exact: true, component: ShippingLine },
      { path: "/shipping_line_son", title: "巡检详情", exact: true, component: ShippingLineSon },



      //常规巡检 (管道、电网)
      { path: "/routine/:type", title: "常规巡检", exact: true, component: Routine },
      { path: "/routine/child", title: "常规巡检 - 单元段", exact: true, component: RoutineChild },


      //定点巡检 (电塔、场站)
      { path: "/fixed_point/:type", title: "定点巡检", exact: true, component: FixedPoint },
      { path: "/fixed_point/child", title: "定点巡检 - 单元段", exact: true, component: FixedPointChild },
      { path: "/fixed_point/risk_set", title: "定点巡检 - 风险设置", exact: true, component: FixedPointRiskSet },

      { path: "/abnormal_troubleshooting", title: "异常排查", exact: true, component: AbnormalTroubleshooting },
      { path: "/abnormal_troubleshooting_steps", title: "异常排查步骤", exact: true, component: AbnormalTroubleshootingSteps },

      { path: "/abnormal_troubleshooting_shows", title: "异常排查 - 演示", exact: true, component: AbnormalTroubleshootingShows },
      { path: "/abnormal_troubleshooting_steps_shows", title: "异常排查步骤 - 演示", exact: true, component: AbnormalTroubleshootingStepsShows },

      { path: "/conventional_risks", title: "管道风险", exact: true, component: ConventionalRisks },
      { path: "/conventional_risks_handle", title: "管道风险处理", exact: true, component: ConventionalRisksHandle },
      { path: "/powerGrid_risks", title: "电网风险", exact: true, component: ConventionalRisks },
      { path: "/electricTower_risks", title: "电塔风险", exact: true, component: ConventionalRisks },
      { path: "/station_risks", title: "场站风险", exact: true, component: ConventionalRisks },


      { path: "/leakage_risk", title: "高危风险", exact: true, component: LeakageRisk },
      { path: "/leak_handle", title: "高危风险处理", exact: true, component: LeakHandle },


      { path: "/conduit_inspections_record", title: "管道巡检记录", exact: true, component: RegularInspectionsRecord },
      { path: "/powerGrid_inspections_record", title: "电网巡检记录", exact: true, component: RegularInspectionsRecord },
      { path: "/electricTower_inspections_record", title: "电塔巡检记录", exact: true, component: DesignatedInspectionsRecord },
      { path: "/station_inspections_record", title: "场站巡检记录", exact: true, component: DesignatedInspectionsRecord },


      { path: "/comprehensive_inspections_record", title: "综合巡检记录", exact: true, component: ComprehensiveInspectionsRecord },



      { path: "/designated_inspections_record", title: "定点巡检记录", exact: true, component: DesignatedInspectionsRecord },
      { path: "/designated_inspections_record_picture", title: "定点图像对比", exact: true, component: DesignatedInspectionsRecordPicture },



      { path: "/leak_inspections_record", title: "高危巡检记录", exact: true, component: LeakInspectionsRecord },
      { path: "/leak_inspections_record_picture", title: "高危查看图片", exact: true, component: LeakInspectionsRecordPicture },



      { path: "/inspection_report", title: "巡检报告", exact: true, component: InspectionReport },
      { path: "/inspection_pilots", title: "巡检飞手", exact: true, component: InspectionPilots },

      { path: "/risk_handlers", title: "风险处理员 - 人员", exact: true, component: RiskHandlers },
      { path: "/team", title: "风险处理员 - 组队", exact: true, component: Team },
      { path: "/conduit_pipeline_gad_map", title: "管道上帝图", exact: true, component: PipelineGadMap },
      { path: "/powerGrid_pipeline_gad_map", title: "电网上帝图", exact: true, component: PipelineGadMap },
      { path: "/pipeline_gad_map_info", title: "管道上帝图详情", exact: true, component: PipelineGadMapInfo },

      // { path: "/user_manual", title: "用户手册", exact: true, component: UserManual },
      { path: "/permission_settings", title: "权限", exact: true, component: PermissionSettings },
      { path: "/SMS_notification", title: "短信通知", exact: true, component: SMSNotification },
      { path: "/risk_identification", title: "风险标识 ", exact: true, component: RiskIdentification },
      { path: "/regular_inspections_record_picture", title: "查看图像", exact: true, component: RegularInspectionsRecordPicture },
    ]
  },
  { path: "/shipping_line_edit", title: "管线编辑", exact: true, component: ShippingLineEdit },
  { path: "/shipping_line_build_segment", title: "生成单元段", exact: true, component: ShippingLineBuildSegment },
  { path: "/shipping_line_overview", title: "航线总览", exact: true, component: ShippingLineOverview },


  { path: "/patrol_map", title: "巡检地图", exact: true, component: PatrolMap },
  { path: "/pipeline_gad_map_show", title: "管道上帝图展示", exact: true, component: PipelineGadMapShow },
  // { path: "/MapReadout", title: "上帝图", exact: true, component: MapReadout },

]
