// 风险识别
import { useNavigate, useLocation, } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { Card, Table, Space, Dropdown, Switch, Form, Drawer, Radio, Button, InputNumber, Spin, message, Popconfirm } from 'antd'
import {
  MenuOutlined,
  EditOutlined,
  BlockOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { getRiskIdentification, isIdentify, subAlarmOrSimilarity, getAlarmDistanceInfo, setCordonInfoFun } from "@/services/risk_identification.js"; // 引入axios
import local from '@/utils/local'

function RiskIdentification () {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数

  const [form] = Form.useForm();
  const [similarityForm] = Form.useForm();
  const [cordonForm] = Form.useForm();

  const token = local.get('cruise_m30_token');
  const chooseId = useRef('');//当前选择的id
  const [switchLoading, setSwitchLoading] = useState(false);//开关是否加载

  const [loading, setLoading] = useState(false)//加载中···
  const [tableData, setTableData] = useState()//表格数据
  const [alarmDistanceList, setAlarmDistanceList] = useState({//警报距离 抽屉
    isOpen: false,
    btnOpen: true,
    loading: false,
    typeTitle: '',//类别名称
    risk_id: '',
    routes_type: '',
    startDistance: 0,//起始距离
    endDistance: 0,//结束距离
  })

  const [cordonList, setCordonList] = useState({//警戒线 抽屉
    isOpen: false,
    loading: false,
    distance: '',//距离
    isEnable: 0,//是否启用
  })

  const [similarityList, setSimilarityList] = useState({//相似度 抽屉
    isOpen: false,
    loading: false,
    typeTitle: '',//类别名称
    risk_id: '',
    routes_type: '',
    similarity: '',//相似度
  })

  const [dictionaryList, setDictionaryList] = useState({//数据字典集合
    typeList: [],//类型列表
  })

  const [searchList, setSearList] = useState({//搜索列表
    type: undefined,
  })

  let temp = ''
  let temp1 = ''
  let temp2 = ''
  let temp3 = ''
  let temp4 = ''
  let temp5 = ''

  const getAll = async (data) => {//获取全部信息
    setLoading(true)
    let res = await getRiskIdentification({ access_token: token, type: data.type })
    if (res.ret !== 200) { setLoading(false); message.warning(res.msg || '数据跑丢了'); return }
    let cor = cordonList
    cor.isEnable = res.data.cordon.is_enable
    cor.distance = res.data.cordon?.distance ? Number(res.data.cordon.distance) : null
    setCordonList({ ...cor })


    if (!searchList.type) {
      let sea = searchList
      sea.type = res.data.type_List[0].value
      setSearList({ ...sea })
    }

    let dic = dictionaryList
    dic.typeList = res.data.type_List
    setDictionaryList({ ...dic })


    setTableData([...res.data.items])
    setLoading(false)
  }

  //完成
  const onFinish = async (e) => {//提交警戒线
    let cor = cordonList
    cor.loading = true
    setCordonList({ ...cor })
    let res = await setCordonInfoFun({
      access_token: token,
      distance: e.cordon,
      is_enable: cordonList.isEnable ? '1' : '0'
    });
    cor.loading = false
    setCordonList({ ...cor })
    if (res.ret !== 200) {
      message.warning(res.mas || '系统出错啦!');
      return;
    };
    message.success('警戒线修改成功');
    cor.isOpen = false
    setCordonList({ ...cor })
  }

  const mergeCells = (text, columns) => {//合并单元格1
    // console.log('mergeCells', text, columns);
    let i = 0;
    let isContinuous = false;
    if (temp !== text) {
      temp = text
      tableData.forEach((res, index) => {
        if (res[columns] === temp) {
          i += 1
          isContinuous = true
        } else {
          if (isContinuous) { return }
        }
      })
    }
    // console.log('mergeCells', text, i);
    return { rowSpan: i }
  }

  const mergeCells1 = (text, columns) => {//合并单元格2
    // console.log('mergeCells', text, columns);
    let i = 0;
    let isContinuous = false;
    if (temp1 !== text) {
      temp1 = text
      tableData.forEach((res, index) => {
        if (res[columns] === temp1) {
          i += 1
          isContinuous = true
        } else {
          if (isContinuous) { return }
        }
      })
    }
    return { rowSpan: i }
  }
  const mergeCells2 = (text, columns) => {//合并单元格3
    // console.log('mergeCells', text, columns);
    let i = 0;
    let isContinuous = false;
    if (temp2 !== text) {
      temp2 = text
      tableData.forEach((res, index) => {
        if (res[columns] === temp2) {
          i += 1
          isContinuous = true
        } else {
          if (isContinuous) { return }
        }
      })
    }
    return { rowSpan: i }
  }
  const mergeCells3 = (text, columns) => {//合并单元格4
    // console.log('mergeCells', text, columns);
    let i = 0;
    let isContinuous = false;
    if (temp3 !== text) {
      temp3 = text
      tableData.forEach((res, index) => {
        if (res[columns] === temp3) {
          i += 1
          isContinuous = true
        } else {
          if (isContinuous) { return }
        }
      })
    }
    return { rowSpan: i }
  }
  const mergeCells4 = (text, columns) => {//合并单元格5
    // console.log('mergeCells', text, columns);
    let i = 0;
    let isContinuous = false;
    if (temp4 !== text) {
      temp4 = text
      tableData.forEach((res, index) => {
        if (res[columns] === temp4) {
          i += 1
          isContinuous = true
        } else {
          if (isContinuous) { return }
        }
      })
    }
    return { rowSpan: i }
  }
  const mergeCells5 = (text, columns) => {//合并单元格5
    // console.log('mergeCells', text, columns);
    let i = 0;
    let isContinuous = false;
    if (temp5 !== text) {
      temp5 = text
      tableData.forEach((res, index) => {
        if (res[columns] === temp5) {
          i += 1
          isContinuous = true
        } else {
          if (isContinuous) { return }
        }
      })
    }
    return { rowSpan: i }
  }

  const setAdsEnable = async (data) => {    //启用|禁用
    let obj = {
      type: 0,
      access_token: token,
      identify_id: data.identify_id,
      is_identify: data.is_identify === 1 ? 0 : 1,
      routes_type: searchList.type,
    }
    setSwitchLoading(true);
    let res = await isIdentify(obj);
    setSwitchLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let tab = [...tableData];

    tab.forEach(res => {
      if (res.identify_id === obj.identify_id) {
        res.is_identify = obj.is_identify
      }
    })
    setTableData(tab);
  }

  const getAlaOrSimInfo = async (type) => { //获取警报距离、相似度信息
    let res = await getAlarmDistanceInfo({ access_token: token, routes_type: searchList.type })
    if (res.ret !== 200) { message.warning(res.msg || '数据跑丢了'); return }
    if (type === 'alarm') {
      let ala = alarmDistanceList
      res.data.forEach(res => {
        if (res.routes_type === ala.routes_type && res.risk_id === ala.risk_id) {
          ala.startDistance = Number(res.min_range) || 0
          ala.endDistance = Number(res.max_range) || 0
          if ((ala.startDistance !== ala.endDistance && ala.endDistance === 0) || (ala.startDistance > ala.endDistance)) {
            ala.btnOpen = true
          } else {
            ala.btnOpen = false
          }
          setAlarmDistanceList({ ...ala })
        }
      })
    }
    if (type === 'similarity') {
      let sim = similarityList
      res.data.forEach(res => {
        if (res.routes_type === sim.routes_type && res.risk_id === sim.risk_id) {
          sim.similarity = res.threshold * 100 || null
          setSimilarityList({ ...sim })
        }
      })
    }
  }

  const submitForm = async (type) => {//提交表单信息
    let obj = {}
    if (type === 'alarm') {
      obj = {
        type: 1,
        routes_type: searchList.type,
        access_token: token,
        risk_id: alarmDistanceList.risk_id,
        min_range: alarmDistanceList.startDistance ? alarmDistanceList.startDistance : 0,
        max_range: alarmDistanceList.endDistance ? alarmDistanceList.endDistance : 0,
      }
    }
    if (type === 'similarity') {
      obj = {
        type: 1,
        routes_type: searchList.type,
        access_token: token,
        risk_id: similarityList.risk_id,
        threshold: similarityList.similarity / 100,
      }
    }
    let res = await subAlarmOrSimilarity(obj)
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    if (type === 'alarm') {
      let ala = alarmDistanceList
      ala.isOpen = false
      setAlarmDistanceList({ ...ala })
    }
    if (type === 'similarity') {
      let sim = similarityList
      sim.isOpen = false
      setSimilarityList({ ...sim })
    }
    getAll(searchList)
  }

  const dropDownOption = (data) => {//表格操作列表
    const typeArr1 = [
      {
        key: '1',
        permissions: 3,
        label: (
          <div
            onClick={() => {
              let ala = alarmDistanceList
              ala.isOpen = true
              ala.startDistance = 0
              ala.endDistance = 0
              ala.typeTitle = data.risk_name
              ala.risk_id = data.risk_id
              ala.routes_type = data.routes_type
              setAlarmDistanceList({ ...ala })
              getAlaOrSimInfo('alarm')
            }}
          >
            <EditOutlined className='marR10' /> 警报距离
          </div>
        ),
      },
      {
        key: '2',
        permissions: 5,
        label: (
          <div onClick={() => {
            let sim = similarityList
            sim.isOpen = true
            sim.typeTitle = data.risk_name
            sim.risk_id = data.risk_id
            sim.routes_type = data.routes_type
            setSimilarityList({ ...sim })
            getAlaOrSimInfo('similarity')
          }}
          >
            <BlockOutlined className='marR10' /> 相似度
          </div>
        ),
      },
    ]

    return { items: typeArr1 }
  }

  const columns = [//表头
    {
      title: '类别',
      dataIndex: 'risk_id',
      width: 80,
      align: 'center',
      render: (text, record) => {
        return (
          <div >
            {
              record.risk_name && record.risk_name !== '-'
                ? <span className='opa85' > {record.risk_name}</span>
                : <span className='opa25'>-</span>
            }
          </div >
        )
      },
      onCell: (_, index) => {
        return mergeCells(_.risk_id, 'risk_id')
      }
    },
    {
      title: '小类',
      width: 300,
      dataIndex: 'risk_rid',
      render: (text, record) => {
        return (
          <div >
            {
              record.rname && record.rname !== '-'
                ? <span className='opa85' > {record.rname}</span>
                : <span className='opa25'>-</span>
            }
          </div >
        )
      },
      onCell: (_, index) => {
        return mergeCells1(_.risk_rid, 'risk_rid')
      }
    },
    {
      title: '本体',
      dataIndex: 'risk_cid',
      render: (text, record, index) => {
        return (
          <div >
            {
              record.name && record.name !== '-'
                ? <span className='opa85' > {record.name}</span>
                : <span className='opa25'>-</span>
            }
          </div >
        )
      },
    },
    {
      title: '启用',
      dataIndex: 'identify_id',
      width: 80,
      align: 'center',
      render: (text, record, index) => {
        return (
          <div>
            {
              text
                ? (
                  <Popconfirm
                    // disabled={!(permissionsList.indexOf('appgggl_up_stop') >= 0)}
                    title={`是否${record.is_identify === 0 ? '启用' : '停用'}？`}
                    okText={`${record.is_identify === 0 ? '启用' : '停用'}`}
                    cancelText='取消'
                    onConfirm={() => {
                      console.log('111');
                      chooseId.current = record.identify_id;
                      setAdsEnable(record, index);
                    }}
                    onCancel={() => {
                    }}
                  >
                    <Switch size='small' loading={chooseId.current === record.id && switchLoading}
                      // disabled={!(permissionsList.indexOf('appgggl_up_stop') >= 0)}
                      checked={record.is_identify === 1 ? true : false}
                      onClick={() => { }}
                    />
                  </Popconfirm>
                )
                : <div style={{ color: colorTextQuaternary }}>-</div>
            }
          </div>
        )
      },
      onCell: (_, index) => {
        return mergeCells4(_.identify_id, 'identify_id')
      }

    },
    {
      title: '警报距离',
      dataIndex: 'min_range',
      onCell: (_, index) => {
        return mergeCells2(_.risk_id, 'risk_id')
      },
      render: (text, record) => {
        return (
          text && record.max_range
            ? (
              <div className='opa85'>
                {
                  Number(record.min_range) === 0 && Number(record.max_range) === 0
                    ? <span>全图警报</span>
                    : <span>{text} ~ {record.max_range}m</span>
                }

              </div>
            )
            : (
              <div>-</div>
            )
        )
      },

    },
    {
      title: '相似度',
      dataIndex: 'threshold',
      onCell: (_, index) => {
        return mergeCells3(_.risk_id, 'risk_id')
      },
      render: (text, record) => {
        return (
          <div className='opa85'>
            {text}
          </div>
        )
      }
    },
    {
      title: '操作',
      dataIndex: '',
      align: 'center',
      width: 120,
      render: (text, record) => {
        return (
          <Dropdown
            menu={dropDownOption(record)}
            placement="bottom"
          >
            <MenuOutlined className='pointer col_primary' />
          </Dropdown>
        )
      },
      onCell: (_, index) => {
        return mergeCells5(_.risk_id, 'risk_id')
      }
    },
  ]

  useEffect(() => {
    getAll(searchList)

    // eslint-disable-next-line
  }, [])

  return (
    <Card className='mar24' style={{ border: 0 }}>
      <div className='marB24 flex jcsb align_center'>

        <Radio.Group
          value={searchList.type}
          onChange={(e) => {
            let sea = searchList
            sea.type = e.target.value
            setSearList({ ...sea })
            getAll(sea)
          }}
        >
          {
            dictionaryList.typeList.length > 0 && dictionaryList.typeList.map((ele, index) => {
              return (
                <Radio.Button value={ele.value} key={index}>{ele.label}</Radio.Button>
              )
            })
          }
        </Radio.Group>


        <Button
          type='primary'
          onClick={async () => {
            let cor = cordonList
            cor.isOpen = true
            setCordonList({ ...cor })
            cordonForm.setFieldsValue({
              cordon: cor.distance ? Number(cor.distance) : null,
            });
          }}
        >
          警戒线
        </Button>
      </div>
      <Table
        rowKey='risk_cid'
        bordered
        loading={loading}
        dataSource={tableData}
        columns={columns}
        pagination={false}
      />

      {/* 警报距离 */}
      <Drawer
        width={256}
        title="警报距离"
        onClose={() => {
          let ala = alarmDistanceList
          ala.isOpen = false
          setAlarmDistanceList({ ...ala })
        }}
        destroyOnClose={true}
        open={alarmDistanceList.isOpen}
        extra={
          <Space>
            <Button
              disabled={alarmDistanceList.btnOpen}
              type="primary"
              onClick={() => {
                submitForm('alarm')
              }}
            >
              提交
            </Button>
          </Space>
        }
      >

        <Form
          layout='vertical'
          form={form}
        >
          <Form.Item
            label={`${alarmDistanceList.typeTitle}识别-起始距离`}
            tooltip={{
              title: 'AI识别风险时，从距离管线此距离开始才会识别，当起始结束距离都为 0 ，则全距离报警',
              icon: <QuestionCircleOutlined />,
              placement: 'left',
            }}
          >
            <InputNumber
              value={alarmDistanceList.startDistance}
              min={0}
              addonAfter="m"
              placeholder='-'
              className='wid100'
              onChange={(e) => {
                let ala = alarmDistanceList
                ala.startDistance = e
                if ((ala.startDistance !== ala.endDistance && ala.endDistance === 0) || (ala.startDistance > ala.endDistance)) {
                  ala.btnOpen = true
                } else {
                  ala.btnOpen = false
                }
                setAlarmDistanceList({ ...ala })
              }}
            />
          </Form.Item>

          <Form.Item
            label={`${alarmDistanceList.typeTitle}识别-结束距离`}
            tooltip={{
              title: 'AI识别风险时，距离管线此距离内才会识别，当起始结束距离都为 0 ，则全距离报警',
              icon: <QuestionCircleOutlined />,
              placement: 'left',
            }}
          >
            <InputNumber
              value={alarmDistanceList.endDistance}
              addonAfter="m"
              min={0}
              placeholder='-'
              className='wid100'
              onChange={(e) => {
                let ala = alarmDistanceList
                ala.endDistance = e
                if ((ala.startDistance !== ala.endDistance && ala.endDistance === 0) || (ala.startDistance > ala.endDistance)) {
                  ala.btnOpen = true
                } else {
                  ala.btnOpen = false
                }
                setAlarmDistanceList({ ...ala })
              }}
            />
          </Form.Item>
        </Form>

      </Drawer>

      {/* 相似度 */}
      <Drawer
        title="相似度"
        width={256}
        onClose={() => {
          let sim = similarityList
          sim.isOpen = false
          setSimilarityList({ ...sim })
        }}
        destroyOnClose={true}
        open={similarityList.isOpen}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                submitForm('similarity')
              }}
            >
              提交
            </Button>
          </Space>
        }
      >

        <Form
          layout='vertical'
          form={similarityForm}
        >
          <Form.Item
            label={`${similarityList.typeTitle}识别-相似度`}
            tooltip={{
              title: 'AI识别风险时，相似度大于该值才会报警',
              icon: <QuestionCircleOutlined />,
              placement: 'left',
            }}
          >
            <InputNumber
              value={similarityList.similarity}
              addonAfter="%"
              placeholder='-'
              min='30'
              max='99'
              className='wid100'
              onChange={(e) => {
                let sim = similarityList
                if (e > 0) {
                  sim.similarity = e
                } else {
                  sim.similarity = null
                }
                setSimilarityList({ ...sim })
              }}
            />
          </Form.Item>
        </Form>

      </Drawer>


      {/* 警戒线 */}
      <Drawer
        width={256}
        title="警戒线"
        placement="right"
        destroyOnClose={true}
        open={cordonList.isOpen}
        onClose={() => {
          let cor = cordonList
          cor.isOpen = false
          setCordonList({ ...cor })
        }}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                document.getElementById('cordonSubmit').click();
              }}
            >
              提交
            </Button>
          </Space>
        }
      >
        <Spin spinning={cordonList.loading}>
          <Form
            layout='vertical'
            form={cordonForm}
            onFinish={onFinish}
          >
            {/* <div className='flex_row align_end justify_between'> */}
            <Form.Item
              label="警戒线"
              name='cordon'
              rules={[
                {
                  required: cordonList.isEnable,
                  message: '警戒线距离!',
                },
              ]}
            >
              <InputNumber
                className='wid100'
                placeholder="警戒线距离"
                disabled={cordonList.isEnable ? false : true}
              />
            </Form.Item>

            <Form.Item
              label='启用'
            >
              <Switch
                checked={cordonList.isEnable}
                onChange={(e) => {
                  let cor = cordonList
                  cor.isEnable = e
                  setCordonList({ ...cor })
                }}
              />
            </Form.Item>
            {/* </div> */}

            <div>
              <span className='col_error'>※</span><span className='opa45'> 说明：在管道两边设定距离显示，仅起到警示作用</span>
            </div>

            <Form.Item style={{ display: 'none' }}>
              <Button type="primary" id='cordonSubmit' htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </Card>
  )
}
export default RiskIdentification;