// 系统设置
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect } from 'react'
import { MenuOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { getUserInfoListApi, getPurviewInfo, setPurviewInfo, submitUserInfoApi, getUserInfoApi, delUserApi } from '@/services/permission_settings.js'
import { getTreeDataByDelayeringData } from '@/common/js/common.js'
import local from '@/utils/local'
import { Card, Table, Space, Dropdown, Button, message, Drawer, Spin, Tree, Form, Input, } from 'antd'

function PermissionSettings () {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数
  const searchInput = useRef(null);//搜索框数据
  const token = local.get('cruise_m30_token');
  const [loading, setLoading] = useState(false)//加载中···
  const [form] = Form.useForm();

  //表格筛选条件
  const [dataList, setDataList] = useState({
    dataSource: [],//表格数据
  });

  const [purviewList, setPurviewList] = useState({//权限列表
    UID: '',//当前选择的ID
    isShowBtn: true,
    isOpen: false,//是否打开抽屉
    isLoading: false,
    allPurview: [],//所有权限
    curPurview: [],//当前拥有的权限
    subPurview: [],//需要提交的权限
  })

  const [userList, setUserList] = useState({//新增、修改用户信息列表
    open: false,
    id: '',//用户id
    loading: false,
    company_id: localStorage.getItem('cruise_m30_companyId'),
    parent_uid: '',//父级id
    newPassword: '',//新密码
    confirmPassword: '',//确认新密码
  })

  const [searchList, setSearchList] = useState({
    title: '',
    username: '',//账号搜索
    total: 0,
    current: 1,
    pageSize: 10,
  });

  // 获取用户信息
  const getAllData = async (search) => {
    let sea = searchList
    setLoading(true)
    let dat = dataList
    let res = await getUserInfoListApi({
      access_token: token,
      username: search?.username || undefined
    })
    if (res.ret === 200) {
      dat.dataSource = res.data.items;
    } else {
      message.warning(res.msg || '系统出错啦，请稍后重试')
    }
    sea.total = res.data.total || 0
    setSearchList({ ...sea })
    setDataList({ ...dat })
    setLoading(false)
  }

  // 获取权限信息
  const getPurviewData = async (id) => {
    let res = await getPurviewInfo({
      access_token: token,
      UID: id
    })
    let pur = purviewList
    if (res.ret === 200) {
      pur.allPurview = JSON.parse(JSON.stringify(getTreeDataByDelayeringData(res.data.menus, 'parentid', 'id')).replaceAll('cname', 'title').replaceAll('currentId', 'key'))
      pur.curPurview = res.data.menuid
      pur.subPurview = res.data.menuid
    } else {
      message.warning(res.msg || '系统出错啦，请稍后重试')
    }
    pur.isLoading = false
    setPurviewList({ ...pur })

  }

  const onChange = (pagination, filters, sorter, extra) => {//表头搜索 change方法
    let sea = searchList;
    sea.username = filters.username ? filters.username : '';
    setSearchList({ ...sea })
    getAllData(sea)
  };

  // 分页、排序、筛选变化时触发
  const handleTableChange = (newPagination, filters, sorter) => {
    let sea = searchList;
    // 分页
    sea.current = newPagination.current;
    sea.pageSize = newPagination.pageSize;
    setSearchList({ ...sea })
    getAllData(sea) //加载列表
  };

  const pwdValidator = (rule, value, callback) => {//校验  密码
    if (!value) {
      return callback('请再次输入新密码')
    } else if (value !== userList.newPassword) {
      return callback('两次密码不相同请重新输入')
    } else {
      return callback()
    }
  }

  const handleSearch = (confirm, dataIndex) => {// 输入框搜索
    confirm()
  };

  const handleReset = (clearFilters, confirm) => { //重置
    clearFilters();
    confirm();
  };

  const getTxtSearch = (dataIndex) => ({//文本 搜索框
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      < div className='pd8' onKeyDown={(e) => e.stopPropagation()} >
        <div>
          <Input ref={searchInput}
            placeholder={`搜索 ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
            onPressEnter={() => handleSearch(confirm)}
          />
        </div>
        <Space className='pdT8 flex jcsb'>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜索
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
        </Space>
      </ div >
    ),
    filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : '' }} />),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  })

  //表格操作列表
  const dropDownOption = (data) => {
    let typeArr = [
      {
        key: '3',
        show: true,
        label: (
          <div
            onClick={async () => {
              console.log('data', data);
              form.resetFields()
              let use = userList
              use.open = true
              use.loading = true
              use.id = data.id
              use.parent_uid = data.parent_uid
              setUserList({ ...use })
              let res = await getUserInfoApi({ access_token: token, user_id: data.id })
              if (res.ret !== 200) {
                use.loading = false
                setUserList({ ...use })
                message.warning(res.msg || '系统出错啦，请重试！');
                return
              }
              form.setFieldsValue({
                nickname: res.data?.nickname || '',
                mobile: res.data?.mobile || '',
                username: res.data?.username || '',
                password: '',
                confirm_password: '',
              })
              use.loading = false
              setUserList({ ...use })
            }}
          >
            <EditOutlined className='marR10' /> 修改
          </div>
        ),
      },
      {
        key: '1',
        show: true,
        label: (
          <div onClick={() => {
            let pur = purviewList
            pur.UID = data.id
            pur.isOpen = true
            pur.isLoading = true
            pur.allPurview = []
            setPurviewList({ ...pur })
            getPurviewData(data.id)
          }}>
            <EditOutlined className='marR10' /> 设置权限
          </div>
        ),
      },
      {
        key: '2',
        show: data?.children.length > 0 ? false : true,
        label: (
          <div
            onClick={async () => {
              console.log('item', data);
              let res = await delUserApi({ access_token: token, user_id: data.id, is_delete: 0 })
              if (res.ret !== 200) {
                message.warning(res.msg || '系统出错啦，请重试！');
                return
              }
              getAllData(searchList)
            }}
          >
            <DeleteOutlined className='marR10' /> 删除
          </div>
        ),
      },
    ]

    let arrList = []
    typeArr.forEach(res => {
      if (res?.show) {
        arrList.push(res)
      }
    })

    return { items: arrList }
  }

  //提交 权限管理
  const submitPermissionSettings = async () => {
    let pur = { ...purviewList }
    let list = {
      access_token: token,
      UID: purviewList.UID,
      menuids: purviewList.subPurview ? purviewList.subPurview.join(',') : '',
    }
    let res = await setPurviewInfo(list)
    if (res.ret === 200) {
      pur.isLoading = false
      pur.isOpen = false
      pur.isShowBtn = true
      message.success('更改成功！');
      getAllData(searchList)
    } else {
      pur.isLoading = false
      message.warning(res.msg || '系统出错啦，请重试！');
    }
    setPurviewList({ ...pur })
  }


  // 表头
  const columns = [
    {
      title: '账号',
      dataIndex: 'username',
      render: (text, record) => {
        return (
          text
            ? <span>{text}</span>
            : <div className='opa25'>-</div>
        )
      },
      ...getTxtSearch('账号')
    },
    {
      title: '姓名',
      dataIndex: 'nickname',
      render: (text, record) => {
        return (
          text
            ? <span>{text}</span>
            : <div className='opa25'>-</div>
        )
      }
    },
    {
      title: '手机',
      dataIndex: 'mobile',
      render: (text, record) => {
        return (
          text
            ? <span>{text}</span>
            : <div className='opa25'>-</div>
        )
      }
    },
    {
      title: '最后登录IP',
      dataIndex: 'ip',
      render: (text, record) => {
        return (
          text
            ? <div className='opa45'>{text}</div>
            : <div className='opa25'>-</div>
        )
      }
    },
    {
      title: '编辑人',
      dataIndex: 'update_user',
      render: (text, record) => {
        return (
          text
            ? <div className='opa45'>{text}</div>
            : <div className='opa25'>-</div>
        )
      }
    },
    {
      title: '编辑时间',
      dataIndex: 'update_time',
      render: (text, record) => {
        return (
          text
            ? <div className='opa45'>{text}</div>
            : <div className='opa25'>-</div>
        )
      }
    },
    {
      title: '操作',
      dataIndex: '',
      align: 'center',
      width: 120,
      render: (text, record) => {
        return (
          <Dropdown
            menu={dropDownOption(record)}
            placement="bottom"
          >
            <MenuOutlined className='pointer col_primary' />
          </Dropdown>
        )
      }
    },
  ]

  const submitUserInfo = async (e) => {//提交用户信息
    let use = userList
    use.loading = true
    setUserList({ ...use })
    let obj = {
      access_token: token,
      user_id: userList.id,
      nickname: e?.nickname || '',
      username: e?.username || '',
      password: e?.password || '',
      mobile: e?.mobile || '',
      company_id: userList.company_id || '',
      parent_uid: userList.parent_uid || '',
    }
    let res = await submitUserInfoApi(obj)
    if (res.ret !== 200) {
      use.loading = false
      setUserList({ ...use })
      message.warning(res.msg || '系统出错啦，请重试！');
      return
    }
    message.success(res.msg || '提交成功')
    use.loading = false
    use.open = false
    setUserList({ ...use })
    getAllData(searchList)
  }


  useEffect(() => {
    getAllData()
    // eslint-disable-next-line
  }, [])

  return (
    <Card className='mar24'>
      {/* 新增 */}
      <div className="flex_row pdRL12 pdB16 jcr align_center">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            let use = userList
            use.open = true
            use.id = ''
            use.parent_uid = ''
            setUserList({ ...use })
            form.resetFields()
          }}
        >
          新增
        </Button>
      </div>

      {/* 甲方所有信息表格 */}
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataList.dataSource}
        rowKey='id'
        onChange={onChange}
      // pagination={{
      //   total: total,
      //   current: searchList.current,
      //   pageSize: searchList.pageSize,
      //   showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      //   showQuickJumper: true,
      //   showSizeChanger: true,
      // }}
      // onChange={handleTableChange}
      >

      </Table>



      <>{/* 新增、修改 */}
        <Drawer
          title={`${userList.id ? '修改' : '新增'}`}
          placement="right"
          width={280}
          onClose={() => {
            let use = userList
            use.open = false
            setUserList({ ...use })
          }}
          open={userList.open}
          extra={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  document.getElementById('subInfo').click()
                }}
              >
                确定
              </Button>
            </Space>
          }
        >
          <Spin spinning={userList.loading}>
            <Form
              form={form}
              layout="vertical"
              name="userForm"
              onFinish={(e) => {
                submitUserInfo(e)
              }}
            >

              <Form.Item
                label="名称"
                name="nickname"
              >
                <Input placeholder='昵称' />
              </Form.Item>

              <Form.Item
                label="手机号"
                name="mobile"
              >
                <Input placeholder='手机号' />
              </Form.Item>

              <Form.Item
                label="账号"
                name="username"
                rules={[
                  {
                    required: true,
                    message: '请输入账号',
                  },
                  {
                    pattern: /^[^\u4e00-\u9fa5]+$/,
                    message: '账号不能使用中文'
                  }
                ]}
              >
                <Input placeholder='账号' />
              </Form.Item>


              <Form.Item
                label="密码"
                name="password"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: '请输入新密码',
                  },
                  {
                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{12,}$/,
                    message: '密码不低于12位，且包含大小写英文、数字、符号'
                  }
                ]}
              >
                <Input.Password
                  placeholder='密码'
                  value={userList.newPassword}
                  onChange={(value) => {
                    let use = userList
                    use.newPassword = value.target.value
                    setUserList({ ...use })
                  }}
                />
              </Form.Item>

              <Form.Item
                label="确认密码"
                name="confirm_password"
                validateTrigger="onBlur"
                required
                rules={[
                  {
                    validator: pwdValidator,
                  }
                ]}
              >
                <Input.Password
                  placeholder='确认密码'
                  value={userList.confirmPassword}
                  onChange={(value) => {
                    let use = userList
                    use.confirmPassword = value.target.value
                    setUserList({ ...use })
                  }} />
              </Form.Item>

              <Form.Item style={{ display: 'none' }}>
                <Button type="primary" id='subInfo' htmlType="submit" />
              </Form.Item>

            </Form>


          </Spin>
        </Drawer>
      </>

      <>{/* 权限管理 */}
        <Drawer
          title="修改权限"
          placement="right"
          width={280}
          onClose={() => {
            let pur = purviewList
            pur.isOpen = false
            pur.isShowBtn = true
            setPurviewList({ ...pur })
          }}
          open={purviewList.isOpen}
          extra={
            <Space>
              <Button
                type="primary"
                disabled={purviewList.isShowBtn}
                onClick={() => { submitPermissionSettings() }}
              >
                确定
              </Button>
            </Space>
          }
        >
          <Spin spinning={purviewList.isLoading}>

            <Tree
              checkable
              showLine={{ showLeafIcon: <div></div> }}
              showIcon={true}
              autoExpandParent={true}
              defaultExpandAll='true'
              defaultExpandParent={true}
              onSelect={() => { }}
              checkedKeys={purviewList.curPurview}//默认选中复选框的节点
              onCheck={(key, info) => {
                let pur = purviewList
                pur.curPurview = key ? key : []
                pur.subPurview = key && info.halfCheckedKeys ? key.concat(info.halfCheckedKeys) : []
                pur.isShowBtn = false
                console.log(key, info.halfCheckedKeys);
                setPurviewList({ ...pur })
              }}
              treeData={purviewList.allPurview}
            />

          </Spin>
        </Drawer>
      </>
    </Card>
  )
}
export default PermissionSettings;