import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Checkbox, Form, Button, Input, message, Card } from 'antd'
import { UserOutlined, LockOutlined, InsuranceOutlined } from '@ant-design/icons';
import { GetSignIn, GetUserInfo, getAllPermissions } from "@/services/home"; // 引入Ajax
import { getLoginVerifyApi } from "@/services/login.js"; // 引入Ajax
import local from '@/utils/local'
import axios from 'axios';
import './index.less'

export default function Login (props) {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [SigninLoading, _SigninLoading] = useState(false);
  const [isNeedCode, setIsNeedCode] = useState(false)//是否需要验证码

  const [codeUrl, setCodeUrl] = useState(`/api/admin.php?service=Admin.Common.CreateCaptcha`)//验证码地址

  // 登录
  const onFinish = async (values) => {
    localStorage.setItem('cruise_m30_permissionsArrName', '');
    _SigninLoading(true)
    const res = await GetSignIn({
      username: values.username,
      password: values.password,
      captcha: values.code,
    });
    if (res.ret !== 200) {
      _SigninLoading(false);
      message.warning(res.msg || '登录失败');
      setTimeout(() => {
        setIsNeedCode(true)
        let imgDom = document.getElementById('ChangeCode')
        imgDom.src = codeUrl
      }, 800);
      return;
    } else {
      setIsNeedCode(false)
      local.set('cruise_m30_token', res.data.access_token)
      local.set('cruise_m30_isLogin', true)
      if (values.remember) {
        local.set('cruise_m30_username', values.username);
        local.set('cruise_m30_password', values.password);
      }

      // 获取当前账号角色，并展示不同的菜单
      const res1 = await GetUserInfo({
        access_token: res.data.access_token,
      });
      if (res1.ret === 200) { // eslint-disable-line
        local.set('cruise_m30_memberLevel', res1.data.profile.member_level)
        // || ['/data_monitor', '/abnormal_troubleshooting', '/inspection_live_broadcast'].indexOf(local.get('cruise_m30_cruiseMenuKeys')) >= 0

        let res2 = await getAllPermissions({ access_token: res.data.access_token, member_level: res1.data.profile.member_level })
        if (res2.ret !== 200) { message.warning(res2.msg); return }
        if (res2.data.menu && res2.data.menu.length) {
          window.localStorage.setItem('cruise_m30_permissionsArr', JSON.stringify(res2.data.menuids));
          localStorage.setItem('cruise_m30_permissionsArrName', JSON.stringify(res2.data.menuName || ''));
        }
        _SigninLoading(false);
        if (Number(local.get('cruise_m30_memberLevel')) !== 255 && (!local.get('cruise_m30_cruiseMenuKeys')) && local.get('cruise_m30_permissionsArr')) {
          local.set('cruise_m30_cruiseMenuKeys', '/inspection_pilots');
          local.set('cruise_m30_defaultOpenKeys', 'shipping_line');
          setTimeout(() => {
            navigate('/user_manual');
          }, 1000);
        } else {
          local.set('cruise_m30_cruiseMenuKeys', '/routine/pipeline');
          setTimeout(() => {
            navigate('/routine/pipeline');
          }, 1000);
        }
      } else {
        //console.log(res1.msg);
        _SigninLoading(false);
        message.warning(res1.msg || '登录失败，请稍后重试~')
      }
    }
    _SigninLoading(false);
  }

  const loginVerify = async (values) => { //登陆前的验证
    let res = await getLoginVerifyApi({ username: values.username })
    if (res.data.verify) {
      setIsNeedCode(true)
    } else {
      onFinish(values)
    }
  }

  useEffect(() => {
    let username = local.get('cruise_m30_username') || '';
    let password = local.get('cruise_m30_password') || '';
    local.clear();
    form.setFieldsValue({
      username: username,
      password: password,
      remember: true,
    });
    local.set('cruise_m30_username', username);
    local.set('cruise_m30_password', password);
    // eslint-disable-next-line
  }, [])
  return (
    <div className='home'>
      <Card>
        <div className="login jb">
          <div className="P_login">
            <div></div>
            <div
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* img */}
              <div className="ipt_title" >
                <div style={{ fontSize: '31px' }}>管理系统</div>
              </div>
              {/* 表单 */}
              <div className='ipt_con'>
                <Form
                  form={form}
                  className=""
                  name="normal_login"
                  onFinish={isNeedCode ? onFinish : loginVerify}//验证码存在则直接调用登录，验证码不存在则调用验证
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: '请输入账号!',
                      },
                    ]}
                  >
                    <Input size="large" prefix={<UserOutlined className="site-form-item-icon" style={{ color: '#007be4' }} />} placeholder="账号" />
                  </Form.Item>


                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: '请输入密码!',
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      prefix={<LockOutlined className="site-form-item-icon" style={{ color: '#007be4' }} />}
                      type="password"
                      placeholder="密码"
                      style={{ marginTop: '6px' }}
                    />
                  </Form.Item>

                  {
                    isNeedCode && codeUrl &&
                    <Form.Item
                      className='h40'
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: '请输入验证码',
                        },
                      ]}
                    >
                      <div className='h40 flex jcsb align_center'>
                        <Input
                          className='h40 marR8'
                          size="large"
                          prefix={<InsuranceOutlined className="site-form-item-icon" style={{ color: '#007be4' }} />}
                          // type="password"
                          placeholder="验证码"
                          style={{ width: 'calc(100% - 168px)' }}
                        />
                        <img
                          // className='w160 h40'
                          id='ChangeCode'
                          src={codeUrl}
                          alt='验证码'
                          onClick={() => {
                            let imgDom = document.getElementById('ChangeCode')
                            imgDom.src = codeUrl
                          }}
                        />
                      </div>
                    </Form.Item>
                  }

                  <Form.Item style={{ minHeight: '22px' }}>
                    <Form.Item name="remember" valuePropName="checked" noStyle className='checkbox'>
                      <Checkbox style={{ marginTop: '1px' }}>记住此账号</Checkbox>
                    </Form.Item>
                  </Form.Item>
                  <Button loading={SigninLoading} type="primary" htmlType="submit" className="wid100" size="large">
                    登录
                  </Button>
                </Form>
              </div>
            </div>

            <div className='zhanwei' />
            <div></div>
            {/* 底部文本框 */}
            {/* <div className='flex-col fSize14 fWei400 opacity0_45 margB27'>
              <div className='flex-row margB10 jusConCen'>
                <div className='tt_a_cen margR100'>智能科技</div>
                <div className='tt_a_cen'>Ant Design</div>
              </div>
              <div className='disFlex jusConCen'  >
                Copyright ©2022 Produced by You Shu Group Technology Department
              </div>
            </div> */}
          </div>
        </div>
      </Card>
    </div>

  )

}
