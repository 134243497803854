import React, { useState, useEffect, useRef } from 'react'
import { Radio, Spin, message, Image as AImage, Badge, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'
import { SubCheckImg, GetImageLineData, SubmageLine, ImageLineCompressData, IgnoreAIAxis, SetAIAxis, DelAIAxis, SendMessageFun } from "@/services/abnormal_troubleshooting.js"; // 引入axios
import yyy from "./bjImg.jpg";
import hourglass from "@/common/img/abnormal_troubleshooting/hourglass.svg";
import local from '@/utils/local'
import AILabel, { async } from "ailabel";
import { basicPath } from '@/router/config.js'
// import 'antd/dist/antd.dark.css';

function Troubleshooting_steps () {
  const navigate = useNavigate();
  const token = local.get('cruise_m30_token');

  const [loading, SetLoading] = useState(false);//上传加载
  const [loadingText, SetLoadingText] = useState('正在上传...');//上传提示文本
  const Stateparams = useLocation();
  const params = Stateparams.state;
  const [con_arr, SetConArr] = useState(params.arr);//图片数据
  const con_arr_ref = useRef(params.arr);
  const [insPointId, SetInsPointId] = useState('');//
  const [pipelineId, SetPipelineId] = useState('');//
  const [pipelinepointId, SetPipelinepointId] = useState('');
  const [len, SetLen] = useState(params.len);//图片数据数量
  const [current, SetCurrent] = useState(0);//当前处理图片的下标
  const cur_ref = useRef(0);
  const [addtime, SetAddtime] = useState('');//时间
  const [point_order, SetPointOrder] = useState('');//航点
  const [pipe_name, SetPipeName] = useState('');//管线名称
  const [title, SetTitle] = useState('');//单元段名称
  const [companyId, SetCompanyId] = useState('');//公司id
  const [statid, SetStatid] = useState('');//排查列表id
  const [pointId, SetPointId] = useState('');//巡检节点id
  const pointIds = useRef('');//巡检节点id
  const [insId, SetInsId] = useState('');//
  const [address, SetAddress] = useState('');//地址
  const [latitude, SetLatitude] = useState('');//纬度
  const [longitude, SetLongitude] = useState('');//经度
  const [original_img, SetOriginalImg] = useState('');//原图片
  const [ori_img_wid, SetOriImgWid] = useState(0);//原图片宽度
  const [ori_img_hei, SetOriImgHei] = useState(0);//原图片高度
  const multiple = 2;//倍数
  const accident = useRef(-1);//当前选中的异常 下标
  const [ac_op, SetAcOp] = useState(-1)
  const [options, setOptions] = useState([]);//异常选项
  const optionsR = useRef([]);//异常选项
  const optionsValue = useRef([]);//异常选项值
  const optionsName = useRef([]);//异常选项名称
  const [abnormal, SetAbnormal] = useState([]);
  //绘制图像数据
  const [gMap, SetgMap] = useState(null);//组件实例
  const layer_feature = useRef(null); //实例化的矢量图层 在图像中绘制矩形等图像
  const layer_lable = useRef(null); //实例化标签
  const image_style = useRef(null);//图像样式
  const [is_exist, SetExist] = useState(false);//是否 触发渲染渲染图片

  const draw_times = useRef(0);//统计图像绘制次数
  // const [choose_img,SetChooseImg] = useState([]); //存放选择的的图像

  const choose_img = useRef([]); //存放选择的的图像

  const [deleteRiskList, setDeleteRiskList] = useState([]); //存放需要删除的风险

  const waypointList = useRef([]);//航点坐标

  const [isInitialize, setIsInitialize] = useState(false); //是否初始化

  //初始化异常选项
  const initializeAbnormal = async () => {

    let list = [
      { idx: 0, value: 2, name: '施工', key: 'sg', color: '#C55A11', max_range: null, min_range: null, display: 0 },
      { idx: 1, value: 8, name: '占压', key: 'zy', color: '#C55A11', max_range: null, min_range: null, display: 0 },
      { idx: 2, value: 3, name: '塌方', key: 'tf', color: '#C55A11', max_range: null, min_range: null, display: 0 },
      { idx: 3, value: 4, name: '水淹', key: 'sy', color: '#C55A11', max_range: null, min_range: null, display: 0 },
      { idx: 4, value: 9, name: '塌陷', key: 'tx', color: '#C55A11', max_range: null, min_range: null, display: 0 },
      { idx: 5, value: 5, name: '漏油', key: 'ly', color: '#C55A11', max_range: null, min_range: null, display: 0 },
      { idx: 6, value: 6, name: '焚烧', key: 'fs', color: '#C55A11', max_range: null, min_range: null, display: 0 },
      { idx: 7, value: -1, name: '双击选择', ky: 'xz', color: '#FFF' }
    ]
    let values = [2, 8, 3, 4, 9, 5, 6];
    let names = ['施工', '占压', '塌方', '水淹', '塌陷', '漏油', '焚烧'];

    setOptions(list);
    optionsR.current = list;
    optionsValue.current = values;
    optionsName.current = names;
    drawingComponent();
    setIsInitialize(true);
  }

  //初始化数据
  const initializeData = async () => {
    SetLoadingText('加载中...');
    SetLoading(true);
    //数据页面初始化
    let val = con_arr[current];
    SetAddtime(val.addtime);
    SetTitle(val.line_name);
    SetPipeName(val.pipe_name);
    SetCompanyId(val.CompanyID);
    SetStatid(val.statid);
    SetPointId(val.pointId);
    pointIds.current = val.pointId;
    SetAddress(val.location);
    SetPointOrder(val.point_order);
    SetInsId(val.insId);
    SetLatitude(val.latitude);
    SetLongitude(val.longitude);
    let data = {
      companyId: val.CompanyID,
      pointId: val.pointId,
    }
    let img_data = await ImageLineCompress(data);
    if (img_data) {
      SetOriginalImg(img_data.picUrl);
      SetPipelineId(img_data.pipelineid);
      SetInsPointId(img_data.insPointId);
      SetPipelinepointId(img_data.pipelinepointId);
      return img_data;
    }

  };

  //切换选项
  const changeAccident = (idx) => {
    accident.current = idx;
    SetAcOp(idx);
    if (idx === options.length - 1) { //最后一个是 双击选中
      setModeTo('POINT');
      return;
    }
    let val = options[idx];
    let obj = {
      col: val.color,
      key: val.key,
      name: val.name
    }
    image_style.current = obj;
    setModeTo('RECT')
  };

  //图片查看页
  const onPatrolMap = () => {
    const coordinate = {
      picUrl: original_img,
      longitude: longitude, //经度
      latitude: latitude, //纬度
    }
    window.sessionStorage.setItem('viewpictureid=tb' + current, JSON.stringify({
      pTitle: pipe_name,
      title: title,
      companyId: companyId,
      statid: statid,
      insIds: insId
    }))
    window.sessionStorage.setItem('patrol_map?id=tb' + current, JSON.stringify(coordinate))
    window.open(basicPath + '/patrol_map?id=tb' + current, '_blank')
  }

  //获取压缩后的图片以及ai识别的类型
  const ImageLineCompress = async (val) => {
    let paramData = {
      access_token: token,
      companyId: val.companyId,
      insPointId: val.pointId
    };
    const res = await ImageLineCompressData(paramData);
    if (res.ret === 200) { // eslint-disable-line
      return res.data
    } else {
      return false;
    }
  };

  //初始化图片
  const handleImg = async (img_data, c) => {
    let url = img_data.picUrl;
    // let url = yyy;
    let image = new Image();
    image.src = url;
    // 图片加载完成执行
    image.onload = async () => {
      SetOriImgWid(image.width);
      SetOriImgHei(image.height);
      let _gMap = new AILabel.Map('map', {
        center: { x: (image.width / 2), y: (image.height / 2) },
        zoom: image.width,
        mode: "PAN", // 绘制线段
        refreshDelayWhenZooming: true, // 缩放时是否允许刷新延时，性能更优
        zoomWhenDrawing: true,
        panWhenDrawing: true,
        zoomWheelRatio: 5, // 控制滑轮缩放缩率[0, 10), 值越小，则缩放越快，反之越慢
        withHotKeys: true, // 关闭快捷键
      });
      SetgMap(_gMap);
      const gFirstImageLayer = new AILabel.Layer.Image(
        "first-layer-image", // id
        {
          src: url,
          width: image.width,
          height: image.height,
          crossOrigin: false, // 如果跨域图片，需要置设为true
          position: {
            // 左上角相对中心点偏移量
            x: 0,
            y: 0,
          },
        }, // imageInfo
        { name: "第一个图片图层" }, // props
        { zIndex: 5 } // style

      );
      // 添加到gMap对象
      _gMap.addLayer(gFirstImageLayer);
      // 添加矢量图层
      const gFirstFeatureLayer = new AILabel.Layer.Feature(
        "first-layer-feature", // id
        { name: "第一个矢量图层" }, // props
        { zIndex: 10 } // style
      );
      layer_feature.current = gFirstFeatureLayer;
      _gMap.addLayer(gFirstFeatureLayer);

      // text-层
      const gFirstTextLayer = new AILabel.Layer.Text(
        'first-layer-text', // id
        { name: '第一个文本图层' }, // props
        { zIndex: 12, opacity: 1 } // style
      );
      layer_lable.current = gFirstTextLayer;

      _gMap.addLayer(gFirstTextLayer);
      addEvent(_gMap, gFirstFeatureLayer, gFirstTextLayer);
      window.onresize = function () {
        _gMap && _gMap.resize();
      };

      //图片加载完成后，默认选中 '双击选择'
      accident.current = optionsR.current.length - 1;
      SetAcOp(optionsR.current.length - 1);
      _gMap.setMode('POINT');//切换操作

      //返回ai识别的坐标
      return c(img_data.abnormal_point);
    };
  };

  const addEvent = (map, gF, gFtext) => {
    map.events.on("drawDone", (type, data) => {
      if (type !== 'RECT') {
        return;
      }
      addFeature(gF, data, gFtext);
    });

    //在编辑状态下 双击图像执行
    //type中可以获取图像的大部分信息，例如id、名称、坐标
    map.events.on("featureSelected", (type) => {
      if (Number(accident.current) !== optionsR.current.length - 1) {
        return;
      }
      let feature = layer_feature.current.getFeatureById(type.id);
      let ch_img = choose_img.current;
      if (ch_img?.indexOf(type.id) >= 0) {
        gF.removeFeatureById('choose' + type.id);
        ch_img.splice(ch_img?.indexOf(type.id), 1);
        choose_img.current = ch_img;
      } else {
        ch_img.push(type.id);
        choose_img.current = ch_img;
        let obj = {
          id: 'choose' + type.id,
          name: feature.props.name,
          x: type.shape.x,
          y: type.shape.y,
          w: type.shape.width,
          h: type.shape.height,
          col: 'white'
        }
        drawRect(layer_feature.current, obj);
      }
    });
  };

  //更换操作移动图片或者标注图形
  const setModeTo = (mode) => {
    if (gMap === null) {
      message.warning('图片加载未完成，暂不可进行操作');
      return
    }
    gMap.setMode(mode);//切换操作
  }

  //添加图形
  const addFeature = (gF, data, gFtext) => {
    let col = image_style.current.col;
    let id = image_style.current.key + [draw_times.current < 10 ? '0' + draw_times.current : draw_times.current];
    let name = image_style.current.name;
    const ltx = data.x;
    const lty = data.y;
    const rtx = data.x + data.width;
    const rty = data.y + data.height;
    const promise = new Promise((resolve, reject) => {
      let distance = pointIsInLineRange({ lx: ltx, ly: lty, rx: rtx, ry: rty });
      resolve(distance);
    });
    promise.then(
      result => {
        // //绘制标签 -- 不需要标签
        // const gFirstText = new AILabel.Text(
        //   id, // id
        //   { text: `${name} ${result}m`, position: { x: ltx, y: lty }, offset: { x: 0, y: 0 } }, // shape, 左上角
        //   { name: name }, // props
        //   { fillStyle: col, strokeStyle: col, background: true, globalAlpha: 1, fontColor: '#FFFFFF', font: 'normal 14px Arial', } // style
        //   // fillStyle 背景颜色  strokeStyle 边框颜色  fontColor 字体颜色
        // );
        // gFtext.addText(gFirstText);

        const rectFeature = new AILabel.Feature.Rect(
          id, // id
          data, // shape
          { name: name }, // props
          { strokeStyle: col, lineWidth: 4 } // style
        );
        draw_times.current = draw_times.current + 1;
        gF.addFeature(rectFeature);
      }, // "Promise resolved" logged after 2 seconds
      error => console.log(error) // undefined, no error
    );

  };

  //绘制矩形
  // f 矢量图层实例
  // l 标签实例
  // obj 对象用于存放下列属性 name 标签名称  id 矩形id  x x坐标  y y坐标  w 矩形宽度  h 矩形高度  col 矩形边框颜色
  const drawRect = (f_instance, obj) => {
    const rect = new AILabel.Feature.Rect(
      obj.id, // id
      { x: obj.x, y: obj.y, width: obj.w, height: obj.h }, // shape
      { name: obj.name }, // props
      { strokeStyle: obj.col, lineWidth: 4 } // style
    );
    f_instance.addFeature(rect);
    draw_times.current = draw_times.current + 1;
  }

  //提交 忽略AI识别区域坐标
  //提交 忽略的坐标
  //1 忽略  0 不忽略
  async function IgnoreAIAxisFun (arr, c) {
    let paramData = {
      access_token: token,
      id: '',// id
      insId: insPointId,
      pipelineId: pipelineId,
      pipelinepointId: pipelinepointId,

      risktype: arr.airisktype,//风险类型
      status: 1,//状态
      lx: arr.lx,//左上角 x坐标
      ly: arr.ly,//左上角 y坐标
      rx: arr.rx,//左上角 x坐标
      ry: arr.ry,//左上角 y坐标
      threshold: arr.threshold,//阈值
    };
    const res = await IgnoreAIAxis(paramData);
    if (res.ret === 200) {
      return c();
    } else {
      message.warning(res.msg || '系统出错啦')
    }
  }

  //提交 删除 AI识别区域坐标
  //0删除  1未删除
  async function DelAIAxisFun (arr, c) {
    let paramData = {
      access_token: token,
      id: arr.id,// id
      status: 0,//状态
    }
    const res = await SetAIAxis(paramData);
    if (res.ret === 200) {
      return c();
    } else {
      message.warning(res.msg || '');
    }

  }

  //新接口 提交 删除 AI识别区域坐标
  //ids 传字符串，例如：1,2,3,4
  //status 0删除  1未删除
  async function NewDelAIAxisFun (arr) {
    let ids = arr.join(',');
    let paramData = {
      access_token: token,
      ids: ids,// id
      status: 0,//状态
    }
    const res = await DelAIAxis(paramData);
    if (res.ret !== 200) {
      message.warning(res.msg || '');
    }
  }

  //提交 新增 AI识别区域坐标
  //0删除  1未删除
  async function AddAIAxisFun (obj) {
    let paramData = {
      access_token: token,
      id: '',
      insID: insId,//管线id
      pointID: pointId,//管线节点id
      risktype: obj.risktype,//风险类型
      status: 1,//状态
      lx: obj.lx * multiple,//左上角 x坐标
      ly: obj.ly * multiple,//左上角 y坐标
      rx: obj.rx * multiple,//左上角 x坐标
      ry: obj.ry * multiple,//左上角 y坐标
      threshold: 0,//阈值
      distance: obj.distance
    };
    const res = await SetAIAxis(paramData);
  }

  // 导出图片
  async function exportImage (c) {
    if (!gMap) {
      message.warning('图片未加载，无法提交');
      return;
    }
    let feature_arr = [...layer_feature.current.getAllFeatures()];
    for (let i = 0; i < feature_arr.length; i++) {
      let val = feature_arr[i];
      if (val.id.substr(0, 6) === "choose") {
        layer_lable.current.removeTextById('choose' + val.id);
        layer_feature.current.removeFeatureById('choose' + val.id);
        continue;
      }
    }
    const imagedata = await gMap.exportLayersToImage(
      { x: 0, y: 0, width: ori_img_wid, height: ori_img_hei },
      { type: 'base64', format: 'image/jpg' }
    );
    const imageDom = new Image();
    imageDom.setAttribute("crossOrigin", "anonymous");
    imageDom.src = imagedata;
    return c(imageDom.src);
  }

  //获取绘制的异常类型
  const getType = () => {
    let arr = [];
    let feature_arr = layer_feature.current.getAllFeatures(); //获取所有的feature对象 也就是绘制的图像
    for (let i = 0; i < feature_arr.length; i++) {
      let val = feature_arr[i];
      console.log(val);
      if (val.id.substr(0, 6) === "choose") {
        continue;
      }
      let text = val.props.name;//去掉 标签 距离 字样
      let type = options[optionsName.current.indexOf(text)].value;
      if (arr.indexOf(type) < 0) {
        arr.push(type);
      }
    }
    if (arr.length === 0) {
      arr = [1]
    }
    return arr.join(',');
  }

  //发送短信
  const sendMessage = async () => {
    let type = getType();
    if (type === '1') return;
    SetLoadingText('短信发送中...');
    SetLoading(true);
    let param = {
      access_token: token,
      risk_ids: type,
      longitude: longitude, //经度
      latitude: latitude, //纬度
    }
    let res = await SendMessageFun(param);
    SetLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错啦!');
      return
    }
    message.success('短信发送成功');
  }

  //上传数据
  const submit = async (val) => {
    SetLoadingText('正在上传...');
    SetLoading(true);
    let paramData = {
      access_token: token,
      companyId: companyId,
      statid: statid,
      pointId: pointId,
    };
    paramData.upload_base = val;
    let type = getType();
    paramData.abnormal_type = type;
    const res = await SubCheckImg(paramData);
    SetLoading(false);
    if (res.ret !== 200) {
      SetLoading(false);
      message.warning('提交失败');
      return;
    }

    accident.current = '';
    SetAcOp('');
    //新增 识别图像
    let feature_arr = layer_feature.current.getAllFeatures(); //获取所有的feature对象 也就是绘制的图像
    for (let i = 0; i < feature_arr.length; i++) {
      let feature = feature_arr[i];
      //选择的数据
      if (feature.id.substr(0, 6) === "choose") {
        continue;
      }
      //ai识别的数据
      if (feature.id.substr(-2, 2) === "ai") {
        continue;
      }
      let text = feature.props.name;
      let optionInfo = options[optionsName.current.indexOf(text)];
      let obj = {
        insID: insId,
        pointId: pointId,
        lx: parseInt(feature.shape.x),
        ly: parseInt(feature.shape.y),
        rx: parseInt(feature.shape.x) + parseInt(feature.shape.width),
        ry: parseInt(feature.shape.y) + parseInt(feature.shape.height),
        risktype: optionInfo.value,
        distance: 0
      }
      await AddAIAxisFun(obj);
    }

    //有需要删除的风险时执行
    if (deleteRiskList.length > 0) {
      NewDelAIAxisFun(deleteRiskList);
    }
    message.success('提交成功');
    await sendMessage();

    let num = len - 1;
    SetLen(num);
    changeImg(0);
  };

  //下一张图片
  const changeImg = (num) => {
    let cur = current;
    cur = cur + num;
    setDeleteRiskList([]);
    if (num === 0) {
      let arr = con_arr;
      arr.splice(cur, 1);
      SetConArr([...arr]);
      con_arr_ref.current = [...arr];
    }

    if (cur === con_arr.length || len === 0) {
      message.success('异常排查完成，即将返回');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
      return;
    }
    accident.current = '';
    SetAcOp('');
    SetLoadingText('加载中...');
    SetLoading(true);
    SetCurrent(cur);
    cur_ref.current = cur;
    draw_times.current = 0;
    let idmap = document.getElementById('map');
    idmap.innerHTML = '';
    setTimeout(() => {
      SetgMap(null);
      SetExist(!is_exist);
    }, 500)
  }

  const drawingComponent = async () => {
    let img_data = await initializeData();
    //清空画图组件
    let idmap = document.getElementById('map');
    idmap.innerHTML = '';
    handleImg(img_data, (abnormals) => {
      if (abnormals.length > 0) {
        SetAbnormal(img_data.abnormal_point);
        for (let i = 0; i < abnormals.length; i++) {
          let val = abnormals[i];
          let idx = optionsValue.current.indexOf(Number(val.risktype) + 1);
          if (idx === -1) continue;
          let obj = {
            id: optionsR.current[idx].key + '' + [draw_times.current < 10 ? '0' + draw_times.current : draw_times.current] + 'ai',
            name: optionsR.current[idx].name,
            x: parseInt(val.lx / multiple),
            y: parseInt(val.ly / multiple),
            w: parseInt(val.rx / multiple) - parseInt(val.lx / multiple),
            h: parseInt(val.ry / multiple) - parseInt(val.ly / multiple),
            col: optionsR.current[idx].color,
            distance: 0
          }
          drawRect(layer_feature.current, obj);

        };
      } else {
        SetAbnormal([]);
      }
      SetLoading(false);
    }
    );
  }

  // 监听键盘事件 通过键盘按键 调用 对应按钮的Click事件
  // -> : 下一张  
  // <- : 上一张
  // Delete : 删除选中的风险
  const onkeydown = (e) => {
    // eslint-disable-next-line
    switch (e.code) {
      case 'ArrowRight': //下一张
        if (Number(cur_ref.current) !== con_arr_ref.current.length - 1) {
          document.getElementById('nextBtn').click();
        }
        break;

      case 'Delete': //删除
        document.getElementById('deleteBtn').click();
        break;

      case 'ArrowLeft': //上一张
        if (Number(cur_ref.current) !== 0) {
          document.getElementById('lastBtn').click();
        }
        break;
    }
  }

  //计算距离 pointList 航点列表  point 异常范围 {lx,ly,rx,ry}
  const pointIsInLineRange = (point) => {
    let pointList = waypointList.current;
    if (pointList.length === 0) {
      return 0;
    }
    let distance = 0;//距离
    let scale = pointList[0].scale;//比例
    let centerPoint = [parseInt(point.lx + ((Number(point.rx) - Number(point.lx)) / 2)), parseInt(point.ly + ((Number(point.ry) - Number(point.ly)) / 2))];//中心点
    for (let i = 0; i < pointList.length - 1; i++) {
      let val = pointList[i];
      let val2 = pointList[i + 1];
      let aSides = [val.x, val.y];//a边
      let bSides = [val2.x, val2.y];//a边

      if (distance === 0) {
        distance = getCoses(aSides, bSides, centerPoint) / scale;
      }
      else {
        let dis = getCoses(aSides, bSides, centerPoint) / scale;
        if (dis < distance) {
          distance = dis;
        }
      }
    }
    return distance.toFixed(1);
  }

  //计算3角形3个角的角度
  // point1 端点1:A point2 端点2:B center 中心点:C
  const getCoses = (point1, point2, center) => {
    //求3边边长,利用勾股定理分别做3角型求出边长
    let AB = Math.sqrt(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2));
    let BC = Math.sqrt(Math.pow(point2[0] - center[0], 2) + Math.pow(point2[1] - center[1], 2));
    let CA = Math.sqrt(Math.pow(center[0] - point1[0], 2) + Math.pow(center[1] - point1[1], 2));

    //求3边反余弦弧度后转换成角度
    let cosABC = Math.acos((AB * AB + BC * BC - CA * CA) / (2 * AB * BC)) * (180 / Math.PI);
    let cosBCA = Math.acos((BC * BC + CA * CA - AB * AB) / (2 * BC * CA)) * (180 / Math.PI);
    let cosCAB = Math.acos((CA * CA + AB * AB - BC * BC) / (2 * CA * AB)) * (180 / Math.PI);

    if (cosABC >= 90) return BC;
    if (cosCAB >= 90) return CA;
    if (cosABC < 90 && cosCAB < 90) return CA * Math.sin(cosCAB * (Math.PI / 180));
  }

  useEffect(() => {
    initializeAbnormal();
    document.onkeyup = onkeydown;
    return () => {
      document.onkeyup = () => { };
    };
    // window.addEventListener("keydown", onkeydown, false);
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (isInitialize) {
      drawingComponent();
    }

    // eslint-disable-next-line
  }, [is_exist])

  return (
    <div className="flex-col tablebackcol" style={{ height: '100vh', overflowY: 'auto' }}>
      <div className="disFlex flexDirCol" >
        <div className='flex-row aliItemsCen paddRL24' style={{ width: '100% ', height: '72px' }}>
          <span className='curPointer' onClick={() => { navigate(-1) }}><ArrowLeftOutlined /> </span>
          <span className='fSize20 fWei500 margR12 margL18'>{'异常排查 - ' + pipe_name + ' - ' + title}</span>
          <span className='flex-auto'></span>
          <span>
            <img alt='hourglass' src={hourglass} style={{ width: '20px', height: '20px' }} />
            <Badge className="site-badge-count-109 fSize12" count={len ? len : 0}
              style={{
                backgroundColor: '#FF4D4F',
                color: '#FFFFFF',
                boxShadow: '0 0 0 1px #000',
              }}
            />
          </span>
        </div>
      </div>
      <Spin spinning={loading} tip={loadingText}>
        <div className="paddRL24 paddTB20 flex-auto backcol flex-row" style={{ overflowY: 'auto' }}>

          {/* <div className='flex-row'> */}
          <div id="map" className="flex-auto margR10 posRel hidden border"></div>

          <div className="flex-col flex-auto" style={{ minWidth: '260px', maxWidth: '260px', height: 'calc(100vh - 72px - 48px)' }}>
            <div className='paddTB16 paddRL24' style={{ borderBottom: '1px solid #303030' }}>
              <div className='fSize14 fWei500 coL1890FF curPointer'
                onClick={() => {
                  onPatrolMap()
                }}>
                {address}
              </div>
              <div className='fSize14 fWei400 opacity0_45'>
                {point_order}
              </div>
              <div className='fSize14 fWei400 opacity0_45'>
                {addtime}
              </div>
            </div>
            <div className='padd24 flex-col' style={{ borderBottom: '1px solid #303030' }}>
              <div className='flex-row'>
                <Radio.Group onChange={(e) => {
                  changeAccident(Number(e.target.value));
                }} value={ac_op}>
                  {
                    options.map(item => {
                      return (
                        <Radio key={item.idx} style={{ color: item.color }} className='wid100' value={item.idx}
                          onClick={() => {
                            if (item.idx === accident.current) {
                              setModeTo('PAN');
                              accident.current = '';
                              SetAcOp('');
                            }
                          }}>
                          {item.name}
                        </Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            </div>

            <div className='paddTB10 paddL16 flex-row flex_wrap' style={{ borderBottom: '1px solid #303030' }}>
              <Button id='deleteBtn' className='marR8' onClick={() => {
                if (choose_img.current.length === 0) {
                  message.warning('没有选择的异常');
                  return;
                }
                let arr = [...deleteRiskList];
                for (let i = 0; i < choose_img.current.length; i++) {
                  let val = choose_img.current[i];
                  if (val.substr(-2, 2) === 'ai') { //表示是AI识别的坐标点，需要请求接口删除
                    let id = val.substr(-4, 2);
                    //新 - 将需要删除的风险存在数组内，点击确定时统一删除
                    //将需要删除的风险存储起来，点击确定按钮时统一提交
                    arr.push(abnormal[Number(id)].id);
                    layer_lable.current.removeTextById(val);
                    layer_feature.current.removeFeatureById(val);
                    layer_lable.current.removeTextById('choose' + val);
                    layer_feature.current.removeFeatureById('choose' + val);
                  } else {
                    layer_lable.current.removeTextById(val);
                    layer_feature.current.removeFeatureById(val);
                    layer_lable.current.removeTextById('choose' + val);
                    layer_feature.current.removeFeatureById('choose' + val);
                  }
                }
                setDeleteRiskList([...arr]);
                choose_img.current = [];
              }}>
                删除
              </Button>

              <Button className='marR8' onClick={() => {
                if (choose_img.current.length === 0) {
                  message.warning('没有选择的异常');
                  return;
                }
                let choose = [...choose_img.current];
                for (let i = 0; i < choose_img.current.length; i++) {
                  let val = choose_img.current[i];
                  if (val.substr(-2, 2) === 'ai') { //表示是AI识别的坐标点，需要请求接口忽略
                    let id = val.substr(-4, 2);
                    IgnoreAIAxisFun(abnormal[Number(id)], () => {
                      layer_lable.current.removeTextById(val);
                      layer_feature.current.removeFeatureById(val);
                      layer_lable.current.removeTextById('choose' + val);
                      layer_feature.current.removeFeatureById('choose' + val);
                    })
                  } else {
                    layer_lable.current.removeTextById('choose' + val);
                    layer_feature.current.removeFeatureById('choose' + val);
                  }
                }
                // layer_lable.current.removeTextById(val);
                choose_img.current = [];
              }}>忽略</Button>

              <Button type="primary" onClick={() => {
                SetLoading(true);
                exportImage((val) => {
                  setModeTo('PAN');
                  setTimeout(() => {
                    submit(val);
                  }, 500)
                });
              }}>确认</Button>

            </div>

            <div className='paddTB10 paddRL16 flex-row jusConCenSB backcol'>
              <div id='lastBtn' className='fSize14 fWei400 coL1890FF bor1890FF tt_a_cen curPointer borRad2'
                style={{ width: '74px', height: '32px', lineHeight: '32px', display: (Number(current) !== 0) ? 'block' : 'none' }}
                onClick={() => {
                  changeImg(-1);
                }}
              >
                上一张
              </div>
              <div className='fSize14 fWei400 tt_a_cen borRad2 coLF0_3'
                style={{ width: '74px', height: '32px', lineHeight: '32px', border: '1px solid #434343', display: (Number(current) === 0) ? 'block' : 'none' }}>
                上一张
              </div>
              <div id='nextBtn' className='fSize14 fWei400 coL1890FF bor1890FF tt_a_cen curPointer borRad2'
                style={{ width: '74px', height: '32px', lineHeight: '32px', display: (Number(current) !== con_arr.length - 1) ? 'block' : 'none' }}
                onClick={() => {
                  changeImg(1);
                }}
              >
                下一张
              </div>
              <div className='fSize14 fWei400 tt_a_cen borRad2 coLF0_3'
                style={{ width: '74px', height: '32px', lineHeight: '32px', border: '1px solid #434343', display: (Number(current) === con_arr.length - 1) ? 'block' : 'none' }}>
                下一张
              </div>
            </div>

          </div>
          {/* </div> */}


        </div>
      </Spin>
    </div>
  )
}

export default Troubleshooting_steps