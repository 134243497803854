import React from 'react'
import { Button, Drawer, Tree, Space, Spin, message } from 'antd';
import { useState, useEffect } from 'react';
import { User_Pipeline, User_Pipeline_Set } from "@/services/inspection_pilots"; // 引入Ajax
import local from '@/utils/local'
import { getTreeDataByDelayeringData } from '@/common/js/common.js'

const Jurisdiction = (record) => {
  const { JurisdictionVal, visible, Operation } = record
  const [spinningLoading, _spinningLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [treeData, settreeData] = useState([]); //树
  const [checkedKeys, setCheckedKeys] = useState([]); //选中复选框的树节点
  const [checkParentKeys, setCheckParentKeys] = useState([]);//父级
  const [isChange, setIsChange] = useState(true)//权限是否被修改

  // const []

  useEffect(() => {
    setIsChange(true)
    if (visible) {
      onUser_Pipeline()
    }
  }, [visible]);// eslint-disable-line react-hooks/exhaustive-deps

  // 获取用户分配管线权限
  const onUser_Pipeline = async () => {
    _spinningLoading(true)
    const res = await User_Pipeline({
      access_token: local.get('cruise_m30_token') || '',
      userId: JurisdictionVal.id,
    })
    _spinningLoading(false)
    if (res.ret == 200) { // eslint-disable-line
      let treeData = JSON.parse(JSON.stringify(getTreeDataByDelayeringData(res.data.treeData, 'RID', 'id')).replaceAll('currentId', 'key'))
      for (let i = 0; i < treeData.length; i++) {
        treeData[i].key = 'p-' + treeData[i].key;
      }
      settreeData(treeData || [])
      setCheckedKeys(res.data.checkedKeys || '');
    }
  };
  // 设置用户管线权限
  const onUser_Pipeline_Set = async () => {
    _spinningLoading(true)
    setloading(true)
    let arr = [];
    for (let i = 0; i < checkParentKeys.length; i++) {
      if (checkedKeys?.indexOf(checkParentKeys[i]) < 0) {
        arr.push(checkParentKeys[i]);
      }
    }
    arr = [...arr, ...checkedKeys];

    let allPId = []//所有父Id为0的id
    treeData.forEach((res, index) => {
      if (res.parentId === 0) {
        allPId.push(res.key)
      }
    })
    let needIds = []//最终提交的ids
    arr.forEach((res) => {
      if (allPId.indexOf(res) < 0) {
        needIds.push(res)
      }
    })
    const res = await User_Pipeline_Set({
      access_token: local.get('cruise_m30_token') || '',
      userId: JurisdictionVal.id,
      pipeIds: needIds,
    })
    _spinningLoading(false)
    setloading(false)
    if (res.ret == 200) { // eslint-disable-line
      message.success(res.msg || '操作成功', 1.5);
      Operation(false)
    }
  };

  const onCheck = (checkedKeysValue, info) => {
    console.log(info);
    setIsChange(false)
    setCheckParentKeys(info.halfCheckedKeys);
    setCheckedKeys(checkedKeysValue);
  };

  const handleOk = (e) => {
    setIsChange(true)
    onUser_Pipeline_Set()
  };
  const handleCancel = (e) => {
    Operation(false)
  };

  return (
    <>
      <Drawer
        width={345}
        title="权限修改"
        open={visible}
        onOk={handleOk}
        onClose={handleCancel}
        extra={
          <Space>
            <Button disabled={isChange} key="submit" type="primary" loading={loading} onClick={handleOk}>
              确定
            </Button>
          </Space>
        }
      >
        <Spin spinning={spinningLoading}>
          <Tree
            checkable
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={treeData}
          />
        </Spin>

      </Drawer>
    </>
  );
}

export default React.memo(Jurisdiction)