// 巡检报告
import { useState, useEffect, useRef } from 'react'
import { Table, Spin, message, Radio, Divider, Button, Modal } from 'antd'
import HeaderBuilder from '@/components/TableBuilder/HeaderBuilder';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { InsReportList_Get, GetReport, getElectricTowersFileApi, exportImgsFun } from "@/services/inspection_report.js"; // 引入Ajax
import local from '@/utils/local'

const { confirm } = Modal;

export default function Inspector () {
  const navigate = useNavigate()
  const limitsAuthority = useRef()//权限列表
  const limitsAuthorityName = useRef()//权限列表
  /**
   * data
   * */
  const [spinningLoading, _spinningLoading] = useState(false);
  const [total, _total] = useState(0);
  const [List, _List] = useState();
  const [searchList, setSearchList] = useState({
    title: '',
    order: '',
    type: 1,
    status: '',
    current: 1,
    pageSize: 10,
  });

  //下载文件弹窗参数
  const modelType = useRef(0);//弹窗类型 1、下载报告  2、导出图片
  const [modelOpen, setModelOpen] = useState(false);
  const [modelTitle, setModelTitle] = useState('');
  const [modelBtnLoading, setModelBtnLoading] = useState(false);
  const [modelContent, setModelContent] = useState('');//内容

  const downloadFile = useRef('');// 下载的文件
  const fileName = useRef('');// 文件名称
  const downloadFileUrl = useRef('');// 文件地址

  useEffect(() => {
    limitsAuthority.current = JSON.parse(local.get('cruise_m30_permissionsArr'))
    limitsAuthorityName.current = JSON.parse(local.get('cruise_m30_permissionsArrName'))

    let sea = searchList
    sea.type = (
      limitsAuthority.current)?.indexOf(116) > -1
      ? 1
      : (limitsAuthority.current)?.indexOf(118) > -1
        ? 6
        : (limitsAuthority.current)?.indexOf(117) > -1
          ? 2
          : (limitsAuthority.current)?.indexOf(498) > -1
            ? 8
            : 0
    setSearchList({ ...sea })

    getList(sea)
  }, []);// eslint-disable-line

  /**
   * methods
  * */
  // 获取数据
  const getList = async (values) => {
    _spinningLoading(true)
    const res = await InsReportList_Get({
      access_token: local.get('cruise_m30_token') || '',
      title: values.title,
      type: values.type,
      order: values.order,
      status: values.status,
      page: values.current,
      perpage: values.pageSize,
    });
    _spinningLoading(false)
    if (res.ret == 200) {// eslint-disable-line
      if (res.data.items.length != 0) {// eslint-disable-line
        res.data.items.forEach((item, index) => {
          item.key = index;
          item.c_list.forEach((val, ndx) => {
            val.key = ndx;
            val.CompanyID = item.CompanyID;
            val.pipe_name = item.pipe_name;
            val.statid = item.id;
          });
        });
      }
      _List(res.data.items || []);
      _total(res.data.total || 0)
    }
  };

  //获取报告
  const downloadReport = async (num, id) => {
    setModelOpen(true);
    setModelBtnLoading(true);
    modelType.current = 1;
    setModelTitle('下载报告文件');
    setModelContent('正在从服务器获取报告文件，请稍等...');

    let obj = {
      access_token: local.get('cruise_m30_token'),
      statid: id,
      insId: num
    }
    let res

    if ([7, 8, 9].indexOf(searchList.type) === -1) { res = await GetReport(obj) }

    if (searchList.type === 8) { res = await getElectricTowersFileApi(obj) }//电塔数据

    setModelBtnLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      setModelOpen(false);
      return;
    }
    setModelContent('报告文件已准备，点击按钮下载');

    message.success('获取报告成功');
    if (res.data.reportfile_url && modelType.current === 1) {
      let file = await urlToFile(res.data.reportfile_url);
      downloadFile.current = file;
      fileName.current = res.data.file_name || '巡检报告';
      downloadFileUrl.current = res.data.reportfile_url;
    }
  }

  //导出图片
  const exportImgs = async (num, id, time) => {
    setModelOpen(true);
    setModelBtnLoading(true);
    modelType.current = 2;
    setModelTitle('下载图片文件');
    setModelContent('正在从服务器获取图片文件，请稍等...');
    let res = await exportImgsFun({
      access_token: local.get('cruise_m30_token'),
      statid: id,
      insId: num
    });
    console.log(res);
    setModelContent('图片文件已准备，点击按钮下载');
    setModelBtnLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      setModelOpen(false);
      return;
    }
    message.success('获取图片成功');
    if (res.data.reportfile_url && modelType.current === 2) {
      let file = await urlToFile(res.data.reportfile_url);
      downloadFile.current = file;
      fileName.current = res.data.file_name || '巡检报告图片';
      downloadFileUrl.current = res.data.reportfile_url;
    }
  }

  // 分页、排序、筛选变化时触发
  const handleTableChange = (newPagination, filters, sorter) => {
    let searchBefore = { ...searchList };
    // 分页
    searchBefore.current = newPagination.current;
    searchBefore.pageSize = newPagination.pageSize;
    setSearchList({ ...searchBefore })
    getList(searchBefore) //加载列表
  };

  const columns = [
    {
      title: '管线/单元名称',
      // issearch: true,
      dataIndex: 'pipe_name',
    },
    {
      title: '巡检开始',
      dataIndex: 'start_time',
      width: 200,
      render: (text, item, index) => {
        return (
          <div>
            {
              text
                ? <div className='' >
                  <span className='fSize14 opacity0_85 margR8'>{text.split(' ')[0]}</span>
                  <span className='fSize14 opacity0_45'>{text.split(' ')[1]}</span>
                </div>
                : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
            }
          </div>
        );
      },
    },
    {
      title: '巡检结束',
      dataIndex: 'end_time',
      width: 200,
      // sorter: {
      //     compare: (a, b) => a.addtime - b.addtime,
      //     multiple: 1,
      // },
      render: (text, item, index) => {
        return (
          <div>
            {
              text
                ? <div className='' >
                  <span className='fSize14 opacity0_85 margR8'>{text.split(' ')[0]}</span>
                  <span className='fSize14 opacity0_45'>{text.split(' ')[1]}</span>
                </div>
                : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
            }
          </div>
        );
      },
    },
    {
      title: '下载报告',
      dataIndex: 'reportfile',
      width: 200,
      render: (text, record) => {
        return (
          <div className='flex_row align_center'>
            <div
              className='curPointer col_primary'
              style={{ display: limitsAuthorityName.current.indexOf(`InsReportList_export_${searchList.type}`) > -1 ? `` : `none` }}
              onClick={() => {
                downloadReport(0, record.id);
              }}
            >
              下载报告
            </div>

            <Divider
              style={{ display: limitsAuthorityName.current.indexOf(`InsReportList_export_${searchList.type}`) > -1 && limitsAuthorityName.current.indexOf(`InsImageExport_Get_${searchList.type}`) > -1 ? '' : 'none' }}
              type="vertical"
            />
            <div style={{ display: limitsAuthorityName.current.indexOf(`InsImageExport_Get_${searchList.type}`) > -1 ? `` : `none` }}
              className='curPointer col_primary'
              onClick={async () => {
                exportImgs(0, record.id, record.start_time);
                // let file = await exportImgs2();
                // console.log(file);
                // saveFile(file);
              }}>
              导出图片
            </div>
          </div>
        )
      },
    },
  ];

  //子表格
  const columns_c = [
    {
      issearch: true,
      dataIndex: 'line_name',
    },
    {
      width: 150,
      dataIndex: 'pipe_len',
      render: (text, item, index) => {
        return (
          <div>
            {
              text
                ? <div >{text}</div>
                : <div className='opa45'>-</div>
            }
          </div>
        );
      },
    },
    {
      dataIndex: 'start_time',
      width: 200,
      render: (text, item, index) => {
        return (
          <div>
            {
              text
                ? <div className='' >
                  <span className='fSize14 opacity0_85 margR8'>{text.split(' ')[0]}</span>
                  <span className='fSize14 opacity0_45'>{text.split(' ')[1]}</span>
                </div>
                : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
            }
          </div>
        );
      },
    },
    {
      dataIndex: 'end_time',
      width: 200,
      // sorter: {
      //     compare: (a, b) => a.addtime - b.addtime,
      //     multiple: 1,
      // },
      render: (text, item, index) => {
        return (
          <div>
            {
              text
                ? <div className='' >
                  <span className='fSize14 opacity0_85 margR8'>{text.split(' ')[0]}</span>
                  <span className='fSize14 opacity0_45'>{text.split(' ')[1]}</span>
                </div>
                : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
            }
          </div>
        );
      },
    },
    {
      title: '下载报告',
      dataIndex: 'reportfile',
      width: 200,
      render: (text, record) => {
        return (
          <div className='flex_row align_center'>
            <div className='curPointer col_primary'
              style={{ display: limitsAuthorityName.current.indexOf(`InsReportList_export_${searchList.type}`) > -1 ? `` : `none` }}
              onClick={() => {
                downloadReport(record.insIds, record.statid);
              }}
            >
              下载报告
            </div>

            <Divider style={{
              display:
                limitsAuthorityName.current.indexOf(`InsReportList_export_${searchList.type}`) > -1 &&
                  limitsAuthorityName.current.indexOf(`InsImageExport_Get_${searchList.type}`) > -1
                  ? ''
                  : 'none'
            }}
              type="vertical"
            />

            <div style={{ display: limitsAuthorityName.current.indexOf(`InsImageExport_Get_${searchList.type}`) > -1 ? `` : `none` }}
              className='curPointer col_primary'
              onClick={() => {
                exportImgs(record.insIds, record.statid, record.start_time);
              }}
            >
              导出图片
            </div>
          </div>
        )
      },
    },

  ]

  //保存文件
  async function saveFile (data, fileName) {
    try {
      const opts = {
        types: [
          {
            description: '文件',
            accept: {
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx', '.doc']
            },
          }
        ],
        suggestedName: fileName,
        excludeAcceptAllOption: true
      };
      const handle = await window.showSaveFilePicker(opts); // 打开保存文件对话框
      const writable = await handle.createWritable(); // 创建可写入的文件对象

      // 在这里写入文件内容
      await writable.write(data);
      await writable.close();
      message.success('文件保存成功');
      setModelOpen(false);
    } catch (error) {
      console.log(error);
      let text = JSON.stringify(error);
      if (text.indexOf('The user aborted a request') === -1) {//无法使用另存为
        message.warning('当前浏览器暂不支持另存为操作，请直接下载 或更换以下浏览器：谷歌、edge、360');
      }
    }
  }

  //地址转文件
  const urlToFile = (url) => {
    return new Promise((resolve, reject) => {
      var blob = null;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader('Accept', 'application/msword');
      xhr.responseType = "blob";
      // 加载时处理
      xhr.onload = () => {
        // 获取返回结果
        blob = xhr.response;
        let file = new File([blob], 'xxx', { type: 'application/msword' });
        // 返回结果
        resolve(file);
      };
      xhr.onerror = (e) => {
        message.warning('文件转换失败');
        reject(e)
      };
      // 发送
      xhr.send();
    });
  }

  return (
    <div className="padd_24 flex-col hei100 tablebackcol" style={{ overflowY: 'auto' }}>
      <a id='a_report' href='undefined' style={{ display: 'none' }}>
        查看
      </a>
      <Spin spinning={spinningLoading}>
        <div className='padd_24 flex-auto backcol'>
          <Radio.Group
            className='marB16'
            optionType="button"
            buttonStyle="solid"
            value={searchList.type}
            onChange={(e) => {
              let sea = searchList
              sea.type = e.target.value
              setSearchList({ ...sea })
              getList(sea)
            }}
          >
            <Radio style={{ display: (limitsAuthority.current)?.indexOf(116) > -1 ? `` : `none` }} value={1}>管道巡检</Radio>
            <Radio style={{ display: (limitsAuthority.current)?.indexOf(498) > -1 ? `` : `none` }} value={8}>电塔巡检</Radio>
            <Radio style={{ display: (limitsAuthority.current)?.indexOf(117) > -1 ? `` : `none` }} value={2}>高危巡检</Radio>
            <Radio style={{ display: (limitsAuthority.current)?.indexOf(118) > -1 ? `` : `none` }} value={6}>综合巡检</Radio>
          </Radio.Group>
          <Table
            columns={HeaderBuilder(columns)}
            dataSource={List}
            expandable={{
              expandedRowRender: (record) => (
                <Table showHeader={false} pagination={false} columns={columns_c} dataSource={record.c_list} />
              )
            }}
            pagination={{
              total: total,
              current: searchList.current,
              pageSize: searchList.pageSize,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              showQuickJumper: true,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />

        </div>
      </Spin>

      <Modal title="" width={416} open={modelOpen}
        footer={false} closable={false}
        onCancel={() => {
          modelType.current = 0;
          setModelOpen(false);
        }}
      >
        <div className='marB8 flex_row align_center'>
          <span className='fs22 marR16 col_primary'><ExclamationCircleOutlined /></span>
          <span style={{ fontWeight: 500, fontSize: 16, color: 'rgba(255, 255, 255, 0.85)' }}>
            {modelTitle}
          </span>
        </div>
        <div className='fs14 marL38' style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.85)' }}>
          {modelContent}
        </div>

        <div className='marT24 tr'>
          <Button className='marR8' onClick={() => {
            modelType.current = 0;
            setModelOpen(false);
          }}>取消</Button>

          <Button className='marR8' type="primary" loading={modelBtnLoading} onClick={() => {
            let aLink = document.getElementById('a_report');
            aLink.download = '';
            aLink.href = downloadFileUrl.current;
            aLink.click();
            modelType.current = 0;
            setModelOpen(false);
          }}>下载</Button>

          <Button type="primary" loading={modelBtnLoading} onClick={() => {
            saveFile(downloadFile.current, fileName.current);
          }}>另存为</Button>
        </div>
      </Modal>
    </div>
  )
}
