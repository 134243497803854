//常规巡检记录
import { useState, useEffect, useRef } from 'react'
import { Card, Table, Radio, Input, Space, Button, DatePicker, message } from 'antd'
import HeaderBuilder from '@/components/TableBuilder/HeaderBuilder';
import SortOrFilter from '@/components/TableBuilder/SortOrFilter';
import {
  SearchOutlined, FileImageOutlined,
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'
import { getInfo, getEndTime } from "@/services/regular_inspections_record.js"; // 引入Ajax
import local from '@/utils/local'
import './index.less'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

export default function RegularInspectionsRecord () {

  const searchInput = useRef(null);//搜索框数据
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const params = location.state;
  const navigate = useNavigate()

  const [AirportPermissionsArrName, setAirportPermissionsArrName] = useState([])//权限列表
  /**
   * data
   * */
  const [spinningLoading, _spinningLoading] = useState(false);
  const [total, _total] = useState(0);
  const [List, _List] = useState();
  const [searchCriteria, setSearchCriteria] = useState({
    title: '',
    urlType: 1,
    order: '',
    status: '',
    type: 1,//1:记录   2统计
    s_start_time: '',//开始-开始时间
    s_end_time: '',//开始-结束时间
    e_start_time: '',//结束-开始时间
    e_end_time: '',//结束-结束时间
    current: 1,
    pageSize: 10,
  });

  /**
   * methods
  * */
  // 巡检报告
  const getList = async (sift_data, show = true) => {
    if (show) { _List([]); _spinningLoading(true) }
    let res = await getInfo({
      access_token: local.get('cruise_m30_token') || '',
      type: sift_data.urlType,
      types: sift_data.type,
      title: sift_data.title,
      order: sift_data.order,
      status: sift_data.status,
      s_start_time: sift_data.s_start_time,
      s_end_time: sift_data.s_end_time,
      e_start_time: sift_data.e_start_time,
      e_end_time: sift_data.e_end_time,
      page: sift_data.current,
      perpage: sift_data.pageSize,
      is_true: show ? 0 : 1
    });
    _spinningLoading(false)
    if (res.ret === 200) {
      let arr = []
      if (res.data.items && res.data.items.length > 0) {
        res.data?.items.forEach((item, index) => {
          let ch = []
          let obj = {}
          if (item.c_list && item.c_list.length > 0) {
            item?.c_list.forEach((ele, idx) => {
              let list = {
                ...ele,
                numKey: `${index}_${idx}`
              }
              ch.push(list)
            })
            obj = {
              ...item,
              c_list: ch,
              numKey: index,
            }
            arr.push(obj)
          } else {
            arr.push({
              ...item,
              numKey: index,
            })
          }
        });
      }
      _List(arr || []);
      // _List(res.data.items || []);
      _total(res.data.total || 0)
    }
  };


  // 分页、排序、筛选变化时触发
  const handleTableChange = (newPagination, filters, sorter) => {
    let searchBefore = { ...searchCriteria };
    // 分页
    searchBefore.current = newPagination.current;
    searchBefore.pageSize = newPagination.pageSize;
    setSearchCriteria({ ...searchBefore })
    getList(searchBefore) //加载列表
  };

  //图片查看页
  const PictureViewPage = (item) => {
    console.log('item', item);
    let transfer = {
      pTitle: item.pipe_name,
      title: item.line_name || '',
      start_time: item.start_time,
      end_time: item.end_time,
      companyId: item.CompanyID,
      statid: item.statId || item.id,
      insIds: item.insIds,
      search: searchCriteria,
      pic_count: item.pic_count,
    }
    navigate('/regular_inspections_record_picture', { state: { transfer: transfer } })
  }

  const columns = [
    {
      title: '管线/单元名称',
      // issearch: true,
      dataIndex: 'pipe_name',
    },
    {
      title: '总里程(km)',
      align: 'right',
      dataIndex: 'pipe_len',
      render: (_, item, index) => {
        return (
          <div>
            {item.pipe_len}
          </div>
        )
      },
    },
    {
      title: '巡检开始时间',
      dataIndex: 'start_time',
      render: (_, item, index) => {
        return (
          <div>
            {
              item.start_time &&
              <div>
                <span className='fSize14 opacity0_85 margR8'>{item.start_time.split(' ')[0]}</span>
                <span className='fSize14 opacity0_45'>{item.start_time.split(' ')[1]}</span>
              </div>
            }
          </div>
        );
      },
    },
    {
      title: '巡检结束时间',
      dataIndex: 'end_time',
      render: (_, item, index) => {
        return (
          <div>
            {
              item.end_time
                ? (
                  <div>
                    <span className='fSize14 opacity0_85 margR8'>{item.end_time.split(' ')[0]}</span>
                    <span className='fSize14 opacity0_45'
                    >
                      {item.end_time.split(' ')[1]}
                    </span>
                  </div>
                )
                : (
                  <div>
                    {item.statId && AirportPermissionsArrName?.indexOf(`Set_InspectionTime`) > -1 &&
                      <div
                        className='pointer'
                        style={{ color: '#1890FF' }}
                        onClick={async () => {
                          let obj = {
                            access_token: local.get('cruise_m30_token') || '',
                            insId: item.insIds,
                            is_true: 1,
                          }
                          let res = await getEndTime(obj)
                          if (res.ret === 200) {
                            setTimeout(() => {
                              getList(searchCriteria, false)
                              message.success('获取成功')
                            }, 500);
                          }
                        }}
                      >
                        更新结束时间
                      </div>
                    }
                  </div>
                )
            }
          </div>
        );
      },
    },
    {
      title: '图像',
      dataIndex: 'pic_count',
      align: 'center',
      width: 120,
      render: (_, item, index) => {
        return (
          Number(item.pic_count) > 0 ?
            <div className='disFlex jusConCen aliItemsCen curPointer' onClick={() => { PictureViewPage(item) }}>
              <div className=''>
                <span className='coL1890FF padd_10'>{item.pic_count}</span>
                <FileImageOutlined style={{ color: '#1890ff' }} />
              </div>
            </div> : ' '
        );
      },
    }
  ];


  useEffect(() => {//监听路由  获取相应的urlType
    console.log('params', params);
    let sea = searchCriteria
    switch (location.pathname) {
      case '/conduit_inspections_record'://管道
        sea.urlType = 1;
        sea.type = 1;
        break;
      case '/powerGrid_inspections_record'://电网
        sea.urlType = 7;
        sea.type = 1;
        break;
      default:
        break;
    }
    setAirportPermissionsArrName(localStorage.getItem('cruise_m30_permissionsArrName'))
    if (params) {
      setSearchCriteria({ ...params.list });
      getList({ ...params.list });
    } else {
      setSearchCriteria({ ...sea });
      getList(sea);
    }

    //eslint-disable-next-line
  }, [params, location.pathname])


  return (
    <div className="hei100 borbox tablebackcol" style={{ overflowY: 'auto' }}>
      <Card style={{ background: 'black' }} >
        <div className="flex-col hei100">

          <div className='padd_24 flex-auto backcol'>
            <div className='marB24 flex jcsb'>
              <Radio.Group
                value={searchCriteria.type}
                optionType="button"
                onChange={(e) => {
                  let sea = searchCriteria
                  sea.type = e.target.value
                  sea.current = 1
                  setSearchCriteria({ ...sea })
                  getList(sea)
                }}
                options={[
                  {
                    label: '记录',
                    value: 1,
                  },
                  {
                    label: '统计',
                    value: 2,
                  },
                ]}
              />

              <div>
                <Space>
                  <Input
                    allowClear
                    placeholder='管线/单元名称'
                    value={searchCriteria.title}
                    onChange={(e) => {
                      let sea = searchCriteria
                      sea.title = e.target.value
                      sea.current = 1
                      setSearchCriteria({ ...sea })
                    }}
                    onKeyUp={(e) => { if (e.key === 'Enter') { e.target.blur() } }}
                    onBlur={() => {
                      getList(searchCriteria)
                    }}
                  />

                  <RangePicker
                    locale={locale}
                    showHour
                    clearFilters
                    value={searchCriteria.s_start_time ? [moment(searchCriteria.s_start_time, 'YYYY-MM-DD'), moment(searchCriteria.s_end_time, 'YYYY-MM-DD')] : null}
                    format="YYYY-MM-DD"
                    placeholder={['巡检开始起', '巡检开始止']}
                    onChange={(e) => {
                      let sea = searchCriteria
                      if (e) {
                        sea.s_start_time = e[0].format('YYYY-MM-DD')
                        sea.s_end_time = e[1].format('YYYY-MM-DD')
                      } else {
                        sea.s_start_time = ''
                        sea.s_end_time = ''
                      }
                      sea.current = 1
                      setSearchCriteria({ ...sea })
                      getList(sea)
                    }}
                  />

                  <RangePicker
                    className='marL16'
                    locale={locale}
                    showHour
                    clearFilters
                    value={searchCriteria.e_start_time ? [moment(searchCriteria.e_start_time, 'YYYY-MM-DD'), moment(searchCriteria.e_end_time, 'YYYY-MM-DD')] : null}
                    format="YYYY-MM-DD"
                    placeholder={['巡检结束起', '巡检结束止']}
                    onChange={(e) => {
                      let sea = searchCriteria
                      if (e) {
                        sea.e_start_time = e[0].format('YYYY-MM-DD')
                        sea.e_end_time = e[1].format('YYYY-MM-DD')
                      } else {
                        sea.e_start_time = ''
                        sea.e_end_time = ''
                      }
                      sea.current = 1
                      setSearchCriteria({ ...sea })
                      getList(sea)
                    }}
                  />

                </Space>
              </div>
            </div>
            {
              searchCriteria.type === 1 &&
              (
                <Table
                  loading={spinningLoading}
                  columns={columns}
                  rowKey='numKey'
                  dataSource={List}
                  expandRowByClick={true}
                  childrenColumnName='c_list'
                  pagination={
                    {
                      total: total,
                      current: searchCriteria.current,
                      pageSize: searchCriteria.pageSize,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                      showQuickJumper: true,
                      showSizeChanger: true,
                    }
                  }
                  onChange={handleTableChange}
                />
              )
            }
            {
              searchCriteria.type === 2 && (
                <Table
                  loading={spinningLoading}
                  columns={columns.filter(item => item.dataIndex !== "pic_count")}
                  rowKey='numKey'
                  dataSource={List}
                  expandRowByClick={true}
                  childrenColumnName='c_list'
                  pagination={false}
                  onChange={handleTableChange}
                />
              )
            }

          </div>

        </div>
      </Card>
    </div>
  )
}
