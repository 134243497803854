import req from './index'


//获取风险处理员列表
export const GetHandlerUserList = (params) => {
  return req.get('api/admin.php?s=Admin.User.RiskHandlerUser_List', params)
}

//获取风险处理员详细信息
export const GetHandlerUserInfo = (params) => {
  return req.get('api/admin.php?s=Admin.User.RiskHandlerUser_Info', params)
}

//风险处理员更新或添加
export const SetHandlerUser = (params) => {
  return req.post('api/admin.php?s=Admin.User.RiskHandlerUser_Set', params)
}

// 风险处理员状态更新
export const SetState = (params) => {
  return req.post('api/admin.php?s=Admin.User.RiskHandlerUser_Status', params)
}

// 获取 处理人员数据
export const getHandlerListFun = (params) => {
  return req.post('api/admin.php?s=Admin.User.UserGroup_Get', params)
}

// 获取 处理人员数据
export const getTeamListFun = (params) => {
  return req.post('api/admin.php?s=Admin.User.UserGroupStr_Get', params)
}

// 新增 | 编辑组队
export const setTeamFun = (params) => {
  return req.post('api/admin.php?s=Admin.User.UserGroup_Add', params)
}

// 组队分配人员
export const teamAssignPersonnelFun = (params) => {
  return req.post('api/admin.php?s=Admin.User.UserGroup_Set', params)
}

// 解散组队
export const delTeamFun = (params) => {
  return req.post('api/admin.php?s=Admin.User.userGroup_Del', params)
}

