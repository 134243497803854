import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Checkbox, Form, Button, Input, message, Card, Spin } from 'antd'
import { GetSignIn, GetUserInfo, getAllPermissions } from "@/services/home"; // 引入Ajax
import local from '@/utils/local'

function Login () {
  const navigate = useNavigate();
  const location = useLocation();
  const [tips, setTips] = useState('加载中');//提示
  const [showLoading, setShowLoading] = useState(true);

  //获取用户信息 及 权限
  const getUserInfoFun = async (token) => {
    localStorage.setItem('cruise_m30_permissionsArrName', '');
    const res = await GetUserInfo({
      access_token: token,
    });
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    local.set('cruise_m30_memberLevel', res.data.profile.member_level);

    //获取权限
    let res2 = await getAllPermissions({ access_token: token, member_level: res.data.profile.member_level })
    if (res2.ret !== 200) {
      message.warning(res2.msg || '数据跑丢了~'); return
    }
    if (res2.data.menu && res2.data.menu.length) {
      local.set('cruise_m30_permissionsArr', JSON.stringify(res2.data.menuids));
      localStorage.setItem('cruise_m30_permissionsArrName', JSON.stringify(res2.data.menuName || ''));
    }

    if (Number(local.get('cruise_m30_memberLevel')) !== 255 && (!local.get('cruise_m30_cruiseMenuKeys')) && local.get('cruise_m30_permissionsArr')) {
      local.set('cruise_m30_cruiseMenuKeys', '/inspection_live_broadcast');
      local.set('cruise_m30_defaultOpenKeys', 'shipping_line');
      setTimeout(() => {
        navigate('/user_manual');
      }, 1000);
    } else {
      local.set('cruise_m30_cruiseMenuKeys', '/shipping_line/routine');
      setTimeout(() => {
        navigate('/shipping_line/routine');
      }, 1000);
    }
  }

  useEffect(() => {
    local.clear();
    if (location.search) { //免登进去系统
      let token = location.search.split('=')[1];
      local.set('cruise_m30_token', token);
      local.set('cruise_m30_isLogin', true);
      local.set('cruise_m30_isAvoidLogin', 1);//是否为免密登录
      getUserInfoFun(token);
    }
    else {//没有token
      setTimeout(() => {
        setTips('加载失败，没有密钥');
        setShowLoading(false);
      }, 1000)

    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className='box border' style={{ height: '100vh', width: '100vw' }}>
      <div className='wid100 hei100 flex_col justify_center  align_center'>
        <div className='fs32 fw600 marB12'>
          管理系统
        </div>
        <div className='flex_row justify_center align_center'>
          <div className='fs24 fw600 marR12'>{tips}</div>
          <span style={{ display: showLoading ? '' : 'none' }}>
            <Spin size="large" />
          </span>
          <span className='pointer col_primary' onClick={() => { navigate('/login'); }}
            style={{ display: !showLoading ? '' : 'none' }}>
            登录
          </span>
        </div>

      </div>
    </div>
  )

}
export default Login
