//定点巡检记录
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Table, Badge, Spin, message } from 'antd'
import HeaderBuilder from '@/components/TableBuilder/HeaderBuilder';
import SortOrFilter from '@/components/TableBuilder/SortOrFilter';
import {
  FileImageOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { InsImageList_Get } from "@/services/leakage_risk.js"; // 引入Ajax
import local from '@/utils/local'
import './index.less'


export default function DesignatedInspectionsRecord () {
  const location = useLocation();
  const params = location.state;
  const navigate = useNavigate()
  /**
   * data
   * */
  const [spinningLoading, _spinningLoading] = useState(false);
  const [total, _total] = useState(0);
  const [List, _List] = useState();
  const [searchList, setSearchList] = useState({
    urlType: 1,
    title: '',
    order: '',
    status: '',
    current: 1,
    pageSize: 10,
  });

  /**
   * methods
  * */
  // 巡检报告
  const getList = async (sift_data) => {
    _spinningLoading(true)
    let res = await InsImageList_Get({
      access_token: local.get('cruise_m30_token') || '',
      type: sift_data.urlType,
      title: sift_data.title,
      order: sift_data.order,
      status: sift_data.status,
      page: sift_data.current,
      perpage: sift_data.pageSize,
    });
    _spinningLoading(false)
    //console.log(res);
    if (res.ret === 200) {
      let arr = []
      if (res.data.items && res.data.items.length > 0) {
        res.data?.items.forEach((item, index) => {
          let ch = []
          let obj = {}
          if (item.c_list && item.c_list.length > 0) {
            item?.c_list.forEach((ele, idx) => {
              let list = {
                ...ele,
                numKey: `${index}_${idx}`
              }
              ch.push(list)
            })
            obj = {
              ...item,
              c_list: ch,
              numKey: index,
            }
            arr.push(obj)
          } else {
            arr.push({
              ...item,
              numKey: index,
            })
          }
        });
      }
      _List(arr || []);
      // _List(res.data.items || []);
      _total(res.data.total || 0)
    }
  };


  // 分页、排序、筛选变化时触发
  const handleTableChange = (newPagination, filters, sorter) => {
    let searchBefore = { ...searchList };
    // 分页
    searchBefore.current = newPagination.current;
    searchBefore.pageSize = newPagination.pageSize;
    setSearchList({ ...searchBefore })
    getList(searchBefore) //加载列表
  };

  //图片查看页
  const PictureViewPage = (item) => {
    console.log(item);
    let transfer = {
      pTitle: item.pipe_name,
      title: item.line_name || '',
      start_time: item.start_time,
      end_time: item.end_time,
      companyId: item.CompanyID,
      statid: item.statId || item.id,
      insIds: item.insIds,
      search: searchList,
    }
    navigate('/designated_inspections_record_picture', { state: { transfer: transfer } })
  }

  // 表头
  const columns = [
    {
      title: '管线/单元名称',
      // issearch: true,
      dataIndex: 'pipe_name',
    },
    {
      title: '巡检开始时间',
      dataIndex: 'start_time',
      // sorter: {
      //     compare: (a, b) => a.addtime - b.addtime,
      //     multiple: 1,
      // },
      render: (_, item, index) => {
        return (
          <div>
            <div style={{ display: (item.start_time) ? 'block' : 'none' }}>
              <span className='fSize14 opacity0_85 margR8'>{item.start_time.split(' ')[0]}</span>
              <span className='fSize14 opacity0_45'>{item.start_time.split(' ')[1]}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: '巡检结束时间',
      dataIndex: 'end_time',
      // sorter: {
      //     compare: (a, b) => a.addtime - b.addtime,
      //     multiple: 1,
      // },
      render: (_, item, index) => {
        return (
          <div>
            <div className='' style={{ display: (item.end_time) ? 'block' : 'none' }}>
              <span className='fSize14 opacity0_85 margR8'>{item.end_time.split(' ')[0]}</span>
              <span className='fSize14 opacity0_45'>{item.end_time.split(' ')[1]}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: '图像',
      dataIndex: 'pic_count',
      align: 'center',
      width: 120,
      render: (_, item, index) => {
        return (
          Number(item.pic_count) > 0 ?
            <div className='disFlex jusConCen aliItemsCen curPointer' onClick={() => { PictureViewPage(item) }}>
              <div className=''>
                <span className='coL1890FF padd_10'>{item.pic_count}</span>
                <FileImageOutlined style={{ color: '#1890ff' }} />
              </div>
            </div> : ' '
        );
      },
    }
  ];

  useEffect(() => {//监听路由  获取相应的urlType

    let sea = searchList
    switch (location.pathname) {
      case '/electricTower_inspections_record'://电塔
        sea.urlType = 8;
        sea.type = 1;
        break;
      case '/station_inspections_record'://场站
        sea.urlType = 9;
        sea.type = 1;
        break;
      default:
        break;
    }

    if (params) {
      setSearchList({ ...params.list });
      getList({ ...params.list });
    } else {
      getList(sea);
    }
    setSearchList({ ...sea });

    //eslint-disable-next-line
  }, [location.pathname, params])

  return (
    <div className="flex-col hei100 tablebackcol" style={{ overflowY: 'auto' }}>
      <Card style={{ background: 'black' }} >
        <div className="flex-col hei100">
          {/* <div onClick={()=>{
                navigate('/Risk', { state: { companyId: 1, statid:18, insIds: 18 } })
            }}>跳转</div> */}
          {/* <a id='a_report' download="巡检报告.docx" style={{ display: 'none' }}>
        查看
      </a> */}
          <Spin spinning={spinningLoading}>
            <div className='padd_24 flex-auto backcol'>
              <Table
                columns={columns}
                dataSource={List}
                rowKey='numKey'
                expandRowByClick={true}
                childrenColumnName='c_list'
                pagination={{
                  total: total,
                  current: searchList.current,
                  pageSize: searchList.pageSize,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  showQuickJumper: true,
                  showSizeChanger: true,
                }}
                onChange={handleTableChange}
              />

            </div>
          </Spin>
        </div>
      </Card>
    </div>
  )
}
