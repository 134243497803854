// 管线、航点编辑
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { message, Checkbox, Tooltip, Divider, Select, Layout, Button, InputNumber, Switch, notification } from 'antd'
import {
  SaveOutlined, PlusOutlined, BlockOutlined, LockFilled, QuestionCircleOutlined,
  EditOutlined, CheckOutlined, SyncOutlined, MenuOutlined,
  DeleteOutlined, VerticalAlignBottomOutlined, InfoCircleOutlined, LeftOutlined,
  RightOutlined, CloseOutlined, ExclamationCircleTwoTone
} from '@ant-design/icons';
import { Input } from 'antd'
import {
  Amap as RMap, config as AmapReactConfig, Marker as AmapMarker, Markers as AmapMarkers,
  BezierCurve,
  Scale, TileLayer, Marker
} from '@amap/amap-react';
import { transformGCJ2WGS, transformWGSGCJ2, distance, debounce } from "@/common/js/common.js" //引入公共方法
import { GetRoutesPoint, SetRoutesPoint, GetRoutesPointAction, SetRoutesPointAction, GetRoutesInfo, SetTakeOff, GetAltitude, getUavpositionInfo, getLimitsAuthorityInfo } from '@/services/shipping_line' //接口
import home from "@/common/img/shipping_line/home.png" //起飞点
import waypoint from "@/common/img/shipping_line/waypoint.svg" //航点
import waypointCheck from "@/common/img/shipping_line/waypoint_check.svg" //航点
import dayjs from 'dayjs';
import local from '@/utils/local'
import uav from '@/common/img/uav.png'
import peg from "@/common/img/peg.png" //标识桩
import './shipping_line_edit.css'
const { Content } = Layout;

//地图key
AmapReactConfig.key = '193db6af15a86cd8d48b102ab461b002';
// AmapReactConfig._prefix = '/';//修改项目访问地址

//当前地图 使用高德地图（GCj坐标）的核心 以及 天地图（W84坐标）的图层

function ShippingLineEdit() {
  const location = useLocation();//接受传值数据
  const PID = location.search.slice(1).split('&')[0];//航线id
  const selectType = location.search.slice(1).split('&')[1];
  const token = local.get('cruise_m30_token');
  const mapkeys = [
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97'
  ];//天地图key
  const [choose_map_key, setChooseMapKey] = useState(0);//随机 0，1，2从 mapkeys 中取一个地图key
  const line_color = '#FFBD0A';//管线颜色

  const limitsAuthority = useRef()//权限列表
  const wideNum = 1;//拍照 广角 幅宽幅长比例参数
  const zoomNum = 1;//拍照 变焦 幅宽幅长比例参数
  const irNum = 1;//拍照 热成像 幅宽幅长比例参数

  const mapRef = useRef();
  const timer = useRef();
  const [isLock, setIsLock] = useState(1)//是否未锁定  0：锁定  1：未锁定
  const curWaypoint = useRef();
  const [zoom, setZoom] = useState(17); //地图缩放比例
  const scale = 3875.8509003722434;//初始化地图比例尺
  const [mapScale, setmapScale] = useState(3875.8509003722434) // 地图当前比例尺
  const [center_point, setCenterPoint] = useState([0, 0]);//地图中心点
  const [center_altitude, setCenterAltitude] = useState(0);//地图中心点 海拔
  const [opacity, setOpacity] = useState(true);//切换图层
  const [waypoint_show, setWaypointShow] = useState(false);//航点编辑是否显示
  const [name_show, setNameShow] = useState(false);//编辑航点名称是否显示
  const inputRef = useRef();//航点名称 用于获取焦点

  const [start_idx, setStartIdx] = useState(-1);//航点动作 开始拖动下标
  const [cur_idx, setCurIdx] = useState(-1);//航点动作 当前执行下标
  const [is_add, setIsAdd] = useState(false);//是否新增航点
  const [action_move, setActionMove] = useState(false);//航点动作是否可以拖动
  const [takeoffpoint_show, setTakeoffpointShow] = useState(false);//起飞点编辑是否显示

  const [altitudes, setAltitudes] = useState([]);//航点 海拔数据
  const [home_altitude, setHomeAltitude] = useState(0);//起飞点 海拔数据

  const [is_compute, setIsCompute] = useState(false);//是否正在计算海拔


  const [safetyWarn, setSafetyWarn] = useState({//安全警告
    isOpen: false,
    // angle: [],//角度 安全警告 
    takeoffAltitude: {
      title1: '',
      title2: '',
      color: '#49AA19',
    },//起飞海拔 警告
    returnAltitude: {
      title1: '',
      title2: '',
      color: '#49AA19',
    },//返航海拔 警告


    waypointActionInfo: [//航点动作
      // {
      // severity: 1,//严重等级  1:严重(红色：#D32029) 2:警告(黄色：#D89614) 3:正常(绿色：#49AA19)
      // txt: '当前航点有问题'// 文字描述
      // },
      //...
    ],
    takeoffAltitudeInfo: { //起飞海拔
      // severity: 1,//严重等级  1:严重(红色：#D32029) 2:警告(黄色：#D89614) 3:正常(绿色：#49AA19)
      // txt: '当前航点有问题'// 文字描述
    },

    returnAltitudeInfo: {//返航海拔
      // severity: 1,//严重等级  1:严重(红色：#D32029) 2:警告(黄色：#D89614) 3:正常(绿色：#49AA19)
      // txt: '当前航点有问题'// 文字描述
    },
    distanceSafetyInfo: [//距离安全
      // {
      // severity: 1,//严重等级  1:严重(红色：#D32029) 2:警告(黄色：#D89614) 3:正常(绿色：#49AA19)
      // txt: '当前航点有问题'// 文字描述
      // },
      //...
    ],
  })


  const [curName, setCurName] = useState({
    title: '',//子管线
    Rtitle: '',//主管线
  })//当前的头部信息
  const [onSiteInspectionTypeValue, setOnSiteInspectionTypeValue] = useState([]);//当前可以选择的航点动作

  const photographed_option = [
    { value: 'wide', label: 'W' },//广角
    { value: 'zoom', label: 'Z' },//变焦
    { value: 'ir', label: 'IR' },//热成像
  ];//航点拍照选项

  const waypoint_types = [
    { value: 1, label: '直线飞行' },
    { value: 2, label: '曲线飞行' }
  ];//航点类型

  const [UAVList, setUAVList] = useState({
    state: '0',//1执行中，
    point: [0, 0],//经纬度
  })//无人机 当前状态以及所在位置

  // const [show_table_option, setShowTableOption] = useState([
  //     { value: 'jack', label: 'Jack' },
  //     { value: 'lucy', label: 'Lucy' }
  // ]);//所有的显表类型


  const [identificationStake, setIdentificationStake] = useState();//标识桩列表
  const [line_data, setLineData] = useState([]);//航线数据
  const [markers, setMarkers] = useState([]);//航点列表
  const [marker_info, setMarkerInfo] = useState({ //当前选中的航点信息
    idx: -1,
    name: '',
    point: ['', ''],
    gcj_point: ['', ''],
    flight_height: '',//飞行高度 ||到达高度
    flight_altitude: '',//飞行海拔 || 到达海拔
    yaw_angle: '',//偏航角度
    scale_ratio: 1,
    action: [
      {
        type: 1,
        uplift_height: '',//抬升高度
        decline_height: '',//下降高度
        UAV_angle: '',//无人机偏航角度
        yaw_angle: '',//偏航角度
        pitch_angle: '',//俯仰角度
        zoom: '2',//变焦倍数
        photograph_type: [],//拍照类型
        readout_type: null,//读数 - 显表类型
        readout_unit: '',//读数 - 数据单位
        readout_range: ['', ''],//读数 - 合理范围
        temperature_range: ['', ''],//温度 - 合理范围
        photography_state: true,//摄像是否开启
        lighting_state: true,//照明辅助是否开启
        tnterval_time_shooting_type: ['wide'],//等时 - 间隔时间拍照
        tnterval_time_shooting_text: '',//等时 - 等时间隔
        tnterval_distance_shooting_type: ['wide'],//等距 - 间隔距离拍照
        tnterval_distance_shooting_text: '',//等距 - 等距间隔
      },
    ],//动作
  });

  const [HomeMarkerInfo, setHomeMarker] = useState({ //起飞点详细信息
    type: 1,
    point: [center_point[0], center_point[1]],
    flight_height: '',//飞行高度 || 到达高度
    return_height: '',//返航高度
    takeoff_range: '',//起飞范围
    uplift_speed: '',//抬升速度
    cruise_speed: '',//巡航速度
    waypoint_speed: '',//航点速度
    photograph_type: [1],//拍照类型
    waypoint_type: null,//航点类型
    UAV_angle: '',//无人机偏航角度
  })

  const [moveMarkerInfo, setMoveMarkerInfo] = useState({});//用于移动的marker
  const isFirstMoveRef = useRef(true);//是否为第一次移动
  const firstMoveCoordinateRef = useRef([]);//蒙层第一次移动的坐标
  const markerCoordinateRef = useRef([]);//marker_info初始坐标 需要改变
  const markerCoordinateNoRef = useRef([]);//marker_info初始坐标 不需要改变


  const manipulate_option = [
    // 常规：1，2，3，4，5，7
    // 高危：1，2，3，4，7，11，12，13
    { value: 1, label: '抬升' },
    { value: 2, label: '下降' },
    { value: 3, label: '云台偏航角' },
    { value: 4, label: '云台俯仰角' },
    { value: 5, label: '拍照' },
    // { value: 6, label: '摄像' },
    { value: 7, label: '相机变焦' },
    { value: 8, label: '采集读数' },
    // { value: 9, label: '照明辅助' },
    { value: 10, label: '测量温度' },
    { value: 11, label: '开始间隔时间拍照' },
    { value: 12, label: '开始间隔距离拍照' },
    { value: 13, label: '结束间隔拍照' },
    { value: 14, label: '悬停' },
    { value: 15, label: '无人机偏航角' },
  ];//航点动作选项 在航点添加动作时，选择相应的动作

  //通过巡检类型 获取 航点动作
  const getActionByOnSiteInspectionType = () => {
    let cg = [1, 2, 14, 15, 3, 4, 7, 5,]//常规动作
    let xl = [1, 2, 15, 3, 4, 7, 11, 12, 13, 14]//高危动作
    if (selectType === '1' || selectType === '6' || selectType === '7') {
      let arr = []
      cg.forEach((ele) => {
        arr.push(manipulate_option.find((res) => res.value === ele))
      })
      setOnSiteInspectionTypeValue([...arr])
    }
    if (selectType === '2') {
      let arr = []
      xl.forEach((ele) => {
        arr.push(manipulate_option.find((res) => res.value === ele))
      })
      setOnSiteInspectionTypeValue([...arr])
    }
  }

  const getLimitsAuthority = async () => {//获取权限
    let res = await getLimitsAuthorityInfo({ access_token: token, type: selectType, member_level: local.get('cruise_m30_memberLevel') })
    if (res.ret !== 200) { return }
    limitsAuthority.current = res.data
  }

  //修改当前选中的航点信息
  const SetMarkerInfoVal = async (txt, val) => {
    if (txt) {
      let info = { ...marker_info };
      info[txt] = val;
      let arr = [...markers];
      let obj = { ...info };
      if (txt === 'point') {
        obj.gcj_point = [val[0], val[1]]
        SetAltitudeFun(obj.point, obj.idx, 0);
      }
      let wh = GetWidthNew2(obj, mapScale);
      obj.width = Number(wh[0]);
      obj.height = Number(wh[1]);
      obj.z = Number(wh[2]);
      obj.offset = Number(wh[3]);
      obj.line = wh[4];
      obj.photograph_width = parseFloat(Number(wh[0]) / (scale / mapScale));//拍照幅宽
      obj.photograph_height = parseFloat(Number(wh[2]) / (scale / mapScale));//拍照幅高
      obj.yaw_angle = Number(wh[5]);
      obj.leave_height = wh[6];
      obj.scale_ratio = wh[7];

      info.leave_height = wh[6];
      info.scale_ratio = wh[7];
      info.photograph_width = parseFloat(Number(wh[0]) / (scale / mapScale));//拍照幅宽
      info.photograph_height = parseFloat(Number(wh[2]) / (scale / mapScale));//拍照幅宽

      arr[obj.idx] = { ...obj };
      setMarkers([...arr]);
      setMarkerInfo({ ...info });
      setMoveMarkerInfo({ ...obj });
    } else {
      let obj = { ...val };
      setMarkerInfo({ ...obj });
      setMoveMarkerInfo(obj);
    }

  }

  //新增航点
  const AddMarker = async () => {
    if (is_compute) { message.warning('正在计算海拔,请稍后重试'); return; }
    if (is_add) return;
    setIsAdd(true);
    //lnglat 地图中心点
    let info = { ...marker_info };
    let idx = -1;
    let lists = [...markers];
    let obj = {};
    if (info.idx === -1) { //未选中航点，在末尾新增
      idx = lists.length;
      if (idx === 0) {//没有航点时
        obj = {
          id: '',
          name: '',
          point: [],
          gcj_point: [],
          flight_height: 80,
          flight_altitude: '',
          yaw_angle: 0,
          action: []
        };
      } else {//至少有一个航点时，复制最后一个航点的信息
        obj = { ...lists[idx - 1] };
      }

    } else { //已选中航点，在航点前面新增
      idx = info.idx + 1;
      obj = { ...info };
    }

    //计算航点的到达高度，到达高度 = 到达高度 + 抬升高度 - 下降高度
    //计算完到达高度后清除 抬升|下降动作
    let flight_height = 0;
    let action = [];
    flight_height = flight_height + Number(obj.flight_height);
    for (let i = 0; i < obj.action.length; i++) {
      let val = obj.action[i];
      if (val.type === 1) {
        flight_height += Number(val.uplift_height);
        continue;
      }
      if (val.type === 2) {
        flight_height -= Number(val.decline_height);
        continue;
      }
      action.push({ ...val });
    }

    obj.flight_height = flight_height;
    obj.action = action;
    let wh = GetWidthNew2(obj, mapScale);
    obj.name = '';
    obj.is_check = false;
    obj.point = [...center_point];
    obj.gcj_point = [center_point[0], center_point[1]]
    obj.width = Number(wh[0]);
    obj.height = Number(wh[1]);
    obj.z = Number(wh[2]);
    obj.offset = Number(wh[3]);//偏移
    obj.line = wh[4];
    obj.photograph_width = parseFloat(Number(wh[0]) / (scale / mapScale));//拍照幅宽
    obj.photograph_height = parseFloat(Number(wh[2]) / (scale / mapScale));//拍照幅高
    obj.yaw_angle = Number(wh[5]);
    obj.leave_height = wh[6];
    obj.scale_ratio = wh[7];
    lists.splice(idx, 0, obj);
    let altitudes_list = [...altitudes];
    altitudes_list.splice(idx, 0, center_altitude);
    setAltitudes([...altitudes_list]);
    for (let i = 0; i < lists.length; i++) {
      let val = lists[i];
      val.idx = i;
    }
    setIsAdd(false);
    setMarkers([...lists]);
    MarkerClick(idx, lists);
  }

  //删除航点
  const DelMarker = async () => {
    //在计算海拔时不能删除
    if (is_compute) {
      message.warning('正在计算海拔,请稍后重试');
      return;
    }
    let info = { ...marker_info };
    let arr = [...markers];
    let altitudes_list = [...altitudes];
    //删除对应航点
    arr.splice(info.idx, 1);
    altitudes_list.splice(info.idx, 1);
    setNameShow(false);
    setWaypointShow(false);
    info.idx = -1;
    setMarkerInfo({ ...info });
    JudgmentSet(2, arr)//删除后马上验证警告
    setMarkers([...arr]);
    setMoveMarkerInfo(info);
    setAltitudes([...altitudes_list]);
    compareAltitude(arr, altitudes_list, HomeMarkerInfo, home_altitude)
  }

  //航点 点击执行
  const MarkerClick = async (idx, arr) => {
    setTakeoffpointShow(false);
    let lists = arr ? arr : [...markers];
    for (let i = 0; i < lists.length; i++) {
      let val = lists[i];
      if (i === idx) {
        if (val.is_check) {
          //再次点击关闭
          // lists[i].is_check = false;
          // let info = { ...marker_info };
          // info.idx = -1;
          // setMarkerInfo({...info});
          // setWaypointShow(false);

          //再次点击选中的航点 增加偏航角
          let info = { ...marker_info };
          if (info.action.length > 0) {
            for (let n = 0; n < info.action.length; n++) {
              let nal = info.action[n];
              if (nal.type === 3) {
                nal.yaw_angle = Number(nal.yaw_angle) + 10 > 360 ? (Number(nal.yaw_angle) + 10 - 360) : Number(nal.yaw_angle) + 10;
              }
              if (nal.type === 15) {
                nal.UAV_angle = Number(nal.UAV_angle) + 10 > 360 ? (Number(nal.UAV_angle) + 10 - 360) : Number(nal.UAV_angle) + 10;
              }
            }

            let wh = GetWidthNew2(info, mapScale);
            lists[i].width = Number(wh[0]);
            lists[i].height = Number(wh[1]);
            lists[i].z = Number(wh[2]);
            lists[i].offset = Number(wh[3]);
            lists[i].line = wh[4];
            lists[i].photograph_width = parseFloat(Number(wh[0]) / (scale / mapScale));//拍照幅宽
            lists[i].photograph_height = parseFloat(Number(wh[2]) / (scale / mapScale));//拍照幅高
            lists[i].yaw_angle = Number(wh[5]);
          }
        } else {
          setNameShow(false);
          val.is_check = true;
          setWaypointShow(true);
          GetMarkerInfoFun(idx, arr ? arr : null);
          // return;
        }
      } else {
        val.is_check = false;
      }
    }
    setMarkers([...lists])
  }

  //航点信息赋值
  const GetMarkerInfoFun = (idx, arr) => {
    let data = arr ? arr : markers;
    let obj = { ...data[idx] };
    obj.idx = idx;
    SetMarkerInfoVal('', obj);
  }

  //航点拖动结束执行 将坐标存储起来
  const onWaypointDragEnd = (idx, lnglat) => {
    let lists = [...markers];
    lists[idx].point = [lnglat.lng, lnglat.lat];
    lists[idx].gcj_point = [lnglat.lng, lnglat.lat];
    if (lists[idx].is_check) {
      SetMarkerInfoVal('point', [lnglat.lng, lnglat.lat]);
    } else {
      setMarkers([...lists]);
    }
  }

  //修改起飞点详细信息
  const SetHomeMarkerInfo = async (txt, val) => {
    let obj = { ...HomeMarkerInfo };
    obj[txt] = val;
    setHomeMarker({ ...obj });
  }

  //航点编辑 关闭执行
  const waypointClose = () => {
    let info = { ...marker_info };
    if (info.idx === -1) return;
    let arr = [...markers];
    arr[info.idx].is_check = false;
    setMarkers([...arr]);
    info.idx = -1;
    setMarkerInfo({ ...info });
    setMoveMarkerInfo(info);
  }

  //新增航点动作
  const AddWaypointAction = () => {
    let arr = [...marker_info.action];
    let obj = {
      type: null,
      uplift_height: '',//抬升高度
      decline_height: '',//下降高度
      UAV_angle: '',//无人机偏航角度
      yaw_angle: '',//偏航角度
      pitch_angle: '',//俯仰角度
      zoom: '5',//变焦倍数
      photograph_type: ['wide'],//拍照类型
      readout_type: null,//读数 - 显表类型
      readout_unit: '',//读数 - 数据单位
      readout_range: ['', ''],//读数 - 合理范围
      temperature_range: ['', ''],//温度 - 合理范围
      photography_state: true,//摄像是否开启
      lighting_state: true,//照明辅助是否开启
      tnterval_time_shooting_type: ['wide'],
      tnterval_distance_shooting_type: ['wide'],
      tnterval_time_shooting_text: '',
      tnterval_distance_shooting_text: '',
    };
    arr.push(obj);
    SetMarkerInfoVal('action', arr);
  }

  //删除航点动作
  const DelWaypointAction = (idx) => {
    let info = { ...marker_info };
    if (info.action[idx].type === 3) {
      info.yaw_angle = 0;
    }
    info.action.splice(idx, 1);
    let arr = [...markers];
    let obj = { ...info };
    let wh = GetWidthNew2(obj, mapScale);
    obj.width = Number(wh[0]);
    obj.height = Number(wh[1]);
    obj.z = Number(wh[2]);
    obj.offset = Number(wh[3]);
    obj.line = wh[4];
    obj.photograph_width = parseFloat(Number(wh[0]) / (scale / mapScale));//拍照幅宽
    obj.photograph_height = parseFloat(Number(wh[2]) / (scale / mapScale));//拍照幅高
    obj.yaw_angle = Number(wh[5]);
    obj.leave_height = wh[6];
    obj.scale_ratio = wh[7];

    info.leave_height = wh[6];
    info.scale_ratio = wh[7];
    info.photograph_width = parseFloat(Number(wh[0]) / (scale / mapScale));//拍照幅宽
    info.photograph_height = parseFloat(Number(wh[2]) / (scale / mapScale));//拍照幅高
    arr[obj.idx] = { ...obj };
    setMarkers([...arr]);
    setMarkerInfo({ ...info });
    setMoveMarkerInfo({ ...obj });
  }

  //俯仰度为 -90时
  const GetWidthNew = (data, _scale) => {
    let obj = {
      type: data.type,
      multiple: data.multiple,
      height: data.height,
      scale_ratio: 1,
    }
    let yaw_angle = data.yaw_angle;//偏航角
    let leave_height = data.leave_height;//离开高度
    let hypotenuse = 0;//斜边
    let focal_length = 0;//当前焦距
    let new_hypotenuse = 0;//实际斜边
    let zoom_ratio = 1;//变焦比值
    if (obj.type === 'zoom') {  //变焦
      obj.scale_ratio = zoomNum;//缩放比例

      //变焦镜头 1/2"CMOS
      //焦距范围 21 ~ 75 mm
      //等效焦距范围 113 ~ 405 mm
      //传感器宽高 	6.4 * 4.8  斜边 8  宽:斜 4:5 高:斜  3:5
      //m30变焦相机，当变焦倍数小于等于5时调用的广角相机，当前焦距就是广角相机的焦距 * 当前倍数
      let max_focal = 75;//最大焦距 mm
      let max_equivalent_focal = 405;//最大等效焦距
      let widt_focal = 4.5;//广角相机焦距
      let widt_equivalent_focal = 24;//广角相机等效焦距
      let max_multiple = parseInt(max_focal / 4.5);//最大光学变焦倍数
      let ratio = max_equivalent_focal / max_focal;//最大等效焦距 与 最大焦距的比值 约等于 5.4 
      hypotenuse = 8;//斜边
      if (obj.multiple <= max_multiple) {
        focal_length = widt_focal * obj.multiple
      } else {
        zoom_ratio = Math.round(obj.multiple * widt_equivalent_focal / max_equivalent_focal);//变焦比值
        focal_length = (obj.multiple * 24 / zoom_ratio) / ratio;//当前焦距  (变焦相机等效焦距 = 倍数 * 广角相机等效焦距（24）/变焦比值) / ratio
      }
      new_hypotenuse = parseFloat((obj.height * hypotenuse) / focal_length);//实际斜边
    }
    if (obj.type === 'wide') { //广角
      obj.scale_ratio = wideNum;//缩放比例

      //广角镜头 1/2"CMOS
      //焦距 4.5 mm
      //传感器宽高 	6.4 * 4.8  斜边 8  宽:斜 4:5 高:斜  3:5
      hypotenuse = 8;//斜边
      focal_length = 4.5;//当前焦距
      new_hypotenuse = parseFloat(2 * (obj.height * (hypotenuse / 2) / focal_length));//实际斜边
    }
    if (obj.type === 'ir') { //热成像
      obj.scale_ratio = irNum;//缩放比例

      // DFOV：61°
      let angle = (90 - (61 / 2)) * (Math.PI / 180);//角度
      new_hypotenuse = parseFloat(2 * (obj.height / Math.tan(angle)));
    }
    let _width = parseFloat((new_hypotenuse * 4) / 5 / zoom_ratio).toFixed(2);//幅宽
    let _height = parseFloat((new_hypotenuse * 3) / 5 / zoom_ratio).toFixed(2);//幅高
    var width = parseFloat((_width * (scale / _scale)).toFixed(2));
    var height = parseFloat((_height * (scale / _scale)).toFixed(2));
    let angle = Math.asin(3 / 5) * (180 / Math.PI);
    let len = [
      { len: parseFloat((new_hypotenuse / 2) * (scale / mapScale)).toFixed(2), angle: parseFloat(angle).toFixed(2) },
      { len: parseFloat((new_hypotenuse / 2) * (scale / mapScale)).toFixed(2), angle: parseFloat(angle).toFixed(2) },
      { len: 0, angle: parseFloat(90).toFixed(2) },
    ]
    //
    return [width, width, height, (height / 2 * -1), len, yaw_angle, leave_height, obj.scale_ratio]

  }

  //俯仰度 小于0并且大于-90时
  //相机的拍摄区域与飞行高度、无人机偏航角度、摄像头的俯仰角度有关，如果是变焦相机还与变焦有关
  const GetWidthNew2 = (data, _scale) => {
    let obj = {
      type: '',
      multiple: 5,
      pitch_angle: 90,//俯仰角度
      height: Number(data.flight_height) || 80,//飞行高度  || 到达高度
      scale_ratio: 1,//缩放比值
    }
    let yaw_angle = 0;
    let leave_height = obj.height;
    //根据动作 获取对应的高度、偏航角度、俯仰角度等信息
    //只有在“拍照”动作之前的动作才会在计算之内
    if (data.action && data.action.length >= 0) {
      let types = data.action.map((item) => { return item.type });
      let idx = types?.indexOf(5);
      for (let i = 0; i < data.action.length; i++) {
        let val = data.action[i];
        switch (val.type) {
          case 1://抬升高度
            leave_height = leave_height + Number(val.uplift_height);
            if (i < idx && idx >= 0) { obj.height = obj.height + Number(val.uplift_height); };
            break;
          case 2://下降高度
            leave_height = leave_height - Number(val.decline_height);
            if (i < idx && idx >= 0) { obj.height = obj.height - Number(val.decline_height); };
            break;
          case 3://偏航角
            if (i < idx && idx >= 0) { yaw_angle = val.yaw_angle } break;
          case 15://无人机偏航角
            if (i < idx && idx >= 0) { yaw_angle = val.UAV_angle } break;
          case 4://俯仰角
            if (i < idx && idx >= 0) { obj.pitch_angle = Number(val.pitch_angle) + 90; } break;
          case 7://变焦
            if (i < idx && idx >= 0) { obj.multiple = Number(val.zoom); } break;
          case 5:
            if (val.photograph_type.length > 0) { obj.type = val.photograph_type[0]; }; break;
          default: break;
        }
      }
      if (idx === -1) { //没有拍照，直接退出
        let len = [
          { len: 0, angle: 0 },
          { len: 0, angle: 0 },
          { len: 0, angle: 0 }
        ];
        return [0, 0, 0, 0, len, yaw_angle, leave_height, obj.scale_ratio]
      }
    } else {
      let len = [
        { len: 0, angle: 0 },
        { len: 0, angle: 0 },
        { len: 0, angle: 0 }
      ];
      return [0, 0, 0, 0, len, yaw_angle, leave_height, obj.scale_ratio]
    }
    if (obj.pitch_angle === 0) {
      let o = {
        type: obj.type,
        multiple: obj.multiple,
        height: obj.height,
        yaw_angle: yaw_angle,
        leave_height: leave_height,
      }
      return GetWidthNew(o, _scale)
    }

    if (obj.pitch_angle === 90) {
      let len = [
        { len: Math.floor(Math.sqrt(50000) * (scale / mapScale)), angle: 63.46 },
        { len: Math.floor(Math.sqrt(50000) * (scale / mapScale)), angle: 63.46 },
        { len: Math.floor(200 * (scale / mapScale)), angle: 90 },
      ]
      return [
        Math.floor(200 * (scale / mapScale)),
        Math.floor(200 * (scale / mapScale)),
        2,
        Math.floor(200 * (scale / mapScale)),
        len,
        yaw_angle,
        leave_height,
        obj.scale_ratio
      ]
    }
    let w = 0;//宽
    let h = 0;//高
    let focal_length = 0;//当前焦距
    let zoom_ratio = 1;//变焦比值
    // m30 相机
    if (obj.type === 'zoom') { //变焦
      obj.scale_ratio = zoomNum;//缩放比例

      //变焦镜头 1/2"CMOS
      //焦距范围 21 ~ 75 mm
      //等效焦距范围 113 ~ 405 mm
      //传感器宽高 	6.4 * 4.8  斜边 8  宽:斜 4:5 高:斜  3:5
      //最大倍数 = 变焦相机最大等效焦距÷广角相机等效焦距x数码变焦比值
      let min_focal = 21;//最小焦距 mm
      let max_focal = 75;//最大焦距 mm
      let equivalent_min_focal = 113;//最小等效焦距
      let max_equivalent_focal = 405;//最大等效焦距
      let widt_equivalent_focal = 24;//广角相机等效焦距
      let ratio = max_equivalent_focal / max_focal;//最大等效焦距 与 最大焦距的比值 约等于 3.58 
      let max_multiple = parseInt(max_focal / 4.5);//最大光学变焦倍数
      let widt_focal = 4.5;//广角相机焦距
      w = 6.4;
      h = 4.8;
      if (obj.multiple <= max_multiple) {
        focal_length = widt_focal * obj.multiple
      } else {
        zoom_ratio = Math.round(obj.multiple * widt_equivalent_focal / max_equivalent_focal);//变焦比值
        focal_length = (obj.multiple * 24 / zoom_ratio) / ratio;//当前焦距  (变焦相机等效焦距 = 倍数 * 广角相机等效焦距（24）/变焦比值) / ratio
      }
    }

    if (obj.type === 'wide') { //广角
      obj.scale_ratio = wideNum;//缩放比例

      //广角镜头 1/2"CMOS
      //焦距 4.5 mm
      //传感器宽高 	6.4 * 4.8  斜边 8  宽:斜 4:5 高:斜  3:5
      w = 6.4;
      h = 4.8;
      focal_length = 4.5;//当前焦距
    }

    if (obj.type === 'ir') { //热成像
      obj.scale_ratio = irNum;//缩放比例

      // DFOV：61°
      // 焦距：9.1 mm
      let tan = 0.589; // tan(61/2) 约等于
      focal_length = 9.1;
      let hypotenuse = 2 * tan * focal_length;//斜边 约等于 10.7198 
      w = 4 * hypotenuse / 5;
      h = 3 * hypotenuse / 5;
    }

    //开始计算相机拍摄区域
    let AH = obj.height / Math.cos((obj.pitch_angle) * (Math.PI / 180)); //邻边比斜边
    let j_DAI = 2 * Math.asin((h / 2) / (Math.sqrt(Math.pow((h / 2), 2) + Math.pow(focal_length, 2)))) * (180 / Math.PI); // 角DAI的角度
    let AD = obj.height / Math.cos(((j_DAI / 2) + obj.pitch_angle) * (Math.PI / 180));
    let j_BAC = 2 * Math.asin((w / 2) / Math.sqrt(Math.pow(Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)) / 2, 2) + Math.pow(focal_length, 2))) * (180 / Math.PI);

    let AC = AD / Math.cos((j_BAC / 2) * (Math.PI / 180));
    let DC = Math.sqrt(Math.pow(AC, 2) - Math.pow(AD, 2));
    let BC = 2 * DC;
    let DH = Math.sqrt(Math.pow(AD, 2) + Math.pow(AH, 2) - (Math.cos((j_DAI / 2) * (Math.PI / 180)) * 2 * AD * AH));
    let j_AHG = 90 - obj.pitch_angle;
    let j_HAI = j_DAI / 2;
    let j_AIH = 180 - j_AHG - j_HAI;
    let HI = AH * Math.sin(j_HAI * (Math.PI / 180)) / Math.sin(j_AIH * (Math.PI / 180));
    let FE = HI * BC / DH;
    let DI = DH + HI;
    let HG = Math.sqrt(Math.pow(AH, 2) - Math.pow(obj.height, 2));
    let DG2 = Math.pow(AD, 2) - Math.pow(obj.height, 2);
    let CG = Math.sqrt(Math.pow(DC, 2) + DG2);
    let j_DCG = Math.acos(DC / CG) * (180 / Math.PI);

    // let IA = (FE / 2) / Math.tan((j_BAC / 2) * (Math.PI / 180));

    let IG = obj.height * Math.tan((obj.pitch_angle - j_HAI) * (Math.PI / 180));
    let EG = Math.sqrt(Math.pow(IG, 2) + Math.pow(FE / 2, 2));
    let j_IEG = Math.acos((FE / 2) / EG) * (180 / Math.PI);
    let len = [
      { len: Math.floor(EG * (scale / mapScale)), angle: parseFloat(j_IEG).toFixed(2) },
      { len: Math.floor(CG * (scale / mapScale)), angle: parseFloat(j_DCG).toFixed(2) },
      { len: Math.floor(HG * (scale / mapScale)), angle: 90 },
    ]
    return [
      Math.floor(BC * (scale / mapScale) / zoom_ratio),//梯形上底
      Math.floor(FE * (scale / mapScale) / zoom_ratio),//梯形下底
      Math.floor(DI * (scale / mapScale) / zoom_ratio),//梯形高
      Math.floor((HG - HI) * (scale / mapScale)),//偏移
      len,//线 中心点到梯形4个点的距离
      yaw_angle,//旋转角度
      leave_height,//离开高度
      obj.scale_ratio,//缩放比值
    ]

  }

  //获取航点| 管线节点  1 管线节点  2 航点
  const GetRoutesPointFun = async (type, c) => {
    let res = await GetRoutesPoint({
      access_token: token,
      id: PID,//航线id
      type: type || 1// 1 :管线   3：标识桩
    });
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return c();
    }
    if (!res.data.length || res.data.length === 0) {
      message.warning('暂无管线节点');
      return c();
    }
    let arr = res.data.map((item) => {
      let d = transformGCJ2WGS({ lat: item.latitude, lon: item.longitude });
      return [Number(parseFloat(d.lon).toFixed(7)), Number(parseFloat(d.lat).toFixed(7))];
    })
    let altitude = await GetAltitudeFun(arr[0]);
    setCenterAltitude(altitude);
    setLineData([...arr]);
    if (selectType === '1' || selectType === '7') {
      getIdentificationStake()
    }
    return c(arr[0], arr[arr.length - 1])
  }

  // 获取标识桩数据
  const getIdentificationStake = async () => {
    let res = await GetRoutesPoint({
      access_token: token,
      id: PID,//航线id
      type: 3,// 1 :管线   3：标识桩
    });
    if (res.ret !== 200) { message.warning(res.msg || '系统出错了'); return }
    let ide = []
    if (JSON.stringify(res.data) !== '{}') {
      res.data.forEach((res) => {
        if (res.wgs84_latitude && JSON.stringify(res.wgs84_latitude) !== '') {
          ide.push(res)
        }
      })
      setIdentificationStake([...ide])
    }
  }

  // 获取无人机信息
  const getUAVInfo = async () => {
    let res = await getUavpositionInfo({ access_token: token, })
    if (res.ret === 200) {
      if (res.data && res.data.latitude !== 'NaN' && res.data.lngtitude !== 'NaN') {
        let uav = UAVList
        uav.state = '1'
        uav.point = [Number(parseFloat(Number(res.data.lngtitude)).toFixed(7)) + 0.00024, Number(parseFloat(Number(res.data.latitude)).toFixed(7))]
        setUAVList({ ...uav })
      }
    }
  }

  //调用编辑航点接口
  const SetRoutesPointFun = async (data) => {
    let obj = {
      access_token: token,
      RID: PID,
      data: data.length > 0 ? JSON.stringify(data) : "[]"
    }
    let res = await SetRoutesPoint(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return false;
    }
    if (!res.data) {
      message.warning('航点保存失败');
      return false;
    }
    return true
  }

  //调用编辑航点动作接口
  const SetRoutesPointActionFun = async (data) => {
    let obj = {
      access_token: token,
      RID: PID,
      Actiondata: data.length > 0 ? JSON.stringify(data) : '[]'
    }
    let res = await SetRoutesPointAction(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return false;
    }
    return true;
  }

  //调用编辑起飞点接口
  const SetTakeOffFun = async (info) => {
    let res = await SetTakeOff(info);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return false;
    }
    return true;
  }

  //获取管线详细信息以及起飞点信息
  const GetRoutesInfoFun = async (point, c) => {
    let obj = {
      access_token: token,
      id: PID,
    }
    let res = await GetRoutesInfo(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    setIsLock(res.data.is_lock)
    let cur = curName
    cur.title = res.data.title
    cur.Rtitle = res.data.Rtitle
    setCurName({ ...cur })

    let val = res.data;
    let c_point = [];
    let d
    if (val.latitude && val.longitude) { //起飞点的经纬度存在
      // 起飞点
      let p = transformGCJ2WGS({ lat: val.latitude, lon: val.longitude });
      d = ([Number(parseFloat(p.lon).toFixed(7)), Number(parseFloat(p.lat).toFixed(7))]);
      setCenterPoint(d);
      c_point = d;
    } else { //起飞点的经纬度不存在
      if (point) {
        // 显示管线的第一个点
        d = point;
      } else {
        //显示成都的经纬度
        d = [104.06, 30.67];
      }
    }


    let altitude = 0;
    if (val.takeoffasl === '') {
      altitude = await GetAltitudeFun(d);
    } else {
      altitude = Number(val.takeoffasl)
    }

    setHomeAltitude(altitude);
    let info = {
      id: val.id,
      point: d,
      surface_altitude: altitude,//地表海拔  海拔(高程)
      flight_height: Number(val.takeoffaltitude) || 0,//飞行高度 || 到达高度
      return_height: Number(val.gohomealtitude) || 0,//返航高度
      takeoff_range: Number(val.takeoffdistance_limit) || 0,//起飞范围
      uplift_speed: Number(val.safespeed) || 0,//抬升速度
      cruise_speed: Number(val.curspeed) || 0,//巡航速度
      waypoint_speed: Number(val.waypointspeed) || 0,//航点速度
      waypoint_type: val.pointtype,//航点类型
    }
    setHomeMarker({ ...info })
    return c(c_point, info, altitude)
  }

  //获取航点以及航点动作
  const GetRoutesPointActionFun = async (ponit, center, home_info, altitude) => {
    let obj = {
      access_token: token,
      id: PID
    }
    let res = await GetRoutesPointAction(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    if (!res.data.length || res.data.length === 0) { //没有航点
      if (center.length === 0) {
        setCenterPoint(ponit)
      }
      return;
    }
    let arr = [];
    let altitudes_list = [];
    for (let i = 0; i < res.data.length; i++) {
      let item = res.data[i];
      let obj = {
        id: item.id,
        name: item.name,
        idx: i,
        point: [Number(item.wgs84_longitude), Number(item.wgs84_latitude)],
        gcj_point: [Number(item.longitude), Number(item.latitude)],
        flight_height: Number(item.altitude) || 0,//飞行高度 || 到达高度
        yaw_angle: 0,//偏航角度
        action: [],//动作
        scale_ratio: 1,//缩放比值
      }
      altitudes_list.push(Number(item.asl));//地表海拔 海拔(高程)

      if (item.aclist && item.aclist.length > 0) {
        obj.action = item.aclist.map((val) => {
          let obj_action = {
            type: val.type,
            uplift_height: '',//抬升高度
            decline_height: '',//下降高度
            UAV_angle: '',//无人机偏航角度
            yaw_angle: '',//偏航角度
            pitch_angle: '',//俯仰角度
            zoom: '',//变焦倍数
            photograph_type: [],//拍照类型
            readout_type: null,//读数 - 显表类型
            readout_unit: '',//读数 - 数据单位
            readout_range: ['', ''],//读数 - 合理范围
            temperature_range: ['', ''],//温度 - 合理范围
            photography_state: true,//摄像是否开启
            lighting_state: true,//照明辅助是否开启

            tnterval_time_shooting_type: [],
            tnterval_time_shooting_text: '',
            tnterval_distance_shooting_type: [],
            tnterval_distance_shooting_text: '',
          }
          switch (val.type) {
            case 1: obj_action.uplift_height = Number(val.value); break;
            case 2: obj_action.decline_height = Number(val.value); break;
            case 3: obj_action.yaw_angle = val.value; obj.yaw_angle = val.value; break;
            case 4: obj_action.pitch_angle = val.value; break;
            case 5: obj_action.photograph_type = [val.value]; break;
            case 6: obj_action.photography_state = (Number(val.value) === 1 ? true : false); break;
            case 7: obj_action.zoom = val.value; break;
            case 8: obj_action.readout_range = val.value !== '' ? [val.value.split('-')[0], val.value.split('-')[1]] : ['', '']; break;
            case 9: obj_action.lighting_state = (Number(val.value) === 1 ? true : false); break;
            case 10: obj_action.temperature_range = val.value !== '' ? [val.value.split('-')[0], val.value.split('-')[1]] : ['', '']; break;
            case 11:
              obj_action.tnterval_time_shooting_type = val.value && val.value !== '' ? [val.value.split('_')[0]] : ['wide'];
              obj_action.tnterval_time_shooting_text = val.value && val.value !== '' ? Number(val.value.split('_')[1]) : '';
              break;
            case 12:
              obj_action.tnterval_distance_shooting_type = val.value && val.value !== '' ? [val.value.split('_')[0]] : ['wide'];
              obj_action.tnterval_distance_shooting_text = val.value && val.value !== '' ? Number(val.value.split('_')[1]) : '';
              break;
            case 14: obj_action.hoverTime = val.value; break;
            case 15: obj_action.UAV_angle = val.value; obj.UAV_angle = val.value; break;
            default:
              break;
          }
          return obj_action
        })
      }
      let wh = GetWidthNew2(obj, mapScale)
      obj.width = Number(wh[0]);
      obj.height = Number(wh[1]);
      obj.z = Number(wh[2]);
      obj.offset = Number(wh[3]);//偏移
      obj.line = wh[4];
      obj.photograph_width = parseFloat(Number(wh[0]) / (scale / mapScale));//拍照幅宽
      obj.photograph_height = parseFloat(Number(wh[2]) / (scale / mapScale));//拍照幅高
      obj.yaw_angle = Number(wh[5]);
      obj.leave_height = wh[6];
      obj.scale_ratio = wh[7];//缩放比例
      arr.push(obj);
    }
    if (center.length === 0) {
      setCenterPoint(arr[0].point);
    }
    setMarkers([...arr]);
    setAltitudes([...altitudes_list]);

    compareAltitude(arr, altitudes_list, home_info, altitude)
  }

  // 比较 起飞点（起飞海拔、返航海拔）与航点（到达海拔）
  const compareAltitude = (arr, altitudes_list, home_info, altitude) => {
    let max = 0
    let idx = 0
    arr.forEach((ele, index) => {
      let cur = Number(ele.flight_height + altitudes_list[index])
      if (max < cur) {
        max = cur.toFixed(1)
        idx = index
      }
    })
    let obj = {
      arr: arr,
      qfd_qfhb: Number(home_info.flight_height + altitude).toFixed(1),//起飞点 起飞海拔
      qfd_fhhb: Number(home_info.return_height + altitude).toFixed(1),//起飞点 返航海拔
      idx: idx,
    }
    let takeOffDifference = (obj.qfd_qfhb - max).toFixed(1)//起飞海拔 相减差值（起飞海拔 - 航点海拔）
    let returnFlightDifference = (obj.qfd_fhhb - max).toFixed(1)//返航海拔 相减差值（起飞海拔 - 航点海拔）


    let saf = safetyWarn
    saf.takeoffAltitudeInfo = {}
    saf.returnAltitudeInfo = {}
    let takeoSaflist = {}//起飞信息
    let returnSaflist = {}//返航信息

    // 起飞海拔警告
    if (takeOffDifference < -50) {
      takeoSaflist = {
        severity: 1,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`起飞海拔（${(obj.qfd_qfhb) * 1}m）低于该航点  ${-1 * takeOffDifference}  m`}</span>
          </div>
        )
      }
    } else if (takeOffDifference >= -50 && takeOffDifference <= -20) {
      takeoSaflist = {
        severity: 2,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`起飞海拔（${(obj.qfd_qfhb) * 1}m）低于该航点  ${-1 * takeOffDifference}  m`}</span>
          </div>
        )
      }
    } else if (takeOffDifference > -20 && takeOffDifference <= 0) {
      takeoSaflist = {
        severity: 3,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`起飞海拔（${(obj.qfd_qfhb) * 1}m）低于该航点  ${-1 * takeOffDifference}  m`}</span>
          </div>
        )
      }
    } else if (takeOffDifference > 0 && takeOffDifference <= 300) {
      takeoSaflist = {
        severity: 3,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`起飞海拔（${(obj.qfd_qfhb) * 1}m）高于该航点  ${takeOffDifference * 1}  m`}</span>
          </div>
        )
      }
    } else if (takeOffDifference > 300) {
      takeoSaflist = {
        severity: 2,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`起飞海拔（${(obj.qfd_qfhb) * 1}m）高于该航点  ${takeOffDifference * 1}  m`}</span>
          </div>
        )
      }
    } else {
      takeoSaflist = {}
    }
    saf.takeoffAltitudeInfo = (takeoSaflist)

    // 返航海拔警告
    if (returnFlightDifference < -50) {
      returnSaflist = {
        severity: 1,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`返航海拔（${(obj.qfd_fhhb) * 1}m）低于该航点  ${-1 * returnFlightDifference}  m`}</span>
          </div>
        )
      }
    } else if (returnFlightDifference >= -50 && returnFlightDifference <= -20) {
      returnSaflist = {
        severity: 2,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`返航海拔（${(obj.qfd_fhhb) * 1}m）低于该航点  ${-1 * returnFlightDifference}  m`}</span>
          </div>
        )
      }
    } else if (returnFlightDifference > -20 && returnFlightDifference <= 0) {
      returnSaflist = {
        severity: 3,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`返航海拔（${(obj.qfd_fhhb) * 1}m）低于该航点  ${-1 * returnFlightDifference}  m`}</span>
          </div>
        )
      }
    } else if (returnFlightDifference > 0 && returnFlightDifference <= 300) {
      returnSaflist = {
        severity: 3,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`返航海拔（${(obj.qfd_fhhb) * 1}m）高于该航点  ${returnFlightDifference * 1}  m`}</span>
          </div>
        )
      }
    } else if (returnFlightDifference > 300) {
      returnSaflist = {
        severity: 2,
        txt: (
          <div className='flex_col'>
            <span>{`${idx + 1}号航点到达海拔为：${max * 1} m`}</span>
            <span>{`返航海拔（${(obj.qfd_fhhb) * 1}m）高于该航点  ${returnFlightDifference * 1}  m`}</span>
          </div>
        )
      }
    } else {
      returnSaflist = {}
    }
    saf.returnAltitudeInfo = (returnSaflist)
    setSafetyWarn({ ...saf })
  }

  // 判断集合
  // type 1:保存    2:删除
  const JudgmentSet = (type, mak) => {
    const key = 'updatable';
    let savdt = []//保存 信息
    let dis = []//距离 信息
    let lists = type === 2 ? mak : [...markers];
    let altitudes_list = [...altitudes];
    let arr_point = [];//航点数据
    let arr_action = [];//航点动作数据

    let sj_hd = []
    let jl_hd = []
    let js_hd = []

    for (let i = 0; i < lists.length; i++) {
      let val = lists[i];
      //判断 + 处理 航点信息
      if (!val.point[0] || !val.point[1]) {
        savdt.push({
          severity: 1,
          txt: '航点' + (i + 1) + '经纬度不能为空',
        })
      }

      let zb = [];
      zb = transformWGSGCJ2(val.point[1], val.point[0]);
      let obj = {
        order: i + 1,
        name: val.name,
        wgs84_longitude: val.point[0],
        wgs84_latitude: val.point[1],
        longitude: Number(parseFloat(Number(zb.lon)).toFixed(7)),
        latitude: Number(parseFloat(Number(zb.lat)).toFixed(7)),
        altitude: val.flight_height,
        type: 2,
        asl: altitudes_list[i],
        relative: Math.round((altitudes_list[i] + val.flight_height) - home_altitude),
      }
      if (!obj.altitude) {
        savdt.push({
          severity: 1,
          txt: '航点' + (i + 1) + '到达高度为空',
        })
      }
      arr_point.push(obj);

      //判断 + 处理 航点动作信息 动作必须要有值，如果不需要请删除动作
      if (val.action.length > 0) {
        for (let n = 0; n < val.action.length; n++) {
          let nal = val.action[n];
          if (!nal.type) {
            savdt.push({
              severity: 1,
              txt: '航点' + (i + 1) + '中，第' + (n + 1) + '个动作为空',
            })
          }
          let obj = {
            Rorder: i + 1,
            order: n + 1,
            type: nal.type,
          }
          switch (nal.type) {
            case 1:
              if (nal.uplift_height === null || nal.uplift_height === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '抬升动作值不能为空',
                })
              }
              obj.value = nal.uplift_height; break;
            case 2:
              if (nal.decline_height === null || nal.decline_height === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '下降动作值不能为空',
                })
              }
              obj.value = nal.decline_height; break;
            case 3:
              if (nal.yaw_angle === null || nal.yaw_angle === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '偏航动作值不能为空',
                })
              }
              obj.value = nal.yaw_angle; break;
            case 4:
              if (nal.pitch_angle === null || nal.pitch_angle === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '俯仰动作值不能为空',
                })
              }
              obj.value = nal.pitch_angle; break;
            case 5: obj.value = nal.photograph_type[0]; break;
            case 6: obj.value = nal.photography_state ? 1 : 0; break;
            case 7: obj.value = nal.zoom || 2; break;
            case 8:
              if (nal.readout_range[0] === '' || nal.readout_range[1] === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '采集读数动作值不能为空',
                })
              }
              obj.value = nal.readout_range[0] && nal.readout_range[1] ? nal.readout_range[0] + '-' + nal.readout_range[1] : ''; break;
            case 9: obj.value = nal.lighting_state ? 1 : 0; break;
            case 10:
              if (nal.temperature_range[0] === '' || nal.temperature_range[1] === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '测量温度动作值不能为空',
                })
              }
              obj.value = nal.temperature_range[0] && nal.temperature_range[1] ? nal.temperature_range[0] + '-' + nal.temperature_range[1] : ''; break;
            case 11:
              if (!nal.tnterval_time_shooting_text || nal.tnterval_time_shooting_text === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '等时间隔值不能为空',
                })
              }
              obj.value = nal.tnterval_time_shooting_type[0] + '_' + nal.tnterval_time_shooting_text;
              sj_hd.push(i + 1)
              break;

            case 12:
              if (!nal.tnterval_distance_shooting_text || nal.tnterval_distance_shooting_text === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '等距间隔值不能为空',
                })
              }
              obj.value = nal.tnterval_distance_shooting_type[0] + '_' + nal.tnterval_distance_shooting_text;
              jl_hd.push(i + 1)
              break;
            case 13:
              js_hd.push(i + 1)
              break;
            case 14:
              if (!nal.hoverTime || nal.hoverTime === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '悬停时间不能为空',
                })
              }
              obj.value = nal.hoverTime
              break;
            case 15:
              if (nal.UAV_angle === null || nal.UAV_angle === '') {
                savdt.push({
                  severity: 1,
                  txt: '航点' + (i + 1) + '无人机偏航动作值不能为空',
                })
              }
              obj.value = nal.UAV_angle; break;
            default:
              break;
          }
          arr_action.push(obj)

        }
      }
    }

    let sj_value = []
    let jl_value = []
    if (sj_hd.length || jl_hd.length || js_hd.length) {
      if (!((sj_hd.length || jl_hd.length) && js_hd.length)) {
        if (!js_hd.length) {
          savdt.push({
            severity: 1,
            txt: '暂无结束间隔拍照动作',
          })
        }
        savdt.push({
          severity: 1,
          txt: '开始间隔拍照与结束间隔拍照不匹配',
        })
      }
    }
    //间隔时间计算
    js_hd.forEach((res, index) => {
      sj_hd.forEach((ele) => {
        if (index > 0) {
          if (ele > js_hd[index - 1] && ele <= res) {
            let obj = {
              key: ele,
              value: (res - ele)
            }
            sj_value.push(obj)
          }
        } else {
          if (res >= ele) {
            let obj = {
              key: ele,
              value: (res - ele)
            }
            sj_value.push(obj)
          }
        }
      })
    })
    // 间隔距离计算
    js_hd.forEach((res, index) => {
      jl_hd.forEach((ele) => {
        if (index > 0) {
          if (ele > js_hd[index - 1] && ele <= res) {
            let obj = {
              key: ele,
              value: (res - ele)
            }
            jl_value.push(obj)
          }
        } else {
          if (res >= ele) {
            let obj = {
              key: ele,
              value: (res - ele)
            }
            jl_value.push(obj)
          }
        }
      })
    })
    // value与动作的条数不相同则证明在相同条数后未显示的条数是没有对应的结束动作
    if (sj_hd.length !== sj_value.length) {
      savdt.push({
        severity: 1,
        txt: '开始间隔时间与结束间隔拍照动作不匹配',
      })
    }
    if (jl_hd.length !== jl_value.length) {
      savdt.push({
        severity: 1,
        txt: '开始间隔距离与结束间隔拍照动作不匹配',
      })
    }

    sj_value.forEach((ele) => {
      arr_action.forEach((res) => {
        if (ele.key === res.Rorder && res.type === 11) {
          res.value = res.value + '_' + ele.value
        }
      })
    })

    jl_value.forEach((ele) => {
      arr_action.forEach((res) => {
        if (ele.key === res.Rorder && res.type === 12) {
          res.value = res.value + '_' + ele.value
        }
      })
    })


    //判断 + 处理 起飞点数据
    let home = { ...HomeMarkerInfo };
    if (!home.point[0] || !home.point[1]) {
      savdt.push({
        severity: 1,
        txt: '起飞点经纬度不能为空',
      })
    }
    let zb = transformWGSGCJ2(home.point[1], home.point[0]);
    let info = {
      access_token: token,
      id: PID,
      takeoffaltitude: home.flight_height,//起飞高度
      gohomealtitude: home.return_height,//返航高度
      takeoffdistance_limit: home.takeoff_range,//起飞范围
      takeoffasl: home_altitude,//起飞海拔 || 地表海拔
      gohomeasl: home_altitude + home.return_height,//返航海拔
      safespeed: home.uplift_speed,//抬升速度
      curspeed: home.cruise_speed,//巡航速度
      waypointspeed: home.waypoint_speed,//航点速度
      longitude: Number(parseFloat(Number(zb.lon)).toFixed(7)),//经度
      latitude: Number(parseFloat(Number(zb.lat)).toFixed(7)),//纬度
      pointtype: home.waypoint_type,//航点类型
    }
    if (!info.takeoffaltitude) {
      savdt.push({
        severity: 1,
        txt: '起飞点飞行高度不能为空或0',
      })
    }
    if (!info.gohomealtitude) {
      savdt.push({
        severity: 1,
        txt: '起飞点返航高度不能为空或0',
      })
    }
    if (!info.curspeed) {
      savdt.push({
        severity: 1,
        txt: '起飞点巡航速度不能为空或0',
      })
    }
    if (!info.waypointspeed) {
      savdt.push({
        severity: 1,
        txt: '起飞点航点速度不能为空或0',
      })
    }
    let num = Math.floor(Math.random() * 3);
    setChooseMapKey(num);
    if (arr_point.length) {
      let arr = []
      arr_point.forEach((res, index) => {
        if (index > 0) {
          if (distance(arr, [res.wgs84_longitude, res.wgs84_latitude]) < 10) {
            dis.push({
              severity: 2,
              txt: `航点${index + 1} 与航点${index} 距离小于 10 米，可能会导致漏拍`,
            })
          }
        }
        arr = [res.wgs84_longitude, res.wgs84_latitude]
      })
    }



    let startTimeNum = 0
    let startDistanceNum = 0
    arr_action.forEach((res) => {
      if (res.type === 11) {
        startTimeNum += 1
      } else if (res.type === 12) {
        startDistanceNum += 1
      }
    })
    if (startTimeNum + startDistanceNum > 2) {
      savdt.push({
        severity: 2,
        txt: '`请注意：当前航线有多个拍照动作',
      })
    }

    let saf = safetyWarn
    saf.waypointActionInfo = savdt
    saf.distanceSafetyInfo = dis
    setSafetyWarn({ ...saf })
    return { arr_point, arr_action, info, saf, savdt }
  }


  //保存所有数据
  //type:true  保存用     false:验证用
  const SaveData = debounce(async () => {
    const key = 'updatable';

    if (is_compute) {
      message.warning({
        content: '正在计算海拔,请稍后重试',
        key,
      });
      return
    }
    if (typeof home_altitude !== 'number') {
      message.loading({
        content: '起飞点地表海拔为空，正在计算...',
        key,
      })
      let altitude = await GetAltitudeFun(HomeMarkerInfo.point);
      setHomeAltitude(altitude)
      return
    }
    if (markers.length < 2) {
      message.warning('至少需要两个航点才能保存');
      return;
    }

    for (let i = 0; i < altitudes.length; i++) {
      if (altitudes[i] === '') {//海拔获取失败
        message.warning('航点' + (i + 1) + '地表海拔为空，正在计算...');
        SetAltitudeFun(markers[i].point, i, 0);
        return;
      }
    }


    let list = JudgmentSet(1)
    if ((list?.savdt.length > 0)) {
      list.saf.isOpen = true
      message.warning({
        content: '请注意：当前有预警未消除，无法保存',
        key,
      });
      setSafetyWarn({ ...list.saf })
      return
    }
    if (list) {
      message.loading({
        content: '保存中...',
        key,
        duration: 0,
      });
      await SetRoutesPointFun(list.arr_point);//保存航点
      await SetRoutesPointActionFun(list.arr_action);//保存航点动作
      await SetTakeOffFun(list.info);//保存起飞点
      message.success({
        content: '保存成功',
        key,
      });
    } else {
      return
    }
  }, 500)

  //根据经纬度获取海拔
  const GetAltitudeFun = async (arr) => {
    setIsCompute(true);
    let params = {
      access_token: token,
      longitude: arr[0],
      latitude: arr[1],
    }
    let res = await GetAltitude(params);
    setIsCompute(false);
    if (res.ret !== 200) {
      message.warning({
        content: '海拔获取失败，请联系管理员或者重试',
        key: 'updatable',
        duration: 2,
      });
      return ''
    } else {
      return Number(res.data[0]?.elevation)
    }
  }

  //新方法 - 获取海拔
  //调用 bing地图的API
  const newGetElevationFun = async (arr) => {
    const microsoftMapKey = 'AlrkD4QTcaAllW-JGjWb1Y11z8Cg-R1mAgCQbVDzh0cua51ySg-hNpRZvCmd3-Uq';
    let url = 'http://dev.virtualearth.net/REST/v1/Elevation/List?points=' + arr[1] + ',' + arr[0] + '&key=' + microsoftMapKey;
    let res = await axios.get(url);
    try {
      if (res.status !== 200 || res.data.statusCode !== 200) {
        message.warning('数据跑丢了~');
        return 0;
      }
      let elevation = res.data.resourceSets[0].resources[0].elevations[0];
      message.success('我获取到海拔了：' + elevation);
      return Number(elevation)
    }
    catch (err) {
      message.warning('数据跑丢了~');
      return 0;
    }

  }

  //修改海拔 value 经纬度 || 海拔  idx 修改航点的下标  type 1 新增 0修改
  //航点的信息与海拔是分开存的
  const SetAltitudeFun = async (value, idx, type) => {
    if (!value) value = '';
    let t = typeof value;
    let altitudes_list = [...altitudes];
    if (t !== 'number') {

      let altitude = await GetAltitudeFun(value);
      if (altitude !== '') {
        altitude = Number(altitude)
      }
      if (type === 1) {
        altitudes_list.splice(idx, 0, altitude);
      } else {
        altitudes_list[idx] = altitude;
      }
    } else {
      altitudes_list[idx] = value;
    }
    setAltitudes([...altitudes_list]);
  }

  // 警告图标自定义
  const WarningIcon = (color) => {
    return (
      <svg viewBox="64 64 896 896" focusable="false" data-icon="exclamation-circle" width="2em" height="2em" fill="currentColor" aria-hidden="true">
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" fill={color}></path>
        <path d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm-32 156c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z" fill="#000"></path>
        <path d="M488 576h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8zm-24 112a48 48 0 1096 0 48 48 0 10-96 0z" fill={color}></path>
      </svg>
    )
  }

  useEffect(() => { //页面加载完成执行
    getLimitsAuthority()//获取权限
    let num = Math.floor(Math.random() * 3);
    setChooseMapKey(num);
    // getUAVInfo()
    timer.current = setInterval(() => {
      getUAVInfo()
    }, 2000)
    GetRoutesPointFun(1, async (point, point2) => {
      GetRoutesInfoFun(point, (center, info, altitude) => {
        GetRoutesPointActionFun(point2, center, info, altitude)//获取航点以及动作
      });//获取起飞点信息
    });//获取管线节点
    getActionByOnSiteInspectionType()

    let saf = safetyWarn
    saf.isOpen = true
    setSafetyWarn({ ...saf })

    //eslint-disable-next-line
  }, [])

  //航点名称输入框自动获取焦点
  useEffect(() => {
    if (name_show) {
      inputRef.current.focus({
        cursor: 'end'
      })
    }
    //eslint-disable-next-line
  }, [name_show, inputRef])

  //地图放大缩小执行，需要根据地图比例重新计算航点的各数据
  useEffect(() => {
    if (markers && markers.length === 0) {
      return;
    }
    let arr = [...markers];
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i];
      val.idx = i;
      let wh = GetWidthNew2(val, mapScale);
      val.width = Number(wh[0]);
      val.height = Number(wh[1]);
      val.z = Number(wh[2]);
      val.offset = Number(wh[3]);
      val.line = wh[4];
      val.photograph_width = parseFloat(Number(wh[0]) / (scale / mapScale));//拍照幅宽
      val.photograph_height = parseFloat(Number(wh[2]) / (scale / mapScale));//拍照幅高
      val.yaw_angle = Number(wh[5]);
      val.leave_height = wh[6];
      val.scale_ratio = wh[7];
      if (val.is_check) {
        setMoveMarkerInfo(val);
      }
    }
    setMarkers([...arr]);
    //eslint-disable-next-line
  }, [mapScale])

  // 根据起飞点地表海拔的改变而改变
  useEffect(() => {
    if (home_altitude) {
      compareAltitude(markers, altitudes, HomeMarkerInfo, home_altitude)
    }
    // eslint-disable-next-line
  }, [home_altitude])

  // 根据航点地表海拔的改变而改变
  useEffect(() => {
    if (home_altitude) {
      compareAltitude(markers, altitudes, HomeMarkerInfo, home_altitude)
    }
    // eslint-disable-next-line
  }, [altitudes])

  // 监听变化  进行警告
  useEffect(() => {
    if ((waypoint_show || takeoffpoint_show)) {
      JudgmentSet(0)
    }

    // eslint-disable-next-line
  }, [marker_info, HomeMarkerInfo, markers])

  return (
    <div style={{ height: '100vh' }}>
      {/* 地图 */}
      <RMap
        features={[]}
        ref={mapRef}
        zoom={zoom}
        center={center_point}
        onClick={(item, data) => {
        }}
        onZoomChange={(map) => {
          // zoom 最大20 最小2
          setZoom(map.getZoom());
          setmapScale(map.getScale())
        }}
        onDragEnd={async (map) => { //地图拖动结束执行
          setCenterPoint(map.getCenter().toArray());
          let altitude = await GetAltitudeFun(map.getCenter().toArray());
          setCenterAltitude(altitude);
        }}
      >
        {/* 天地图图层 */}
        <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=vec_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />
        <TileLayer getTileUrl={'http://t0.tianditu.gov.cn/DataServer?T=img_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} opacity={opacity} />
        <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=cia_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />
        {/* <TileLayer getTileUrl={'https://m30test.9zhouzhineng.com/sky_map_tiles/[z]/[y]/[x].png'} /> */}

        {/* 管线 */}
        <>
          {
            line_data.length > 0 ? (
              <BezierCurve
                path={line_data}
                isOutline={true}
                outlineColor="white"
                // outlineColor={opacity ? `white` : `red`}
                borderWeight={2}
                strokeColor={line_color}
                strokeOpacity={1}
                strokeWeight={4}
                strokeStyle="solid" // 线样式还支持 'dashed'
                lineJoin='round'
                lineCap='round'
                // strokeStyle是dashed时有效
                // strokeDasharray={[4, 4]}  //0 设置线段粗细  1 设置线段粗细间隔
                zIndex={50}
              />) : ''
          }
        </>

        {/* 标识桩 */}
        <>
          {
            identificationStake && identificationStake.length > 0
              ?
              identificationStake.map((res, index) => {
                return (
                  <AmapMarker
                    key={index}
                    draggable={false}
                    clickable={false}
                    position={[res.wgs84_longitude, res.wgs84_latitude]}
                    // zIndex={99999}
                    offset={[0, 0]}
                  >
                    <div>
                      <img className='shadow pos_abs ' src={peg} alt='标识桩' style={{ top: '-93px', left: '-14px' }} />
                      <div className='pegpoint pos_abs  h17 w78 flex jcc align_center' style={{ top: '-58px', left: '-38px', color: '#000' }}>{res.name}</div>
                    </div>
                  </AmapMarker>

                )
              })
              : ''
          }
        </>

        {/* 起飞点 */}
        <>
          {
            HomeMarkerInfo
            &&
            JSON.stringify(HomeMarkerInfo) !== '{}'
            &&
            (
              <AmapMarker
                visiable="true"
                draggable={takeoffpoint_show}
                position={HomeMarkerInfo.point}
                zIndex={99999}
                offset={[0, 0]}
                onClick={(m) => {
                  if (limitsAuthority.current?.indexOf(`RouteEditing_${selectType ? selectType : local.get('cruise_m30_type')}`) > -1) {
                    if (takeoffpoint_show) {
                      //再次点击关闭
                      // setTakeoffpointShow(false);
                    } else {
                      if (marker_info.idx !== -1) {
                        setNameShow(false);
                        setWaypointShow(false);
                        waypointClose();
                      }
                      setTakeoffpointShow(true);
                    }
                  }

                }}
                onDragStart={() => {
                  if (!takeoffpoint_show) {
                    if (marker_info.idx !== -1) {
                      setWaypointShow(false);
                      waypointClose();
                    }
                    setTakeoffpointShow(true);
                  }
                }}
                onDragging={() => {
                  setHomeAltitude('')
                }}

                onDragEnd={async (m) => { //拖动结束执行
                  const lnglat = m.getPosition();
                  let info = { ...HomeMarkerInfo };
                  SetHomeMarkerInfo('point', [lnglat.lng, lnglat.lat]);
                  let altitude = await GetAltitudeFun([lnglat.lng, lnglat.lat]);
                  setHomeAltitude(altitude);
                }}
              >
                <img alt='起飞点' style={{
                  width: "48px",
                  height: "48px"
                }} src={home} />
              </AmapMarker>
            )

          }
        </>

        {/* 航点 */}
        <>
          {
            markers && markers.length > 0 ? (
              markers.map((val, idx) => {
                return (
                  <AmapMarker
                    features={[]}
                    key={idx}
                    visiable={true}
                    // draggable={val.is_check}
                    draggable={false}
                    position={val.point}
                    extData={idx}
                    offset={[0, 11]} //偏移量
                    onClick={() => { //点击执行
                      if (limitsAuthority.current?.indexOf(`RouteEditing_${selectType ? selectType : local.get('cruise_m30_type')}`) > -1) {
                        MarkerClick(idx);
                        curWaypoint.current = idx
                      }
                    }}
                    angle={val.yaw_angle}
                  // onDragStart={() => {
                  //   if (val.is_check) return;
                  //   MarkerClick(idx);
                  // }}
                  // onDragEnd={async (m) => {
                  //   let lnglat = m.getPosition();
                  //   let lists = [...markers];
                  //   let lnglat2 = lists[idx].point
                  //   let len = await distance([lnglat.lng, lnglat.lat], lnglat2);
                  //   if (len > 20) {
                  //     onWaypointDragEnd(m.getExtData(), lnglat);
                  //   } else {
                  //     let info = { ...marker_info };
                  //     lists[idx].point = [lnglat.lng, lnglat.lat];
                  //     setMarkers([...lists]);
                  //     info.point = [lnglat.lng, lnglat.lat];
                  //     setMarkerInfo({ ...info });
                  //   }
                  // }}

                  >
                    {/* 航点 */}
                    <div className='pos_rel' style={{ zIndex: 99 }}>
                      <img alt="航点未选中" src={waypoint} style={{ width: '22px', height: '22px', display: (val.is_check ? 'none' : '') }} />
                      <img alt="航点选中" src={waypointCheck} style={{ width: '22px', height: '22px', display: (val.is_check ? '' : 'none') }} />
                    </div>

                    {/* 航点名称 */}
                    <div style={{ position: 'absolute', zIndex: 100, top: '3px', left: '30px' }}>
                      <span style={{
                        position: 'absolute', zIndex: 10, fontSize: '12px', fontWeight: '600',
                        color: 'white', whiteSpace: 'nowrap',
                        transform: 'rotate(' + (val.yaw_angle * -1) + 'deg)', transformOrigin: '-20px center'
                      }}>{(idx + 1) + '' + val.name}</span>
                      <span style={{
                        position: 'absolute', top: '0', left: '0', zIndex: 1, fontSize: '12px', fontWeight: '600', textStroke: '2px black',
                        WebkitTextStroke: '2px black', whiteSpace: 'nowrap', color: 'white',
                        transform: 'rotate(' + (val.yaw_angle * -1) + 'deg)', transformOrigin: '-20px center'
                      }}>
                        {(idx + 1) + '' + val.name}
                      </span>
                    </div>

                    {/* 镜头平面图 */}
                    <div className='border_w pos_abs' style={{
                      zIndex: 10,
                      width: val.width * val.scale_ratio + 'px',
                      height: '0',
                      borderWidth: val.z * val.scale_ratio + 'px ' + (val.width - val.height) / 2 * val.scale_ratio + 'px 0 ' + (val.width - val.height) / 2 * val.scale_ratio + 'px',
                      borderStyle: 'solid', borderColor: 'rgba(233, 168, 65, 0.35) transparent transparent transparent',
                      bottom: (val.offset + 11) * val.scale_ratio + 'px', left: (-1 * (val.width / 2 - 11)) * val.scale_ratio + 'px',
                    }} />

                    {/* 镜头中心线 */}
                    <div className='pos_abs' style={{
                      zIndex: 1, display: (val.is_check ? '' : 'none'),
                      width: Number(val.line[2].len) * val.scale_ratio + 'px',
                      top: '11px',
                      right: '11px',
                      transformOrigin: (Number(val.line[2].len) - 1) * val.scale_ratio + 'px 1px',
                      transform: 'rotate(90deg)',
                      borderTop: '1px dashed rgba(255, 255, 255, 0.2)',
                    }} />

                    {/* 辅助线 */}
                    <div className='border_w2 pos_abs' style={{
                      zIndex: 19, display: (val.is_check ? '' : 'none'),
                      width: Number(val.line[0].len) * val.scale_ratio + 'px', top: '11px', right: '11px',
                      transformOrigin: (Number(val.line[0].len) - 1) * val.scale_ratio + 'px 0',
                      transform: 'rotate(' + (
                        val.offset < 0
                          ? -1 * Number(val.line[0].angle)
                          : Number(val.line[0].angle)
                      ) + 'deg)'
                    }} />

                    <div className='border_w2 pos_abs' style={{
                      zIndex: 19, display: (val.is_check ? '' : 'none'),
                      width: Number(val.line[1].len) * val.scale_ratio + 'px', top: '11px', right: '11px',
                      transformOrigin: (Number(val.line[1].len) - 1) * val.scale_ratio + 'px 0',
                      transform: 'rotate(' + Number(val.line[1].angle) + 'deg)'
                    }} />

                    <div className='border_w2 pos_abs' style={{
                      zIndex: 19, display: (val.is_check ? '' : 'none'),
                      width: Number(val.line[0].len) * val.scale_ratio + 'px', top: '11px', right: '11px',
                      transformOrigin: (Number(val.line[0].len) - 1) * val.scale_ratio + 'px 0',
                      transform: 'rotate(' + (
                        val.offset < 0
                          ? -1 * (180 - Number(val.line[0].angle))
                          : (180 - Number(val.line[0].angle))
                      ) + 'deg)'
                    }} />

                    <div className='border_w2 pos_abs' style={{
                      zIndex: 19, display: (val.is_check ? '' : 'none'),
                      width: Number(val.line[1].len) * val.scale_ratio + 'px', top: '11px', right: '11px',
                      transformOrigin: (Number(val.line[1].len) - 1) * val.scale_ratio + 'px 0',
                      transform: 'rotate(' + (180 - Number(val.line[1].angle)) + 'deg)'
                    }} />

                  </AmapMarker>
                )
              })
            ) : ''
          }
        </>

        {/* 无人机 */}
        <>
          {
            UAVList.state === '1'
              ? (
                <AmapMarker
                  position={UAVList.point}
                >
                  <img alt='起飞点' style={{
                    width: "48px",
                    height: "48px"
                  }}
                    src={uav}>

                  </img>
                </AmapMarker>
              )
              : ''
          }
        </>
        {/* 移动的marker */}
        <>
          {
            moveMarkerInfo && moveMarkerInfo.idx >= 0 &&
            <AmapMarker
              zIndex={99999}
              style={{ opacity: 0 }}
              features={[]}
              visiable={true}
              draggable={true}
              position={moveMarkerInfo.point}
              extData={moveMarkerInfo.idx}
              offset={[0, 11]} //偏移量
              onClick={() => { //点击执行
                if (limitsAuthority.current?.indexOf(`RouteEditing_${selectType ? selectType : local.get('cruise_m30_type')}`) > -1) {
                  MarkerClick(moveMarkerInfo.idx);
                  curWaypoint.current = moveMarkerInfo.idx
                }
              }}
              angle={moveMarkerInfo.yaw_angle}
              onDragStart={(m) => {
                markerCoordinateNoRef.current = marker_info.point;
                if (moveMarkerInfo.is_check) return;
                MarkerClick(moveMarkerInfo.idx);
              }}
              onDragging={(m) => {
                let lnglat = m.getPosition();
                if (isFirstMoveRef.current) {
                  isFirstMoveRef.current = false;
                  firstMoveCoordinateRef.current = [lnglat.lng, lnglat.lat];
                  markerCoordinateRef.current = marker_info.point;
                } else {
                  console.log('111');
                  let lng = lnglat.lng - firstMoveCoordinateRef.current[0];
                  let lat = lnglat.lat - firstMoveCoordinateRef.current[1];
                  let info = { ...marker_info };
                  let lists = [...markers];
                  let alt = [...altitudes]
                  let p = [Number((markerCoordinateRef.current[0] + lng).toFixed(6)), Number((markerCoordinateRef.current[1] + lat).toFixed(6))];
                  lists[moveMarkerInfo.idx].point = p;
                  alt[moveMarkerInfo.idx] = ''
                  setAltitudes([...alt])
                  setMarkers([...lists]);
                  info.point = p;
                  setMarkerInfo({ ...info });
                }
              }}
              onDragEnd={async (m) => {
                let lnglat = m.getPosition();
                let info = { ...moveMarkerInfo };
                info.point = marker_info.point;
                setMoveMarkerInfo(info);
                isFirstMoveRef.current = true;
                firstMoveCoordinateRef.current = [];
                markerCoordinateRef.current = [];
                onWaypointDragEnd(info.idx, { lng: marker_info.point[0], lat: marker_info.point[1] });
                markerCoordinateNoRef.current = [];

              }}
            >
              {/* 航点 */}
              <div className='pos_rel' style={{ zIndex: 99 }}>
                <img alt="航点未选中" src={waypoint} style={{ width: '22px', height: '22px', display: (moveMarkerInfo.is_check ? 'none' : '') }} />
                <img alt="航点选中" src={waypointCheck} style={{ width: '22px', height: '22px', display: (moveMarkerInfo.is_check ? '' : 'none') }} />
              </div>

              {/* 航点名称 */}
              <div style={{ position: 'absolute', zIndex: 100, top: '3px', left: '30px' }}>
                <span style={{
                  position: 'absolute', zIndex: 10, fontSize: '12px', fontWeight: '600',
                  color: 'white', whiteSpace: 'nowrap',
                  transform: 'rotate(' + (moveMarkerInfo.yaw_angle * -1) + 'deg)', transformOrigin: '-20px center'
                }}>{(moveMarkerInfo.idx + 1) + '' + moveMarkerInfo.name}</span>
                <span style={{
                  position: 'absolute', top: '0', left: '0', zIndex: 1, fontSize: '12px', fontWeight: '600', textStroke: '2px black',
                  WebkitTextStroke: '2px black', whiteSpace: 'nowrap', color: 'white',
                  transform: 'rotate(' + (moveMarkerInfo.yaw_angle * -1) + 'deg)', transformOrigin: '-20px center'
                }}>
                  {(moveMarkerInfo.idx + 1) + '' + moveMarkerInfo.name}
                </span>
              </div>

              {/* 镜头平面图 */}
              <div className='border_w pos_abs' style={{
                zIndex: 10,
                width: moveMarkerInfo.width * moveMarkerInfo.scale_ratio + 'px',
                height: '0',
                borderWidth: moveMarkerInfo.z * moveMarkerInfo.scale_ratio + 'px ' + (moveMarkerInfo.width - moveMarkerInfo.height) / 2 * moveMarkerInfo.scale_ratio + 'px 0 ' + (moveMarkerInfo.width - moveMarkerInfo.height) / 2 * moveMarkerInfo.scale_ratio + 'px',
                borderStyle: 'solid', borderColor: 'black transparent transparent transparent',
                bottom: (moveMarkerInfo.offset + 11) * moveMarkerInfo.scale_ratio + 'px', left: (-1 * (moveMarkerInfo.width / 2 - 11)) * moveMarkerInfo.scale_ratio + 'px',
              }}></div>

              {/* 镜头中心线 */}
              <div className='pos_abs' style={{
                zIndex: 1, display: (moveMarkerInfo.is_check ? '' : 'none'),
                width: Number(moveMarkerInfo.line[2].len) * moveMarkerInfo.scale_ratio + 'px',
                top: '11px',
                right: '11px',
                transformOrigin: (Number(moveMarkerInfo.line[2].len) - 1) * moveMarkerInfo.scale_ratio + 'px 1px',
                transform: 'rotate(90deg)',
                borderTop: '1px dashed rgba(255, 255, 255, 0.2)',
              }}>
              </div>

              {/* 辅助线 */}
              <div className='border_w2 pos_abs' style={{
                zIndex: 19, display: (moveMarkerInfo.is_check ? '' : 'none'),
                width: Number(moveMarkerInfo.line[0].len) * moveMarkerInfo.scale_ratio + 'px', top: '11px', right: '11px',
                transformOrigin: (Number(moveMarkerInfo.line[0].len) - 1) * moveMarkerInfo.scale_ratio + 'px 0',
                transform: 'rotate(' + (
                  moveMarkerInfo.offset < 0
                    ? -1 * Number(moveMarkerInfo.line[0].angle)
                    : Number(moveMarkerInfo.line[0].angle)
                ) + 'deg)'
              }}>
              </div>

              <div className='border_w2 pos_abs' style={{
                zIndex: 19, display: (moveMarkerInfo.is_check ? '' : 'none'),
                width: Number(moveMarkerInfo.line[1].len) * moveMarkerInfo.scale_ratio + 'px', top: '11px', right: '11px',
                transformOrigin: (Number(moveMarkerInfo.line[1].len) - 1) * moveMarkerInfo.scale_ratio + 'px 0',
                transform: 'rotate(' + Number(moveMarkerInfo.line[1].angle) + 'deg)'
              }}></div>

              <div className='border_w2 pos_abs' style={{
                zIndex: 19, display: (moveMarkerInfo.is_check ? '' : 'none'),
                width: Number(moveMarkerInfo.line[0].len) * moveMarkerInfo.scale_ratio + 'px', top: '11px', right: '11px',
                transformOrigin: (Number(moveMarkerInfo.line[0].len) - 1) * moveMarkerInfo.scale_ratio + 'px 0',
                transform: 'rotate(' + (
                  moveMarkerInfo.offset < 0
                    ? -1 * (180 - Number(moveMarkerInfo.line[0].angle))
                    : (180 - Number(moveMarkerInfo.line[0].angle))
                ) + 'deg)'
              }}></div>

              <div className='border_w2 pos_abs' style={{
                zIndex: 19, display: (moveMarkerInfo.is_check ? '' : 'none'),
                width: Number(moveMarkerInfo.line[1].len) * moveMarkerInfo.scale_ratio + 'px', top: '11px', right: '11px',
                transformOrigin: (Number(moveMarkerInfo.line[1].len) - 1) * moveMarkerInfo.scale_ratio + 'px 0',
                transform: 'rotate(' + (180 - Number(moveMarkerInfo.line[1].angle)) + 'deg)'
              }}></div>

            </AmapMarker>
          }
        </>

        <Scale position={{ bottom: '24px', left: '4px' }} /> {/* 比例尺 */}
        {/* <Toolbar position={{ bottom: '24px', right: '20px' }} /> 按钮缩放 */}

      </RMap>

      {/* 功能按钮 */}
      <div className="pos_abs flex-col" style={{ top: 0 }}>
        {/* 按钮 */}
        <div className='flex-row mar24 radius8 hidden'>
          <div className='flex jcc align_center'>
            {
              limitsAuthority.current && limitsAuthority.current?.indexOf(`RouteEditing_${selectType ? selectType : local.get('cruise_m30_type')}`) > -1 &&
              <>
                {/* 保存 */}
                <>
                  {
                    isLock === 1
                      ? (
                        <div
                          className='flex jcc align_center pointer'
                          style={{
                            background: '#1F1F1F',
                            borderRight: '1px solid rgba(255,255,255,0.1)'
                          }}
                          onClick={() => {
                            SaveData();
                          }}
                        >
                          <SaveOutlined className="mar12 w16 h16" style={{ color: 'white' }} />
                        </div>
                      )
                      : (

                        <div
                          className='flex jcc align_center'
                          style={{
                            background: '#1F1F1F',
                            borderRight: '1px solid rgba(255,255,255,0.1)'
                          }}
                        >
                          <LockFilled className="mar12 w16 h16 opa45" style={{ color: 'white' }} />
                        </div>
                      )
                  }
                </>

                {/* 添加 */}
                <div
                  className='flex jcc align_center pointer'
                  style={{
                    background: '#1F1F1F',
                    borderRight: '1px solid rgba(255,255,255,0.1)'
                  }}
                  onClick={() => {
                    AddMarker();
                  }}
                >
                  <PlusOutlined className="mar12 w16 h16" style={{ color: 'white' }} />
                </div>
              </>
            }




            {/* 切换 */}
            <div
              className='flex jcc align_center pointer'
              style={{
                background: '#1F1F1F',
                borderRight: '1px solid rgba(255,255,255,0.1)'
              }}
              onClick={() => {
                setOpacity(!opacity)
              }}
            >
              <BlockOutlined className="mar12 w16 h16" style={{ color: 'white' }} />
            </div>

            {
              curName &&
              <div className='h40' style={{ background: '#1F1F1F' }}>
                <div className='pdTB8 pdRL12 lh24 fw600 fs16' style={{ color: 'white' }}>
                  {curName.Rtitle}
                  {
                    curName.Rtitle &&
                    <span>&nbsp;-&nbsp;</span>
                  }
                  {curName.title}
                </div>
              </div>
            }
          </div>

          <div className='flex-auto'></div>
        </div>
      </div>

      {/* 航点编辑 */}
      <div className="pos_abs flex-col" style={{ top: 0, right: '0', height: '100%', width: '300px', display: (waypoint_show ? '' : 'none') }}>
        <Layout>
          <Content style={{ background: '#141414', color: 'rgba(255, 255, 255, 0.85)', overflowY: 'auto' }}>
            <div className='no_rad'>
              <div className='flex-col' style={{ borderBottom: '1px solid #434343' }}>
                <div className='pdTB16 pdRL24 flex-row flex-justify-between'>
                  <div className='flex flex-align-center' style={{ height: '22px' }}>
                    <div className='flex jcsb align_center w164'>
                      <div>
                        <Button
                          disabled={curWaypoint.current === 0}
                          icon={<LeftOutlined />}
                          onClick={() => {
                            MarkerClick(curWaypoint.current - 1);
                            curWaypoint.current -= 1
                          }}
                        >

                        </Button>
                      </div>
                      <div>
                        <span className='fs16 fw500 marR4'>{marker_info.idx + 1}</span>
                        <span className='fs16 fw500 marR4' style={{ display: (!name_show ? '' : 'none') }}>{marker_info.name}</span>
                        <span className='pointer' style={{ display: (!name_show ? '' : 'none') }}
                          onClick={() => {
                            setNameShow(true);
                          }}>
                          <EditOutlined className="w16 h16 col_primary" />
                        </span>
                      </div>
                      <div>
                        <Button
                          disabled={curWaypoint.current + 1 >= markers.length}
                          icon={<RightOutlined />}
                          onClick={() => {
                            MarkerClick(curWaypoint.current + 1);
                            curWaypoint.current += 1
                          }}
                        >
                        </Button>
                      </div>
                    </div>

                    <span className="pointer flex-row flex-align-center" style={{ display: (name_show ? '' : 'none') }}>
                      <Input placeholder="航点名称" ref={inputRef} bordered={false} value={marker_info.name} onChange={(e) => {
                        SetMarkerInfoVal('name', e.target.value)
                      }} onPressEnter={() => {
                        setNameShow(false);
                      }} />
                      <CheckOutlined className="w16 h16 col_primary marR8"
                        onClick={() => {
                          setNameShow(false);
                        }} />
                    </span>

                  </div>
                  <div className='flex-row'>
                    {/* 删除 */}
                    <div className="pointer flex flex-align-center"
                      style={{ borderRight: '1px solid rgba(255, 255, 255, 0.1)', paddingRight: '8px' }}
                      onClick={() => { DelMarker() }}
                    >
                      <DeleteOutlined style={{ fontSize: '16px' }} />
                    </div>
                    {/* 关闭编辑 */}
                    <div className="pointer flex flex-align-center"
                      style={{ paddingLeft: '8px' }}
                      onClick={() => {
                        setNameShow(false);
                        setWaypointShow(false);
                        waypointClose();
                      }}>
                      <VerticalAlignBottomOutlined style={{ fontSize: '16px', transform: 'rotate(-90deg)' }} />
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="pd24 flex-col">
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>经度</span>
                <span className='marR8'>:</span>
                <InputNumber className='flex-auto' value={marker_info.point[0]} placeholder="经度"
                  onChange={(e) => {
                    let obj = { ...marker_info };
                    obj.point[0] = e || 0;
                    SetMarkerInfoVal('point', [Number(e || 0), marker_info.point[1]]);
                  }}
                  onBlur={(e) => {
                    SetAltitudeFun([Number(e.target.value), marker_info.point[1]], marker_info.idx, 0);
                  }}
                />
              </div>
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>纬度</span>
                <span className='marR8'>:</span>
                <InputNumber className='flex-auto' value={marker_info.point[1]} placeholder="纬度"
                  onChange={(e) => {
                    let obj = { ...marker_info };
                    obj.point[1] = e || 0;
                    SetMarkerInfoVal('point', [marker_info.point[0], Number(e || 0)]);
                  }}
                  onBlur={(e) => {
                    SetAltitudeFun([marker_info.point[0], Number(e.target.value)], marker_info.idx, 0);
                  }}
                />
              </div>

              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>地表海拔</span>
                <span className='marR8'>:</span>
                <InputNumber value={altitudes[marker_info.idx]} placeholder="-" className='marR8' addonAfter="m"
                  onChange={(e) => {
                    let arr = [...altitudes];
                    arr[marker_info.idx] = e === null ? '' : e;
                    setAltitudes(arr);
                  }}
                  onBlur={() => { compareAltitude(markers, altitudes, HomeMarkerInfo, home_altitude) }}
                />

                <Button className='flex-shrink0' icon={<SyncOutlined />} onClick={async () => {
                  SetAltitudeFun(marker_info.point, marker_info.idx, 0);
                }} />
              </div>

              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>到达高度</span>
                <span className='marR8'>:</span>
                <InputNumber value={marker_info.flight_height} placeholder="到达高度" addonAfter="m"
                  onChange={(e) => {
                    SetMarkerInfoVal('flight_height', e);
                  }}
                  onBlur={() => { compareAltitude(markers, altitudes, HomeMarkerInfo, home_altitude) }}
                />
              </div>

              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>到达海拔</span>
                <span className='marR8'>:</span>
                <Input value={altitudes[marker_info.idx] !== '' ? Math.round(altitudes[marker_info.idx] + marker_info.flight_height) : altitudes[marker_info.idx]} placeholder="-" addonAfter="m" disabled={true}></Input>
              </div>

              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>离开高度</span>
                <span className='marR8'>:</span>
                <Input value={Math.round(marker_info.leave_height)} addonAfter="m" disabled={true}></Input>
              </div>

              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>离开海拔</span>
                <span className='marR8'>:</span>
                <Input value={altitudes[marker_info.idx] !== '' ? Math.round(altitudes[marker_info.idx] + marker_info.leave_height) : altitudes[marker_info.idx]} placeholder="-" addonAfter="m" disabled={true}></Input>
              </div>

              {/* <Divider style={{color: 'red'}} /> */}
              <div style={{ margin: '24px 0', borderBottom: '1px solid rgb(67, 67, 67)' }}>

              </div>

              {/* 操作 */}
              <>
                {
                  marker_info.action && marker_info.action.length > 0 ? (
                    marker_info.action.map((item, idx) => {
                      return (
                        <div className={'flex-col marB8 pos_rel'} key={idx} draggable={action_move}
                          onDragStart={(e) => { //开始拖动
                            setStartIdx(idx);
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          onDragEnter={() => {
                            if (cur_idx === idx) return;
                            setCurIdx(idx);
                          }}
                          onDrop={(e) => {//拖动结束
                            setStartIdx(-1);
                            setCurIdx(-1);
                            if (start_idx === idx) {
                              return;
                            }
                            let arr = [...marker_info.action];
                            let obj = { ...arr[start_idx] };
                            if (idx < start_idx) {
                              arr.splice(start_idx, 1);
                              arr.splice(idx, 0, obj);
                            } else {
                              arr.splice(idx + 1, 0, obj);
                              arr.splice(start_idx, 1);
                            }
                            SetMarkerInfoVal('action', arr);
                            setActionMove(false);
                          }}
                        >
                          <div className='pos_abs' style={{ border: cur_idx === idx ? '1px solid #1668dc' : '0', top: -5, width: '100%' }}></div>
                          <div style={{ padding: '12px', background: '#000000' }}>
                            <div className='flex-row flex-align-center marB8'>
                              <div className='marR8 move'
                                onMouseDown={() => {
                                  setActionMove(true);
                                }}
                                onMouseUp={() => {
                                  setActionMove(false);
                                }}
                              >
                                <MenuOutlined />
                              </div>
                              <Select
                                className="flex-auto marR8"
                                options={onSiteInspectionTypeValue}
                                defaultOpen={item.type ? false : true} value={item.type}
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  let types = arr.map((item) => {
                                    return item.type;
                                  })
                                  let num = types?.indexOf(5);
                                  if (e === 5 && num >= 0) {
                                    message.warning('只能有一个拍照动作')
                                    return
                                  } else {
                                    arr[idx].type = e;
                                    SetMarkerInfoVal('action', arr)
                                  }
                                  if (e === 11) {
                                    arr[idx].tnterval_time_shooting_type = ['wide']
                                    SetMarkerInfoVal('action', arr)
                                  }
                                  if (e === 12) {
                                    arr[idx].tnterval_distance_shooting_type = ['wide']
                                    SetMarkerInfoVal('action', arr)
                                  }
                                }}
                              />
                              <Button className='flex-shrink0' icon={<DeleteOutlined />} onClick={() => { DelWaypointAction(idx) }} />
                            </div>

                            {/* 抬升 */}
                            <div className='flex-row flex-align-center marB8' style={{ display: (item.type === 1 ? '' : 'none') }}>
                              <span className='flex-shrink0 marR4'>高度</span>
                              <span className='marR8'>:</span>
                              <InputNumber value={item.uplift_height} min={0} addonAfter="m" placeholder="抬升"
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  arr[idx].uplift_height = e;
                                  SetMarkerInfoVal('action', arr);
                                }}
                              />
                            </div>

                            {/* 下降 */}
                            <div className='flex-row flex-align-center marB8' style={{ display: (item.type === 2 ? '' : 'none') }}>
                              <span className='flex-shrink0 marR4'>高度</span>
                              <span className='marR8'>:</span>
                              <InputNumber value={item.decline_height} min={0} addonAfter="m" placeholder="下降"
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  arr[idx].decline_height = e;
                                  SetMarkerInfoVal('action', arr);
                                }}
                              />
                            </div>

                            {/* 无人机偏航角 */}
                            <div className='flex_row align_center marB8' style={{ display: (item.type === 15 ? '' : 'none') }}>
                              <span className='flex_shrink0 marR4'>角度</span>
                              <span className='marR8'>:</span>
                              <InputNumber min={0} max={360} value={item.UAV_angle} addonAfter="°" placeholder="范围 0~360"
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  arr[idx].UAV_angle = e;
                                  SetMarkerInfoVal('action', arr);
                                }}
                              />
                            </div>

                            {/* 云台偏航角 */}
                            <div className='flex-row flex-align-center marB8' style={{ display: (item.type === 3 ? '' : 'none') }}>
                              <span className='flex-shrink0 marR4'>角度</span>
                              <span className='marR8'>:</span>
                              <InputNumber value={item.yaw_angle} min={0} max={360} addonAfter="°" placeholder="范围 0~360"
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  arr[idx].yaw_angle = e;
                                  SetMarkerInfoVal('action', arr);
                                }}
                              />
                            </div>

                            {/* 云台俯仰角 */}
                            <div className='flex-row flex-align-center marB8' style={{ display: (item.type === 4 ? '' : 'none') }}>
                              <span className='flex-shrink0 marR4'>角度</span>
                              <span className='marR8'>:</span>
                              <InputNumber min={-90} max={0} value={item.pitch_angle} addonAfter="°" placeholder="俯仰角 -90 ~ 0"
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  arr[idx].pitch_angle = e;
                                  SetMarkerInfoVal('action', arr);
                                }}
                              />
                            </div>

                            {/* 拍照 */}
                            <div className='flex-col' style={{ display: (item.type === 5 ? '' : 'none') }}>
                              <Checkbox.Group value={item.photograph_type} className='marB8' options={photographed_option}
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  if (e.length === 0) {
                                    return;
                                  }
                                  let num = e?.indexOf(arr[idx].photograph_type[0]);
                                  e.splice(num, 1);
                                  arr[idx].photograph_type = e;
                                  SetMarkerInfoVal('action', arr);
                                }} />
                              <div className='flex-row flex-align-center marB8'>
                                <span className='flex-shrink0 marR4'>幅宽</span>
                                <span className='marR8'>:</span>
                                <Input value={(marker_info.photograph_width * marker_info.scale_ratio).toFixed(0)} addonAfter="m" disabled={true} placeholder="幅宽"></Input>
                              </div>
                              <div className='flex-row flex-align-center marB8'>
                                <span className='flex-shrink0 marR4'>幅长</span>
                                <span className='marR8'>:</span>
                                <Input value={(marker_info.photograph_height * marker_info.scale_ratio).toFixed(0)} addonAfter="m" disabled={true} placeholder="幅长"></Input>
                              </div>
                            </div>

                            {/* 摄像 */}
                            <div className='flex-row flex-align-center marB8' style={{ display: (item.type === 6 ? '' : 'none') }}>
                              <span className="marR4 flex-shrink0">是否开启</span>
                              <span className='marR8'>:</span>
                              <Switch checkedChildren="开启" checked={item.photography_state} unCheckedChildren="关闭" onChange={(e) => {
                                let arr = [...marker_info.action];
                                arr[idx].photography_state = e;
                                SetMarkerInfoVal('action', arr);
                              }} />
                            </div>

                            {/* 相机变焦 */}
                            <div className='flex-row flex-align-center marB8' style={{ display: (item.type === 7 ? '' : 'none') }}>
                              <span className='flex-shrink0 marR4'>倍数</span>
                              <span className='marR8'>:</span>
                              <InputNumber value={item.zoom} min={2} max={20} addonAfter="×" placeholder="倍数"
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  arr[idx].zoom = e || 2;
                                  SetMarkerInfoVal('action', arr);
                                }}
                              />
                            </div>

                            {/* 采集读数 */}
                            {/* <div className='flex-col' style={{ display: (item.type === 8 ? '' : 'none') }}>
                                                        <div className='flex-row flex-align-center marB8'>
                                                            <span className="marR4 flex-shrink0">显表类型</span>
                                                            <span className='marR8'>:</span>
                                                            <Select
                                                                className='flex-auto'
                                                                value={item.readout_type}
                                                                placeholder="显表类型"
                                                                options={show_table_option}
                                                                onChange={(e) => {
                                                                    let arr = [...marker_info.action];
                                                                    arr[idx].readout_type = e;
                                                                    SetMarkerInfoVal('action', arr);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='flex-row flex-align-center marB8'>
                                                            <span className="marR4 flex-shrink0">数据单位</span>
                                                            <span className='marR8'>:</span>
                                                            <Input placeholder="数据单位" className='flex-auto' value={item.readout_unit} onChange={(e) => {
                                                                let arr = [...marker_info.action];
                                                                arr[idx].readout_unit = e.target.value;
                                                                SetMarkerInfoVal('action', arr);
                                                            }} />
                                                        </div>
                                                        <div className='flex-row flex-align-center marB8 site-input-group-wrapper'>
                                                            <span className="marR4 flex-shrink0">合理范围</span>
                                                            <span className='marR8'>:</span>
                                                            <Input.Group compact className='flex-auto'>
                                                                <Input value={item.readout_range[0]} style={{ width: 'calc((100% - 28px)/2)' }} onChange={(e) => {
                                                                    let arr = [...marker_info.action];
                                                                    arr[idx].readout_range[0] = e.target.value;
                                                                    SetMarkerInfoVal('action', arr);
                                                                }} />
                                                                <Input
                                                                    className='site-input-split input_sty'
                                                                    style={{ width: 30, borderLeft: 0, borderRight: 0, pointerEvents: 'none' }} placeholder="~" disabled
                                                                />
                                                                <Input className='site-input-right' value={item.readout_range[1]} style={{ width: 'calc((100% - 28px)/2)' }}
                                                                    onChange={(e) => {
                                                                        let arr = [...marker_info.action];
                                                                        arr[idx].readout_range[1] = e.target.value;
                                                                        SetMarkerInfoVal('action', arr);
                                                                    }}
                                                                />
                                                            </Input.Group>
                                                        </div>
                                                    </div> */}

                            <div className='flex-col' style={{ display: (item.type === 8 ? '' : 'none') }}>
                              <div className='flex-row flex-align-center marB8 site-input-group-wrapper'>
                                <span className="marR4 flex-shrink0">合理范围</span>
                                <span className='marR8'>:</span>
                                <Input.Group compact className='flex-auto'>
                                  <Input value={item.readout_range[0]} style={{ width: 'calc((100% - 28px)/2)' }}
                                    onChange={(e) => {
                                      let arr = [...marker_info.action];
                                      arr[idx].readout_range[0] = e.target.value;
                                      SetMarkerInfoVal('action', arr);
                                    }}
                                  />
                                  <Input
                                    className='site-input-split input_sty'
                                    style={{ width: 30, borderLeft: 0, borderRight: 0, pointerEvents: 'none' }} placeholder="~" disabled
                                  />
                                  <Input className='site-input-right' value={item.readout_range[1]} style={{ width: 'calc((100% - 28px)/2)' }}
                                    onChange={(e) => {
                                      let arr = [...marker_info.action];
                                      arr[idx].readout_range[1] = e.target.value;
                                      SetMarkerInfoVal('action', arr);
                                    }}
                                  />
                                </Input.Group>
                              </div>
                            </div>

                            {/* 照明辅助 */}
                            <div className='flex-row flex-align-center marB8' style={{ display: (item.type === 9 ? '' : 'none') }}>
                              <span className="marR4 flex-shrink0">是否开启</span>
                              <span className='marR8'>:</span>
                              <Switch checkedChildren="开启" checked={item.lighting_state} unCheckedChildren="关闭" onChange={(e) => {
                                let arr = [...marker_info.action];
                                arr[idx].lighting_state = e;
                                SetMarkerInfoVal('action', arr);
                              }} />
                            </div>

                            {/* 测量温度 */}
                            <div className='flex-row flex-align-center marB8 site-input-group-wrapper'
                              style={{ display: (item.type === 10 ? '' : 'none') }}
                            >
                              <span className="marR4 flex-shrink0">合理范围</span>
                              <span className='marR8'>:</span>
                              <Input.Group compact className='flex-auto'>
                                <Input className='site-input-split'
                                  style={{ width: 'calc((100% - 67px)/2)', textAlign: 'center' }}
                                  value={item.temperature_range[0]}
                                  onChange={(e) => {
                                    let arr = [...marker_info.action];
                                    arr[idx].temperature_range[0] = e.target.value;
                                    SetMarkerInfoVal('action', arr);
                                  }}
                                />
                                <Input className='site-input-split'
                                  style={{ width: 30, borderLeft: 0, borderRight: 0, pointerEvents: 'none', }} placeholder="~" disabled
                                />
                                <Input className="site-input-right"
                                  style={{ width: 'calc((100% - 67px)/2)', textAlign: 'center' }}
                                  value={item.temperature_range[1]}
                                  onChange={(e) => {
                                    let arr = [...marker_info.action];
                                    arr[idx].temperature_range[1] = e.target.value;
                                    SetMarkerInfoVal('action', arr);
                                  }}
                                />
                                <Input className='site-input-split'
                                  style={{ width: 40, borderLeft: 0, pointerEvents: 'none' }} placeholder="℃" disabled
                                />
                              </Input.Group>
                            </div>

                            {/* 开始间隔时间拍照 */}
                            <div className='flex_col' style={{ display: (item.type === 11 ? '' : 'none'), }}>
                              <Checkbox.Group value={item.tnterval_time_shooting_type} className='marB8' options={photographed_option}
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  if (e.length === 0) {
                                    return;
                                  }
                                  let num = e?.indexOf(arr[idx].tnterval_time_shooting_type[0]);
                                  e.splice(num, 1);
                                  arr[idx].tnterval_time_shooting_type = e;
                                  SetMarkerInfoVal('action', arr);
                                }} />
                              <div className='flex_row align_center marB8'>
                                <span className='flex_shrink0 marR4'>等时间隔</span>
                                <span className='marR8'>:</span>
                                <InputNumber value={item.tnterval_time_shooting_text} min={1} max={60} addonAfter="s" placeholder="-"
                                  onChange={(e) => {
                                    let arr = [...marker_info.action];
                                    arr[idx].tnterval_time_shooting_text = e;
                                    SetMarkerInfoVal('action', arr);
                                  }}
                                />
                              </div>
                            </div>

                            {/* 开始间隔距离拍照 */}
                            <div className='flex_col' style={{ display: (item.type === 12 ? '' : 'none') }}>
                              <Checkbox.Group value={item.tnterval_distance_shooting_type} className='marB8' options={photographed_option}
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  if (e.length === 0) {
                                    return;
                                  }
                                  let num = e?.indexOf(arr[idx].tnterval_distance_shooting_type[0]);
                                  e.splice(num, 1);
                                  arr[idx].tnterval_distance_shooting_type = e;
                                  SetMarkerInfoVal('action', arr);
                                }} />
                              <div className='flex_row align_center marB8'>
                                <span className='flex_shrink0 marR4'>等距间隔</span>
                                <span className='marR8'>:</span>
                                <InputNumber value={item.tnterval_distance_shooting_text} min={1} max={100} addonAfter="m" placeholder="-"
                                  onChange={(e) => {
                                    let arr = [...marker_info.action];
                                    arr[idx].tnterval_distance_shooting_text = e;
                                    SetMarkerInfoVal('action', arr);
                                  }}
                                />
                              </div>
                            </div>


                            {/* 悬停 */}
                            <div className='flex_row align_center marB8' style={{ display: (item.type === 14 ? '' : 'none') }}>
                              <span className='flex_shrink0 marR4'>时间</span>
                              <span className='marR8'>:</span>
                              <InputNumber min={1} max={900} value={item.hoverTime} addonAfter="s" placeholder="悬停时间 1~900"
                                onChange={(e) => {
                                  let arr = [...marker_info.action];
                                  arr[idx].hoverTime = e;
                                  SetMarkerInfoVal('action', arr);
                                }}
                              />
                            </div>



                          </div>
                        </div>
                      )
                    })
                  ) : ''
                }
              </>

              <div className='tc'>
                <Button className='flex-shrink0' type="dashed" icon={<PlusOutlined />}
                  onClick={() => { AddWaypointAction() }}
                >航点动作</Button>
              </div>
            </div>
          </Content>
        </Layout>
      </div>

      {/* 起飞点编辑 */}
      <div className="pos_abs flex-col" style={{ top: 0, right: '0', height: '100%', width: '300px', display: (takeoffpoint_show ? '' : 'none') }}>
        <Layout>
          <Content style={{ background: '#141414', color: 'rgba(255, 255, 255, 0.85)', overflowY: 'auto' }}>
            <div className='no_rad'>
              <div className='flex-col' style={{ borderBottom: '1px solid #434343' }}>
                <div className='pdTB16 pdRL24 flex-row flex-justify-between'>
                  <span className='fs16 fw500 marR4'>起飞点</span>
                  <div className="pointer flex flex-align-center" onClick={() => { //关闭 起飞点 编辑
                    setWaypointShow(false);
                    waypointClose();
                    setTakeoffpointShow(false);
                  }}>
                    <VerticalAlignBottomOutlined style={{ fontSize: '16px', transform: 'rotate(-90deg)' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="pd24 flex-col">
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>经度</span>
                <span className='marR8'>:</span>
                <InputNumber className='flex-auto' placeholder="经度" value={HomeMarkerInfo.point[0]}
                  onChange={(e) => {
                    let obj = { ...HomeMarkerInfo };
                    obj.point[0] = e || 0;
                    // setHomeMarker({ ...obj });
                    SetHomeMarkerInfo('point', [Number(e || 0), HomeMarkerInfo.point[1]]);
                  }}
                  onBlur={async (e) => {
                    let altitude = await GetAltitudeFun([Number(e.target.value), HomeMarkerInfo.point[1]]);
                    setHomeAltitude(altitude);
                  }}
                />
              </div>
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>纬度</span>
                <span className='marR8'>:</span>
                <InputNumber className='flex-auto' placeholder="纬度" value={HomeMarkerInfo.point[1]}
                  onChange={(e) => {
                    let obj = { ...HomeMarkerInfo };
                    obj.point[1] = e || 0;
                    // setHomeMarker({ ...obj });

                    SetHomeMarkerInfo('point', [HomeMarkerInfo.point[0], Number(e || 0)]);
                  }}
                  onBlur={async (e) => {
                    let altitude = await GetAltitudeFun([HomeMarkerInfo.point[0], Number(e.target.value)]);
                    setHomeAltitude(altitude);
                  }}
                />
              </div>
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>地表海拔</span>
                <span className='marR8'>:</span>
                <InputNumber placeholder="-" value={home_altitude} className='marR8' addonAfter="m"
                  onChange={(e) => { setHomeAltitude(e === null ? '' : e) }}
                  onBlur={() => { compareAltitude(markers, altitudes, HomeMarkerInfo, home_altitude) }}
                />
                <Button className='flex-shrink0' icon={<SyncOutlined />} onClick={async () => {
                  let altitude = await GetAltitudeFun(HomeMarkerInfo.point);
                  setHomeAltitude(altitude)
                }} />
              </div>
              <div className='flex-row flex-align-center marB8'>
                {/* 起飞高度 === 飞行高度  */}
                <span className='flex-shrink0 marR4'>起飞高度</span>
                <span className='marR8'>:</span>
                <InputNumber
                  placeholder="-"
                  value={HomeMarkerInfo.flight_height} addonAfter="m"
                  onChange={(e) => { SetHomeMarkerInfo('flight_height', e) }}
                  onBlur={() => { compareAltitude(markers, altitudes, HomeMarkerInfo, home_altitude); }}
                />
              </div>
              <div className='flex-row flex-align-center marB8'>
                {/* 起飞海拔 === 飞行海拔  */}
                <span className='flex-shrink0 marR4'>起飞海拔</span>
                <span className='marR8'>:</span>
                <Input placeholder="-" value={home_altitude !== '' ? home_altitude + HomeMarkerInfo.flight_height : home_altitude} disabled={true} addonAfter="m"
                ></Input>
              </div>
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>返航高度</span>
                <span className='marR8'>:</span>
                <InputNumber
                  placeholder="-"
                  value={HomeMarkerInfo.return_height} addonAfter="m"
                  onChange={(e) => { SetHomeMarkerInfo('return_height', e) }}
                  onBlur={() => { compareAltitude(markers, altitudes, HomeMarkerInfo, home_altitude); }}
                />
              </div>
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>返航海拔</span>
                <span className='marR8'>:</span>
                <Input placeholder="-" value={home_altitude !== '' ? home_altitude + HomeMarkerInfo.return_height : home_altitude} disabled={true} addonAfter="m"
                ></Input>
              </div>
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>
                  起飞范围
                </span>
                <span className='marR8'>:</span>
                <InputNumber
                  className='marR8'
                  min={0}
                  max={9999}
                  placeholder="起飞范围"
                  value={HomeMarkerInfo.takeoff_range}
                  addonAfter="m"
                  onChange={(e) => { SetHomeMarkerInfo('takeoff_range', e) }}
                  onBlur={() => { compareAltitude(markers, altitudes, HomeMarkerInfo, home_altitude); }}
                />
                <div>
                  <Tooltip placement="left" title={<div><div>0：表示不限制</div><div>{`> 0：则需要在范围内起飞`}</div></div>}>
                    <QuestionCircleOutlined className='opa45' />
                  </Tooltip>
                </div>
              </div>

              <div className='marTB24' style={{ borderBottom: '1px solid #434343' }}> </div>

              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>抬升速度</span>
                <span className='marR8'>:</span>
                <InputNumber placeholder="抬升速度" value={HomeMarkerInfo.uplift_speed} disabled={true} addonAfter="m/s"
                  onChange={(e) => { SetHomeMarkerInfo('uplift_speed', e) }}
                />
              </div>
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>巡航速度</span>
                <span className='marR8'>:</span>
                <InputNumber placeholder="巡航速度" min={0} max={15} value={HomeMarkerInfo.cruise_speed} className='' addonAfter="m/s"
                  onChange={(e) => { SetHomeMarkerInfo('cruise_speed', e) }}
                />
              </div>
              <div className='flex-row flex-align-center marB8'>
                <span className='flex-shrink0 marR4'>航点速度</span>
                <span className='marR8'>:</span>
                <InputNumber placeholder="航点速度" min={0} max={15} value={HomeMarkerInfo.waypoint_speed} className='' addonAfter="m/s"
                  onChange={(e) => { SetHomeMarkerInfo('waypoint_speed', e) }}
                />
              </div>
              <div className='flex-row aliItemsCen'>
                <span className='flex_shrink0' style={{ marginRight: '4px' }}>航点类型</span>
                <span style={{ marginRight: '8px' }}>:</span>
                <Tooltip placement="top"
                  title={
                    HomeMarkerInfo.waypoint_type === 1
                      ? '直线飞行 - 飞行器到点停'
                      : HomeMarkerInfo.waypoint_type === 2
                        ? '曲线飞行 - 飞行器过点不停'
                        : ''
                  }>
                  <Select options={waypoint_types} className="flex-auto"
                    placeholder="航点类型"
                    value={HomeMarkerInfo.waypoint_type}
                    onChange={(e) => {
                      SetHomeMarkerInfo('waypoint_type', e)
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </Content>
        </Layout>
      </div>


      {/* 警告按钮 */}
      <>
        {
          (HomeMarkerInfo.waypoint_type === 2 || markers.length > 0)
          &&
          <div className='pos_abs marL24' style={{ top: '88px', left: '0', }}>
            <div
              className='flex jcc align_center pointer radius50'
              onClick={() => {
                let saf = safetyWarn
                saf.isOpen = true
                setSafetyWarn({ ...saf })
              }}
            >
              {
                WarningIcon(
                  safetyWarn.returnAltitudeInfo.severity === 1 || safetyWarn.takeoffAltitudeInfo.severity === 1 || safetyWarn.waypointActionInfo.length > 0
                    ? '#D32029'
                    : safetyWarn.returnAltitudeInfo.severity === 2 || safetyWarn.takeoffAltitudeInfo.severity === 2 || HomeMarkerInfo.waypoint_type === 2 || safetyWarn.distanceSafetyInfo.length > 0
                      ? '#D89614'
                      : '#49AA19'
                )
              }
            </div>
          </div>
        }
      </>

      {/* 警告信息 */}
      <>
        {
          // 有航线列表  或者  曲线飞行 时 显示警告
          (HomeMarkerInfo.waypoint_type === 2 || markers.length > 0 || safetyWarn.waypointActionInfo.length > 0)
          &&
          <div
            className="warn  pos_abs pd24 flex-col marL24 hidden radius8"
            style={{
              top: '88px',
              left: '0',
              maxHeight: 'calc(100vh - 188px)',
              width: '330px',
              backgroundColor: '#1F1F1F',
              display: safetyWarn.isOpen ? `` : `none`,
            }}
          >
            <div className='marB24'>
              <CloseOutlined className='h16 w16'
                onClick={() => {
                  let saf = safetyWarn
                  saf.isOpen = false
                  setSafetyWarn({ ...saf })
                }}
              />
            </div>

            <div className='' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 188px)' }}>
              {/* 保存未成功 错误 */}
              <div
                className='marB24'
                style={{ display: safetyWarn.waypointActionInfo.length > 0 ? `` : `none` }}
              >
                <span className='fs16 fw600' style={{ color: '#D32029' }}>
                  航点动作错误
                </span>
                <div className='flex_col jcl'>
                  {
                    safetyWarn.waypointActionInfo.map((res, index) => {
                      return (
                        <div key={index}>
                          {res.txt}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              {/* 曲线飞行警告 */}
              <div
                className='marB24'
                style={{ display: HomeMarkerInfo.waypoint_type === 2 ? `` : `none` }}>
                {
                  HomeMarkerInfo.waypoint_type === 2 &&
                  <span>
                    当前航点设置类型为到点不停（<span style={{ color: 'red' }}>曲线飞行</span>），
                    可能导致拍不准，建议调整航点类型为到点停（<span style={{ color: 'red' }}>直线飞行</span>）
                  </span>
                }

              </div>
              {/* 起飞海拔警告 */}
              <div
                className='marB24'
                style={{ display: markers.length > 0 ? `` : `none` }}>
                <span
                  className='fs16 fw600'
                  style={{
                    color: `${safetyWarn.takeoffAltitudeInfo.severity === 1
                      ? '#D32029'
                      : safetyWarn.takeoffAltitudeInfo.severity === 2
                        ? '#D89614'
                        : '#49AA19'
                      }`
                  }}
                >
                  起飞海拔警告
                </span>
                <div className='flex_col'>
                  <span>{safetyWarn.takeoffAltitudeInfo.txt}</span>
                </div>
              </div>

              {/* 返航海拔警告 */}
              <div className='marB24' style={{ display: markers.length > 0 ? `` : `none` }}>
                <span
                  className='fs16 fw600'
                  style={{
                    color: `${safetyWarn.returnAltitudeInfo.severity === 1
                      ? '#D32029'
                      : safetyWarn.returnAltitudeInfo.severity === 2
                        ? '#D89614'
                        : '#49AA19'
                      }`
                  }}
                >
                  返航海拔警告
                </span>
                <div className='flex_col'>
                  <span>{safetyWarn.returnAltitudeInfo.txt}</span>
                </div>
              </div>

              {/* 距离安全警告 */}
              <div className='marB24' style={{ display: safetyWarn.distanceSafetyInfo.length > 0 ? `` : `none` }}>
                <span className='fs16 fw600' style={{ color: '#D89614' }}>
                  距离安全警告
                </span>
                <div className=''>
                  {
                    safetyWarn.distanceSafetyInfo.map((res, index) => {
                      return (
                        <div key={index}>
                          {res.txt}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </>
    </div>
  );
};

export default ShippingLineEdit;