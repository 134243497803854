// home的相关请求
import req from './index'

//获取直播地址
export const getLive = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Live_address', params)
}
// 获取机场地址
export const getAirportAddress = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.GetCompanyParareList', params)
}