//改变标签页头部标题的方法
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet"; // 引用head管理工具

class HeadHelmet extends React.Component {

  render () {
    const hrefs = window.document.location.href  // 获取当前页面的地址
    let titles = '管理系统'
    if (hrefs.indexOf("shipping_line_build_segment") > 0) {  // 当地址里面包含 shipping_line_build_segment 的时候页面标题为 '划分单元端编辑器'
      titles = '划分单元段编辑器'
    }
    // 当前的例子只改变了页面标题title，这里可以根据自己的需求来动态改变页面head里面所需要的元素
    return (
      <Helmet>
        <title>{titles}</title>
      </Helmet>
    )
  }
}

// export default connect()(HeadHelmet);//系统推荐使用此命令  但会引发store报错，未想到解决方案，暂用以下命令满足需求
export default HeadHelmet;