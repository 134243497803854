import React from "react";
import {
  InstagramOutlined,
  ImportOutlined,
  AlertOutlined,
  NodeIndexOutlined,
  FolderOpenOutlined,
  BookOutlined,
  LockOutlined,
  AimOutlined,
  FireOutlined,
  GlobalOutlined,
  FundOutlined,
  FileDoneOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  SettingOutlined
} from '@ant-design/icons';

import MessagePrompt from './messagePrompt'


// label 菜单名称
// key 菜单主键 用于路由跳转
// icon 菜单图标
// children 子菜单 数组
// parent 父级菜单key
function getItem (label, key, icon, children, parent = '', permissionkey) {
  return { label, key, icon, children, parent, permissionkey };
}
// 文本，当前key,图标，下级，父级key，权限key 
export const MenuList = [
  getItem('数据监控', '/data_monitor', <FundOutlined />, '', '', ''),
  getItem('巡检直播', '/inspection_live_broadcast', <InstagramOutlined />, '', '', 136),//权限为136
  getItem('航线列表', 'shipping_line', <NodeIndexOutlined />, [
    getItem('管道巡检', '/routine/pipeline', '', '', 'shipping_line', 1),
    getItem('电网巡检', '/routine/power_grid', '', '', 'shipping_line', 139),
    getItem('电塔巡检', '/fixed_point/electric_towers', '', '', 'shipping_line', 142),
    getItem('场站巡检', '/fixed_point/stations', '', '', 'shipping_line', 143),
    getItem('高危巡检', '/shipping_line/leak', '', '', 'shipping_line', 31),
    getItem('综合巡检', '/shipping_line/comprehensive', '', '', 'shipping_line', 66),
  ], 115),
  getItem(<MessagePrompt type={'4'} count={0} />, '/abnormal_troubleshooting', <AlertOutlined />, '', '', 14),//异常排查

  // getItem('异常排查 - 演示', '/abnormal_troubleshooting_shows', <AlertOutlined />, '', '', ''),//异常排查 - 演示

  getItem(<MessagePrompt type={'2'} count={0} />, '/conventional_risks', <AimOutlined />, '', '', 19),//管道风险
  getItem(<MessagePrompt type={'7'} count={0} />, '/powerGrid_risks', <AimOutlined />, '', '', 148),//电网风险
  getItem(<MessagePrompt type={'8'} count={0} />, '/electricTower_risks', <AimOutlined />, '', '', 151),//电塔风险
  getItem(<MessagePrompt type={'9'} count={0} />, '/station_risks', <AimOutlined />, '', '', 177),//场站风险
  getItem(<MessagePrompt type={'3'} count={0} />, '/leakage_risk', <FireOutlined />, '', '', 90),//高危风险


  getItem('巡检记录', 'InspectionRecords', <FolderOpenOutlined />, [
    // getItem('定点巡检记录', '/designated_inspections_record', '', '', 'InspectionRecords', 104),
    getItem('管道巡检记录', '/conduit_inspections_record', '', '', 'InspectionRecords', 25),
    getItem('电网巡检记录', '/powerGrid_inspections_record', '', '', 'InspectionRecords', 192),
    getItem('电塔巡检记录', '/electricTower_inspections_record', '', '', 'InspectionRecords', 213),
    getItem('场站巡检记录', '/station_inspections_record', '', '', 'InspectionRecords', 216),
    getItem('高危巡检记录', '/leak_inspections_record', '', '', 'InspectionRecords', 98),
    getItem('综合巡检记录', '/comprehensive_inspections_record', '', '', 'InspectionRecords', 110),
  ], '', 137),

  // getItem('常规巡检记录', '/regular_inspections_record', <FolderOpenOutlined />, '', '', 25),
  // getItem('高危巡检记录', '/leak_inspections_record', <FolderOpenOutlined />, '', '', 98),
  // getItem('综合巡检记录', '/comprehensive_inspections_record', <FolderOpenOutlined />, '', '', 110),
  // getItem('定点巡检记录', '/designated_inspections_record', <FolderOpenOutlined />, '', '', 104),

  getItem('巡检报告', '/inspection_report', <FileDoneOutlined />, '', '', 29),
  getItem('巡检飞手', '/inspection_pilots', <UsergroupAddOutlined />, '', '', ''),
  getItem('风险处理员', 'risk', <TeamOutlined />, [
    getItem('人员', '/risk_handlers', '', '', 'risk', ''),
    getItem('组队', '/team', '', '', 'risk', ''),
  ], ''),


  getItem('巡检上帝图', 'gadMap', <GlobalOutlined />, [
    getItem('管道上帝图', '/conduit_pipeline_gad_map', '', '', 'gadMap', 258),
    getItem('电网上帝图', '/powerGrid_pipeline_gad_map', '', '', 'gadMap', 261),
  ], '', 255),

  // getItem('修改密码', '/edit_password', <LockOutlined />, '', '', ''),
  // getItem('用户手册', '/user_manual', <BookOutlined />, '', '', ''),

  getItem('设置', 'settings', <SettingOutlined />, [
    getItem('风险识别', '/risk_identification', '', '', 'settings', 381),
    getItem('短信通知', '/SMS_notification', '', '', 'settings', 132),
    getItem('权限', '/permission_settings', '', '', 'settings', 135),
  ], ''),


  // getItem('退出登录', '/login', <ImportOutlined />, '', '', ''),
];