import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Document, Page, pdfjs } from "react-pdf";

// import pdf from "@/common/img/anomalous/data.png";
// import pdf from "../../common/file.pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cat.net/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CheckPdf(props) {
  const navigate = useNavigate();
  const file = props.file;
  const fileWidth = props.width ? props.width : null;
  const [pageNumber, SetPageNumber] = useState(1);//pdf文件当前页
  const [totalPage, SetTotalPage] = useState(0);//pdf文件总页数
  const [pages, SetPages] = useState();

  useEffect(() => {

  }, []);// eslint-disable-line

  //获取pdf信息
  const onDocumentLoadSuccess = (fileInfo) => {
    SetTotalPage(fileInfo._pdfInfo.numPages);
    let arr = [];
    for (let i = 0; i < fileInfo._pdfInfo.numPages; i++) {
      arr.push((
        <div style={{ marginBottom: '20px' }} key={i}>
          <Page width={fileWidth} pageNumber={i + 1} scale={1.5} />
        </div>
      ))
    }
    SetPages([...arr]);
  }

  return (
    <div style={{ width: '100%' }}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={() => {
          console.error();
        }}
      >
        {pages}
      </Document>
    </div>
  )
}
