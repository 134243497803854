import React from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import './App.css';
import RouterList from './router/index.js'
import { startPath, startPath2 } from '@/router/config'

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('cruise_m30_isLogin');
  useEffect(() => {
    if (!token) {
      if (location.pathname !== startPath2) {
        navigate(startPath, { replace: true })
      }
    }

    //eslint-disable-next-line
  }, [token])
  return (
    <RouterList></RouterList>
  );
}

export default App;
