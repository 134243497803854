import React, { useState, useEffect, useRef } from 'react'
import { Breadcrumb, Spin, Tabs, Pagination, Image, Tag, message } from 'antd';
import { ArrowLeftOutlined, EnvironmentFilled } from '@ant-design/icons';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { GetPointList, GetPointListTab, GetPointInfo } from "@/services/leakage_risk.js"; // 引入Ajax
import './leak_inspections_record_picture.css'
import local from '@/utils/local'
import ai_processing from '@/common/img/ai_processing.svg'
import { basicPath } from '@/router/config'

const { TabPane } = Tabs;

function LeakInspectionsRecordPicture (props) {
  const navigate = useNavigate()
  const Stateparams = useLocation();
  const params = Stateparams.state;
  if (!params) {
    window.location.replace('/PatrolResult')
  }
  const transfer = params.transfer;

  /**
   * data
   * */
  const token = local.get('cruise_m30_token') || ''
  const [spinningLoading, _spinningLoading] = useState(false)
  const [pictureData, setPictureData] = useState([]) //获取的数据
  const [pictureInfo, setPictureInfo] = useState([]);//图片信息
  const [curIdx, setCurIdx] = useState(-1);
  const [total, _total] = useState(0);
  const [searchCriteria, _searchCriteria] = useState({
    check: 0,
    page: 1,
    perpage: 12,
  });
  const searchCriteriaRef = useRef({
    check: 0,
    page: 1,
    perpage: 12,
  });
  const [titleData, _titleData] = useState([])

  const visibleRef = useRef(false)//是否显示大图
  const [visibleImg, setVisibleImg] = useState(false)//是否显示大图

  const [seePhotoList, setSeePhotoList] = useState({
    currentIndex: '',//当前图片下标
    currentTitle: '',//当前图片标题
  })//查看图片组

  const dq_data = useRef([]); //当前图片数据

  const currIdx = useRef(-1);//查看图片 当前下标
  const pictureTotal = useRef(0);//查看图片 总数
  const [showPictureData, setShowPictureData] = useState([]);//查看图片 图片信息
  const pictureDataRef = useRef([]);//表格数据
  const [loading, setLoading] = useState(false);//
  const totalPageRef = useRef(1);//总页数

  useEffect(() => {
    GetPointListTabFun((val) => {
      GetPointListFun(val, searchCriteria);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //获取图片tab列表
  const GetPointListTabFun = async (c) => {
    _spinningLoading(true)
    let res = await GetPointListTab({
      access_token: token,
      companyId: transfer.companyId,
      statid: transfer.statid,
      insIds: transfer.insIds,
    });
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    const data = []
    if (res.data?.length > 0) {
      res.data.forEach(item => {
        item.is_point = false;
        if (Number(item.total) >= 0) { data.push(item) }
      })
      if (data.length > 0) {
        let search = { ...searchCriteria };
        search.check = data[0].value;
        _searchCriteria({ ...search });
        searchCriteriaRef.current = { ...search };
      }
    }
    _titleData(data || []);
    return c(data[0].value);
  }

  //获取图片列表
  const GetPointListFun = async (value, search, next = 0) => {
    setLoading(true);
    setShowPictureData([]);
    setPictureInfo([]);
    _spinningLoading(true);
    let params = {
      access_token: token,
      companyId: transfer.companyId,
      statid: transfer.statid,
      insIds: transfer.insIds,
      point_status: value || search.check,
      page: search.page,
      perpage: search.perpage,
    }
    let res = await GetPointList(params);
    _spinningLoading(false);
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let sea = { ...searchCriteria };
    sea.page = search.page;
    sea.perpage = search.perpage;
    sea.check = params.point_status;
    _searchCriteria(sea);
    _total(res.data.total || 0);
    totalPageRef.current = Math.ceil(Number(res.data.total) / Number(search.perpage));//总页数

    let pic = [];
    let list = res.data.items.map((item, idx) => {
      pic.push({ picUrl: item.picUrl });
      return {
        ...item, key: idx, divrisk: [],
        userName: [],
        advice_div: []
      }
    })
    setPictureData([...list]);
    dq_data.current = res.data.items;
    setShowPictureData(pic);
    pictureDataRef.current = list;

    if (next === 1) {
      currIdx.current = 0;
      pictureTotal.current = pic.length;
      let see = { ...seePhotoList };
      see.currentIndex = 0;
      setSeePhotoList(see);

      //修改箭头样式
      let dom = document.getElementsByClassName('ant-image-preview-operations')[document.getElementsByClassName('ant-image-preview-operations').length - 1]
      if (document.getElementById('point_info')) {
        dom.removeChild(document.getElementById('point_info'));
      }
      let dataInfo = list[0];
      let newDom = document.createElement('div');
      newDom.id = 'point_info';
      newDom.innerHTML = dataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;';
      newDom.style = 'text-align:left;top:0;left:0';
      newDom.className = 'fs20 pointer pos_abs h46 flex align_center';
      dom.appendChild(newDom);
    }

    if (next === 2) {
      currIdx.current = pic.length - 1;
      pictureTotal.current = pic.length;
      let see = { ...seePhotoList };
      see.currentIndex = pic.length - 1;
      setSeePhotoList(see);
      let dom = document.getElementsByClassName('ant-image-preview-operations')[document.getElementsByClassName('ant-image-preview-operations').length - 1]
      if (document.getElementById('point_info')) {
        dom.removeChild(document.getElementById('point_info'));
      }
      let dataInfo = list[pic.length - 1];
      let newDom = document.createElement('div');
      newDom.id = 'point_info';
      newDom.innerHTML = dataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;';
      newDom.style = 'text-align:left;top:0;left:0';
      newDom.className = 'fs20 pointer pos_abs h46 flex align_center';
      dom.appendChild(newDom);
    }

  }

  //图片查看页
  const onPatrolMap = (item) => {
    const coordinate = {
      picUrl: item.picUrl,
      longitude: item.longitude, //经度
      latitude: item.latitude, //纬度
    }
    window.sessionStorage.setItem('viewpictureid=vp' + item.key, JSON.stringify(transfer))
    window.sessionStorage.setItem('patrol_map?id=vp' + item.key, JSON.stringify(coordinate))
    window.open(basicPath + '/patrol_map?id=vp' + item.key, '_blank')
  }

  //获取节点处理详细信息
  const GetPointInfoFun = async (val1, val2, val3, idx) => {
    let paramdata = {
      access_token: token,
      companyId: val1,
      statid: val2,
      pointId: val3,
    }
    let res = await GetPointInfo(paramdata);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let val = res.data;

    let info = {
      divrisk: [],//风险
      userName: [],//处理人名称
      advice: [],//处理意见
    }
    let arr = val.abnormal_type_str ? val.abnormal_type_str.split(",") : [];
    let divrisk = arr.map((item, i) => {
      return <div key={i}>{item}</div>
    });
    info.divrisk = divrisk.length > 0 ? divrisk : <div className='opa25'>-</div>;

    let userArr = val.handler_user_name ? val.handler_user_name.split(",") : [];
    let users = userArr.map((item, i) => {
      return <div key={i}>{item}</div>
    });
    info.userName = users.length > 0 ? users : <div className='opa25'>-</div>;

    if (val.list.length > 0) {
      let advice_div = val.list.map((item, i) => {
        return (
          <div className='margB4' key={i}>
            <div className='flex-row'>
              <Tag style={{
                background: "#262626", borderRadius: 3,
                display: (item.status === 1) ? 'block' : 'none'
              }}>处理完毕</Tag>
              <div className='flex-auto'></div>
            </div>
            <div className='fSize14 opacity0_85'>{item.detail}</div>
            <div className='fSize12 opacity0_45'>{item.addtime}</div>
          </div>
        )
      })
      info.advice = advice_div;
    } else {
      if (val.handler_status === 3) {
        info.advice = <div>忽略</div>;
      } else {
        info.advice = <div className='opa25'>-</div>;
      }
    }
    let pic = [...pictureInfo];
    pic[idx] = true;
    setPictureInfo(pic);

    let picData = [...pictureData];
    picData[idx].divrisk = info.divrisk;
    picData[idx].userName = info.userName;
    picData[idx].advice_div = info.advice;
    setPictureData(picData);
  }

  const showImg = () => {
    // 图片标题
    let dom = document.getElementsByClassName('ant-image-preview-operations')[document.getElementsByClassName('ant-image-preview-operations').length - 1]
    if (document.getElementById('point_info')) {
      dom.removeChild(document.getElementById('point_info'));
    }
    let dataInfo = pictureDataRef.current[currIdx.current];
    let newDom = document.createElement('div');
    newDom.id = 'point_info';
    newDom.innerHTML = dataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;';
    newDom.style = 'text-align:left;top:0;left:0';
    newDom.className = 'fs20 pointer pos_abs h46 flex align_center';
    dom.appendChild(newDom);
    if (pictureTotal.current > 1) {
      // 下一页
      let rightPhoto = document.getElementsByClassName('ant-image-preview-switch-right')[0];
      let leftPhoto = document.getElementsByClassName('ant-image-preview-switch-left')[0];
      if (currIdx.current === pictureTotal.current - 1 && (pictureTotal.current < searchCriteriaRef.current.perpage || totalPageRef.current === searchCriteriaRef.current.page)) {
        rightPhoto.style.color = 'rgba(255,255,255,0.2)';
        rightPhoto.style.cursor = 'not-allowed';
      } else {
        rightPhoto.style.color = 'white';
        rightPhoto.style.cursor = 'pointer';
      }

      if (currIdx.current === 0 && searchCriteriaRef.current.page === 1) {
        leftPhoto.style.color = 'rgba(255,255,255,0.2)';
        leftPhoto.style.cursor = 'not-allowed';
      } else {
        leftPhoto.style.color = 'white';
        leftPhoto.style.cursor = 'pointer';
      }

      let rightDiv = document.createElement('div')
      rightDiv.id = 'rightDiv'
      rightDiv.onclick = () => {
        if (loading) { return; }
        if (currIdx.current === pictureTotal.current - 1 && (pictureTotal.current < searchCriteriaRef.current.perpage || totalPageRef.current === searchCriteriaRef.current.page)) {
          return;
        }
        if (document.getElementById('point_info')) {
          dom.removeChild(document.getElementById('point_info'));
        }
        currIdx.current = currIdx.current + 1;
        if (pictureTotal.current - 1 >= currIdx.current) {
          let see = { ...seePhotoList };
          see.currentIndex = currIdx.current;
          setSeePhotoList(see);
          let newDataInfo = pictureDataRef.current[currIdx.current];
          newDom.innerHTML = newDataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;';
          dom.appendChild(newDom);
        }

        let sea = { ...searchCriteriaRef.current };
        if (currIdx.current === sea.perpage) {
          sea.page = sea.page + 1;
          searchCriteriaRef.current = sea;
          GetPointListFun('', sea, 1);

        }
        if (currIdx.current === pictureTotal.current - 1 && (pictureTotal.current < searchCriteriaRef.current.perpage || totalPageRef.current === searchCriteriaRef.current.page)) {
          rightPhoto.style.color = 'rgba(255,255,255,0.2)';
          rightPhoto.style.cursor = 'not-allowed';
        } else {
          rightPhoto.style.color = 'white';
          rightPhoto.style.cursor = 'pointer';

          leftPhoto.style.color = 'white';
          leftPhoto.style.cursor = 'pointer';
        }

      }
      rightDiv.className = 'pos_abs wid100 hei100 radius50'
      rightPhoto.appendChild(rightDiv);

      // 上一页
      let leftDiv = document.createElement('div')
      leftDiv.id = 'leftDiv'
      leftDiv.onclick = () => {
        if (loading) { return; }
        if (currIdx.current === 0 && searchCriteriaRef.current.page === 1) {
          return;
        }
        if (document.getElementById('point_info')) {
          dom.removeChild(document.getElementById('point_info'));
        }
        currIdx.current = currIdx.current - 1;
        let sea = { ...searchCriteriaRef.current };
        if (pictureTotal.current > currIdx.current && currIdx.current >= 0) {
          let see = { ...seePhotoList };
          see.currentIndex = currIdx.current;
          setSeePhotoList(see);
          let newDataInfo = pictureDataRef.current[currIdx.current];
          newDom.innerHTML = newDataInfo.point_order + '&nbsp;&nbsp;&nbsp;&nbsp;';
          dom.appendChild(newDom);
        }


        if (currIdx.current === -1) {
          if (sea.page > 1) {
            sea.page = sea.page - 1;
            searchCriteriaRef.current = sea;
            GetPointListFun('', sea, 2);
          }
        }

        if (currIdx.current === 0 && sea.page === 1) {
          leftPhoto.style.color = 'rgba(255,255,255,0.2)';
          leftPhoto.style.cursor = 'not-allowed';
        } else {
          leftPhoto.style.color = 'white';
          leftPhoto.style.cursor = 'pointer';

          rightPhoto.style.color = 'white';
          rightPhoto.style.cursor = 'pointer';
        }
      }
      leftDiv.className = 'pos_abs wid100 hei100 radius50'
      leftPhoto.appendChild(leftDiv)


    }
  }

  // 监听键盘事件来展示图片上下页
  const onkeydown = (e) => {
    if (e.code === 'ArrowLeft' && visibleRef.current) {
      document.getElementById('leftDiv').click()
    }
    if (e.code === 'ArrowRight' && visibleRef.current) {
      document.getElementById('rightDiv').click()
    }

  }

  useEffect(() => {
    window.addEventListener("keydown", onkeydown)
    return () => {
      window.removeEventListener('keydown', onkeydown); // 销毁
    };
    // eslint-disable-next-line
  }, [])


  // 分页
  const onPagination = (page, pageSize) => {
    let search = { ...searchCriteria };
    search.page = page;
    search.perpage = pageSize;
    searchCriteriaRef.current = { ...search };
    GetPointListFun('', search);
  }

  return (
    <div id='main_div' className="disFlex flexDirCol posRel tablebackcol" style={{ height: '100%', overflow: 'auto' }}>
      <Spin spinning={spinningLoading}>
        <div className="disFlex flexDirCol">
          <div className='flex-row aliItemsCen paddL24' style={{ width: '100% ', padding: '16px 24px' }}>
            <span onClick={() => { navigate(-1) }} className='curPointer'><ArrowLeftOutlined /> </span>
            {/* <span className='fSize20 fWei500 margR12 margL18'>{transfer.pTitle}{transfer.title ? ' - ' + transfer.title : ''}</span> */}
            <span className='fSize20 fWei500 margR12 margL18'>{transfer.title}</span>
            <span className='fSize14 fWei400 opacity0_45'>巡检时间：{transfer.start_time} ~ <span>{transfer.end_time !== '' ? transfer.end_time : '未结束'}</span></span>
          </div>
        </div>
        <div className="padd24 " style={{ flex: 'auto', padding: '0 24px 24px 24px' }}>
          <div className="disFlex flexDirCol hei100 padd24 backcol">
            <Tabs defaultActiveKey={searchCriteria.check}
              onTabClick={(key) => {
                // setTimeout(() => {
                //   let tab = document.getElementsByClassName('TabPane');
                //   for (let i = 0; i < tab.length; i++) {
                //     tab[i].style.color = 'rgba(255, 255, 255, 0.3)';
                //     tab[i].style.backgroundColor = 'rgba(255, 255, 255, 0.08)';
                //   }
                // }, 1)
              }}>
              {
                titleData.map((item, index) => {
                  return <TabPane
                    tab={
                      <div className='disFlex aliItemsCen' key={index} onClick={() => {
                        let search = { ...searchCriteria };
                        search.page = 1;
                        search.check = item.value;
                        searchCriteriaRef.current = { ...search };
                        GetPointListFun('', search);
                      }}>
                        <div className='margR4'>
                          {item.label}
                        </div>
                        <div className='TabPane' style={{
                          // backgroundColor: (searchCriteria.check === item.value) ? '#E6F7FF' : '#F0F0F0',
                        }}>
                          {item.total}
                        </div>
                      </div>
                    }
                    key={index}
                  >
                  </TabPane>
                })
              }
            </Tabs>
            <div className="disFlex flexDirCol" style={{ flex: 'auto', paddingLeft: '24px', }} >
              <div className="disFlex flex_wrap  "
                style={{ flexDirection: 'row', maxHeight: '100%', overflowY: 'auto', alignContent: 'flex-start' }}>
                {pictureData.length > 0 ? pictureData.map((item, item_idx) => {
                  return (
                    item.handler_status === 0
                      ?
                      <div key={item_idx} className="disFlex flexDirCol"
                        style={{ width: '24%', height: 'auto', marginBottom: '24px', marginRight: '1%', }}>
                        <div className='pos_rel radius8' style={{ backgroundColor: '#000' }}>
                          <img className='radius8' style={{ width: '100%' }} src={ai_processing} alt="人工复核中..." />
                          {/* 覆盖图层  显示想要显示的文字 */}
                          <div
                            className=' pos_abs wid100 hei100 flex jcc align_center radius8'
                            style={{ backgroundColor: '#000', top: 0, left: 0 }}>
                            · · · · · ·
                          </div>
                        </div>
                        <div className="disFlex jusConCenSB"
                          style={{ flexDirection: 'row', flex: 'auto', paddingLeft: '16px', paddingRight: '16px' }}>
                          <div className='coLF0_85' style={{ fontSize: '14px', fontWeight: '600' }}>
                            {(item.point_order < 10) ? '0' + item.point_order : item.point_order}
                          </div>
                        </div>
                      </div>
                      :
                      <div key={item_idx}
                        className="flex_col pos_rel"
                        style={{ width: '24%', height: 'auto', marginBottom: '24px', marginRight: '1%' }}>
                        <div className='pos_rel' onMouseLeave={() => setCurIdx(-1)}
                          onMouseEnter={() => {
                            if (!pictureInfo[item_idx]) {
                              GetPointInfoFun(item.CompanyID, item.statid, item.pointId, item_idx)
                            }
                            setCurIdx(item_idx);
                          }}
                        >
                          <img className='radius8' alt='index' width='100%' src={item.compress_pic || item.picUrl}
                            onClick={() => {
                              let see = seePhotoList;
                              see.currentIndex = item_idx;
                              setSeePhotoList({ ...see });
                              visibleRef.current = true;
                              setVisibleImg(true);
                              setCurIdx(-1);

                              currIdx.current = item_idx;
                              pictureTotal.current = showPictureData.length;
                              setTimeout(() => {
                                showImg()
                              }, 100)

                            }} />

                          <div className='pos_abs radius8 wid100 hei100 paddRL24 paddTB16'
                            style={{ background: 'rgba(0, 0, 0, 0.45)', top: 0, left: 0, overflowY: 'auto', display: item_idx === curIdx ? '' : 'none' }}
                            onClick={() => {
                              let see = seePhotoList;
                              see.currentIndex = item_idx;
                              setSeePhotoList({ ...see });
                              visibleRef.current = true;
                              setVisibleImg(true);
                              setCurIdx(-1);

                              currIdx.current = item_idx;
                              pictureTotal.current = showPictureData.length;
                              setTimeout(() => {
                                showImg()
                              }, 100)
                            }}
                          >
                            <div className='fSize14 fWei400 margB8 flex-row'>
                              <div className='flex_shrink0'>
                                位置：
                              </div>
                              <div>
                                {item.location}
                              </div>
                            </div>
                            <div className='fSize14 fWei400 margB8 flex-row'>
                              <div className='flex_shrink0'>
                                时间：
                              </div>
                              <div className=''>
                                {item.addtime}
                              </div>
                            </div>
                            <div className='fSize14 fWei400 margB8 flex-row'>
                              <div className='flex_shrink0'>
                                风险初判：
                              </div>
                              <div className='flex-col'>
                                {item.divrisk}
                              </div>
                            </div>
                            <div className='fSize14 fWei400 margB8 flex-row'>
                              <div className='flex_shrink0'>
                                处理人员：
                              </div>
                              <div className={'flex_col flex-auto'}>
                                {item.userName}
                              </div>
                            </div>
                            <div className='fSize14 fWei400 margB8 flex-row'>
                              <div className='flex_shrink0'>
                                处理意见：
                              </div>
                              <div className='flex-col autoY' style={{ maxHeight: '600px' }}>
                                {item.advice_div}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="disFlex jusConCenSB"
                          style={{ flexDirection: 'row', flex: 'auto', }}>
                          <div className='coLF0_85' style={{ fontSize: '14px', fontWeight: '600' }}>
                            {(item.point_order < 10) ? '0' + item.point_order : item.point_order}
                          </div>
                          <div style={{ fontSize: '14px', fontWeight: '400', color: '#1890FF', cursor: 'pointer' }}
                            onClick={() => { onPatrolMap(item) }}>
                            <EnvironmentFilled style={{ color: '#177DDC' }} />
                          </div>
                        </div>

                        <div className='coLF0_85 backColorD32029 borRad2' style={{ position: 'absolute', bottom: '32px', right: '8px', padding: '1px 8px', display: (item.methane_con > -1 ? '' : 'none') }}>
                          CH4：{item.methane_con} ppm·m
                        </div>
                      </div>

                  )
                }) : ''}
              </div>
            </div>

            <div className='disFlex jusConEnd Pagination'>
              <Pagination
                total={total}
                current={searchCriteria.page}
                pageSize={searchCriteria.perpage}
                showQuickJumper
                showSizeChanger={true}
                pageSizeOptions={['12', '24', '48', '96']}
                showTotal={(total) => `Total ${total} items`}
                onChange={onPagination}
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'none', }} >
          <Image.PreviewGroup
            preview={{
              visible: visibleImg,
              current: seePhotoList.currentIndex,
              onVisibleChange: (vis) => { visibleRef.current = vis; setVisibleImg(vis) },
              countRender: (cur, tol) => {
                return <div>{(currIdx.current + 1) + ' / ' + pictureTotal.current}</div>
              }
            }}
          >
            {
              showPictureData && showPictureData.map((item, index) => {
                return (
                  <Image key={index} src={item.picUrl} />
                )
              })
            }
          </Image.PreviewGroup>
        </div>

      </Spin>
      <canvas id="posiToShape" width="960" height="720" style={{ display: 'none' }} ></canvas>
    </div>
  );
}
export default LeakInspectionsRecordPicture