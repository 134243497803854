/**
 *
 * describe: ant表格操作生成器
 * author:MXC
 * Date:2022/6/9
 *
 * */

/**
record 当前条数据
index  索引
type   类型   actions_drop 折叠操作按钮栏    actions_button 平铺操作按钮栏 actions_text 文字操作
ButtonList   按钮列表
Operation    操作回调
 * */
import { useState } from 'react';
import {
  Button,
  Dropdown,
  Menu,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  MenuOutlined,
  UserSwitchOutlined,
  GlobalOutlined,
  ImportOutlined,
  FlagOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  RotateLeftOutlined,
  NodeIndexOutlined,
  RotateRightOutlined,
  VerticalAlignBottomOutlined,
  TeamOutlined
} from '@ant-design/icons';

const OperationBuilder = (record, index, type, ButtonOrText, Operation = () => { }) => {
  const onClick = (key) => {
    Operation(record, index, type, key.key)
  }
  const items = () => {
    // eslint-disable-next-line
    const elemt = (ButtonOrText || []).map((item, index) => {
      switch (item.type) {
        case 'SetUp':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }}  >
            <EditOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'File':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }}  >
            <EditOutlined />
            <input type="file" accept=".xls,.xlsx" />
          </Menu.Item>
        case 'Authority':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <UserSwitchOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'NodeMap':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <GlobalOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'Divider':
          return <Menu.Divider key={item.key} style={{ display: item?.purview ? `` : `none` }} />
        case 'Delete':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <DeleteOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'Outlined':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <ImportOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'Exeroute':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <FlagOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'ImportKmz':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <ImportOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'NodeExpandOutlined':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <NodeExpandOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'NodeCollapseOutlined':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <NodeCollapseOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'RotateLeftOutlined':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <RotateLeftOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'NodeIndexOutlined':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <NodeIndexOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'RotateRightOutlined':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <RotateRightOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'TeamOutlined':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <TeamOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        case 'VerticalAlignBottomOutlined':
          return <Menu.Item key={item.key} style={{ display: item?.purview ? `` : `none` }} >
            <VerticalAlignBottomOutlined />
            <span className='margL10'>{item.label}</span>
          </Menu.Item>
        default:
          break;
      }

    });

    return <Menu key='Menu' onClick={onClick}>{elemt}</Menu>;

  }
  switch (type) {
    case 'actions_drop':
      let isShow = false;
      for (let i = 0; i < ButtonOrText.length; i++) {
        if (ButtonOrText[i].purview) {
          isShow = true
        }
      }
      if (isShow) {
        return <Dropdown overlay={items} trigger={['click']}>
          <MenuOutlined className='col_primary pointer' />
        </Dropdown>
      } else {
        return <div></div>
      }

    case 'actions_button':
      return <Button type="primary" className='margL12' icon={<PlusOutlined />}>新增</Button>
    case 'actions_text':
      return <span className='coL1890FF curPointer' onClick={() => { onClick('text') }}>{ButtonOrText}</span>
    default:
      break;
  }
}
export default OperationBuilder;

