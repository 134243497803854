//航线总览
// 管线、航点编辑
import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { message, Divider, Tree, Spin, Checkbox } from 'antd'
import {
  Amap as RMap, config as AmapReactConfig, Marker as AmapMarker, Markers as AmapMarkers,
  BezierCurve, Scale, TileLayer
} from '@amap/amap-react';
import { transformGCJ2WGS, transformWGSGCJ2, distance } from "@/common/js/common.js" //引入公共方法
import { getRouteOverview } from '@/services/shipping_line' //接口
import home from "@/common/img/shipping_line/home.png" //起飞点
import local from '@/utils/local'

import './shipping_line_overview.css'

//地图key
AmapReactConfig.key = '193db6af15a86cd8d48b102ab461b002';

//当前地图 使用高德地图（GCj坐标）的核心 以及 天地图（W84坐标）的图层
function ShippingLineOverview () {
  const token = local.get('cruise_m30_token');
  const mapkeys = [
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97'
  ];//天地图key
  const [choose_map_key, setChooseMapKey] = useState(0);//随机 0，1，2从 mapkeys 中取一个地图key
  const line_color = '#FFBD0A';//管线颜色

  const [isCheck, setIsCheck] = useState(false);
  const [allLength, setAllLength] = useState(0);//总数

  const mapRef = useRef();
  const [zoom, setZoom] = useState(17); //地图缩放比例
  const scale = 3875.8509003722434;//初始化地图比例尺
  const [mapScale, setmapScale] = useState(3875.8509003722434) // 地图当前比例尺
  const [centerPoint, setCenterPoint] = useState([104, 30]);//地图中心点
  const centerPointRef = useRef([0, 0]);//中心点
  const [opacity, setOpacity] = useState(true);//切换图层

  const [loading, setLoading] = useState(false);
  const [lineData, setLineData] = useState([]);//所有航线的数据

  const [allKeys, setAllKeys] = useState([]);//所有节点
  const [lineTreeData, setLineTreeData] = useState([]);//航线树数据
  const [checkedKeys, setCheckedKeys] = useState([]);//勾选的数据
  const [expandedKeys, setExpandedKeys] = useState([]);//展开的节点
  const [selectedKey, setSelectedKey] = useState([]);//选中的节点

  const [divLeft, setDivLeft] = useState(0);//
  const [divTop, setDivTop] = useState(0);
  const [floating, setFloating] = useState({
    visible: false,//是否可见
    title: '',//航线名称
  });

  //鼠标移动事件
  document.onmousemove = function () {
    setDivTop(window.event.pageY);
    setDivLeft(window.event.pageX);
  };

  //获取所有的航线数据
  const getData = async () => {
    setLoading(true);
    let res = await getRouteOverview({
      access_token: token
    });
    console.log('数据', res);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      setLoading(false);
      return;
    }
    let tree = [];//树状数据
    let line = [];//数据
    let allKey = [];//树状选择的数据 - 默认全选
    let openKey = [];//树状展开的节点 - 默认全部展开
    if (res.data && res.data.length > 0) {
      for (let i = 0; i < res.data.length; i++) {
        let val = res.data[i];
        let obj = { title: val.title, key: val.RID };
        allKey.push(val.RID);
        openKey.push(val.RID);
        // 处理单元段
        if (val.data && val.data.length > 0) {
          obj.children = [];
          for (let n = 0; n < val.data.length; n++) {
            let valn = val.data[n];
            allKey.push(valn.id);
            obj.children.push({ title: valn.title, key: valn.id });
            let obj2 = {
              key: valn.id,
              name: valn.title,
              startPoint: [],//起飞点
              line: [],//航线
            }

            if (valn.latitude && valn.longitude) { //起飞点的经纬度存在
              // 起飞点
              let p = transformGCJ2WGS({ lat: valn.latitude, lon: valn.longitude });
              obj2.startPoint = ([Number(parseFloat(p.lon).toFixed(6)), Number(parseFloat(p.lat).toFixed(6))]);
            }

            if (valn.pipeline && valn.pipeline.length > 0) { //处理航线
              obj2.line = valn.pipeline.map((item) => {
                return [Number(item.wgs84_longitude), Number(item.wgs84_latitude)];
              });
              if (obj2.startPoint.length === 0) { // 如果没有起飞点 则获取航线的第一个点
                obj2.startPoint = obj2.line[0];
              }
            }
            if (obj2.startPoint.length === 0) {
              obj2.startPoint = [0, 0];
            }
            // 处理地图中心点
            if (centerPointRef.current[0] === 0 && centerPointRef.current[1] === 0 && JSON.stringify(obj2.startPoint) !== '[]') {
              setCenterPoint(obj2.startPoint);
              centerPointRef.current = obj2.startPoint;
            }
            line.push(obj2);
          }
        }
        tree.push(obj);
      }
    }
    setLineTreeData(tree);
    setLineData(line);
    setCheckedKeys(allKey);
    setAllLength(allKey.length);
    setAllKeys(allKey);
    setIsCheck(true);
    setExpandedKeys(openKey);
    setLoading(false);
  }

  useEffect(() => { //页面加载完成执行
    let num = Math.floor(Math.random() * 3);
    setChooseMapKey(num);
    getData();
    // setTimeout(() => {
    //   console.log(mapRef.current.setCenter([0, 0]));
    // }, 5000)
    //eslint-disable-next-line
  }, [])

  return (
    <Spin spinning={loading}>
      <div className='flex_row' style={{ height: '100vh' }}>
        {/* 航点编辑 */}
        <div className="flex-col flex_shrink0" style={{ top: 0, left: 0, height: '100%', width: 320, background: 'black', overflowY: 'auto' }}>
          <div className='marRL24 marT16 fs16 fw600 col_text'>
            航线列表
          </div>
          <Divider plain></Divider>

          <div className='marRL24 marB48' id="overviewTree">
            <div className='marB12'>
              <Checkbox checked={isCheck} onChange={(e) => {
                if (isCheck) { //当前选中
                  setCheckedKeys([]);
                } else {
                  setCheckedKeys(allKeys);
                }
                setIsCheck(e.target.checked);
              }}>全选</Checkbox>
            </div>
            <Tree
              checkable
              multiple={true}
              autoExpandParent={true}
              onCheck={(val) => { //选择
                if (val.length === allLength) {
                  setIsCheck(true);
                } else {
                  setIsCheck(false);
                }
                setCheckedKeys(val);
              }}
              onExpand={(val) => { //节点展开
                setExpandedKeys(val);
              }}
              selectedKeys={selectedKey}
              checkedKeys={checkedKeys}
              expandedKeys={expandedKeys}
              treeData={lineTreeData}
            />
          </div>
        </div>

        {/* 地图 */}
        <RMap
          className="flex_auto"
          features={[]}
          ref={mapRef}
          zoom={zoom}
          center={centerPoint}
          onZoomChange={(map) => {
            setZoom(map.getZoom());
            setmapScale(map.getScale())
          }}
        >
          {/* 天地图图层 */}
          <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=vec_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />
          <TileLayer getTileUrl={'http://t0.tianditu.gov.cn/DataServer?T=img_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} opacity={opacity} />
          <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=cia_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />

          <>
            {
              lineData && lineData.length > 0 ?
                lineData.map((item, index) => {
                  if (checkedKeys.indexOf(item.key) < 0) {
                    return ''
                  }
                  return <div key={index}>
                    {/* startPoint */}
                    {/* 起飞点 */}
                    <>
                      <AmapMarker
                        position={item.startPoint}
                        zIndex={99999}
                        offset={[0, 0]}
                        onClick={(m) => {
                          if (selectedKey[0] === item.key) {
                            setSelectedKey([]);
                          } else {
                            //找出起飞点相同的航线
                            let selectK = [item.key];
                            for (let i = 0; i < lineData.length; i++) {
                              let val = lineData[i];
                              if (i === index) continue;
                              if (item.startPoint[0] === val.startPoint[0] && item.startPoint[1] === val.startPoint[1] && checkedKeys.indexOf(val.key) >= 0) {
                                selectK.push(val.key);
                              }
                            }
                            setSelectedKey(selectK);
                          }

                        }}
                        onMouseOut={() => {
                          let info = { ...floating };
                          info.visible = false;
                          info.title = '';
                          setFloating(info);
                        }}
                        onMouseOver={() => {
                          let info = { ...floating };
                          info.visible = true;
                          info.title = item.name;
                          setFloating(info);
                        }}
                      >
                        <img alt='起飞点' style={{
                          width: "48px",
                          height: "48px"
                        }} src={home}></img>
                      </AmapMarker>
                    </>

                    {/* 航线 */}
                    <>
                      {
                        item.line.length > 0 ? (
                          <BezierCurve key={index} path={item.line} isOutline={true} outlineColor="white" borderWeight={2}
                            onClick={() => {
                              if (selectedKey[0] === item.key) {
                                setSelectedKey([]);
                              } else {
                                setSelectedKey([item.key]);
                              }
                            }}
                            onMouseOut={() => {
                              let info = { ...floating };
                              info.visible = false;
                              info.title = '';
                              setFloating(info);
                            }}
                            onMouseOver={() => {
                              let info = { ...floating };
                              info.visible = true;
                              info.title = item.name;
                              setFloating(info);
                            }}
                            strokeColor={line_color} strokeOpacity={1} strokeWeight={4} strokeStyle="solid" // 线样式还支持 'dashed'
                            zIndex={50}
                            lineJoin='round'
                            lineCap='round'
                          />) : ''
                      }
                    </>


                  </div>
                })
                : ''
            }
          </>

          <Scale position={{ bottom: '24px', left: '4px' }} /> {/* 比例尺 */}
        </RMap>

        <div id='div_info' className='pos_abs'
          style={{
            zIndex: 20, left: divLeft + 10, padding: '6px 8px', color: 'white',
            top: divTop + 15, background: '#000000bf', display: floating.visible ? '' : 'none',
          }}>
          {floating.title}
        </div>

      </div>
    </Spin>
  );
};

export default ShippingLineOverview;