//高危处理
import React, { useState, useEffect } from 'react'
import { Modal, Input, Space, Button, Table, message, Spin, Image as AntImage, Badge, Card } from 'antd';
import { UserAddOutlined, UserSwitchOutlined, SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'
import { GetPoint, GetPointInfo, GetUserInfo, AllocationUser, Ignore } from "@/services/leakage_risk"; // 引入axios
import local from '@/utils/local'
// import data from '@/common/img/anomalous/data.png' //引入图片的路径
import hourglass from "@/common/img/leakage_risk/hourglass.svg";
import { basicPath } from '@/router/config'


function LeakHandle () {
  const navigate = useNavigate();
  //获取路由传值
  const Stateparams = useLocation();
  const params = Stateparams.state;
  const companyId = params.companyId;
  const statid = params.statid;
  const insIds = params.insIds;
  const is_handle = params.is_handle;//是否处理风险
  const total = params.total;//总条数
  const routes_type = params.list?.routes_type
  const token = local.get('cruise_m30_token');
  const [list, SetList] = useState([]);//数据
  const [len, SetLen] = useState();//数据长度
  const [visible, setVisible] = useState(false);//是否显示大图
  const [current, SetCurrent] = useState(0);//当前 idx
  const [pipe_name, SetPipeName] = useState('');//管线名称
  const [line_name, SetLineName] = useState('');//单元段名称
  const [address, SetAddress] = useState();//风险地址
  const [addtime, SetAddTime] = useState('');//时间
  const [pointId, SetPointId] = useState('');//节点id
  const [latitude, SetLatitude] = useState('');//纬度
  const [longitude, SetLongitude] = useState('');//经度
  const [point_order, SetPointOrder] = useState('');//图片编号
  const [user, SetUser] = useState('');//处理人员姓名
  const [user_id, SetUserId] = useState('');//处理人员id
  const [search_user, SetSearchUser] = useState('');//搜索处理人员关键字
  const [user_data, SetUserData] = useState('');//处理人员数据
  const [choose_user, SetChooseUser] = useState('');//选择的处理人员
  const [point_state, SetPointState] = useState('');//节点处理状态
  const [loading, SetLoading] = useState(false);//加载
  const [advice, SetAdvice] = useState([]);//处理意见数据
  const [advice_div, SetAdviceDiv] = useState();//处理意见div
  const [picUrl, SetPicUrl] = useState('');//图片
  const [methane_con, setMethane_con] = useState('');//甲烷值
  const [risk_type, SetRiskType] = useState([]);//风险类型
  const [divrisk, SetDivrisk] = useState();//
  const [is_show_model, SetShowModel] = useState(false);//是否显示选择用户弹窗

  //搜索处理人员
  const SearchUser = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8, }}>
        <Input
          placeholder='请输入处理人员'
          value={search_user}
          onChange={(e) => { SetSearchUser(e.target.value) }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {

            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>

          <Button
            onClick={() => { SetSearchUser('') }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });
  //表格单选
  const rowSelection = {
    type: 'radio',
    onChange: (selectedRowKeys, selectedRows) => {
      SetChooseUser(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const user_cil = [
    {
      title: 'ID',
      dataIndex: 'id',
    }, {
      title: '姓名',
      dataIndex: 'realname',
      ...SearchUser(),
    }, {
      title: '部门',
      dataIndex: 'department',
    }, {
      title: '电话',
      dataIndex: 'phone',
    },
  ]


  //获取数据
  const getData = async () => {
    SetLoading(true);
    let param = {
      access_token: token,
      companyId: companyId,
      statid: statid,
      insIds: insIds,
      handler_status: is_handle ? 1 : 2,
      type: 2
    };

    let res = await GetPoint(param);
    if (res.ret === 200 && res.data.list) {
      let data = res.data.list.map((item, idx) => {
        return { ...item, key: idx }
      })
      SetList(data);
      SetLen(data.length);
      let val = res.data.list[0];
      SetPipeName(val.pipe_name);
      SetLineName(val.line_name);
      SetPointId(val.pointId);
      SetPointState(val.handler_status);
      SetPointOrder(val.point_order)
      GetPointInfoFun(val.CompanyID, val.statid, val.pointId);
    }
  }

  //初始化数据
  const initializeData = (num) => {
    SetLoading(true);
    let val = list[num];
    SetPipeName(val.pipe_name);
    SetLineName(val.line_name);
    SetPointId(val.pointId);
    SetPointOrder(val.point_order)
    GetPointInfoFun(val.CompanyID, val.statid, val.pointId);
  }

  //获取节点处理详细信息
  const GetPointInfoFun = async (val1, val2, val3) => {
    let paramdata = {
      access_token: token,
      companyId: val1,
      statid: val2,
      pointId: val3,
    }
    let res = await GetPointInfo(paramdata);
    //console.log('风险点详细数据1');
    //console.log(res);
    setTimeout(() => {
      SetLoading(false);
    }, 2000);
    if (res.ret === 200) {
      let val = res.data;
      SetAddress(val.location);
      let arr = val.abnormal_type_str.split("，");
      let divrisk = arr.map((item, i) => {
        //console.log(item);
        return <div key={i}>{item}</div>
      })
      SetRiskType(arr);
      SetDivrisk(divrisk);
      SetAddTime(val.addtime);
      SetPicUrl(val.picUrl);
      setMethane_con(val.methane_con)
      SetUser(val.handler_user_name);
      SetUserId(val.handler_user);
      SetPointState(val.handler_status);
      SetAdvice(val.list);
      SetLatitude(val.latitude);
      SetLongitude(val.longitude);
      if (val.list.length > 0) {
        let advice_div = val.list.map((item, i) => {
          return (
            <div className='margB4' key={i}>
              <div className='flex-row'>
                <div className='paddRL8 paddTB1' style={{ border: '1px solid #434343', background: "#FFFFFF0A", color: "#FFFFFFD9", display: (item.status === 1) ? 'block' : 'none' }}>
                  处理完毕
                </div>
                {/* <Tag ></Tag> */}
                <div className='flex-auto'></div>
              </div>
              <div className='fSize14 opacity0_85'>{item.detail}</div>
              <div className='fSize12 opacity0_45'>{item.addtime}</div>
            </div>
          )
        })
        SetAdviceDiv(advice_div);
      }
      else {
        if (val.handler_status === 3) {
          SetAdviceDiv('忽略');
        } else if (val.handler_status === 2) {
          SetAdviceDiv('已处理');
        } else {
          SetAdviceDiv();
        }
      }
      let img = new Image();
      img.src = val.picUrl;
      img.onload = () => {
        SetLoading(false);
      }
    }
  }

  //获取处理人员
  const getUser = async () => {
    let param = {
      access_token: token,
      companyId: companyId,
      search: search_user,
    }
    let res = await GetUserInfo(param);
    //console.log('处理人员数据');
    //console.log(res);
    if (res.ret === 200) {
      let data = res.data.list;
      for (let i = 0; i < data.length; i++) {
        data[i].key = i;
      }
      SetUserData(data);
    }
  }

  //分配处理人员
  const AllocationUserFun = async (num, c) => {
    let param = {
      access_token: token,
      companyId: companyId,
      pointId: pointId,
      handler_user: num,
    }
    let res = await AllocationUser(param);
    if (res.ret === 200) {
      SetShowModel(false);
      message.success(res.msg || '分配人员成功');
      let cur = current;
      if (current !== list.length - 1) {
        cur = cur + 1;
      }
      SetCurrent(cur);
      return c(cur)
    }
  }

  //忽略
  const IgnorePoint = async (c) => {
    let param = {
      access_token: token,
      companyId: companyId,
      statid: statid,
      pointId: pointId,
    }
    let res = await Ignore(param);
    if (res.ret === 200) {
      let num = len - 1;
      SetLen(num);
      let arr = list;
      arr.splice(current, 1);
      SetList([...arr]);
      let cur = current;
      if (arr.length === 0) {
        message.success('风险处理完成，即将返回');
        setTimeout(() => {
          navigate(-1)
        }, 2000)
        return;
      }
      if (current === list.length && arr.length > 0) {
        //console.log('zhix -1');
        cur = cur - 1;
        SetCurrent(cur);
      }
      message.success(res.msg || '节点已忽略');
      return c(cur);
    }
  }

  //图片查看页
  const onPatrolMap = () => {
    let coordinate = {
      picUrl: picUrl,
      longitude: longitude, //经度
      latitude: latitude, //纬度
    }
    //console.log(picUrl, pipe_name, line_name, companyId, statid, insIds);
    window.sessionStorage.setItem('viewpictureid=risk' + current, JSON.stringify({
      pTitle: pipe_name,
      title: line_name,
      companyId: companyId,
      statid: statid,
      insIds: insIds
    }))
    window.sessionStorage.setItem('patrol_map?id=risk' + current, JSON.stringify(coordinate))
    window.open(basicPath + '/patrol_map?id=risk' + current, '_blank')
  }

  //在函数组件中，组件加载完成后执行
  useEffect(() => {
    getData();
    getUser();
    // eslint-disable-next-line
  }, [])

  return (
    <Card style={{ background: 'black', overflowY: 'auto' }} bodyStyle={{ padding: 0 }}>
      <div className="flex_col">
        <div className="disFlex flexDirCol">
          <div className='flex-row aliItemsCen paddRL24' style={{ width: '100% ', height: '72px' }}>
            <span className='curPointer' onClick={() => { navigate(-1) }}><ArrowLeftOutlined /> </span>
            <span className='fSize20 fWei500 margR12 margL18'>{(is_handle ? '风险待处理 - ' : '风险已处理 - ') + pipe_name + ' - ' + line_name}</span>
            <span className='flex-auto'></span>
            <span style={{ display: (is_handle) ? 'block' : 'none' }}>
              <img alt='沙漏图标' src={hourglass} style={{ width: '20px', height: '20px', color: '#FFF', marginRight: '4px' }}></img>
              <Badge className="site-badge-count-109 fSize12" count={len ? len : 0}
                style={{
                  backgroundColor: '#FF4D4F',
                  color: '#FFFFFF',
                }}
              />
            </span>
            <span style={{ display: (is_handle) ? 'none' : 'block' }}>
              <Badge className="site-badge-count-109 fSize12" count={len ? (current + 1) + '/' + len : 0}
                style={{
                  backgroundColor: '#FF4D4F',
                  color: '#FFFFFF',
                  boxShadow: '0 0 0 1px #000',
                }}
              />
            </span>
          </div>
        </div>
        <div className="paddRL24 paddTB20 backcol">
          <Spin tip="Loading..." spinning={loading}>
            <div className="flex-row">
              <div className='margR10 flex-auto flex-col'>
                <div style={{ position: 'relative' }}>
                  <AntImage
                    width={200}
                    style={{
                      display: 'none',
                    }}
                    src={picUrl}
                    preview={{
                      visible,
                      src: picUrl,
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />
                  <img alt='风险' width='100%' className='curPointer' src={picUrl} onClick={() => {
                    setVisible(true)
                  }} />

                  {/* 甲烷读数 */}
                  <div style={{ display: methane_con ? '' : 'none', position: 'absolute', top: '44px', right: '20px', background: 'rgba(211, 32, 41, 0.8)', borderRadius: '8px', padding: '4px 12px' }}>
                    <span style={{ fontWeight: '500', fontSize: '20px' }}>{`CH4:${methane_con}ppm·m`}</span>
                  </div>
                </div>

                <div style={{ minHeight: '20px' }}></div>
              </div>
              <div className='' style={{ minWidth: '240px', maxWidth: '240px' }} >
                <div className='paddRL24 paddTB16 backcol' style={{ borderBottom: '1px solid #303030' }}>
                  <div className='fSize14 fWei500 coL1890FF curPointer'
                    onClick={() => {
                      onPatrolMap()
                    }}>
                    {address}
                  </div>
                  {/* <div className='fSize14 fWei400 opacity0_45'>
                    {point_order}
                  </div> */}
                  <div className='fSize14 fWei400 opacity0_45'>
                    {addtime}
                  </div>
                </div>
                <div className='padd_24 backcol' style={{ borderBottom: '1px solid #303030' }}>
                  <div className='fSize14 fWei400 margB8 flex-row'>
                    <div style={{ minWidth: '70px' }}>
                      风险初判：
                    </div>
                    <div className='flex-col'>
                      {divrisk}
                    </div>
                  </div>
                  <div className='fSize14 fWei400 margB8 flex-row'>
                    <div style={{ minWidth: '70px' }}>
                      处理人员：
                    </div>
                    <div className={'flex-row flex-auto ' + (user ? ' jusConCenSB' : '')}>
                      <span style={{ display: (user) ? 'block' : 'none' }} >{user}</span>
                      <span style={{ display: ((point_state === 1 || point_state === 0) && !user_id) ? ' block' : 'none' }}
                        className='curPointer'
                        onClick={() => {
                          SetShowModel(true);
                          setTimeout(() => {
                            document.getElementsByClassName('ant-modal-content')[0].style.backgroundColor = "#1F1F1F";
                            document.getElementsByClassName('ant-modal-header')[0].style.backgroundColor = "#1F1F1F";
                            document.getElementsByClassName('ant-modal-title')[0].style.color = "#FFFFFFD9";
                            document.getElementsByClassName('ant-modal-header')[0].style.borderBottom = "1px solid #303030";
                            document.getElementsByClassName('ant-modal-close-x')[0].style.color = "#FFFFFFD9";
                            document.getElementsByClassName('ant-modal-footer')[0].style.borderTop = "1px solid #303030";
                          }, 50)
                        }}>
                        <UserAddOutlined style={{ color: '#1890FF', width: '13px', height: '15px' }} />
                      </span>
                      <span style={{ display: ((point_state === 1 || point_state === 0) && user_id && advice.length < 1) ? 'block' : 'none' }} className='curPointer'
                        onClick={() => {
                          SetShowModel(true);
                        }}>
                        <UserSwitchOutlined style={{ color: '#1890FF', width: '13px', height: '15px' }} />
                      </span>
                    </div>
                  </div>
                  <div className='fSize14 fWei400 margB8 flex-row'>
                    <div style={{ minWidth: '70px' }}>
                      处理意见：
                    </div>
                    <div className='flex-col autoY' style={{ maxHeight: '600px', color: advice_div && advice_div !== '-' ? `` : `rgba(255, 255, 255,0.45)` }}>
                      {advice_div && advice_div !== '-' ? advice_div : '-'}
                    </div>
                  </div>
                  {/* <div className='fSize14 fWei400 tt_a_cen backColor1890FF coLFFF curPointer borRad2'
                    style={{ width: '60px', height: '32px', lineHeight: '32px', }}
                    onClick={() => {
                      IgnorePoint((cur) => {

                        initializeData(cur);
                      });
                    }}
                  >
                    忽略
                  </div> */}
                  <Button type="primary" style={{ display: ((point_state === 1 || point_state === 0) && user === '') ? 'block' : 'none' }} onClick={() => {
                    IgnorePoint((cur) => {

                      initializeData(cur);
                    });
                  }}>忽 略</Button>
                </div>
                <div className='paddTB10 paddRL16 flex-row jusConCenSB backcol'>
                  <div className='fSize14 fWei400 coL1890FF bor1890FF tt_a_cen curPointer borRad2'
                    style={{ width: '74px', height: '32px', lineHeight: '32px', display: (current !== 0) ? 'block' : 'none' }}
                    onClick={() => {
                      let num = current - 1;
                      SetCurrent(num);
                      SetPointState('');
                      initializeData(num);
                    }}
                  >
                    上一张
                  </div>
                  <div className='fSize14 fWei400 tt_a_cen borRad2 coLF0_3'
                    style={{ width: '74px', height: '32px', lineHeight: '32px', border: '1px solid #434343', display: (current === 0) ? 'block' : 'none' }}>
                    上一张
                  </div>
                  <div className='fSize14 fWei400 coL1890FF bor1890FF tt_a_cen curPointer borRad2'
                    style={{ width: '74px', height: '32px', lineHeight: '32px', display: (current !== list.length - 1) ? 'block' : 'none' }}
                    onClick={() => {
                      let num = current + 1;
                      SetCurrent(num);
                      SetPointState('')
                      initializeData(num);
                    }}
                  >
                    下一张
                  </div>
                  <div className='fSize14 fWei400 tt_a_cen borRad2 coLF0_3'
                    style={{ width: '74px', height: '32px', lineHeight: '32px', border: '1px solid #434343', display: (current === list.length - 1) ? 'block' : 'none' }}>
                    下一张
                  </div>
                </div>
              </div>
            </div>


          </Spin>
        </div>

        <Modal title="处理人员" open={is_show_model} maskClosable={false} okText="确定" cancelText="取消"
          onOk={() => {
            if (choose_user === '') {
              message.error('请选择处理人员后再点击确定按钮');
            } else {
              let data = user_data[choose_user];
              //console.log(data);
              SetUser(data.realname);
              SetUserId(data.id);
              AllocationUserFun(data.id, (cur) => {
                initializeData(cur);
              });
            }
          }}
          onCancel={() => {
            SetShowModel(false)
          }}
        >
          <Table
            columns={user_cil}
            dataSource={user_data}
            pagination={false}
            rowSelection={rowSelection}
          />
        </Modal>
        <canvas id="posiToShape" width="960" height="720" style={{ display: 'none' }} ></canvas>
      </div>
    </Card>
  );
}
export default LeakHandle