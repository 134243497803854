import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { basicPath } from '@/router/config'
import HeadHelmet from '@/common/js/HeadHelmet.js'
// import 'dayjs/locale/zh-cn';
import '@/common/css/baseCss.css' // 全局样式
import '@/common/css/common.css' // 全局样式
import '@/common/css/theme.css' // 全局样式

//theme.defaultAlgorithm 明亮主题
//theme.darkAlgorithm 暗黑主题

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={basicPath}>
    <React.Suspense>
      <HeadHelmet />
      <App />
    </React.Suspense>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

