// 常规风险处理

import React, { useState, useEffect, useRef } from 'react'
import { Modal, Drawer, Button, Table, message, Tag, Image as AntImage, Badge, Card, Spin, Tree, Space, Input, Form, Upload } from 'antd';
import { UserAddOutlined, UserSwitchOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'
import { GetPoint, GetPointInfo, GetPointNew, Ignore, AllocationUser, uploadImageFun, stageFillFun } from "@/services/conventional_risks"; // 引入axios
import { getHandlerListFun } from "@/services/risk_handlers.js"; // 引入axios
import local from '@/utils/local'
import hourglass from "@/common/img/abnormal_troubleshooting/hourglass.svg";
import { basicPath } from '@/router/config.js'
import { async } from 'ailabel';
import { transformGCJ2WGS } from "@/common/js/common.js" //引入公共方法

function ConventionalRisksHandle() {
  const { TextArea } = Input;

  const navigate = useNavigate();
  //获取路由传值
  const Stateparams = useLocation();
  const params = Stateparams.state;
  const statid = params.statid;
  const insIds = params.insIds;
  const is_handle = params.is_handle;//是否处理风险
  const routes_type = params.list ? params.list.routes_type : '';
  const abnormal_type = params.abnormal_type || '';

  const token = local.get('cruise_m30_token');
  const companyId = local.get('cruise_m30_companyId');

  const [spanHeight, setSpanHeight] = useState(0);
  const [loading, setLoading] = useState(false);//加载
  const [allData, setAllData] = useState([]);//所有数据
  const [curNum, setCurNum] = useState(1);//当前风险
  const [curPipeName, setCurPipeName] = useState('');//当前 管线名称
  const [curLineName, setCurLineName] = useState('');//当前 单元段名称
  const [curPointOrder, setCurPointOrder] = useState('');//当前 风险序号
  const [lineInfo, setLineInfo] = useState({ //航线信息
    id: '',
    longitude: '', //经度
    latitude: '', //纬度
    picUrl: '',//图片地址
    address: '',//地址
    date: '',//日期
    pointOrder: '',//图片序号
    divrisk: [],//风险初判
    userName: [],//处理人员
    userIds: [],//处理人员Id
    advice: [],//处理意见
    pointState: '',//风险状态
  });
  const [showImgInfo, setShowImgInfo] = useState({ //显示图片信息
    visible: false,
    current: 1,
    url1: '',
    url2: '',
    url3: '',
  });

  const [drawerOpen, setDrawerOpen] = useState(false);//
  const [drawerLoading, setDrawerLoading] = useState(false);

  const [handleForm] = Form.useForm();//警戒线表单
  const [drawerHandleOpen, setDrawerHandleOpen] = useState(false);//
  const [drawerHandleLoading, setDrawerHandleLoading] = useState(false);

  const [openParentKey, setOpenParentKey] = useState([]);//展开的父级
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);//选择的信息 - 为了获取名字
  const [treeData, setTreeData] = useState([]);

  const [riskStatus, setRiskStatus] = useState(0);//风险状态
  const [disable, setDisable] = useState(true);
  const [chooseFile, setChooseFile] = useState([]);//选择的文件
  const statusRef = useState(0);//提交的阶段填报状态

  //获取所有的风险
  const getAllData = async () => {
    setLoading(true);
    let param = {
      access_token: token,
      companyId: companyId,
      statid: statid,
      insIds: insIds,
      handler_status: abnormal_type ? 0 : is_handle ? 1 : 2,
      type: routes_type === 6 ? 1 : abnormal_type ? 1 : undefined,
      abnormal_type: abnormal_type
    };
    let res = await GetPointNew(param);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      setLoading(false);
      return;
    }
    let data = res.data.list.map((item, idx) => {
      return { ...item, key: idx }
    })
    setAllData(data);
    let val = data[0];
    setCurPipeName(val.pipe_name);
    setCurLineName(val.line_name);
    setCurPointOrder(val.point_order);
    getPointInfoFun(val.CompanyID, val.statid, val.pointId);
  }

  //初始化数据
  const initializeData = (num, data) => {
    setLoading(true);
    let val = '';
    if (data) {
      val = data[num - 1];
    } else {
      val = allData[num - 1];
    }
    setCurPipeName(val.pipe_name);
    setCurLineName(val.line_name);
    setCurPointOrder(val.point_order);
    getPointInfoFun(val.CompanyID, val.statid, val.pointId);
  }

  //获取节点处理详细信息
  const getPointInfoFun = async (val1, val2, val3) => {
    let paramdata = {
      access_token: token,
      companyId: val1,
      statid: val2,
      pointId: val3,
    }
    let res = await GetPointInfo(paramdata);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let val = res.data;
    let info = { ...lineInfo };
    info.id = val.pointId;
    info.longitude = Number(val.longitude);
    info.latitude = Number(val.latitude);
    info.divrisk = val.abnormal_type_str ? val.abnormal_type_str.split(",") : [];
    info.address = val.location;
    info.date = val.addtime;
    info.picUrl = val.picUrl;
    info.userName = val.handler_user_name ? val.handler_user_name.split(',') : [];
    info.pointState = Number(val.handler_status);
    info.userIds = val.handler_user.split(',');
    if (val.list.length > 0) {
      setRiskStatus(0);
      info.advice = val.list.map((item, i) => {
        let imgs = item.detail_pic ? item.detail_pic : [];
        let detailList = item.detail ? item.detail.split('\n') : [];
        if (item.status === 1) {
          setRiskStatus(1);
        }

        return (
          <div className='margB12' key={i}>
            <div className='flex_row'>
              <Tag style={{
                background: "#262626", borderRadius: 3,
                display: (item.status === 1) ? 'block' : 'none'
              }} className='marB4'>处理完毕</Tag>
            </div>
            <div className='flex_row' style={{ display: imgs.length > 0 ? '' : 'none' }}>
              <img alt='处理拍照1' src={imgs[0] ? imgs[0] : ''} className='w64 h64 marR8 pointer radius8' style={{ width: 64, objectFit: 'cover', display: imgs[0] ? '' : 'none' }}
                onClick={() => { showImg(0, item) }}>
              </img>
              <img alt='处理拍照2' src={imgs[1] ? imgs[1] : ''} className='w64 h64 marR8 pointer radius8' style={{ width: 64, objectFit: 'cover', display: imgs[1] ? '' : 'none' }}
                onClick={() => { showImg(1, item) }}>
              </img>
              <img alt='处理拍照3' src={imgs[2] ? imgs[2] : ''} className='w64 h64 pointer radius8' style={{ width: 64, objectFit: 'cover', display: imgs[2] ? '' : 'none' }}
                onClick={() => { showImg(2, item) }}>
              </img>
            </div>
            {
              detailList.map((detailItem, detailIndex) => {
                return <div key={detailIndex} style={{ wordBreak: 'break-word' }}>{detailItem}</div>
              })
            }
            <div className='col_secondary_text'>{item.addtime + ' ' + item.realname}</div>
          </div>
        )
      });
    } else {
      if (info.pointState === 3) {
        info.advice = [<div className='margB12' key={1}>忽略</div>];
      } else if (info.pointState === 2) {
        info.advice = [<div className='margB12' key={1}>已处理</div>];
      } else {
        info.advice = [<div className='margB12 opa25' key={1}>-</div>];
      }
    }
    setLineInfo(info);
    let img = new Image();
    img.src = val.picUrl;
    img.onload = () => {
      setLoading(false);
    }
  }

  //查看地图
  const onPatrolMap = () => {
    let coordinate = {
      picUrl: lineInfo.picUrl,
      longitude: lineInfo.longitude, //经度
      latitude: lineInfo.latitude, //纬度
    }
    window.sessionStorage.setItem('viewpictureid=risk' + lineInfo.id, JSON.stringify({
      pTitle: curPipeName,
      title: curLineName,
      companyId: companyId,
      statid: statid,
      insIds: insIds
    }))

    let d = transformGCJ2WGS({ lat: coordinate.latitude, lon: coordinate.longitude });
    coordinate.latitude = Number(parseFloat(d.lat).toFixed(7));
    coordinate.longitude = Number(parseFloat(d.lon).toFixed(7));

    window.sessionStorage.setItem('patrol_map?id=risk' + lineInfo.id, JSON.stringify(coordinate))
    console.log('风险：', coordinate);
    window.open(basicPath + '/patrol_map?id=risk' + lineInfo.id, '_blank')
  }

  //显示图片
  const showImg = (cur, data = '') => {
    let info = { ...showImgInfo };
    info.visible = true;
    if (data) { //多个图片
      info.current = cur;
      let imgs = data.detail_pic ? data.detail_pic : [];
      info.url1 = imgs[0] ? imgs[0] : '';
      info.url2 = imgs[1] ? imgs[1] : '';
      info.url3 = imgs[2] ? imgs[2] : '';

    } else { //单个图片
      info.url1 = cur;
      info.url2 = '';
      info.url3 = '';
      info.current = 0;
    }
    setShowImgInfo(info);
  }

  //忽略
  const IgnorePoint = async (c) => {
    let param = {
      access_token: token,
      companyId: companyId,
      statid: statid,
      pointId: lineInfo.id,
    }
    let res = await Ignore(param);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let arr = [...allData];
    let cur = curNum;
    if (arr.length === 1) {
      message.success('风险处理完成，即将返回');
      setTimeout(() => {
        navigate('/conventional_risks', { state: { list: params.transfer } })
      }, 2000)
      return;
    }
    arr.splice(cur - 1, 1);
    if (cur === allData.length) {
      cur = cur - 1;
      setCurNum(cur);
    }
    setAllData(arr);
    initializeData(cur, arr);
    message.success('节点已忽略');
  }

  //获取 处理人员
  const getHandlerList = async () => {
    let params = {
      access_token: token,
      CompanyID: companyId,
    };
    setDrawerLoading(true);
    let res = await getHandlerListFun(params);
    setDrawerLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }

    let userIds = lineInfo.userIds;
    let arr = [];
    let keys = [];
    let parent = [];
    for (let i = 0; i < res.data.list.length; i++) { //处理组队的人员
      let val = res.data.list[i];
      parent.push('zd-' + i);
      let obj = {
        title: val.groupname,
        key: 'zd-' + i,
        // eslint-disable-next-line
        children: val.list.map((item, idx) => {
          let text = 'zd-' + i + ':' + item.id;
          if (userIds.indexOf(item.id) >= 0) {
            keys.push(text);
          }
          return {
            title: item.realname,
            key: text,
          }
        })
      };
      arr.push(obj);
    }

    for (let n = 0; n < res.data.other.length; n++) { //处理组队的人员
      let val = res.data.other[n];
      let text = val.id.toString();
      if (userIds.indexOf(text) >= 0) {
        keys.push(text);
      }
      let obj = {
        title: val.realname,
        key: text,
      };
      arr.push(obj);
    }
    keys = Array.from(new Set(keys));
    setSelectedKeys(keys);
    setTreeData(arr);
    setOpenParentKey(parent);
  }

  //阶段填报
  const stageFill = async (info) => {
    let files = chooseFile.length > 0 ? chooseFile.map((item) => {
      return item.response
    }) : [];
    let params = {
      access_token: token,
      pointId: lineInfo.id,
      status: statusRef.current,
    }
    if (statusRef.current === 1) { //处理完毕
      if (handleForm.getFieldValue('situation')) { //处理情况存在
        let params2 = {
          access_token: token,
          pointId: lineInfo.id,
          status: 0,
        }
        params2.detail = handleForm.getFieldValue('situation');
        params2.pics = files.join(',');
        setDrawerHandleLoading(true);
        let res2 = await stageFillFun(params2);
        if (res2.ret !== 200) {
          message.warning(res2.msg || '数据跑丢了~');
          setDrawerHandleLoading(false);
          return;
        }
      }
    }
    if (statusRef.current === 0) {
      params.detail = info.situation;
      params.pics = files.join(',');
    }
    setDrawerHandleLoading(true);
    let res = await stageFillFun(params);
    setDrawerHandleLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    closeDrawerHandle();
    getPointInfoFun(companyId, statid, lineInfo.id);
  }

  //关闭抽屉
  const closeDrawer = () => {
    setDisable(true);
    setDrawerOpen(false);
    setSelectedKeys([]);
    setTreeData([]);
    setSelectedData([]);
  }

  //关闭处理填报抽屉
  const closeDrawerHandle = () => {
    handleForm.resetFields();
    setChooseFile([]);
    setDrawerHandleOpen(false);
  }

  //提交分配人员
  const submitAllocation = async () => {
    let ids = [];
    let names = [];
    if (selectedData.length > 0) {
      for (let i = 0; i < selectedData.length; i++) {
        let val = selectedData[i];
        if (val.key.indexOf('zd') < 0 || val.key.indexOf(':') >= 0) {
          if (val.key.indexOf('zd') < 0) { //没有分组的 人员
            ids.push(val.key);
          } else { //分组人员
            ids.push(val.key.split(':')[1]);
          }
          names.push(val.title);
        }
      }
    } else {
      for (let i = 0; i < selectedKeys.length; i++) {
        let val = selectedKeys[i];
        if (val.indexOf('zd') < 0 || val.indexOf(':') >= 0) {
          if (val.indexOf('zd') < 0) { //没有分组的 人员
            ids.push(val);
          } else { //分组人员
            ids.push(val.split(':')[1]);
          }
        }
      }
    }

    ids = Array.from(new Set(ids));
    names = Array.from(new Set(names));

    let param = {
      access_token: token,
      companyId: companyId,
      pointId: lineInfo.id,
      handler_user: ids.join(','),
    }
    setDrawerLoading(true);
    let res = await AllocationUser(param);
    setDrawerLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    message.success(res.msg || '分配人员成功');
    let info = { ...lineInfo };
    info.userName = names;
    info.userIds = ids.join(',');
    setLineInfo(info);
    closeDrawer();

  }

  //在函数组件中，组件加载完成后执行
  useEffect(() => {
    getAllData();
    setSpanHeight(document.getElementById('xxx').clientHeight);
    // eslint-disable-next-line
  }, [])

  return (
    <Card id='xxx' style={{ background: 'black', height: '100%', boxSizing: 'border-box', overflowY: 'hidden' }}
      bodyStyle={{
        padding: 0, height: '100%', display: 'flex', flexDirection: 'column'
      }}>
      {/* 标题 */}
      <div className='marTB16 flex_row align_center flex_shrink0 pdRL24' >
        <span className='curPointer marR16'
          onClick={() => {
            navigate('/conventional_risks', { state: { list: params.transfer } })
          }}
        >
          <ArrowLeftOutlined />
        </span>
        <span className='fSize20 fWei500 margR12 margL18'>{(is_handle ? '风险待处理 - ' : '风险已处理 - ') + curPipeName + ' - ' + curLineName}</span>
        <div className='flex_auto'></div>
        <Badge count={curNum + '/' + allData.length} />
      </div>

      {/* 内容 */}
      <div className='flex_row flex_auto pdRL24' style={{ overflowY: 'auto' }}>
        {/* 图片 */}
        <div className='flex_auto marR12 hei100'>
          {
            lineInfo.picUrl &&
            <img alt='风险图片' width='100%' height='100%' style={{ objectFit: 'contain', }} className='curPointer' src={lineInfo.picUrl} onClick={() => { showImg(lineInfo.picUrl) }} />
          }
        </div>

        {/* 信息 */}
        <div className='flex_col w240 flex-shrink0 col_block_bg'>
          <div className='pd16'>
            <div className='col_primary fw500 pointer' onClick={() => { onPatrolMap() }}>{lineInfo.address}</div>
            <div className='col_secondary_text'>{curPointOrder}</div>
            <div className='col_secondary_text'>{lineInfo.date}</div>
          </div>

          <div className='pdRL16 pdTB10 flex justify_between col_divider_w_top'>
            <Button type="primary" ghost disabled={curNum - 1 === 0} onClick={() => {
              let cur = curNum - 1;
              setCurNum(cur);
              initializeData(cur);
            }}>
              上一张
            </Button>
            <Button type="primary" ghost disabled={curNum === allData.length} onClick={() => {
              let cur = curNum + 1;
              setCurNum(cur);
              initializeData(cur);
            }}>
              下一张
            </Button>
          </div>

          {/* 风险初判 */}
          <div className='flex_col pd16 col_divider_w_top' style={{ overflowY: 'auto' }}>
            <div className='marB12 col_divider_w_btm'>
              <div className='col_secondary_text'> 风险初判 </div>
              <div className='flex_row flex_wrap marB4'>
                {
                  lineInfo.divrisk.length > 0 ? lineInfo.divrisk.map((item, idx) => {
                    return <Tag key={idx} color="error" className='marR8 marB8'>{item}</Tag>
                  }) : <div className='opa25 marB8'>-</div>
                }
              </div>
            </div>

            {/* 处理人员 */}
            <div className='marB12 col_divider_w_btm'>
              <div className='flex_row'>
                <div className='col_secondary_text marR8'>处理人员</div>
                <div className='pointer col_primary' onClick={() => {
                  setDrawerOpen(true);
                  getHandlerList();
                }}>
                  <UserSwitchOutlined style={{ display: is_handle && (lineInfo.pointState === 1 || lineInfo.pointState === 0) && lineInfo.userName.length > 0 ? '' : 'none' }} />
                  <UserAddOutlined style={{ display: is_handle && (lineInfo.pointState === 1 || lineInfo.pointState === 0) && lineInfo.userName.length === 0 ? '' : 'none' }} />
                </div>
              </div>
              <div className='flex_row flex_wrap marB12'>
                {
                  lineInfo.userName.length > 0 ?
                    <div>{lineInfo.userName.join('、')}</div>
                    : <div className='opa25 marB8'>-</div>
                }
              </div>
            </div>

            {/* 处理意见 */}
            <div>
              <div className='col_secondary_text marB12'> 处理意见 </div>
              <div className='flex_col'>
                {lineInfo.advice}
              </div>
            </div>


            <div className='flex_row'>
              {/* 忽略 */}
              <Button type="primary" className='marR12' style={{ display: is_handle && ((lineInfo.pointState === 1 || lineInfo.pointState === 0) && lineInfo.userName.length === 0) ? 'block' : 'none' }}
                onClick={() => {
                  IgnorePoint();
                }}>忽略</Button>

              <Button style={{ display: is_handle && riskStatus !== 1 ? 'block' : 'none' }} type="primary" ghost onClick={() => {
                setDrawerHandleOpen(true);
              }}>阶段填报</Button>
            </div>
          </div>

          <div className='flex_auto' style={{ background: 'black' }}></div>
        </div>
      </div>

      <div className='flex_shrink0' style={{ height: 24 }}></div>

      {/* 加载蒙层 */}
      <div className='flex justify_center align_center pos_abs'
        style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.5)', top: 0, left: 0, display: loading ? '' : 'none' }}>
        <Spin />
      </div>

      {/* 图片放大 */}
      <AntImage.PreviewGroup
        preview={{
          visible: showImgInfo.visible,
          current: showImgInfo.current,
          onVisibleChange: (value) => {
            let info = { ...showImgInfo };
            info.visible = false;
            info.url1 = '';
            info.url2 = '';
            info.url3 = '';
            info.current = 0;
            setShowImgInfo(info);
          },
        }}
      >
        {showImgInfo.url1 && <AntImage style={{ display: 'none', }} src={showImgInfo.url1} />}
        {showImgInfo.url2 && <AntImage style={{ display: 'none', }} src={showImgInfo.url2} />}
        {showImgInfo.url3 && <AntImage style={{ display: 'none', }} src={showImgInfo.url3} />}
      </AntImage.PreviewGroup>

      {/* 分配人员 */}
      <Drawer title="处理人员" placement="right"
        onClose={closeDrawer} open={drawerOpen} destroyOnClose={true}
        extra={
          <Space>
            <Button onClick={submitAllocation} type="primary" disabled={disable}>
              提交
            </Button>
          </Space>
        }
      >
        <Spin spinning={drawerLoading}>
          <Tree
            checkable
            expandedKeys={openParentKey}
            onExpand={(e) => {
              setOpenParentKey(e);
            }}
            onCheck={(e, e2) => {
              setSelectedKeys(e);
              setSelectedData([...e2.checkedNodes]);
              setDisable(false);
            }}
            checkedKeys={selectedKeys}
            treeData={treeData}
          />
        </Spin>
      </Drawer>

      {/* 处理填报 */}
      <Drawer title="处理填报" width={280} placement="right"
        onClose={closeDrawerHandle} open={drawerHandleOpen} destroyOnClose={true}
        extra={
          <Space>
            <Button onClick={() => {
              statusRef.current = 1;
              stageFill({})
            }} type="primary" >
              处理完毕
            </Button>
          </Space>
        }
      >
        <Spin spinning={drawerHandleLoading}>
          <Form
            form={handleForm}
            layout="vertical"
            onFinish={(e) => {
              stageFill(e)
            }}
          >
            <Form.Item
              label="处理情况"
              name="situation"
              rules={[
                { required: true, message: '请输入处理情况', },
              ]}
            >
              <TextArea placeholder="-" />
            </Form.Item>

            <Form.Item label="添加图片" valuePropName="fileList">
              <Upload
                data={{ pointId: lineInfo.id }} customRequest={async (e) => {
                  console.log(e);
                  let res = await uploadImageFun({
                    access_token: token,
                    pointId: lineInfo.id,
                    file: e.file
                  });
                  if (res.ret !== 200) {
                    message.warning(res.msg || '数据跑丢了~');
                    return;
                  }
                  return e.onSuccess(res.data)
                }} listType="picture-card" maxCount={3}
                onChange={(e) => {
                  setChooseFile(e.fileList);
                }}
              >
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button type="primary" ghost onClick={() => {
                statusRef.current = 0;
                handleForm.submit();
              }}>
                阶段填报
              </Button>
            </Form.Item>
          </Form>

        </Spin>
      </Drawer>

    </Card >
  );
}
export default ConventionalRisksHandle