// 巡检员相关api
import req from './index'

//巡检员列表
export const GetUserList = (params) => {
  return req.get('api/admin.php?s=Admin.User.AviatorUserList_Get', params)
}

//巡检员更新或添加
export const UserInfo_Set = (params) => {
  return req.post('api/admin.php?s=Admin.User.AviatorUserInfo_Set', params)
}

//获取用户分配管线权限
export const User_Pipeline = (params) => {
  return req.get('api/admin.php?s=Admin.User.AviatorUserAuth_Info', params)
}

//用户详情
export const GetUserInfo = (params) => {
  return req.get('api/admin.php?s=Admin.User.AviatorUserInfo_Get', params)
}

//用户状态更新
export const UserInfo_SetStatus = (params) => {
  return req.post('api/admin.php?s=Admin.User.AviatorUserInfo_SetStatus', params)
}

//设置用户管线权限
export const User_Pipeline_Set = (params) => {
  return req.post('api/admin.php?s=Admin.User.AviatorUserAuth_Set', params)
}