//生成单元段
// 管线、航点编辑
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import * as XLSX from 'xlsx';
import { Input, message, Divider, Space, Modal, Layout, Button, Card } from 'antd'
import {
  SaveOutlined, UploadOutlined, BlockOutlined, PaperClipOutlined, DeleteOutlined, NodeIndexOutlined, EditOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import {
  Amap as RMap, config as AmapReactConfig, Marker as AmapMarker, LabelsLayer as AmapLabelsLayer, LabelMarker as AmapLabelMarker,
  Scale, TileLayer, PolygonEditor, Polygon, BezierCurve
} from '@amap/amap-react';
import { transformGCJ2WGS, transformWGSGCJ2, distance } from "@/common/js/common.js" //引入公共方法
import { batchAddUnitSegments, GetRoutesInfo } from '@/services/shipping_line' //接口

import mapNode from "@/common/img/shipping_line/map_node.png" //管线节点
import mapNodeCheck from "@/common/img/shipping_line/map_node_check.png" //管线节点选中
import mapStake from "@/common/img/shipping_line/map_stake.png" //标识桩
import mapStakeCheck from "@/common/img/shipping_line/map_stake_check.png" //标识桩选中

import node from "@/common/img/shipping_line/node.svg" //管线节点
import nodeDisable from "@/common/img/shipping_line/node_disable.svg" //管线节点选中
import stake from "@/common/img/shipping_line/stake.svg" //标识桩
import stakeDisable from "@/common/img/shipping_line/stake_disable.svg" //标识桩选中

import dayjs from 'dayjs';
import local from '@/utils/local'
const { Content } = Layout;
const { confirm } = Modal;

//地图key
AmapReactConfig.key = '193db6af15a86cd8d48b102ab461b002';

function ShippingLineBuildSegment () {
  const location = useLocation();//接受传值数据
  const PID = location.search.slice(1);//航线id
  const token = local.get('cruise_m30_token');
  const mapkeys = [
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97'
  ];//天地图key
  const [choose_map_key, setChooseMapKey] = useState(0);//随机 0，1，2从 mapkeys 中取一个地图key
  const mapRef = useRef();
  const [zoom, setZoom] = useState(10); //地图缩放比例
  const [centerPoint, setCenterPoint] = useState([104.07, 30.67]);//地图中心点
  const [opacity, setOpacity] = useState(true);//切换图层

  const [lineTitle, setLineTitle] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [nodeData, setNodeData] = useState([]);//管线节点
  const [nodePointData, setNodePointData] = useState([]);//管线节点坐标
  const [nodeFileName, setNodeFileName] = useState('');//上传的管线节点文件名称
  const [stakeData, setStakeData] = useState([]);//标识桩
  const [stakeFileName, setStakeFileName] = useState('');//上传的标识桩文件名称
  const [polygonPath, setPolygonPath] = useState([]);//当前 多边形路径
  const [unitSegmentData, setUnitSegmentData] = useState([]);//保存的单元段
  const [currentUnitSegment, setCurrentUnitSegment] = useState({ //当前单元段
    totalMileage: 0,//总里程
    nodeNum: 0,//节点数量
    stakeNum: 0,//标识桩数量
    nodeData: [],//选中的节点
    stakeData: [],//选中的标识桩
    nodeInfos: [],//节点信息
    stakeInfos: [],//标识桩信息
  });

  const [editMode, setEditMode] = useState(false);//是否进入编辑模式

  const [selectModal, setSelectModal] = useState({//选择  坐标系
    isOpen: false,
    type: 0,//  1：上传管线  2：上传标识桩
  });

  //初始化
  const initialize = async () => {
    let num = Math.floor(Math.random() * 3);
    setChooseMapKey(num);
    let params = {
      access_token: token,
      id: PID
    }
    let res = await GetRoutesInfo(params);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错啦!');
      return;
    }
    setLineTitle(res.data.title);
  }

  //读取excel文件数据
  const readExcel = async (file, type, name) => {
    let sel = selectModal
    sel.isOpen = false
    sel.type = 0
    setSelectModal({ ...sel })
    document.getElementById('importNodeWGS84').value = '';
    document.getElementById('importNodeGCJ').value = '';
    document.getElementById('importStakeWGS84').value = '';
    document.getElementById('importStakeGCJ').value = '';

    let types = file.name.split('.');
    if (types[types.length - 1] !== 'xlsx' && types[types.length - 1] !== 'xls') {
      message.warning('请上传后缀名为 .xlsx、.xls 的文件');
      return;
    }
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file)//读取这个文件
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        // 以二进制流方式读取得到整份excel表格对象
        const workbook = XLSX.read(result, { type: 'binary' });
        // 存储获取到的数据
        let filedata = [];
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          // esline-disable-next-line
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            filedata = filedata.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            // break; // 如果只取第一张表，就取消注释这行
          }
        }
        let data = [];
        if (filedata.length > 0) {
          let keys = [];
          for (let key in filedata[0]) {
            keys.push(key);
          }
          if (keys.indexOf('序号') === -1 || keys.indexOf('经度') === -1 || keys.indexOf('纬度') === -1) {
            message.warning('文件 “表头名称” 不符合规范 或 数据不完整，请对照操作手册进行修改');
            return;
          }
        }
        for (let i = 0; i < filedata.length; i++) {
          let val = filedata[i];
          if (!val['序号'] || !val['经度'] || !val['纬度']) {
            message.warning('第' + (i + 2) + '行数据不完整，请修改后再上传');
            return;
          }
          let lon_lat = [Number(val['经度']), Number(val['纬度'])]
          let toWGS84ByGCJ = [Number(transformGCJ2WGS({ lat: lon_lat[1], lon: lon_lat[0] }).lon.toFixed(6)), Number(transformGCJ2WGS({ lat: lon_lat[1], lon: lon_lat[0] }).lat.toFixed(6))]
          data.push({
            idx: i,
            number: Number(val['序号']),
            name: val['名称'] ? val['名称'] : Number(val['序号']),
            point: name === 'GCJ' ? toWGS84ByGCJ : lon_lat,
            longitude: name === 'GCJ' ? toWGS84ByGCJ[0] : lon_lat[0],
            latitude: name === 'GCJ' ? toWGS84ByGCJ[1] : lon_lat[1],
            isCheck: false
          })
        }
        if (data.length > 0) { setCenterPoint(data[0].point) }
        if (type === 'node') {
          setNodeData(data);
          let point = data.map((val) => { return val.point });
          setNodePointData(point);
          setNodeFileName(file.name);
        }
        if (type === 'stake') {
          setStakeData(data);
          setStakeFileName(file.name);
        }
        message.success('文件解析成功!');
        setUnitSegmentData([]);//清空单元段
      } catch (e) {
        // 这里可以抛出文件类型错误不正确的相关提示
        message.error('文件解析失败!');
      }
    };
  }

  //获取多边形 path
  const path2Array = (path) => {
    if (!Array.isArray(path)) return [];
    const dims = arrayDims(path);
    if (dims.length === 1) {
      return path.map(lngLat2Array);
    } else if (dims.length === 2) {
      return path.map(lngLat2Array);
    } else if (dims.length === 3) {
      return path.map((subpath) => subpath.map(lngLat2Array));
    }
    return [];
  }
  const lngLat2Array = (p) => {
    if (!p) return [];
    if (Array.isArray(p)) return [p[0], p[1]];
    return [p.lng, p.lat];
  }
  const arrayDims = (arr) => {
    const dims = [];
    while (Array.isArray(arr)) {
      dims.push(arr.length);
      arr = arr[0];
    }
    return dims;
  }
  //----------------------------------------

  //筛选多边形内的管线节点以及标识桩
  const judgment = (path) => {
    let total = 0;//单元段 管线总长度 单位 m
    let node = [...nodeData]; //所有的管线节点
    let chooseNode = []; //选中的管线节点
    let chooseNodeInfo = []; //选中的管线节点信息
    let stake = [...stakeData]; //所有的标识桩
    let chooseStake = []; //选中的标识桩
    let chooseStakeInfo = [];//选中的标识桩信息

    if (node.length > 0) { //管线节点
      for (let i = 0; i < node.length; i++) {
        let val = node[i];
        let isPointInRing = AMap.GeometryUtil.isPointInRing(val.point, path);
        if (isPointInRing) {
          val.isCheck = true;
          chooseNode.push(val.point);
          chooseNodeInfo.push(val);
        } else {
          val.isCheck = false;
        }
      }
      if (chooseNode.length > 1) {
        for (let i = 0; i < chooseNode.length - 1; i++) {
          let dis = AMap.GeometryUtil.distance(chooseNode[i], chooseNode[i + 1]);
          total = total + dis;
        }
      }
      setNodeData(node);
    }

    if (stake.length > 0) { //标识桩
      for (let i = 0; i < stake.length; i++) {
        let val = stake[i];
        let isPointInRing = AMap.GeometryUtil.isPointInRing(val.point, path);
        if (isPointInRing) {
          val.isCheck = true;
          chooseStake.push(val.point);
          chooseStakeInfo.push(val);
        } else {
          val.isCheck = false;
        }
      }
      setStakeData(stake);
    }

    let segment = { ...currentUnitSegment };
    segment.totalMileage = parseInt(total);
    segment.nodeNum = chooseNode.length;
    segment.stakeNum = chooseStake.length;
    segment.nodeData = chooseNode;
    segment.stakeData = chooseStake;
    segment.nodeInfos = chooseNodeInfo;
    segment.stakeInfos = chooseStakeInfo;
    setCurrentUnitSegment(segment);

  }

  //取消编辑
  const cancelEdit = () => {
    setEditMode(false);
    setPolygonPath([]);
    let curr = { ...currentUnitSegment };
    curr.totalMileage = 0;
    curr.nodeNum = 0;
    curr.stakeNum = 0;
    curr.nodeData = [];
    setCurrentUnitSegment(curr);
    let node = nodeData.map((val) => {
      val.isCheck = false;
      return val;
    });
    let stake = stakeData.map((val) => {
      val.isCheck = false;
      return val;
    });
    setNodeData(node);
    setStakeData(stake);

  }

  //保存 当前 单元段
  const saveUnitSegment = () => {
    let curr = { ...currentUnitSegment };//当前单元段信息
    let list = [...unitSegmentData];//保存的所有单元段
    if (curr.nodeNum === 0) { //保存 - 必须要有管线节点
      message.warning('当前单元段没有管线节点，无需保存');
      return;
    }
    let obj = { ...curr };
    obj.name = null;
    list.push(obj);
    setUnitSegmentData(list);
    cancelEdit();
  }

  //删除单元段
  const delUnitSegment = (idx) => {
    let arr = [...unitSegmentData];
    arr.splice(idx, 1);
    setUnitSegmentData(arr);
  }

  //提交数据
  const submit = async () => {
    messageApi.open({
      key: 'updatable',
      type: 'loading',
      content: 'Loading...',
    });

    for (let i = 0; i < unitSegmentData.length; i++) {
      let point = [];
      let val = unitSegmentData[i];
      if (!val.name) {
        messageApi.open({
          key: 'updatable',
          type: 'warning',
          content: '第' + (i + 1) + '条单元段没有名称，请输入',
          duration: 2,
        });
        return;
      }
      for (let n = 0; n < val.nodeInfos.length; n++) {
        let valN = val.nodeInfos[n];
        let obj = {
          type: 1,
          order: valN.number,
          name: valN.name,
          longitude: valN.longitude,
          latitude: valN.latitude,
        };
        point.push(obj);
      }
      for (let s = 0; s < val.stakeInfos.length; s++) {
        let valS = val.stakeInfos[s]
        let obj = {
          type: 3,
          order: valS.number,
          name: valS.name,
          longitude: valS.longitude,
          latitude: valS.latitude,
        };
        point.push(obj);
      }
      let param = {
        access_token: token,
        Routes_data: JSON.stringify([{ RID: PID, title: val.name, length: (val.totalMileage / 1000).toFixed(2) }]),
        RoutesPoint_data: JSON.stringify(point),
        is_delete: i === 0 ? '1' : '0'
      }

      // return;
      let res = await batchAddUnitSegments(param);
      console.log(res);
      if (res.ret !== 200) {
        messageApi.open({
          key: 'updatable',
          type: 'warning',
          content: res.msg || '系统出错啦!',
          duration: 2,
        });
        return;
      }
      if (i === unitSegmentData.length - 1) {
        messageApi.open({
          key: 'updatable',
          type: 'success',
          content: '单元段保存成功',
          duration: 2,
        });
      }
    }
  }

  const getInfo = () => {
    //获取ip
    axios.get('https://api.ipify.org?format=json').then((res) => {
      console.log(res.data.ip);
    });

    //高德地图根据ip获取经纬度
    axios
      .get('https://restapi.amap.com/v3/ip?ip=221.237.119.36&key=2be68c50f5543edefcd774fe9ee6483d')
      .then((response) => {
        let arr = response.data?.rectangle ? response.data.rectangle.split(';') : [];
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }


  useEffect(() => { //页面加载完成执行
    initialize();

    //获取坐标
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude;
        let lon = position.coords.longitude;
        let wz = transformGCJ2WGS({ lat: lat, lon: lon });
        setCenterPoint([Number(parseFloat(wz.lon).toFixed(7)), Number(parseFloat(wz.lat).toFixed(7))]);
      }, () => { }, {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0,
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }

    //eslint-disable-next-line
  }, [])

  return (
    <div style={{ height: '100vh' }}>
      {contextHolder}
      {/* 地图 */}
      <RMap features={[]} zoom={zoom} ref={mapRef} center={centerPoint}
        onDragEnd={async (map) => { //地图拖动结束执行
          setCenterPoint(map.getCenter().toArray());
        }}
        onZoomChange={(r) => {
          setZoom(mapRef.current.getZoom());
        }}
      >
        {/* 天地图图层 */}
        <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=vec_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />
        <TileLayer getTileUrl={'http://t0.tianditu.gov.cn/DataServer?T=img_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} opacity={opacity} />
        <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=cia_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />

        <>{/* 管线预览  存在管线节点、没有进入编辑状态、没有保存的单元段 才显示 */}
          {
            nodePointData.length > 0 && unitSegmentData.length === 0 && !editMode ?
              <BezierCurve
                path={nodePointData}
                isOutline={true}
                outlineColor="#B8B8B8"
                borderWeight={1}
                strokeColor="#B8B8B8"
                strokeOpacity={1}
                strokeWeight={4}
                strokeStyle="solid" // 线样式还支持 'dashed'
                lineJoin='round'
                lineCap='round'
              // strokeStyle是dashed时有效
              // strokeDasharray={[4, 4]}  //0 设置线段粗细  1 设置线段粗细间隔
              // zIndex={50}
              />
              : <></>
          }
        </>

        <>{/* 保存的单元段预览 */}
          {
            unitSegmentData.length > 0 ? unitSegmentData.map((val, idx) => {
              return (
                <BezierCurve
                  key={idx}
                  path={val.nodeData}
                  isOutline={true}
                  outlineColor="#D32029"
                  borderWeight={1}
                  strokeColor="#D32029"
                  strokeOpacity={1}
                  strokeWeight={4}
                  strokeStyle="solid" // 线样式还支持 'dashed'
                  lineJoin='round'
                  lineCap='round'
                // strokeStyle是dashed时有效
                // strokeDasharray={[4, 4]}  //0 设置线段粗细  1 设置线段粗细间隔
                // zIndex={50}
                />
              );
            }) : <></>
          }
        </>

        <>
          {/* 多边形 当前单元段 选中的管线节点组成的管线 */}
          {
            currentUnitSegment.nodeData.length > 0 ?
              <BezierCurve
                path={currentUnitSegment.nodeData}
                isOutline={true}
                strokeColor="#B8B8B8"
                strokeOpacity={1}
                strokeWeight={4}
                strokeStyle="solid" // 线样式还支持 'dashed'
                lineJoin='round'
                lineCap='round'
              // strokeStyle是dashed时有效
              // strokeDasharray={[4, 4]}  //0 设置线段粗细  1 设置线段粗细间隔
              // zIndex={50}
              />
              : <></>
          }
        </>

        <>{/* 管线节点 */}
          {
            nodeData && nodeData.length > 0 &&
            <AmapLabelsLayer zooms={[0, 20]} zIndex={1000} collision={zoom > 19 ? false : true}>
              {
                nodeData.map((val, idx) => {
                  return (
                    <AmapLabelMarker key={idx} visiable={true}
                      position={val.point}
                      icon={{
                        image: val.isCheck ? mapNodeCheck : mapNode,
                        size: [22, 22],
                        anchor: [11, 11]
                      }}
                      text={{ content: val.number + '', direction: 'right', offset: [0, -2], style: { fontSize: 14, fillColor: 'white', strokeColor: 'black', strokeWidth: 5 } }}
                    >

                    </AmapLabelMarker>
                  )
                })
              }
            </AmapLabelsLayer>

          }
        </>

        <>{/* 标识桩 */}
          {
            stakeData && stakeData.length > 0 &&
            <AmapLabelsLayer zooms={[0, 20]} zIndex={1000} collision={zoom > 19 ? false : true}>
              {
                stakeData.map((val, idx) => {
                  return (
                    <AmapLabelMarker key={idx} visiable={true}
                      position={val.point}
                      icon={{
                        image: val.isCheck ? mapStakeCheck : mapStake,
                        size: [22, 22],
                        anchor: [11, 11]
                      }}
                    >

                    </AmapLabelMarker>
                  )
                })
              }
            </AmapLabelsLayer>
          }
        </>

        <> {/* 多边形 当前单元段 */}
          {
            polygonPath && polygonPath.length > 0 ? (
              <PolygonEditor
                enabled={true}
                showDir={true}
                onChange={(poly) => {
                  console.log(poly);
                  let path = path2Array(poly.getPath());
                  setPolygonPath(path);
                  judgment(path);
                  // var isPointInRing = AMap.GeometryUtil.isPointInRing([104.185008, 30.838009], path2Array(path));
                  // console.log(isPointInRing);
                }}
              >
                <Polygon
                  path={polygonPath}
                  draggable={true}
                  fillColor="#409EFF"
                  strokeColor="red"
                  fillOpacity={0.5}
                />
              </PolygonEditor>
            ) : ''}

        </>

        <Scale position={{ bottom: '24px', left: '4px' }} /> {/* 比例尺 */}
      </RMap>

      {/* 功能按钮 */}
      <div className="pos_abs flex-col" style={{ top: 0 }}>
        <div className='flex-row mar24 radius8 hidden'>
          <div className='flex jcc align_center'>
            {/* 保存 */}
            <div className='flex jcc align_center pointer'
              style={{ background: '#1F1F1F', borderRight: '1px solid rgba(255,255,255,0.1)' }}
              onClick={() => {
                if (unitSegmentData.length === 0) {
                  message.warning('没有需要保存的单元段');
                  return;
                }
                submit();
              }}
            >
              <SaveOutlined className="mar12 w16 h16" style={{ color: 'white' }} />
            </div>
            {/* 切换 */}
            <div className='flex jcc align_center pointer'
              style={{ background: '#1F1F1F', borderRight: '1px solid rgba(255,255,255,0.1)' }}
              onClick={() => {
                setOpacity(!opacity)
              }}
            >
              <BlockOutlined className="mar12 w16 h16" style={{ color: 'white' }} />
            </div>

            <> {/* 管线名称 */}
              {
                lineTitle &&
                <div className='h40' style={{ background: '#1F1F1F' }}>
                  <div className='pdTB8 pdRL12 lh24 fw600 fs16' style={{ color: 'white' }}>
                    {lineTitle}
                  </div>
                </div>
              }
            </>
          </div>

          <div className='flex-auto'></div>
        </div>
      </div>

      {/* 操作 */}
      <div className="pos_abs flex-col pd24" style={{ top: 0, right: 0, height: '100%', width: 284, background: '#1F1F1F' }}>
        <div className='marB8'>
          <Button
            className='marR8'
            disabled={editMode}
            icon={<UploadOutlined />}
            onClick={() => {
              let sel = selectModal
              if (unitSegmentData.length > 0) {//已经保存了单元段
                confirm({
                  title: '是否重新上传管线？',
                  icon: <ExclamationCircleOutlined />,
                  content: '当前保存的的单元段将被删除，请谨慎操作！',
                  okText: '重新上传',
                  cancelText: '取消',
                  onOk () {
                    sel.isOpen = true
                    sel.type = 1
                    setSelectModal({ ...sel })
                  },
                  onCancel () { },
                });
                return;
              }
              sel.isOpen = true
              sel.type = 1
              setSelectModal({ ...sel })
            }}
          >
            上传管线
          </Button>
          <div className='pdRL4' style={{ display: nodeFileName ? '' : 'none' }}><PaperClipOutlined className='marR8' />{nodeFileName}</div>
        </div>

        <div>
          <Button className='marR8' icon={<UploadOutlined />} disabled={editMode} onClick={() => {
            let sel = selectModal
            if (unitSegmentData.length > 0) {//已经保存了单元段
              confirm({
                title: '是否重新上传标识桩？',
                icon: <ExclamationCircleOutlined />,
                content: '当前保存的的单元段将被删除，请谨慎操作！',
                okText: '重新上传',
                cancelText: '取消',
                onOk () {
                  sel.isOpen = true
                  sel.type = 2
                  setSelectModal({ ...sel })
                },
                onCancel () { },
              });
              return;
            }
            sel.isOpen = true
            sel.type = 2
            setSelectModal({ ...sel })
          }}
          >
            上传标识桩
          </Button>
          <div className='pdRL4' style={{ display: stakeFileName ? '' : 'none' }}><PaperClipOutlined className='marR8' />{stakeFileName}</div>
        </div>

        <Divider plain></Divider>

        <div className='marB8' style={{ display: editMode ? '' : 'none' }}>
          <Button className='marR8' type="primary" icon={<SaveOutlined />} onClick={saveUnitSegment}>保存单元段</Button>
          <Button onClick={cancelEdit}>取消编辑</Button>
        </div>
        <div className='marB8' style={{ display: editMode ? 'none' : '' }}>
          <Button type="primary" icon={<EditOutlined />} onClick={() => {
            if (nodeData.length === 0) {
              message.warning('没有可编辑的管线节点');
              return;
            }
            //暂时 - 以地图中心点为坐标，创建一个正方形
            console.log(centerPoint);
            let path = [
              centerPoint,
              [centerPoint[0], (centerPoint[1] - 0.01).toFixed(2)],
              [(centerPoint[0] - 0.01).toFixed(2), (centerPoint[1] - 0.01).toFixed(2)],
              [(centerPoint[0] - 0.01).toFixed(2), centerPoint[1]]
            ];
            setPolygonPath(path);
            setEditMode(true);
          }}>选择单元段</Button>
        </div>
        <div>总里程:<span className='marL4' style={{ opacity: polygonPath.length === 0 ? '0.25' : '' }}>{polygonPath.length === 0 ? '-' : (currentUnitSegment.totalMileage / 1000).toFixed(2) + 'km'}</span></div>
        <div>管线节点数量:<span className='marL4' style={{ opacity: polygonPath.length === 0 ? '0.25' : '' }}>{polygonPath.length === 0 ? '-' : currentUnitSegment.nodeNum}</span></div>
        <div>标识桩数量:<span className='marL4' style={{ opacity: polygonPath.length === 0 ? '0.25' : '' }}>{polygonPath.length === 0 ? '-' : currentUnitSegment.stakeNum}</span></div>
        <Divider plain></Divider>
        {/* 单元段 */}
        <>
          {
            unitSegmentData.length > 0 && unitSegmentData.map((val, idx) => {
              return (
                <Card key={idx} className='marB8' bodyStyle={{ padding: 12 }}>
                  <div className='flex_row marB8'>
                    <Input className='flex_auto marR8' value={val.name} placeholder='单元段名称' onChange={(e) => {
                      console.log(e);
                      let info = { ...val };
                      info.name = e.target.value;
                      let arr = [...unitSegmentData];
                      arr[idx] = info;
                      setUnitSegmentData(arr);
                    }} />
                    <Button className='flex_shrink0' icon={<DeleteOutlined />} onClick={() => {
                      //删除单元段
                      delUnitSegment(idx);
                    }}></Button>
                  </div>
                  <div className='flex_row align_center justify_between'>
                    <div className='flex_row align_center marR8'>
                      <NodeIndexOutlined className='marR4' /><span>{(val.totalMileage / 1000).toFixed(2)}km</span>
                    </div>
                    <div className='flex_row align_center marR8'>
                      <img src={val.nodeNum ? node : nodeDisable} alt='x' className='w14 h14 marR4'></img>
                      <span style={{ opacity: val.nodeNum ? '' : '0.25' }}>{val.nodeNum ? val.nodeNum : '-'}</span>
                    </div>
                    <div className='flex_row align_center'>
                      <img src={val.stakeNum ? stake : stakeDisable} alt='x' className='w14 h14 marR4'></img>
                      <span style={{ opacity: val.stakeNum ? '' : '0.25' }}>{val.stakeNum ? val.stakeNum : '-'}</span>
                    </div>
                  </div>
                </Card>

              )
            })
          }
        </>
      </div>

      <>
        {/* 上传 WGS84 标识桩 */}
        <input id="importStakeWGS84" type="file" accept=".xls,.xlsx" hidden={true}
          onChange={(e) => {
            let file = e.target.files[0];
            readExcel(file, 'stake', 'WGS84');
          }}
        />
      </>

      <>
        {/* 上传 GCJ 标识桩 */}
        <input id="importStakeGCJ" type="file" accept=".xls,.xlsx" hidden={true}
          onChange={(e) => {
            let file = e.target.files[0];
            readExcel(file, 'stake', 'GCJ');
          }}
        />
      </>

      <>
        {/* 上传 WGS84 管线 */}
        <input id="importNodeWGS84" type="file" accept=".xls,.xlsx" hidden={true}
          onChange={async (e) => {
            let file = e.target.files[0];
            readExcel(file, 'node', 'WGS84');
          }}
        />
      </>

      <>
        {/* 上传 GCJ 管线 */}
        <input id="importNodeGCJ" type="file" accept=".xls,.xlsx" hidden={true}
          onChange={async (e) => {
            let file = e.target.files[0];
            readExcel(file, 'node', 'GCJ');
          }}
        />
      </>

      {/* 保存时 选择坐标系 */}
      <Modal title="" width={416} open={selectModal.isOpen} footer={false} closable={false} destroyOnClose={true}>
        <div className='marB8 flex_row align_center'>
          <span className='fs22 marR16 col_primary'><ExclamationCircleOutlined /></span>
          <span style={{ fontWeight: 500, fontSize: 16, color: 'rgba(255, 255, 255, 0.85)' }}>
            {`上传${selectModal.type === 1 ? '管线' : '标识桩'}`}
          </span>
        </div>
        <div className='fs14 marL38' style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.85)' }}>
          请选择要上传的坐标系
        </div>

        <div className='marT24 tr'>
          <Space>
            <Button
              onClick={() => {
                let sel = selectModal
                sel.isOpen = false
                sel.type = 0
                setSelectModal({ ...sel })
              }}
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                switch (selectModal.type) {
                  case 1:
                    document.getElementById('importNodeWGS84').click();
                    break;
                  case 2:
                    document.getElementById('importStakeWGS84').click();
                    break;

                  default:
                    break;
                }
              }}
            >
              WGS84
            </Button>

            <Button
              type="primary"
              onClick={() => {
                switch (selectModal.type) {
                  case 1:
                    document.getElementById('importNodeGCJ').click();
                    break;
                  case 2:
                    document.getElementById('importStakeGCJ').click();
                    break;

                  default:
                    break;
                }
              }}
            >
              GCJ
            </Button>

          </Space>
        </div>
      </Modal>
    </div >
  );
};

export default ShippingLineBuildSegment;