// 登录相关请求
import req from './index'

// 获取信息
export const getInfo = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.New_InsImageList_Get', params)
}

// 提交结束时间
export const getEndTime = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.Set_InspectionTime', params)
}
