//组件 - 图片显示，附带错误显示
import React, { useRef, useState, useEffect } from 'react';
import {
  LoadingOutlined
} from '@ant-design/icons';
import LoadingError from './loading_error.svg'
import Loading from './loading.svg'
import './index.css'

/**
 * @param {string} src   图片地址 
 * @param {number} width   图片宽度 
 * @param {number} height    图片高度 
 * @param {string} alt   图片加载失败提示文字 
 * @param {number} borderRadius    图片圆角 
 * @param {string} errorBackgrounds    错误背景 
 * @param {callback} deletion    img图片是否缺失 
 * @returns 
 */
const ImageCom = ({ src, width, height, alt, borderRadius, errorBackgrounds, deletion }) => {
  let imgWidth = width || '100%';
  let imgHeight = height || 200;
  let imgUrl = src || '';
  let imgLat = alt || '图片';
  let imgBorderRadius = borderRadius || 0;
  let errorBackground = errorBackgrounds || 'white';

  const [loadingError, setLoadingError] = useState(false);//图片是否加载失败 
  const [loading, setLoading] = useState(false);//图片加载
  const [start, setStart] = useState(false);

  useEffect(() => {

    getImgUrl(imgUrl)

    // eslint-disable-next-line
  }, [imgUrl])



  const getImgUrl = (url) => {
    let img = new Image();
    img.src = url;
    setLoading(true);
    img.onload = () => {
      console.log('加载中···')
      setStart(true);
      setTimeout(() => {
        deletion(false)
        setStart(false)
        setLoading(false)
        setLoadingError(false)
      }, 400)
    }
    img.onerror = () => {//图片加载失败
      setTimeout(() => {
        deletion(true)
        setLoading(false)
      }, 400);
      setLoadingError(true)
    }
  }



  return (
    <>
      {
        loading
          ? (
            <div
              className={start ? 'disappear' : ''}
              style={{
                width: imgWidth,
                height: imgHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: errorBackground,
                borderRadius: imgBorderRadius,
              }} >
              <LoadingOutlined className='col_primary' style={{ fontSize: 25 }} />
            </div>
          )
          : (
            loadingError
              ? (
                <div style={{
                  width: imgWidth, height: imgHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center',
                  alignItems: 'center', background: errorBackground, borderRadius: imgBorderRadius
                }}>
                  <img alt='图片缺失' src={LoadingError} width={40} height={40} style={{ marginBottom: 4 }} />
                  <div style={{ fontSize: 12 }}>图片缺失</div>
                </div>
              )
              : (
                <img
                  alt={imgLat}
                  src={imgUrl}
                  width={imgWidth}
                  height={imgHeight}
                  style={{ borderRadius: imgBorderRadius, objectFit: 'fill' }}
                  onError={() => {
                    //图片加载失败执行
                    setLoadingError(true);
                    setLoading(false);
                  }}
                />
              )
          )

      }
    </>
  );
};

export default ImageCom;