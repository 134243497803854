import React, { useState, useRef, useEffect } from 'react';
import { Image, } from 'antd';
import { useLocation } from 'react-router-dom'
import {
  Amap as RMap, config as AmapReactConfig, Marker as AmapMarker, Markers as AmapMarkers,
  BezierCurve,
  Scale, TileLayer
} from '@amap/amap-react';
import './map_components.less'
import noPhoto from '@/common/img/record/nodePhoto.svg'
AmapReactConfig.key = '193db6af15a86cd8d48b102ab461b002';


export default function MapComponents (transfer) {
  const mapkeys = [
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97',
  ];//天地图key
  const [choose_map_key, setChooseMapKey] = useState();
  const location = useLocation();//接受传值数据

  useEffect(() => { //页面加载完成执行
    let num = Math.floor(Math.random() * 3)
    setChooseMapKey(num);
    //eslint-disable-next-line
  }, [])


  return (
    <div style={{ height: '100vh' }}>
      <RMap
        className='pos_abs'
        style={{ height: '100%', width: '100%' }}
        features={[]}
        zoom={17}
        center={[transfer.longitude, transfer.latitude]}
      >
        {/* 天地图图层 */}
        <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=vec_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />
        <TileLayer getTileUrl={'http://t0.tianditu.gov.cn/DataServer?T=img_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} opacity={1} />
        <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=cia_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />

        <AmapMarker
          visiable={true}
          offset={[0, 0]}
          position={[transfer.longitude, transfer.latitude]}
        >
          <div style={{ position: 'relative' }}>
            {/* <div className='border w1 h1'>
            </div> */}
            <div className='punctuation pos_abs'
              style={{
                top: '-74px',
                left: '-42px',
                position: 'absolute',
              }}>
              <div className='pos_rel radius6 w86 h66'
                style={{
                  top: '-1px',
                  left: '0px',
                  border: '3px solid #fff'
                }}
              >
                <Image className='radius4 w80 h60'
                  src={transfer.picUrl ? transfer.picUrl : noPhoto}
                />
              </div>
              <div className='pos_abs' style={{
                top: '65px',
                left: '33px',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: '10px solid #fff',
              }}>

              </div>
            </div>
          </div>
        </AmapMarker>

        <Scale position={{ top: '24px', right: '24px' }} /> {/* 比例尺 */}
        {/* <Toolbar position={{ bottom: '24px', right: '20px' }} /> 按钮缩放 */}
      </RMap>

      <div style={{ position: ' absolute', height: '100%', }}>
        <div style={{
          position: 'relative',
          left: '20px',
          bottom: "55px",
        }}
        >
          <div
            style={{ backgroundColor: 'rgba(0,0,0,0.4)', borderRadius: '4px' }}
          >
            <div style={{ color: '#FFF', padding: '4px 12px', fontSize: '14px', fontWeight: '500', }}>
              <span>{transfer.longitude}</span>
              <span>{` S / `}</span>
              <span>{transfer.latitude}</span>
              <span>{` N `}</span>
            </div>
          </div>


        </div>
      </div>
    </div >
  )
}

