//高危风险 LeakageRisk
import { useState, useEffect } from 'react'
import { Table, Spin, Card, Radio, Space, Input, DatePicker, Select } from 'antd'
import HeaderBuilder from '@/components/TableBuilder/HeaderBuilder';
import SortOrFilter from '@/components/TableBuilder/SortOrFilter';
import { useNavigate } from 'react-router-dom'
import { RiskDisposeList_Get } from "@/services/leakage_risk"; // 引入Ajax
import local from '@/utils/local'
import './index.less'
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';

export default function LeakageRisk () {
  const navigate = useNavigate()
  /**
   * data
   * */
  const { RangePicker } = DatePicker;
  const [spinningLoading, setSpinningLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [List, setList] = useState();
  const [searchCriteria, setSearchCriteria] = useState({
    title: '',
    order: '',
    status: '',
    current: 1,
    pageSize: 10,
    type: 2,
    types: 1,//1:记录   2统计
    s_start_time: '',//开始-开始时间
    s_end_time: '',//开始-结束时间
    e_start_time: '',//结束-开始时间
    e_end_time: '',//结束-结束时间
    pending: 0,//待处理   0全部 1有 2无
  });

  const [countColumns, setCountColumns] = useState([]);

  /**
   * methods
  * */
  // 巡检报告
  const getList = async (sift_data) => {
    setSpinningLoading(true)
    let res = await RiskDisposeList_Get({
      access_token: local.get('cruise_m30_token') || '',
      type: '2',
      types: sift_data.types,
      title: sift_data.title,
      order: sift_data.order,
      status: sift_data.status,
      page: sift_data.current,
      perpage: sift_data.pageSize,
    });
    setSpinningLoading(false)
    //console.log(res);
    if (res.ret === 200) {// eslint-disable-line


      let type1 = res.data.sjzd.risk_type.map((item) => { return item.value });
      let type2 = res.data.sjzd.risk_type.map((item) => { return [] });

      let arr = []
      res.data?.items?.forEach((item, index) => {
        let ch = []
        let obj = {}
        if (item.c_list && item.c_list.length > 0) {
          item?.c_list.forEach((ele, idx) => {
            let list = {
              ...ele,
              numKey: `${index}_${idx}`
            }
            ch.push(list)
          })
          obj = {
            ...item,
            c_list: ch,
            numKey: index,
          }
          arr.push(obj)
        } else {
          arr.push({
            ...item,
            numKey: index,
          })
        }
      });

      if (Number(sift_data.type) === 2 && res.data.items) {
        for (let i = 1; i < res.data.items.length; i++) {//筛选
          let itemsValue = res.data.items[i].c_list;
          for (let n = 0; n < itemsValue.length; n++) {
            for (let key in itemsValue[n]) {
              let keyNum = type1.indexOf(key);
              if (keyNum >= 0 && Number(itemsValue[n][key]) > 0) {
                type2[keyNum].push(itemsValue[n].insIds);
              }
            }
          }
        }
      }



      // 统计表头拼装 ↓
      let colArr = [
        {
          title: '管线/单元名称',
          dataIndex: 'pipe_name',
          render: (text, item, index) => {
            return (
              <div>
                {
                  text
                    ? <div>{text}</div>
                    : <div className='opa25'>-</div>
                }
              </div>
            );
          },
        },
        {
          title: '巡检开始时间',
          dataIndex: 'start_time',
          render: (text, item, index) => {
            return (
              <div>
                {
                  text
                    ? <div>
                      <span className='fSize14  opacity0_85 margR8'>{text.split(' ')[0]}</span>
                      <span className='fSize14  opacity0_45'>{text.split(' ')[1]}</span>
                    </div>
                    : <div className='opa25'>-</div>
                }
              </div>
            );
          },
        },
        {
          title: '巡检结束时间',
          dataIndex: 'end_time',
          render: (text, item, index) => {
            return (
              <div>
                {
                  text
                    ? <div>
                      <span className='fSize14  opacity0_85 margR8'>{text.split(' ')[0]}</span>
                      <span className='fSize14  opacity0_45'>{text.split(' ')[1]}</span>
                    </div>
                    : <div className='opa25'>-</div>
                }
              </div>
            );
          },
        },
      ]
      let colArrLast = [
        {
          title: '忽略',
          dataIndex: 'ignore_count',
          render: (text, item, index) => {
            return (
              <div>
                {
                  text
                    ? <div>{text}</div>
                    : <div className='opa25'>-</div>
                }
              </div>
            );
          },
        },
        {
          title: '分配',
          dataIndex: 'user_count',
          render: (text, item, index) => {
            return (
              <div>
                {
                  text
                    ? <div>{text}</div>
                    : <div className='opa25'>-</div>
                }
              </div>
            );
          },
        },
      ]
      res.data.sjzd.risk_type
        &&
        res.data.sjzd.risk_type.forEach((res) => {
          // if (res.label === '天然气泄露') {
          let list = {
            title: res.label,
            dataIndex: res.value,
            render: (text, item, index) => {
              return (
                <div>
                  {
                    Number(item.id) === -1 && Number(text) > 0
                      ?
                      (
                        <div
                          // className='col_primary pointer'
                          className='pointer'
                          onClick={() => {
                            // return
                            let num = res.value.split('_')[1];
                            let val = item[res.value];
                            let typIdx = type1.indexOf(res.value);
                            // navigate('/leak_handle', {
                            //   state: {
                            //     companyId: item.CompanyID, statid: '',
                            //     insIds: type2[typIdx].join(','), is_handle: false, total: val, list: item, transfer: searchCriteria,
                            //     abnormal_type: num
                            //   }
                            // });
                          }}>
                          {text}
                        </div>
                      )
                      :
                      (
                        text
                          ? <div>{text}</div>
                          : <div className='opa25'>-</div>
                      )
                  }
                </div>
              );
            },
          }
          colArr.push(list)
          // }
        })

      colArrLast.forEach(e => {
        colArr.push(e)
      })
      console.log('colArr', colArr, arr);
      setCountColumns([...colArr])
      // 统计表头拼装 ↑

      setList([...arr] || []);
      setTotal(res.data.total || 0);
    }
  };

  // 分页、排序、筛选变化时触发
  const handleTableChange = (newPagination, filters, sorter) => {
    let searchBefore = { ...searchCriteria };
    // 分页
    searchBefore.current = newPagination.current;
    searchBefore.pageSize = newPagination.pageSize;
    _searchCriteria({ ...searchBefore })
    getList(searchBefore) //加载列表
  };

  // 记录表头
  const columns = [
    {
      title: '管线/单元名称',
      dataIndex: 'pipe_name',
    },
    {
      title: '巡检开始时间',
      dataIndex: 'start_time',
      render: (_, item, index) => {
        return (
          <div className='flex align_center' style={{ display: (item.start_time) ? 'block' : 'none' }}>
            <span className='fSize14 l opacity0_85 margR8'>{item.start_time.split(' ')[0]}</span>
            <span className='fSize14 l opacity0_45'>{item.start_time.split(' ')[1]}</span>
          </div>
        );
      },
    },
    {
      title: '巡检结束时间',
      dataIndex: 'end_time',
      render: (_, item, index) => {
        return (
          <div className='' style={{ display: (item.end_time) ? 'block' : 'none' }}>
            <span className='fSize14 opacity0_85 margR8'>{item.end_time.split(' ')[0]}</span>
            <span className='fSize14 opacity0_45'>{item.end_time.split(' ')[1]}</span>
          </div>
        );
      },
    },
    {
      title: '风险点',
      dataIndex: 'risks_count',
      render: (text, item, index) => {
        return (
          <div className='curPointer margL6'>
            {text}
          </div>
        );
      },
    },
    {
      title: '待处理',
      dataIndex: 'pending_count',
      render: (text, item, index) => {
        return (
          <div className='margL6'>
            {
              Number(text) === 0
                ? <span>{text}</span>
                : (
                  <span
                    style={{ color: 'red' }}
                    className='pointer'
                    onClick={() => {
                      navigate('/leak_handle', { state: { companyId: item.CompanyID, statid: item.id, insIds: item.insIds, is_handle: true, total: item.pending_count, list: item, transfer: searchCriteria } })
                    }}
                  >
                    {text}
                  </span>
                )
            }
          </div>
        );
      },
    },
    {
      title: '已处理',
      dataIndex: '',
      render: (_, item, index) => {
        return (
          <div>
            <div className='curPointer'>
              <div style={{ display: (item.risks_count > 0) ? 'block' : 'none' }}
                className="margL6 "
                onClick={
                  () => {
                    //如果待处理=风险点说明没有处理
                    if (item.pending_count === item.risks_count) {
                      return;
                    }
                    navigate('/leak_handle', { state: { companyId: item.CompanyID, statid: item.id, insIds: item.insIds, is_handle: false, total: item.risks_count, transfer: searchCriteria } })
                  }
                }>
                <span style={{ color: (Number(item.risks_count) - Number(item.pending_count) > 0) ? '#177ddc' : 'white' }}>
                  {Number(item.risks_count) - Number(item.pending_count)}
                </span>
              </div>
            </div>
          </div>
        );
      },
    }
  ];

  //子表格
  const columns_c = [
    {
      issearch: true,
      dataIndex: 'line_name',
    },
    {
      width: 150,
      dataIndex: '',
    },
    {
      issearch: true,
      dataIndex: 'start_time',
      width: 200,
      render: (_, item, index) => {
        return (
          <div>
            <div style={{ display: (item.start_time) ? 'block' : 'none' }}>
              <span className='fSize14 opacity0_85 margR8'>{item.start_time.split(' ')[0]}</span>
              <span className='fSize14 opacity0_45'>{item.start_time.split(' ')[1]}</span>
            </div>
            <div style={{ display: (item.end_time) ? 'none' : 'block', paddingLeft: '50px' }}>
              <span className='coL0_85' style={{ color: (item.end_time) ? 'black' : 'red' }}>~</span>
            </div>
            <div className='' style={{ display: (item.end_time) ? 'block' : 'none' }}>
              <span className='fSize14 opacity0_85 margR8'>{item.end_time.split(' ')[0]}</span>
              <span className='fSize14 opacity0_45'>{item.end_time.split(' ')[1]}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: '风险点',
      dataIndex: '',
      width: 180,
      render: (_, item, index) => {
        return (
          <div className='curPointer'>
            <div className="margL6 ">
              {item.risks_total}
            </div>
          </div>
        );
      },
    },
    {
      title: '待处理',
      dataIndex: '',
      width: 180,
      render: (_, item, index) => {
        return (
          <div>
            <div className='curPointer'>
              <div className="margL6"
                onClick={
                  () => {
                    if (item.pending_total === 0) {
                      return;
                    }
                    navigate('/leak_handle', { state: { companyId: item.CompanyID, statid: item.statid, insIds: item.insIds, is_handle: true, total: item.pending_total, list: item } })
                  }
                }>
                <span style={{ color: (item.pending_total > 0) ? 'red' : 'white' }}>{item.pending_total}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: '已处理',
      dataIndex: '',
      width: 180,
      render: (_, item, index) => {
        return (
          <div>
            <div className='curPointer'>
              <div className="margL6 "
                onClick={
                  () => {
                    //如果待处理=风险点说明没有处理
                    if (item.pending_total === item.risks_total) {
                      return;
                    }
                    navigate('/leak_handle', { state: { companyId: item.CompanyID, statid: item.statid, insIds: item.insIds, is_handle: false, total: item.risks_total, list: item } })
                  }
                }>
                <span style={{ color: (Number(item.risks_total) - Number(item.pending_total) > 0) ? '#177ddc' : 'white' }}>
                  {Number(item.risks_total) - Number(item.pending_total)}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getList(searchCriteria);
    // eslint-disable-next-line
  }, []);

  return (
    <Card style={{ background: 'black', overflowY: 'auto' }} >
      <div className="flex-col tablebackcol">

        <div className='padd_24 flex-auto backcol'>
          <div className='marB24 flex jcsb align_center'>
            <Radio.Group
              style={{ display: '' }}
              value={searchCriteria.types}
              optionType="button"
              onChange={(e) => {
                let sea = searchCriteria
                sea.types = e.target.value
                sea.current = 1
                setSearchCriteria({ ...sea })
                getList(sea)
              }}
              options={[
                {
                  label: '记录',
                  value: 1,
                },
                {
                  label: '统计',
                  value: 2,
                },
              ]}
            />



            <div>
              <Space>
                <Input
                  allowClear
                  placeholder='管线/单元名称'
                  value={searchCriteria.title}
                  onChange={(e) => {
                    let sea = searchCriteria
                    sea.title = e.target.value
                    sea.current = 1
                    setSearchCriteria({ ...sea })
                  }}
                  onKeyUp={(e) => { if (e.key === 'Enter') { e.target.blur() } }}
                  onBlur={() => {
                    getList(searchCriteria)
                  }}
                />

                <RangePicker
                  locale={locale}
                  showHour
                  clearFilters
                  value={searchCriteria.s_start_time ? [moment(searchCriteria.s_start_time, 'YYYY-MM-DD'), moment(searchCriteria.s_end_time, 'YYYY-MM-DD')] : null}
                  format="YYYY-MM-DD"
                  placeholder={['巡检开始起', '巡检开始止']}
                  onChange={(e) => {
                    let sea = searchCriteria
                    if (e) {
                      sea.s_start_time = e[0].format('YYYY-MM-DD')
                      sea.s_end_time = e[1].format('YYYY-MM-DD')
                    } else {
                      sea.s_start_time = ''
                      sea.s_end_time = ''
                    }
                    sea.current = 1
                    setSearchCriteria({ ...sea })
                    getList(sea)
                  }}
                />

                <RangePicker
                  className='marL16'
                  locale={locale}
                  showHour
                  clearFilters
                  value={searchCriteria.e_start_time ? [moment(searchCriteria.e_start_time, 'YYYY-MM-DD'), moment(searchCriteria.e_end_time, 'YYYY-MM-DD')] : null}
                  format="YYYY-MM-DD"
                  placeholder={['巡检结束起', '巡检结束止']}
                  onChange={(e) => {
                    let sea = searchCriteria
                    if (e) {
                      sea.e_start_time = e[0].format('YYYY-MM-DD')
                      sea.e_end_time = e[1].format('YYYY-MM-DD')
                    } else {
                      sea.e_start_time = ''
                      sea.e_end_time = ''
                    }
                    sea.current = 1
                    setSearchCriteria({ ...sea })
                    getList(sea)
                  }}
                />

                <Select
                  value={searchCriteria.pending}
                  style={{
                    display: searchCriteria.type === 2 ? `none` : ``,
                    width: '126px'
                  }}
                  onChange={(e) => {
                    let sea = searchCriteria
                    sea.pending = e
                    sea.current = 1
                    setSearchCriteria({ ...sea })
                    getList(sea)
                  }}
                  options={[
                    {
                      value: 0,
                      label: '待处理：全部',
                    },
                    {
                      value: 1,
                      label: '待处理：有',
                    },
                    {
                      value: 2,
                      label: '待处理：无',
                    },
                  ]}
                />
              </Space>

            </div>
          </div>
          <Spin spinning={spinningLoading}>
            <Table
              columns={searchCriteria.types === 1 ? columns : countColumns}
              dataSource={List}
              rowKey='numKey'
              expandRowByClick={true}
              childrenColumnName='c_list'
              pagination={{
                total: total,
                current: searchCriteria.current,
                pageSize: searchCriteria.pageSize,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                showQuickJumper: true,
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
            />
          </Spin>
        </div>

      </div>
    </Card>
  )
}
