// 巡检详情
// 子管线
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { PlusOutlined, ArrowLeftOutlined, GlobalOutlined, ExclamationCircleOutlined, UnlockOutlined, LockFilled } from '@ant-design/icons';
import { Button, Modal, Input, Pagination, Spin, message, InputNumber, Popconfirm } from 'antd';
import { Table } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom' //路由跳转使用 useNavigate react-router v6已经把usehistory替换成了useNavigate
import OperationBuilder from '@/components/TableBuilder/OperationBuilder';
import { GetRoutesList, GetRoutesInfo, AddRoutes, EditRoutes, ImportPoint, IdentificationStake, DelRoutes, exportWaypoints, ImportAirway, lockListData } from '@/services/shipping_line'
import { basicPath } from '@/router/config.js'
import local from '@/utils/local'
// import 'antd/dist/antd.dark.css';

const { TextArea } = Input;

function ShippingLineSon() {
  const navigate = useNavigate();
  const location = useLocation();//接受传值数据
  const limitsAuthority = useRef()//权限列表
  const limitsAuthorityName = useRef()//权限列表name
  const PID = location.state.id;//主管线id
  const name = location.state.name;//主管线名称
  const btnsize = 'default';
  const token = local.get('cruise_m30_token');
  const [current, setCurrent] = useState(1);//第几页
  const [data, setData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//总共多少条
  const [loading, setLoading] = useState(false);
  const [showModle, setShowModel] = useState(false);//是否显示弹窗
  const [modelTitle, setModelTitle] = useState('');//弹窗标题
  const [choose_id, setChooseId] = useState('');//当前选中的id
  const [title, setTitle] = useState('');//单元名称
  const [des, setDes] = useState('');//单元备注
  const [length, setLength] = useState('');//单元长度

  const importPointRef = useRef();//上传管线、标识桩input

  const [modelInfo, setModelInfo] = useState({
    type: '',//1 管线  2 标识桩
    coordinate: '',//WGS84 GCJ
    isOpen: false,
    title: '',
    btnLoading: false
  })

  const [search, setSearch] = useState({
    PID: PID,
    access_token: token,
    page: 1,
    perpage: 10,
    type: location.state.type
  });//查询

  const columns = [ //表格头
    {
      title: '单元名称',
      dataIndex: 'title',
    },
    {
      title: '总里程(km)',
      dataIndex: 'length',
      // sorter: {
      //     compare: (a, b) => a.chinese - b.chinese,
      //     multiple: 3,
      // },
    },
    {
      title: '编辑人',
      dataIndex: 'username',
      render: (text, record) => {
        return (
          text
            ? <div style={{ color: 'rgba(255,255,255,0.45)' }}>{text}</div>
            : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
        )
      }
    },
    {
      title: '编辑时间',
      dataIndex: 'update_time',
      render: (text, record) => {
        return (
          text
            ? <div style={{ color: 'rgba(255,255,255,0.45)' }}>{text}</div>
            : <div style={{ color: 'rgba(255,255,255,0.25)' }}>-</div>
        )
      }
    },
    {
      title: '锁定',
      dataIndex: 'is_lock',
      width: 80,
      align: 'center',
      render: (text, record) => {
        return (
          text
            ? (
              <div className='col_primary pointer flex jcc align_center'>
                <Popconfirm
                  title="是否锁定?"
                  description="Are you sure to delete this task?"
                  onConfirm={async () => {
                    let obj = {
                      access_token: token,
                      RID: record.id,
                      is_lock: 0
                    }
                    let res = await lockListData(obj)
                    if (res.ret !== 200) { return message.warning(res.msg || '数据跑丢了') }
                    getData(search, false);
                  }}
                  okText="锁定"
                  cancelText="取消"
                >
                  <UnlockOutlined className='col_primary pointer' />
                </Popconfirm>
              </div>
            )
            : (
              <div className='col_primary pointer flex jcc align_center'>

                <Popconfirm
                  title="是否解锁?"
                  description=""
                  onConfirm={async () => {
                    let obj = {
                      access_token: token,
                      RID: record.id,
                      is_lock: 1
                    }
                    let res = await lockListData(obj)
                    if (res.ret !== 200) { return message.warning(res.msg || '数据跑丢了') }
                    getData(search, false);
                  }}
                  okText="解锁"
                  cancelText="取消"
                >

                  <LockFilled className='col_primary pointer' />
                </Popconfirm>
              </div>
            )
        )
      }
    },
    {
      title: '地图',
      dataIndex: '',
      width: 80,
      align: 'center',
      render: (text, record) => {
        if ((limitsAuthorityName.current)?.indexOf(`RouteView_${search.type}`) > -1) {
          return (
            <div className='coL1890FF curPointer' style={{ display: (Number(record.length) > 0 ? 'block' : 'none') }} onClick={() => {
              window.open(basicPath + '/shipping_line_edit?' + record.id + `&${search.type}`);
            }}>
              <GlobalOutlined style={{ width: '16px', height: '16px' }} />
            </div>
          )
        } else {
          return (<GlobalOutlined style={{ width: '16px', height: '16px' }} />)
        }
      }

    },
    {
      title: '操作',
      dataIndex: 'op',
      width: '100px',
      align: 'center',
      render: (_, record, index) => {
        if (record.is_lock === 1) {

          //操作按钮集合
          const ButtonList = [
            {

              key: '0',
              type: 'SetUp',
              label: "修改",
              purview: limitsAuthorityName.current?.indexOf(`RoutesInfo_Edit_${search.type}`) > -1,
            },
            {
              key: "1",
              type: 'NodeCollapseOutlined',
              label: "上传管线",
              purview: limitsAuthorityName.current?.indexOf(`RoutesPoint_Import_${search.type}`) > -1,
            },
            {
              key: "2",
              type: 'RotateLeftOutlined',
              label: "上传标识桩",
              purview: limitsAuthorityName.current?.indexOf(`RoutesMark_Import_${search.type}`) > -1,
            },
            {
              key: "4",
              type: 'NodeExpandOutlined',
              label: "导出管线",
              purview: limitsAuthorityName.current?.indexOf(`Routes_PointExport_${search.type}`) > -1,
            },
            {
              key: "5",
              type: 'NodeIndexOutlined',
              label: "导出航点",
              purview: limitsAuthorityName.current?.indexOf(`Routes_PointExport_${search.type}`) > -1,
            },
            {
              key: "6",
              type: 'RotateRightOutlined',
              label: "导出标识桩",
              purview: limitsAuthorityName.current?.indexOf(`Routes_PointExport_${search.type}`) > -1,
            },
            {
              key: "7",
              type: 'NodeCollapseOutlined',
              label: "上传航线",
              purview: limitsAuthorityName.current?.indexOf(`RoutesRoute_Import_${search.type}`) > -1,
            },
            {
              key: 'divider',
              type: 'Divider',
              purview: true,
            },
            {
              key: "3",
              type: 'Delete',
              label: '删除',
              purview: limitsAuthorityName.current?.indexOf(`RoutesInfo_Del_${search.type}`) > -1,
            },
          ]

          return OperationBuilder(record, index, 'actions_drop', ButtonList, Operation)
        } else {
          return <div className='opa25'>-</div>
        }
      }
    },
  ];


  // 操作按钮 回调
  const Operation = (record, index, type, key) => {
    let info = { ...modelInfo };
    switch (key) {
      case '0':
        getInfor(record.id);
        break;
      case '1':
        setChooseId(record.id);
        info.type = 1;
        info.isOpen = true;
        info.title = '上传管线';
        setModelInfo(info);
        break;
      case '2':
        setChooseId(record.id);
        info.type = 2;
        info.isOpen = true;
        info.title = '上传标识桩';
        setModelInfo(info);
        break;
      case '3':
        delData(record.id);
        break;
      case '4':
        DCHD(key, record.id)
        break;
      case '5':
        DCHD(key, record.id)
        break;
      case '6':
        DCHD(key, record.id)
        break;
      case '7':
        setChooseId(record.id);
        document.getElementById('importRoute').click();
        break;
      default:
        break;
    }

  };


  //获取表格数据
  const getData = async (obj, isloding = true) => {
    if (isloding) { setLoading(true); }
    let res = await GetRoutesList(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    setData([...res.data.items]);
    setTotal(res.data.total);
    setLoading(false);
  };

  // 导出航点
  const DCHD = async (key, id) => {
    let res = await exportWaypoints({
      access_token: token,
      id: id,
      type: key === '4' ? '1' : key === '5' ? '2' : key === '6' ? '3' : undefined
    })
    if (res.ret === 200) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = res.data
      document.body.appendChild(link);
      link.click();
      // 释放的 URL 对象以及移除 a 标签
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
      message.success('导出成功~')
    } else {
      message.warning(res.msg || '导出失败，请稍后重试~')
    }
  }

  //删除表格数据
  const delData = async (id) => {
    let res = await DelRoutes({
      access_token: token,
      id: id
    })
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success('删除成功');
    getData(search);
  };

  //修改表格数据
  const updateData = async () => {
    if (!title) {
      message.warning('请输入单元名称');
      return;
    }
    if (!des) {
      message.warning('请输入备注');
      return;
    }
    let obj = {
      access_token: token,
      title: title,
      des: des,
      length: length,
    }
    let res;
    if (choose_id) {
      obj.id = choose_id;
      res = await EditRoutes(obj);
    } else {
      obj.RID = PID;
      obj.type = search.type;
      res = await AddRoutes(obj);
    }
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success(choose_id ? '编辑成功' : '新增成功');
    setTitle('');
    setDes('');
    setLength('')
    setChooseId('');
    setShowModel(false);
    getData(search);
  };

  //获取管线详细信息
  const getInfor = async (id) => {
    let params = {
      access_token: token,
      id: id
    }
    let res = await GetRoutesInfo(params);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return
    }
    let val = res.data;
    setTitle(val.title);
    setDes(val.des);
    setLength(val.length);
    setChooseId(id);
    setShowModel(true);
    setModelTitle('编辑单元');
  };

  //新增管线
  const addPipe = async () => {
    setChooseId('');
    setShowModel(true);
    setModelTitle('新增单元');
  }

  //上传管线
  const importData = async (val) => {
    let param = {
      access_token: token,
      id: val.id,
      file: val.file,
      type: val.type
    }
    let info = { ...modelInfo };
    info.btnLoading = true;
    setModelInfo(info);
    let res = await ImportPoint(param);
    info.isOpen = false;
    info.btnLoading = false;
    setModelInfo(info);
    console.log('执行');
    importPointRef.current.value = '';
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
    } else {
      message.success('上传成功');
      getData(search);
    }

  };

  //上传航线
  const importRoute = async (val) => {
    var data = new FormData();
    // 创建一个表单数据
    data.append("access_token", token);
    data.append("id", val.id);
    data.append("file", val.file);
    let res = await ImportAirway(data);
    document.getElementById('importRoute').value = '';
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success('上传成功');
    getData(search);
  };

  //上传标识桩
  const identificationStake = async (val) => {
    let param = {
      access_token: token,
      id: val.id,
      file: val.file,
      type: val.type
    }
    let info = { ...modelInfo };
    info.btnLoading = true;
    setModelInfo(info);
    let res = await IdentificationStake(param);
    importPointRef.current.value = '';
    info.isOpen = false;
    info.btnLoading = false;
    setModelInfo(info);
    console.log('执行');
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    message.success('上传成功');
    getData(search);
  };

  useEffect(() => {
    limitsAuthority.current = JSON.parse(local.get('cruise_m30_permissionsArr'))
    limitsAuthorityName.current = localStorage.getItem('cruise_m30_permissionsArrName')
    getData(search);
    //eslint-disable-next-line
  }, [])

  return (
    <div className="flex-col hei100 tablebackcol" style={{ overflowY: 'auto' }}>
      <div className="disFlex flexDirCol">
        <div className='flex-row aliItemsCen paddL24' style={{ width: '100% ', padding: '16px 24px' }}>
          <span onClick={() => { navigate(-1) }} className='curPointer'><ArrowLeftOutlined /> </span>
          <span className='fSize20 fWei500 margR12 margL18'>{name}</span>
        </div>
      </div>
      <div className="flex-col margRL24 paddRL24 backcol" style={{ marginBottom: '24px' }}>
        <div className="h64 flex-row aliItemsCen min-h64">
          <div className="fSize16 fWei600">单元列表</div>
          <div className="flex-auto"></div>
          <Button type="primary" icon={<PlusOutlined />} size={btnsize}
            onClick={() => {
              if (limitsAuthorityName.current?.indexOf(`RoutesInfo_Add_${search.type}`) > -1) {
                addPipe()
              } else {
                message.warning('暂无权限！')
              }
            }}
          >
            新增
          </Button>
        </div>
        <div className="borbox">
          <Spin spinning={loading}>
            <Table
              rowKey='id'
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </Spin>
          <div className="min-h64 tt_a_r disFlex aliItemsCen">
            <Pagination
              total={total}
              showSizeChanger
              showQuickJumper={false}
              showTotal={total => `共 ${total} 条数据`}
              style={{ marginLeft: 'auto' }}
              current={current}
              onChange={
                (current, pageSize) => { //选择当前页后执行
                  let obj = { ...search };
                  obj.page = current;
                  setCurrent(current);
                  obj.perpage = pageSize;
                  setSearch({ ...obj })
                  getData(obj);
                }
              }
            />
          </div>
        </div>

      </div>
      <Modal title={modelTitle} open={showModle} maskClosable={false} cancelText='取消' okText="确定"
        onOk={() => { updateData() }}
        onCancel={() => {
          setTitle('');
          setDes('');
          setChooseId('');
          setLength()
          setShowModel(false);
        }}>
        <div className="margB24">
          <Input placeholder="单元名称" value={title} onChange={(e) => {
            setTitle(e.target.value)
          }} />
        </div>
        <div className="margB24">
          <InputNumber style={{ width: '100%' }} placeholder="单元长度" value={length} onChange={(e) => {
            setLength(e)
          }} />
        </div>
        <div>
          <TextArea id="" className="margT12 h54" value={des} rows={4} placeholder="单元描述"
            onChange={(e) => { setDes(e.currentTarget.value); }
            } />
        </div>
      </Modal>
      {/* 上传管线、上传标识桩 */}
      <input ref={importPointRef}
        type="file"
        accept=".xls,.xlsx"
        hidden={true}
        onChange={(e) => {
          switch (modelInfo.type) {
            //管线
            case 1: importData({ id: choose_id, file: e.target.files[0], type: modelInfo.coordinate })
              break;
            //标识桩
            case 2: identificationStake({ id: choose_id, file: e.target.files[0], type: modelInfo.coordinate })
              break;
            default: return
          }
        }}
      />

      {/* 上传航线 */}
      <input id="importRoute"
        type="file"
        accept=".kmz"
        hidden={true}
        onChange={(e) => {
          importRoute({ id: choose_id, file: e.target.files[0] })
        }}
      />

      {/* 上传管线标识桩时，选择坐标系 */}
      <Modal title="" width={416} open={modelInfo.isOpen} footer={false} closable={false}>
        <div className='marB8 flex_row align_center'>
          <span className='fs22 marR16 col_primary'><ExclamationCircleOutlined /></span>
          <span style={{ fontWeight: 500, fontSize: 16, color: 'rgba(255, 255, 255, 0.85)' }}>
            {modelInfo.title || '上传文件'}
          </span>
        </div>
        <div className='fs14 marL38' style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.85)' }}>
          请选择对应的坐标系文件
        </div>

        <div className='marT24 tr'>
          <Button className='marR8' onClick={() => {
            let info = { ...modelInfo };
            info.isOpen = false;
            setModelInfo(info);
          }}>取消</Button>

          <Button className='marR8' loading={modelInfo.btnLoading} type="primary" onClick={() => {
            let info = { ...modelInfo };
            info.coordinate = 'WGS84';
            setModelInfo(info);
            importPointRef.current.click();
          }}>WGS84</Button>

          <Button type="primary" loading={modelInfo.btnLoading} onClick={() => {
            let info = { ...modelInfo };
            info.coordinate = 'GCJ';
            setModelInfo(info);
            importPointRef.current.click();

          }}>GCJ</Button>
        </div>
      </Modal>

    </div>
  );
}
export default ShippingLineSon