// 异常排查
import React from 'react'
import { useState, useEffect } from 'react'
import { Pagination, Spin, Table, Badge, Input, Button, Space, message, Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom' //路由跳转使用 useNavigate react-router v6已经把usehistory替换成了useNavigate
import SortOrFilter from '@/components/TableBuilder/SortOrFilter';
import { GetAnomalous, GetAnomalousImage, ImportInsPic } from "@/services/abnormal_troubleshooting"; // 引入axios
import local from '@/utils/local'
import dot from '@/common/img/abnormal_troubleshooting/dot.png' //引入图片的路径
import undot from '@/common/img/abnormal_troubleshooting/undot.png' //引入图片的路径


function AbnormalTroubleshooting (props) {
  const navigate = useNavigate();
  const token = local.get('cruise_m30_token');

  const [search_tit, SetSearchTit] = useState('');//搜索的管线或者子管线名称
  const [search_company, SetSearchCom] = useState('');//搜索公司名称
  const [clickId, SetClickId] = useState('');//
  const [companyId, SetCompanyId] = useState('');//
  const [current, setCurrent] = useState(1);//第几页
  const [data, setData] = useState([]);//表格数据
  const [chooseData, setChooseData] = useState([]);//表格选择的数据
  const [total, setTotal] = useState(0);//总共多少条
  const [loading, setLoading] = useState(false);
  const [tip, SetTip] = useState('');
  const [param, setParam] = useState({
    access_token: token,
    title: '',
    company: '',
    routes_type: null,
    page: current,
    perpage: 10,
  });//筛选条件
  const [dictionaryList, setDictionaryList] = useState({//数据字典
    typeList: [],
  })

  //搜索管线/单元段名称
  const SearchTitle = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8, }}>
        <Input
          placeholder='请输入管线/单元段名称'
          value={search_tit}
          onChange={(e) => { SetSearchTit(e.target.value) }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              let obj = { ...param };
              obj.page = 1;
              obj.title = search_tit;
              setParam({ ...obj });
              getData(obj);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>

          <Button
            onClick={() => { SetSearchTit('') }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  //搜索公司名称
  const SearchCompany = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8, }}>
        <Input
          placeholder='请输入公司名称'
          value={search_company}
          onChange={(e) => { SetSearchCom(e.target.value) }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              let obj = param;
              obj.page = 1;
              obj.company = search_company;
              setParam({ ...obj });
              getData(obj);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>

          <Button
            onClick={() => { SetSearchCom('') }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  //主管线表头
  const columns = [ //表格头
    {
      title: '类型',
      width: '160px',
      dataIndex: 'routes_type',
      filters: dictionaryList.typeList,
      filterMultiple: false,
    },
    {
      title: '管线/单元名称',
      dataIndex: 'pipe_name',
      // ...SearchTitle(),
    },
    {
      title: '状态',
      dataIndex: 'ins_status',
      width: '160px',
      // sorter: {
      //     compare: (a, b) => a.chinese - b.chinese,
      //     multiple: 3,
      // },
      render: (text, record) => (
        <div>
          {
            Number(text) === 2 &&
            <>
              <Badge className='marR4' status="processing" />
              {record.ins_status_s}
            </>
          }
          <span style={{ display: (Number(text) === 1) ? "block" : "none" }} className="fSize14 fWei400 coLD9">
            <img alt='img1' src={undot} style={{ width: '6px', height: '6px' }}></img> {record.ins_status_s}
          </span>
        </div>
      )

    },
    {
      title: '待排查',
      dataIndex: 'check_count',
      width: '160px',
      // sorter: {
      //     compare: (a, b) => a.math - b.math,
      //     multiple: 2,
      // },
      align: 'center',
      render: (text, record) => (
        <span className='curPointer' onClick={
          () => {
            let insIds = record.list_ids.join(',');
            getImage(record.CompanyID, record.id, record.insIds, (arr) => {
              //console.log('arr:', arr);
              navigate('/abnormal_troubleshooting_steps', { state: { arr: arr, len: arr.length } })
            });
          }
        }>
          <Badge count={text} style={{ boxShadow: '0 0 0 1px #000' }} />
        </span>
      )
    },
    // {
    //     title: '公司名称',
    //     dataIndex: 'company_s',
    //     width: '160px',
    //     // ...SearchCompany(),
    // },
    {
      title: '巡检起止',
      dataIndex: '',
      width: '340px',
      // sorter: {
      //     compare: (a, b) => a.math - b.math,
      //     multiple: 2,
      // },
      render: (text, record) => (
        <span className=''>
          <span>
            {record.start_time.split(' ')[0]}
            <span className='opacity0_45'> {record.start_time.split(' ')[1]} </span>
          </span>
          <span style={{ color: (record.end_time) ? 'FFFFFFD9' : 'red' }}> ~ </span>
          <span>
            {record.end_time.split(' ')[0]}
            <span className='opacity0_45'> {record.end_time.split(' ')[1]} </span>
          </span>
        </span>
      )
    },
    // {
    //     title: '操作',
    //     dataIndex: '',
    //     width: '100px',
    //     // sorter: {
    //     //     compare: (a, b) => a.math - b.math,
    //     //     multiple: 2,
    //     // },
    //     align:'center',
    //     render: (text, record) => (
    //         <span>
    //             -
    //         </span>
    //     )
    // },
  ];

  //单元段表头
  const columns_c = [
    {
      dataIndex: '',
      width: '160px',
      // ...SearchTitle(),
    },
    {
      dataIndex: 'line_name',
    },
    {
      dataIndex: 'ins_status',
      width: '160px',
      render: (text, record) => (
        <div >
          <span style={{ display: (Number(text) === 2) ? "block" : "none" }} className="fs14 fw400">
            <img alt='dot' className='w6 h6' src={dot} />
            {record.ins_status_s}
          </span>
          <span style={{ display: (Number(text) === 1) ? "block" : "none" }} className="fs14 fw400 coLD9">
            <img alt='undot' className='w6 h6' src={undot} /> {record.ins_status_s}
          </span>
        </div>
      )
    },
    {
      dataIndex: 'check_count',
      width: '160px',
      align: 'center',
      render: (text, record) => (
        <span className='curPointer'
          onClick={
            () => {
              getImage(record.companyId, record.statid, record.insIds, (arr) => {
                navigate('/abnormal_troubleshooting_steps', { state: { arr: arr, len: arr.length } })
              });

            }
          }
        >
          <Badge count={text} style={{ boxShadow: '0 0 0 1px #000' }} />
        </span>
      )
    },
    // {
    //     dataIndex: 'company_s',
    //     width: '160px',
    // },
    {
      title: '巡检起止',
      dataIndex: '',
      width: '340px',
      // sorter: {
      //     compare: (a, b) => a.math - b.math,
      //     multiple: 2,
      // },
      render: (text, record) => (
        <span className=''>
          <span>
            {record.addtime.split(' ')[0]}
            <span className='opacity0_45'> {record.addtime.split(' ')[1]} </span>
          </span>
          <span style={{ color: (record.endtime) ? '#FFFFFFD9' : 'red' }}> ~ </span>
          <span>
            {record.endtime.split(' ')[0]}
            <span className='opacity0_45'> {record.endtime.split(' ')[1]} </span>
          </span>
        </span>
      )
    },
    // {
    //     title: '操作',
    //     dataIndex: '',
    //     width: '100px',
    //     align:'center',
    //     // sorter: {
    //     //     compare: (a, b) => a.math - b.math,
    //     //     multiple: 2,
    //     // },
    //     render: (text, record) => (
    //         <span className='curPointer coL1890FF' onClick={()=>{
    //             //console.log(record);
    //             document.getElementById('picChange').click();
    //             SetClickId(record.insIds);
    //             SetCompanyId(record.companyId);
    //         }}>
    //             导入
    //         </span>
    //     )
    // },
  ]


  //获取表格数据
  const getData = async (page) => {
    setLoading(true);
    SetTip('加载中...');
    let obj = {
      access_token: token,
      title: page.title,
      company: page.company,
      routes_type: page.routes_type,
      page: page.page,
      perpage: page.perpage,
    }
    const res = await GetAnomalous(obj);
    //console.log('获取表格数据');
    //console.log(res);
    if (res.ret === 200) { // eslint-disable-line
      for (let i = 0; i < res.data.items.length; i++) {
        let val = res.data.items[i];
        val.key = i;
        let list_ids = [];
        for (let n = 0; n < val.c_list.length; n++) {
          let val_c = val.c_list[n];
          val_c.companyId = val.CompanyID;
          val_c.statid = val.id;
          val_c.key = n;
          val_c.company_s = '-';
          list_ids.push(val_c.insIds);
        }
        val.list_ids = list_ids;
      }


      let dic = dictionaryList
      dic.typeList = res.data.sjzd.typeList.map((res) => { return { text: res.label, value: res.value } })
      setDictionaryList({ ...dic })
      setData([...res.data.items]);
      setTotal(res.data.total);
    } else {
      //console.log(res.msg);
    }
    setLoading(false);
  };

  //获取图片数据
  const getImage = async (companyId, statid, insIds, c) => {
    setLoading(true);
    SetTip('获取图片中...');
    let paramData = {
      access_token: token,
      companyId: companyId,
      statid: statid,
      insIds: insIds,
    };
    const res = await GetAnomalousImage(paramData);
    //console.log('图片数据');
    //console.log(res);
    if (res.ret === 200) {
      let data = [];
      res.data.list.forEach((item, item_idx) => {
        data.push({ ...item, key: item_idx });
      });
      setLoading(false);
      return c(data)
    }

  }

  //上传图片
  const UploadImg = async (file) => {
    setLoading(true);
    for (let i = 0; i < file.length; i++) {
      const formdata = new FormData();
      formdata.append("access_token", token);
      formdata.append("insId", clickId);
      formdata.append("companyId", companyId);
      formdata.append("file", file[i]);
      //console.log(formdata);
      // return;
      const res = await ImportInsPic(formdata);
      if (res.ret === 200) {
        if (i === file.length - 1) {
          setLoading(false);
          message.success('图片导入成功');
          document.getElementById('picChange').value = '';
        }
        continue;
      } else {
        document.getElementById('picChange').value = '';
      }

    }
  };

  //图片改变执行
  const handlePicChange = (e) => {
    let file = e.target.files;
    //console.log(file);
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > (20 * 1024 * 1024)) {
        return message.warning('导入的图片中存在大于20M的图片');
      }
      if (i === file.length - 1) {
        UploadImg(file);
      }
    }

  }

  //在函数组件中，组件加载完成后执行
  useEffect(() => {
    getData(param);
    let con = document.getElementsByClassName('ant-badge-count');
    for (let i = 0; i < con.length; i++) {
      con[i].style['box-shadow'] = '0 0 0 1px #000';
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card style={{ background: 'black', overflowY: 'auto' }}>
      <div className="borbox">
        {/* <div onClick={()=>{
                navigate('/Troubleshoot', { state: { arr: {} } })
            }}>跳转</div> */}
        <div className="flex-col padd_24 hei100 backcol">
          <div className="flex-auto borbox" style={{ overflowY: 'auto' }}>
            <Spin spinning={loading} tip={tip}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                expandable={{
                  expandedRowRender: (record) => (
                    <Table showHeader={false} pagination={false} columns={columns_c} dataSource={record.c_list} style={{ display: (record.c_list.length > 0) ? 'block' : 'none' }} />
                  )
                }}
                onChange={(pagination, filters, sorter) => {
                  setCurrent(1);
                  let val = SortOrFilter(pagination, filters, sorter);
                  let obj = { ...param };
                  obj.order = val.sorter;
                  obj.page = 1;
                  obj.routes_type = filters.routes_type ? filters.routes_type[0] : null
                  setParam({ ...obj });
                  getData(obj);
                }}
              />
            </Spin>
          </div>
          <div className="min-h64 tt_a_r disFlex aliItemsCen">
            <Pagination
              total={total}
              showSizeChanger
              showQuickJumper
              showTotal={total => `共 ${total} 条数据`}
              style={{ marginLeft: 'auto' }}
              current={current}
              onChange={
                (current, pageSize) => { //选择当前页后执行
                  let obj = { ...param };
                  obj.page = current;
                  setCurrent(current);
                  obj.perpage = pageSize;
                  setParam({ ...obj });
                  getData(obj);
                }
              }
            />
          </div>
        </div>
        <input id="picChange" type='file' accept="image/*" onChange={handlePicChange} multiple="multiple" style={{ display: 'none' }} />
      </div>
    </Card>
  );
}
export default AbnormalTroubleshooting