//综合巡检记录
import { useState, useEffect } from 'react'
import { Card, Table, Badge, Spin, message } from 'antd'
import HeaderBuilder from '@/components/TableBuilder/HeaderBuilder';
import SortOrFilter from '@/components/TableBuilder/SortOrFilter';
import {
  FileImageOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { InsImageList_Get } from "@/services/leakage_risk.js"; // 引入Ajax
import local from '@/utils/local'
import './index.less'

export default function RegularInspectionsRecord () {
  const navigate = useNavigate()
  /**
   * data
   * */
  const [spinningLoading, _spinningLoading] = useState(false);
  const [total, _total] = useState(0);
  const [List, _List] = useState();
  const [searchCriteria, _searchCriteria] = useState({
    title: '',
    order: '',
    status: '',
    current: 1,
    pageSize: 10,
  });

  /**
   * methods
  * */
  // 巡检报告
  const getList = async (sift_data) => {
    _spinningLoading(true)
    const res = await InsImageList_Get({
      access_token: local.get('cruise_m30_token') || '',
      type: '6',
      title: sift_data.title,
      order: sift_data.order,
      status: sift_data.status,
      page: sift_data.current,
      perpage: sift_data.pageSize,
    });
    _spinningLoading(false)
    //console.log(res);
    if (res.ret == 200) {// eslint-disable-line
      if (res.data.items.length != 0) {// eslint-disable-line
        res.data.items.forEach((item, index) => {
          item.key = index;
          item.c_list.forEach((val, ndx) => {
            val.key = ndx;
            val.CompanyID = item.CompanyID;
            val.pipe_name = item.pipe_name;
            val.statid = item.id;
          });
        });
      }
      _List(res.data.items || []);
      _total(res.data.total || 0)
    }
  };


  // 分页、排序、筛选变化时触发
  const handleTableChange = (newPagination, filters, sorter) => {
    const searchBefore = { ...searchCriteria };
    // 分页
    searchBefore.current = newPagination.current;
    searchBefore.pageSize = newPagination.pageSize;
    _searchCriteria({ ...searchBefore })
    getList(searchBefore) //加载列表
  };

  //图片查看页
  const PictureViewPage = (item) => {
    //console.log(item);
    const transfer = {
      pTitle: item.pipe_name,
      title: item.line_name || '',
      start_time: item.start_time,
      end_time: item.end_time,
      companyId: item.CompanyID,
      statid: item.id || item.statid,
      insIds: item.insIds
    }
    navigate('/regular_inspections_record_picture', { state: { transfer: transfer, type: 6 } })
  }

  const columns = [
    {
      title: '管线/单元名称',
      // issearch: true,
      dataIndex: 'pipe_name',
    },
    {
      title: '总里程(km)',
      dataIndex: 'pipe_len',
    },
    {
      title: '巡检开始时间',
      dataIndex: 'start_time',
      // sorter: {
      //     compare: (a, b) => a.addtime - b.addtime,
      //     multiple: 1,
      // },
      render: (_, item, index) => {
        return (
          <div>
            <div style={{ display: (item.start_time) ? 'block' : 'none' }}>
              <span className='fSize14 opacity0_85 margR8'>{item.start_time.split(' ')[0]}</span>
              <span className='fSize14 opacity0_45'>{item.start_time.split(' ')[1]}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: '巡检结束时间',
      dataIndex: 'end_time',
      // sorter: {
      //     compare: (a, b) => a.addtime - b.addtime,
      //     multiple: 1,
      // },
      render: (_, item, index) => {
        return (
          <div>
            <div className='' style={{ display: (item.end_time) ? 'block' : 'none' }}>
              <span className='fSize14 opacity0_85 margR8'>{item.end_time.split(' ')[0]}</span>
              <span className='fSize14 opacity0_45'>{item.end_time.split(' ')[1]}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: '图像',
      dataIndex: 'pic_count',
      align: 'center',
      width: 120,
      render: (_, item, index) => {
        return (
          Number(item.pic_count) > 0 ?
            <div className='disFlex jusConCen aliItemsCen curPointer' onClick={() => { PictureViewPage(item) }}>
              <div className=''>
                <span className='coL1890FF padd_10'>{item.pic_count}</span>
                <FileImageOutlined style={{ color: '#1890ff' }} />
              </div>
            </div> : ' '
        );
      },
    }
  ];

  //子表格
  const columns_c = [
    {
      issearch: true,
      dataIndex: 'line_name',
    },
    {
      width: 150,
      dataIndex: '',
    },
    {
      issearch: true,
      dataIndex: 'start_time',
      width: 200,
      render: (_, item, index) => {
        //console.log(item);
        return (
          <div>
            <div style={{ display: (item.start_time) ? 'block' : 'none' }}>
              <span className='fSize14 opacity0_85 margR8'>{item.start_time.split(' ')[0]}</span>
              <span className='fSize14 opacity0_45'>{item.start_time.split(' ')[1]}</span>
            </div>
            <div style={{ display: (item.end_time) ? 'none' : 'block', paddingLeft: '50px' }}>
              <span className='coL0_85' style={{ color: (item.end_time) ? 'black' : 'red' }}>~</span>
            </div>
            <div className='' style={{ display: (item.end_time) ? 'block' : 'none' }}>
              <span className='fSize14 opacity0_85 margR8'>{item.end_time.split(' ')[0]}</span>
              <span className='fSize14 opacity0_45'>{item.end_time.split(' ')[1]}</span>
            </div>
          </div>
        );
      },
    }, {
      dataIndex: 'pic_count',
      align: 'center',
      width: 120,
      render: (_, item, index) => {
        return (
          Number(item.pic_count) > 0 ?
            <div className='disFlex jusConCen aliItemsCen curPointer' onClick={() => { PictureViewPage(item) }}>
              <span className='coL1890FF margR8 margT4'>{item.pic_count}</span>
              <FileImageOutlined style={{ color: '#1890FF' }} />
            </div> : ' '
        );
      },
    }

  ]

  useEffect(() => {
    getList(searchCriteria)
  }, []);// eslint-disable-line

  return (
    <div className="flex-col hei100 tablebackcol" style={{ overflowY: 'auto' }}>
      <Card style={{ background: 'black' }} >
        <div className="flex-col hei100">
          {/* <div onClick={()=>{
                navigate('/Risk', { state: { companyId: 1, statid:18, insIds: 18 } })
            }}>跳转</div> */}
          {/* <a id='a_report' download="巡检报告.docx" style={{ display: 'none' }}>
        查看
      </a> */}
          <Spin spinning={spinningLoading}>
            <div className='padd_24 flex-auto backcol'>
              <Table
                columns={HeaderBuilder(columns)}
                dataSource={List}
                expandable={{
                  expandedRowRender: (record) => (
                    <Table showHeader={false} pagination={false} columns={columns_c} dataSource={record.c_list} />
                  )
                }}
                pagination={{
                  total: total,
                  current: searchCriteria.current,
                  pageSize: searchCriteria.pageSize,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  showQuickJumper: true,
                  showSizeChanger: true,
                }}
                onChange={handleTableChange}
              />

            </div>
          </Spin>
        </div>
      </Card>
    </div>
  )
}
