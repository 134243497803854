//常规风险
import { useState, useEffect, useRef } from 'react'
import { Table, Spin, Radio, Space, DatePicker, Button, message, Input, Select, Drawer, Form, Switch, InputNumber } from 'antd'
import HeaderBuilder from '@/components/TableBuilder/HeaderBuilder';
import SortOrFilter from '@/components/TableBuilder/SortOrFilter';
import { SearchOutlined, FileImageOutlined, } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'
import { RiskDisposeList_Get, getAlarmDistanceInfo, setAlarmDistanceInfo, setCordonInfoFun } from "@/services/conventional_risks"; // 引入Ajax
import local from '@/utils/local'
// import 'antd/dist/antd.dark.css';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import bt from '@/common/img/conventional_risks/bt.svg'

export default function Riskhandel () {
  const Stateparams = useLocation();
  let params = Stateparams.state;
  const token = local.get('cruise_m30_token');
  const navigate = useNavigate()
  /**
   * data
   * */
  const { RangePicker } = DatePicker;
  const [spinningLoading, _spinningLoading] = useState(false);
  const [total, _total] = useState(0);
  const [List, _List] = useState();
  const [searchCriteria, setSearchCriteria] = useState({
    title: '',//管线/单元名称
    order: '',
    status: '',
    type: 1,
    types: 1,//1:记录   2统计
    s_start_time: '',//开始-开始时间
    s_end_time: '',//开始-结束时间
    e_start_time: '',//结束-开始时间
    e_end_time: '',//结束-结束时间
    pending: 0,//待处理   0全部 1有 2无
    current: 1,
    pageSize: 10,
  });

  const [alarmDistance, setAlarmDistance] = useState({//警报距离
    isOpen: false,
    loading: false,
    isTrue: true,
    originalData: [],//原数据
  })

  const [countColumns, setCountColumns] = useState([]);

  const [form] = Form.useForm();//警戒线表单
  const [cordonOpen, setCordonOpen] = useState(false); //警戒线弹窗
  const [cordonLoading, setCordonLoading] = useState(false); //警戒线弹窗加载
  const [cordonEnable, setCordonEnable] = useState(false); //警戒线是否启用

  /* methods */
  // 常规风险
  const getTableData = async (sift_data) => {
    _List([])
    params = null
    _spinningLoading(true)
    const res = await RiskDisposeList_Get({
      access_token: local.get('cruise_m30_token') || '',
      type: sift_data.urlType,
      types: sift_data.type,
      title: sift_data.title,
      order: sift_data.order,
      status: sift_data.status,
      page: sift_data.current,
      perpage: sift_data.pageSize,
      s_start_time: sift_data.s_start_time,
      s_end_time: sift_data.s_end_time,
      e_start_time: sift_data.e_start_time,
      e_end_time: sift_data.e_end_time,
      pending: sift_data.pending,
    });
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let type1 = res.data.sjzd.risk_type.map((item) => { return item.value });
    let type2 = res.data.sjzd.risk_type.map((item) => { return [] });
    let arr = []
    res.data?.items?.forEach((item, index) => {
      let ch = []
      let obj = {}
      if (item.c_list && item.c_list.length > 0) {
        item?.c_list.forEach((ele, idx) => {
          let list = {
            ...ele,
            numKey: `${index}_${idx}`
          }
          ch.push(list)
        })
        obj = {
          ...item,
          c_list: ch,
          numKey: index,
        }
        arr.push(obj)
      } else {
        arr.push({
          ...item,
          numKey: index,
        })
      }
    });
    if (Number(sift_data.type) === 2 && res.data.items) {
      // 将列表中的insIDS分类总结
      for (let i = 1; i < res.data.items.length; i++) {//筛选
        let itemsValue = res.data.items[i].c_list;
        for (let n = 0; n < itemsValue.length; n++) {
          for (let key in itemsValue[n]) {
            let keyNum = type1.indexOf(key);
            if (keyNum >= 0 && Number(itemsValue[n][key]) > 0) {
              type2[keyNum].push(itemsValue[n].insIds);
            }
          }
        }
      }
    }
    // 统计表头拼装 ↓
    let colArr = [
      {
        title: '管线/单元名称',
        dataIndex: 'pipe_name',
        render: (text, item, index) => {
          return (
            <div>
              {
                text
                  ? <div>{text}</div>
                  : <div className='opa25'>-</div>
              }
            </div>
          );
        },
      },
      {
        title: '巡检开始时间',
        dataIndex: 'start_time',
        render: (text, item, index) => {
          return (
            <div>
              {
                text
                  ? <div>
                    <span className='fSize14  opacity0_85 margR8'>{text.split(' ')[0]}</span>
                    <span className='fSize14  opacity0_45'>{text.split(' ')[1]}</span>
                  </div>
                  : <div className='opa25'>-</div>
              }
            </div>
          );
        },
      },
      {
        title: '巡检结束时间',
        dataIndex: 'end_time',
        render: (text, item, index) => {
          return (
            <div>
              {
                text
                  ? <div>
                    <span className='fSize14  opacity0_85 margR8'>{text.split(' ')[0]}</span>
                    <span className='fSize14  opacity0_45'>{text.split(' ')[1]}</span>
                  </div>
                  : <div className='opa25'>-</div>
              }
            </div>
          );
        },
      },
    ]
    let colArrLast = [
      {
        title: '忽略',
        dataIndex: 'ignore_count',
        render: (text, item, index) => {
          return (
            <div>
              {
                text
                  ? <div>{text}</div>
                  : <div className='opa25'>-</div>
              }
            </div>
          );
        },
      },
      {
        title: '分配',
        dataIndex: 'user_count',
        render: (text, item, index) => {
          return (
            <div>
              {
                text
                  ? <div>{text}</div>
                  : <div className='opa25'>-</div>
              }
            </div>
          );
        },
      },
    ]
    res.data.sjzd.risk_type
      &&
      res.data.sjzd.risk_type.forEach((res) => {
        let list = {
          title: res.label,
          dataIndex: res.value,
          render: (text, item, index) => {
            return (
              <div>
                {
                  Number(text) > 0
                    // id存在为父级  不存在为子集，子集为insIds
                    // && Number(item.id)
                    ?
                    (
                      <div className='col_primary pointer'
                        onClick={() => {
                          let num = res.value.split('_')[1];
                          let val = item[res.value];
                          let typIdx = type1.indexOf(res.value);
                          let ids
                          // 总计或者单个
                          if (Number(item.id) === -1) {//总计
                            ids = type2[typIdx].join(',')
                          } else if (item.c_list) {//父级
                            let idLs = []
                            item.c_list.forEach((res) => {
                              idLs.push(res.insIds)
                            })
                            ids = idLs.join(',')
                          } else if (item.insIds) {//子级
                            ids = item.insIds
                          }
                          navigate('/conventional_risks_handle', {
                            state: {
                              companyId: item.CompanyID, statid: '',
                              insIds: ids,
                              is_handle: false,
                              total: val, list: item,
                              transfer: searchCriteria,
                              abnormal_type: num
                            }
                          });
                        }}
                      >
                        {text}
                      </div>
                    )
                    : (
                      text
                        ? <div>{text}</div>
                        : <div className='opa25'>-</div>
                    )
                }
              </div>
            );
          },
        }
        colArr.push(list)
      })

    colArrLast.forEach(e => {
      colArr.push(e)
    })

    setCountColumns([...colArr])
    // 统计表头拼装 ↑

    _List(arr || []);
    _total(res.data.total || 0)
    form.setFieldsValue({
      cordon: res.data.Company.distance ? Number(res.data.Company.distance) : null,
    });
    setCordonEnable(Number(res.data.Company.is_enable) ? true : false);
    _spinningLoading(false)
  };

  // 分页、排序、筛选变化时触发
  const handleTableChange = (newPagination, filters, sorter) => {
    let searchBefore = { ...searchCriteria };
    // 分页
    searchBefore.current = newPagination.current;
    searchBefore.pageSize = newPagination.pageSize;
    setSearchCriteria({ ...searchBefore })
    getTableData(searchBefore) //加载列表
  };

  const submitAlarmDistance = async () => {//提交报警距离
    let arr = []
    alarmDistance.originalData.forEach(res => {
      let list = {
        risk_id: res.risk_id,
        min_range: res.min_range,
        max_range: res.max_range,
        threshold: res.threshold,
        display: res.display,
      }
      arr.push(list)
    })

    let obj = {
      access_token: token,
      data: JSON.stringify(arr),
    }
    let res = await setAlarmDistanceInfo(obj)
    if (res.ret !== 200) { message.warning(res.msg || ''); return }
    let ala = alarmDistance
    ala.isOpen = false
    setAlarmDistance({ ...ala })
    message.success('提交成功！')
  };

  const checkIsSubmit = (data) => { //校验是否可以提交
    let ala = alarmDistance
    ala.isTrue = true
    setAlarmDistance({ ...ala })
    data.forEach((res, index) => {
      if (res.display && (res.max_range === null || res.min_range === null)) {
        ala.isTrue = false
        data[index].msg = '数据不能为空！！！'
        setAlarmDistance({ ...ala })
        return
      } else if (res.display && (Number(res.max_range) < Number(res.min_range))) {
        ala.isTrue = false
        setAlarmDistance({ ...ala })
        return
      }
    })
  }

  // 记录表头
  const columns = [
    {
      title: '管线/单元名称',
      dataIndex: 'pipe_name',
    },
    {
      title: '巡检开始时间',
      dataIndex: 'start_time',
      render: (_, item, index) => {
        return (
          <div className='flex align_center' style={{ display: (item.start_time) ? 'block' : 'none' }}>
            <span className='fSize14 l opacity0_85 margR8'>{item.start_time.split(' ')[0]}</span>
            <span className='fSize14 l opacity0_45'>{item.start_time.split(' ')[1]}</span>
          </div>
        );
      },
    },
    {
      title: '巡检结束时间',
      dataIndex: 'end_time',
      render: (_, item, index) => {
        return (
          <div className='' style={{ display: (item.end_time) ? 'block' : 'none' }}>
            <span className='fSize14 opacity0_85 margR8'>{item.end_time.split(' ')[0]}</span>
            <span className='fSize14 opacity0_45'>{item.end_time.split(' ')[1]}</span>
          </div>
        );
      },
    },
    {
      title: '风险点',
      dataIndex: 'risks_count',
      render: (text, item, index) => {
        return (
          <div className='curPointer margL6'>
            {text}
          </div>
        );
      },
    },
    {
      title: '待处理',
      dataIndex: 'pending_count',
      render: (text, item, index) => {
        return (
          <div className='margL6'>
            {
              Number(text) === 0
                ? <span>{text}</span>
                : (
                  <span
                    style={{ color: 'red' }}
                    className='pointer'
                    onClick={() => {
                      navigate('/conventional_risks_handle', { state: { companyId: item.CompanyID, statid: item.id, insIds: item.insIds, is_handle: true, total: item.pending_count, list: item, transfer: searchCriteria } })
                    }}
                  >
                    {text}
                  </span>
                )
            }
          </div>
        );
      },
    },
    {
      title: '已处理',
      dataIndex: '',
      render: (_, item, index) => {
        return (
          <div>
            <div className='curPointer'>
              <div style={{ display: (item.risks_count > 0) ? 'block' : 'none' }}
                className="margL6 "
                onClick={
                  () => {
                    //如果待处理=风险点说明没有处理
                    if (item.pending_count === item.risks_count) {
                      return;
                    }
                    navigate('/conventional_risks_handle', { state: { companyId: item.CompanyID, statid: item.id, insIds: item.insIds, is_handle: false, total: item.risks_count, transfer: searchCriteria } })
                  }
                }>
                <span style={{ color: (Number(item.risks_count) - Number(item.pending_count) > 0) ? '#177ddc' : 'white' }}>
                  {Number(item.risks_count) - Number(item.pending_count)}
                </span>
              </div>
            </div>
          </div>
        );
      },
    }
  ];

  // //完成
  // const onFinish = async (e) => {
  //   setCordonLoading(true);
  //   let res = await setCordonInfoFun({
  //     access_token: token,
  //     distance: e.cordon,
  //     is_enable: cordonEnable ? '1' : '0'
  //   });
  //   setCordonLoading(false);
  //   if (res.ret !== 200) {
  //     message.warning(res.mas || '系统出错啦!');
  //     return;
  //   };
  //   message.success('警戒线修改成功');
  //   setCordonOpen(false);
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (params) {
  //       let sea = searchCriteria
  //       sea.title = params.list.title
  //       sea.current = params.list.current
  //       sea.type = params.list.type
  //       sea.s_start_time = params.list.s_start_time
  //       sea.s_end_time = params.list.s_end_time
  //       sea.e_start_time = params.list.e_start_time
  //       sea.e_end_time = params.list.e_end_time
  //       sea.pending = params.list.pending
  //       setSearchCriteria({ ...sea })
  //       getTableData(sea)
  //     } else {
  //       getTableData(searchCriteria)
  //     }
  //   }, 400);

  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    let sea = { ...searchCriteria };
    // 根据url地址确定当前属于哪个类型的记录
    switch (Stateparams.pathname) {
      case '/conventional_risks'://管道
        sea.urlType = 1;
        sea.type = 1
        break;
      case '/powerGrid_risks'://电网
        sea.urlType = 7;
        sea.type = 1
        break;
      case '/electricTower_risks'://电塔
        sea.urlType = 8;
        sea.type = 1
        break;
      case '/station_risks'://场站
        sea.urlType = 9;
        sea.type = 1
        break;
      default:
        break;
    }
    if (params) {
      setSearchCriteria({ ...params.list });
      getTableData(params.list);
    } else {
      setSearchCriteria({ ...sea })
      getTableData(sea)
    }
    //eslint-disable-next-line
  }, [params, Stateparams.pathname])





  return (
    <div className="padd_24 flex-col tablebackcol" style={{ overflowY: 'auto' }}>
      <div className='padd_24 flex-auto backcol'>
        <div className='marB24 flex jcsb align_center'>
          <Radio.Group
            style={{ display: '' }}
            value={searchCriteria.type}
            optionType="button"
            onChange={(e) => {
              let sea = searchCriteria
              sea.type = e.target.value
              sea.current = 1
              setSearchCriteria({ ...sea })
              getTableData(sea)
            }}
            options={[
              {
                label: '记录',
                value: 1,
              },
              {
                label: '统计',
                value: 2,
              },
            ]}
          />
          <div>
            <Space>
              <Input
                allowClear
                placeholder='管线/单元名称'
                value={searchCriteria.title}
                onChange={(e) => {
                  let sea = searchCriteria
                  sea.title = e.target.value
                  sea.current = 1
                  setSearchCriteria({ ...sea })
                }}
                onKeyUp={(e) => { if (e.key === 'Enter') { e.target.blur() } }}
                onBlur={() => {
                  getTableData(searchCriteria)
                }}
              />

              <RangePicker
                locale={locale}
                showHour
                clearFilters
                value={searchCriteria.s_start_time ? [moment(searchCriteria.s_start_time, 'YYYY-MM-DD'), moment(searchCriteria.s_end_time, 'YYYY-MM-DD')] : null}
                format="YYYY-MM-DD"
                placeholder={['巡检开始起', '巡检开始止']}
                onChange={(e) => {
                  let sea = searchCriteria
                  if (e) {
                    sea.s_start_time = e[0].format('YYYY-MM-DD')
                    sea.s_end_time = e[1].format('YYYY-MM-DD')
                  } else {
                    sea.s_start_time = ''
                    sea.s_end_time = ''
                  }
                  sea.current = 1
                  setSearchCriteria({ ...sea })
                  getTableData(sea)
                }}
              />

              <RangePicker
                className='marL16'
                locale={locale}
                showHour
                clearFilters
                value={searchCriteria.e_start_time ? [moment(searchCriteria.e_start_time, 'YYYY-MM-DD'), moment(searchCriteria.e_end_time, 'YYYY-MM-DD')] : null}
                format="YYYY-MM-DD"
                placeholder={['巡检结束起', '巡检结束止']}
                onChange={(e) => {
                  let sea = searchCriteria
                  if (e) {
                    sea.e_start_time = e[0].format('YYYY-MM-DD')
                    sea.e_end_time = e[1].format('YYYY-MM-DD')
                  } else {
                    sea.e_start_time = ''
                    sea.e_end_time = ''
                  }
                  sea.current = 1
                  setSearchCriteria({ ...sea })
                  getTableData(sea)
                }}
              />

              <Select
                value={searchCriteria.pending}
                style={{
                  display: searchCriteria.type === 2 ? `none` : ``,
                  width: '126px'
                }}
                onChange={(e) => {
                  let sea = searchCriteria
                  sea.pending = e
                  sea.current = 1
                  setSearchCriteria({ ...sea })
                  getTableData(sea)
                }}
                options={[
                  {
                    value: 0,
                    label: '待处理：全部',
                  },
                  {
                    value: 1,
                    label: '待处理：有',
                  },
                  {
                    value: 2,
                    label: '待处理：无',
                  },
                ]}
              />

              {/* <Button
                type='primary'
                onClick={async () => {
                  setCordonOpen(true);
                }}
              >
                警戒线
              </Button> */}

              {/* <Button
                type='primary'
                onClick={async () => {
                  let ala = alarmDistance
                  ala.isOpen = true
                  ala.loading = true
                  setAlarmDistance({ ...ala })
                  let res = await getAlarmDistanceInfo({ access_token: token });
                  if (res.ret === 200) {
                    let arr = [];
                    for (let i = 0; i < res.data.length; i++) {
                      res.data[i].threshold = res.data[i].threshold ? res.data[i].threshold : 0.3
                      if (Number(res.data[i].risk_id) === 7) {
                        continue;
                      }
                      arr.push(res.data[i]);
                    }
                    ala.originalData = arr;
                    setAlarmDistance({ ...ala })
                    checkIsSubmit(arr)
                  }
                  ala.loading = false
                  setAlarmDistance({ ...ala })
                }}
              >
                警报距离
              </Button> */}
            </Space>

          </div>
        </div>

        <Table
          columns={searchCriteria.type === 1 ? columns : countColumns}
          rowKey='numKey'
          loading={spinningLoading}
          dataSource={List}
          expandRowByClick={true}
          childrenColumnName='c_list'
          pagination={{
            total: total,
            current: searchCriteria.current,
            pageSize: searchCriteria.pageSize,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />

        <Drawer
          title="警报距离"
          width={304}
          placement="right"
          onClose={() => {
            let ala = alarmDistance
            ala.isOpen = false
            setAlarmDistance({ ...ala })
          }}
          open={alarmDistance.isOpen}
          extra={
            <Space>
              <Button
                type="primary"
                onClick={() => { submitAlarmDistance() }}
                disabled={!alarmDistance.originalData.length > 0 || !alarmDistance.isTrue}
              >
                提交
              </Button>
            </Space>
          }
        >
          <Spin spinning={alarmDistance.loading}>
            {
              alarmDistance.originalData.length > 0 &&
              alarmDistance.originalData.map((ele, index) => {
                return (
                  <div
                    key={index}
                    style={{ width: 270 }}
                    className='flex_col'
                  >
                    {/* 标题 */}
                    <div className='flex align_center marB8'>
                      {
                        ele.display === 1 &&
                        <span className='marR4' style={{ color: 'red' }}>*</span>
                      }
                      {ele.name}
                    </div>
                    {/* 正文 */}
                    <div className='flex jcsb align_center'>
                      <InputNumber
                        placeholder='-'
                        className='w90'
                        disabled={ele.display !== 1}
                        value={ele.min_range}
                        min={0}
                        formatter={(value) => value ? `${value} m` : ''}
                        parser={(value) => value ? value.replace(' m', '') : ''}
                        onChange={(e) => {
                          e = e ? Number(e.toFixed(0)) : e
                          let ala = alarmDistance
                          ala.originalData[index].min_range = e
                          setAlarmDistance({ ...ala })
                          if (ala.originalData[index].max_range < e) {
                            ala.originalData[index].msg = '结束范围必须大于开始范围'
                          } else {
                            ala.originalData[index].msg = ''
                          }
                          setAlarmDistance({ ...ala })
                          checkIsSubmit(ala.originalData)
                        }}
                      />

                      <span >~</span>

                      <InputNumber
                        placeholder='-'
                        className='w90'
                        disabled={ele.display !== 1}
                        value={ele.max_range}
                        min={0}
                        formatter={(value) => value ? `${value} m` : ''}
                        parser={(value) => value ? value.replace(' m', '') : ''}
                        onChange={(e) => {
                          e = e ? Number(e.toFixed(0)) : e
                          let ala = alarmDistance
                          ala.originalData[index].max_range = e
                          setAlarmDistance({ ...ala })
                          if (ala.originalData[index].min_range > e) {
                            ala.originalData[index].msg = '结束范围必须大于开始范围'
                          } else {
                            ala.originalData[index].msg = ''
                          }
                          setAlarmDistance({ ...ala })
                          checkIsSubmit(ala.originalData)
                        }}
                      />

                      <Switch
                        checked={ele.display}
                        onChange={(e) => {
                          let ala = alarmDistance
                          ala.originalData[index].display = e ? 1 : 0
                          setAlarmDistance({ ...ala })
                          if (!e) {
                            ala.originalData[index].msg = ''
                          } else {
                            if (ala.originalData[index].max_range < ala.originalData[index].min_range) {
                              ala.originalData[index].msg = '结束范围必须大于开始范围'
                            } else {
                              ala.originalData[index].msg = ''
                            }
                          }
                          setAlarmDistance({ ...ala })
                          checkIsSubmit(ala.originalData)
                        }}
                      />
                    </div>
                    {/* 提示文字 */}
                    <div className='h24'
                      style={{
                        display: ele.msg ? `` : `none`
                      }}
                    >
                      <span style={{ color: '#ff4d4f' }}> {ele.msg}</span>
                    </div>
                    {/* 相似度 */}
                    <div className='pdB8' style={{ paddingTop: ele.msg ? `` : `8px` }}>
                      <InputNumber
                        value={ele.threshold}
                        // style={{ display: ele.threshold ? `` : `none`, }}
                        placeholder='相似度'
                        min={0.1}
                        max={1}
                        step={0.1}
                        onChange={(e) => {
                          e = e ? e : null
                          let ala = alarmDistance
                          ala.originalData[index].threshold = e
                          setAlarmDistance({ ...ala })
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
          </Spin>
        </Drawer>

        {/* <Drawer title="警戒线" width={256} placement="right" open={cordonOpen}
          onClose={() => {
            setCordonOpen(false);
          }}
          extra={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  document.getElementById('cordonSubmit').click();
                }}
              >
                提交
              </Button>
            </Space>
          }
        >
          <Spin spinning={cordonLoading}>
            <Form
              layout='vertical'
              form={form}
              onFinish={onFinish}
            >
              <div className='flex_row align_end justify_between'>
                <Form.Item label="警戒线" name='cordon' rules={[
                  {
                    required: cordonEnable,
                    message: '警戒线距离!',
                  },
                ]}>
                  <InputNumber disabled={cordonEnable ? false : true} placeholder="警戒线距离" className='w150' />
                </Form.Item>
                <Form.Item>
                  <Switch checked={cordonEnable} onChange={(e) => { setCordonEnable(e) }} />
                </Form.Item>
              </div>

              <div>
                <span className='col_error'>※</span><span className='opa45'> 说明：在管道两边设定距离显示，仅起到警示作用</span>
              </div>

              <Form.Item style={{ display: 'none' }}>
                <Button type="primary" id='cordonSubmit' htmlType="submit">Submit</Button>
              </Form.Item>
            </Form>
          </Spin>
        </Drawer> */}
      </div>
    </div>
  )
}
