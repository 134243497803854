// 登录相关请求
import req from './index'

//获取主管线列表
export const compareImages = (params) => {
  return req.get('api/admin.php?s=Admin.Routes.InsImageList_Compare', params)
}

export const cilckImg = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.FaultCheckList_Set', params)
}

//获取对比图片
export const getContrastImagesAPI = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.InsFixImageList_Compare', params)
}