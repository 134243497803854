// 巡检直播
import { useState, useEffect } from 'react'
import { Button, Radio, Space, Select, message } from 'antd'
import { getAirportAddress, getLive } from '@/services/inspection_live_broadcast.js'
import local from '@/utils/local'
import warningImg from '@/common/img/inspection_live_broadcast/warning.svg';


export default function InspectionLiveBroadcast () {

  const token = local.get('cruise_m30_token');
  const [isOpen, setIsOpen] = useState(false)//是否在直播
  const [msg, setMsg] = useState({
    code: '',
    message: '',
  })//直播信息


  const [airportType, setAirportType] = useState(null)//当前所选择的机场
  const [airportDictionary, setAirportDictionary] = useState([])//机场字典


  const [type, setType] = useState(1);//当前选中的直播
  const [address, setAddress] = useState({})//获取的所有直播地址



  const getAllData = async () => {//获取多机场信息
    let res = await getAirportAddress({ access_token: token });
    if (res.ret !== 200) {
      message.warning(res.msg);
      return
    }
    if (res.data.items.length < 1) {
      message.warning('暂未获取到多机场信息，请稍后重试');
      return;
    }

    let airDic = res.data.items.map((res) => {
      return {
        value: res.id,
        label: res.code,
      }
    })

    setAirportType(airDic[0].value)
    setAirportDictionary([...airDic])
    getLiveAddress(airDic[0].value)
  }


  const getLiveAddress = async (type) => {//获取直播地址
    let res = await getLive({ access_token: token, parare_id: type });
    if (res.ret === 200 && res.data && JSON.stringify(res.data) !== '{}') {
      setAddress({ ...res.data })
      setIsOpen(true)
      liveAddress(res.data?.cam[0] || '')
    } else {
      setAddress({})
      message.warning('暂未获取到直播地址，请刷新后重试！')
      setIsOpen(false)
    }
  }

  //切换播放
  const changeType = (type, num) => {
    if (type === 1) {
      liveAddress(address.cam[num])
    } else {
      liveAddress(address.fpv[num])
    }
  }

  const liveAddress = (url) => {  //获取直播地址
    setIsOpen(true)
    if (document.getElementById('Fplayer')) {
      document.getElementById('Fplayer').innerHTML = ''
      // 创建阿里云播放器dom
      const Fplayer = document.getElementById('Fplayer')
      const Cplayer = document.createElement('div')
      Fplayer.display = 'none'
      Cplayer.id = 'J_prismPlayer'
      Fplayer.appendChild(Cplayer)
      // let url = 'artc://video.9zhouzhineng.com/Parare/fpv_4'
      // let url = 'https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv'
      StartVideoFun(url)
    }
  }

  //开启直播
  const StartVideoFun = async (url) => {
    console.log('当前播放的地址为：', url);
    let player = new Aliplayer(
      {
        id: `J_prismPlayer`,
        source: url,//视频的播放凭证，可以在通用媒体管理服务控制台获取。
        width: '100%', //容器的大小
        height: '100%',
        format: "video",
        mediaType: "video",
        autoplay: true,
        isLive: true,
        rePlay: true,
        playsinline: true,
        preload: true,
        controlBarVisibility: "hover",
        useH5Prism: true,
        skinLayout: [
          { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
          { name: "H5Loading", align: "blabs", x: "calc(50% - 30px)", y: 'calc(50% - 30px)' },
          {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
              { name: "liveDisplay", align: "tlabs", x: 15, y: 6 },
              { name: "fullScreenButton", align: "tr", x: 10, y: 10 },
            ]
          }
        ],
        components: [
          { name: 'adComponent', type: StaticAdComponent },
        ]
      }, (player) => {
        console.log("播放器创建成功~");
      }
    );
  };

  // Aliplayer配置生命周期
  const StaticAdComponent = Aliplayer.Component({
    play: () => {
    },
    waiting: () => {
    },
    playing: () => {
      setIsOpen(true)
      //console.log('正在播放中');
    },
    error: (player, e) => {
      setIsOpen(true)
      let obj = { ...msg }
      obj.code = '111'
      obj.message = '浏览器不支持该链路，请使用备用地址或者使用谷歌浏览器'
      setMsg({ ...obj })
      // message.warning('浏览器不支持该链路，请使用备用地址或者使用谷歌浏览器')
    },
    ended: (player, e) => {
      setIsOpen(false)
      let obj = { ...msg }
      obj.code = '111'
      obj.message = '直播未开启'
      setMsg({ ...obj })
    }
  })

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="padd_24 flex-col hei100 tablebackcol">
      <div className='pdB24 flex jcsb align_center'>
        <>
          {
            airportDictionary.length > 0
              ? (
                <Select
                  value={airportType}
                  bordered={false}
                  options={airportDictionary}
                  onChange={(e) => {
                    setAirportType(e)
                    getLiveAddress(e)
                    setType(1)
                  }}
                />
              )
              : (
                <div></div>
              )

          }
        </>


        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          value={type}
          onChange={(e) => {
            if (JSON.stringify(address) !== '{}') {
              setType(e.target.value)
              changeType(e.target.value, 0)
            } else {
              message.warning('暂未获取到直播地址，请刷新后重试！')
              let obj = { ...msg }
              obj.code = '111'
              obj.message = '浏览器不支持该链路，请使用备用地址或者使用谷歌浏览器'
              setMsg({ ...obj })
            }
          }}>
          <Radio.Button value={1}>巡检镜头</Radio.Button>
          <Radio.Button value={2}>FPV镜头</Radio.Button>
        </Radio.Group>

      </div>
      <div className='flex-col tablebackcol hei100'>
        <div style={{ display: isOpen ? `` : `none` }} id='Fplayer' className='wid100 hei100'></div>
        <div
          style={{ display: isOpen ? `none` : ``, }}
          className='wid100 hei100'
        >
          <div
            className='wid100 hei100'
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={warningImg} alt='错误' style={{ height: '80px', width: '80px' }}></img>
            <div style={{ display: 'flex' }}>
              {isOpen
                ? (
                  <>
                    <div style={{ display: msg.code === '111' ? `none` : `` }}>
                      <span style={{ paddingRight: '16px' }}>error_code:</span>
                      <span style={{ paddingRight: '16px' }}>{msg.code}</span>
                    </div>
                    <span style={{ fontSize: '24px' }}>{msg.message}</span>
                  </>
                )
                : (<span style={{ fontSize: '24px' }}>直播未开启</span>)
              }
            </div>
            <Space className='flex jcc  align_center marT16 marB16' style={{ display: JSON.stringify(address) !== '{}' ? `none` : `` }}>
              <Button type="primary" onClick={() => {
                if (JSON.stringify(address) !== '{}') {
                  changeType(type, 0);
                } else {
                  getLiveAddress(airportType)
                }
              }}>
                Try Again
              </Button>
            </Space>

            <Space style={{ display: JSON.stringify(address) !== '{}' ? `` : `none` }}>
              {
                JSON.stringify(address) !== '{}' && address[type === 1 ? `cam` : `fpv`].map((res, index) => {
                  return (
                    <Button
                      // type="primary"
                      key={index}
                      onClick={() => {
                        if (JSON.stringify(address) !== '{}') {
                          changeType(type, index);
                        } else {
                          message.warning('暂未获取到直播地址，请刷新后重试！')
                        }
                      }}
                    >
                      {`备用地址${index + 1}`}
                    </Button>
                  )
                })
              }
            </Space>
          </div>
        </div>
      </div>
    </div>
  )
}
