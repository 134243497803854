// 巡检地图
import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { message, InputNumber, Input, Card, Divider, Form, Select, Layout, Button, Modal, Switch } from 'antd'
import {
  NodeIndexOutlined, EnvironmentOutlined, BlockOutlined,
  SaveOutlined, ExclamationCircleOutlined, SyncOutlined, MenuOutlined,
  DeleteOutlined, VerticalAlignBottomOutlined
} from '@ant-design/icons';
import {
  Amap as RMap, config as AmapReactConfig, Marker as AmapMarker, Markers as AmapMarkers,
  BezierCurve, PolylineEditor, Polyline, Scale, TileLayer, LabelsLayer as AmapLabelsLayer, LabelMarker as AmapLabelMarker,
} from '@amap/amap-react';
import { transformGCJ2WGS, transformWGSGCJ2 } from "@/common/js/common.js" //引入公共方法
import { GetRoutesPoint, SetRoutesPoint, GetRoutesPointAction, SetRoutesPointAction, GetRoutesInfo, SetTakeOff, GetAltitude } from '@/services/shipping_line' //接口
import home from "@/common/img/shipping_line/home.png" //起飞点
import waypoint from "@/common/img/shipping_line/waypoint.svg" //航点
import waypointCheck from "@/common/img/shipping_line/waypoint_check.svg" //航点
import dayjs from 'dayjs';
import local from '@/utils/local'
import mapNode from "@/common/img/shipping_line/dotPoint.svg" //管线节点
import Frame from '@/common/img/pipeline_gad_map/Frame.svg'
import ExportJsonExcel from "js-export-excel";//生成|导出excel

import './pipeline_gad_map_show.css'
const { Content } = Layout;

AmapReactConfig.key = '193db6af15a86cd8d48b102ab461b002';

function NewGodMap () {
  const [form] = Form.useForm();
  const location = useLocation();//接受传值数据
  const PID = location.search.slice(1);//航线id
  // const token = localStorage.getItem('token');
  const token = local.get('cruise_m30_token');

  const wideNum = 1;//拍照 广角 幅宽幅长比例参数
  const zoomNum = 1;//拍照 变焦 幅宽幅长比例参数
  const irNum = 1;//拍照 热成像 幅宽幅长比例参数

  const mapRef = useRef();
  const [zoom, setZoom] = useState(17); //地图缩放比例
  const scale = 3875.8509003722434;
  const [mapScale, setmapScale] = useState(3875.8509003722434) // 地图比例尺
  const [center_point, setCenterPoint] = useState([116.480881, 39.989410]);//地图中心点
  const [opacity, setOpacity] = useState(true);//切换图层
  const mapkeys = [
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97',
    'db8f2c84c6785572755c3f25fd58ac97'
  ];//天地图key
  const [choose_map_key, setChooseMapKey] = useState(0);//随机 0，1，2从 mapkeys 中取一个地图key

  const [line_data, setLineData] = useState([]);//航线数据
  const [lineTitle, setLineTitle] = useState('');//管线名称
  const [markers, setMarkers] = useState([]);//航点
  const [HomeMarkerInfo, setHomeMarker] = useState({
    type: 1,
    point: [center_point[0], center_point[1]],
    flight_height: '',//飞行高度 || 到达高度
    return_height: '',//返航高度
    uplift_speed: '',//抬升速度
    cruise_speed: '',//巡航速度
    waypoint_speed: '',//航点速度
    // photograph_type: [1],//拍照类型
    // waypoint_remain: true,//航点停留
  })


  //下载文件弹窗参数
  const [downloadModel, setDownloadModel] = useState({
    isOpen: false,
    title: '',
    content: '',
  });

  const isPressCtrl = useRef(false)//是否按下 ctrl 键
  const [isPressCtrlState, setIsPressCtrlState] = useState(false)//是否按下 ctrl 键
  const [drawPipelineList, setDrawPipelineList] = useState({//绘制管线
    isSelect: 0,//是否开始绘制管线
    isDblClick: false,//是否双击
    isAdjustment: false,//是否开始调整管线
    points: [],
  })


  const [dotList, setDotList] = useState([])//打点功能数据
  const [isShowDot, setIsShowDot] = useState(false)//是否展示打点
  const [dotIdx, setDotIdx] = useState(0)//当前选中的打点idx

  //航点点击执行
  const MarkerClick = async (idx) => {
    let lists = [...markers];
    for (let i = 0; i < lists.length; i++) {
      let val = lists[i];
      if (i === idx) {
        if (lists[i].is_check) {

        } else {
          lists[i].is_check = true;
          // return;
        }
      } else {
        lists[i].is_check = false;
      }
    }
    setMarkers([...lists])
  }

  //俯仰度为 -90时
  const GetWidthNew = (data, _scale) => {
    let obj = {
      type: data.type,
      multiple: data.multiple,
      height: data.height,
      scale_ratio: 1,
    }
    let yaw_angle = data.yaw_angle;//偏航角
    let leave_height = data.leave_height;//离开高度
    let hypotenuse = 0;//斜边
    let focal_length = 0;//当前焦距
    let new_hypotenuse = 0;//实际斜边
    let zoom_ratio = 1;//变焦比值
    if (obj.type === 'zoom') {  //变焦
      obj.scale_ratio = zoomNum;//缩放比例

      //变焦镜头 1/2"CMOS
      //焦距范围 21 ~ 75 mm
      //等效焦距范围 113 ~ 405 mm
      //传感器宽高 	6.4 * 4.8  斜边 8  宽:斜 4:5 高:斜  3:5
      //m30变焦相机，当变焦倍数小于等于5时调用的广角相机，当前焦距就是广角相机的焦距 * 当前倍数
      let max_focal = 75;//最大焦距 mm
      let max_equivalent_focal = 405;//最大等效焦距
      let widt_focal = 4.5;//广角相机焦距
      let widt_equivalent_focal = 24;//广角相机等效焦距
      let max_multiple = parseInt(max_focal / 4.5);//最大光学变焦倍数
      let ratio = max_equivalent_focal / max_focal;//最大等效焦距 与 最大焦距的比值 约等于 5.4 
      hypotenuse = 8;//斜边
      if (obj.multiple <= max_multiple) {
        focal_length = widt_focal * obj.multiple
      } else {
        zoom_ratio = Math.round(obj.multiple * widt_equivalent_focal / max_equivalent_focal);//变焦比值
        focal_length = (obj.multiple * 24 / zoom_ratio) / ratio;//当前焦距  (变焦相机等效焦距 = 倍数 * 广角相机等效焦距（24）/变焦比值) / ratio
      }
      new_hypotenuse = parseFloat((obj.height * hypotenuse) / focal_length);//实际斜边
    }
    if (obj.type === 'wide') { //广角
      obj.scale_ratio = wideNum;//缩放比例

      //广角镜头 1/2"CMOS
      //焦距 4.5 mm
      //传感器宽高 	6.4 * 4.8  斜边 8  宽:斜 4:5 高:斜  3:5
      hypotenuse = 8;//斜边
      focal_length = 4.5;//当前焦距
      new_hypotenuse = parseFloat(2 * (obj.height * (hypotenuse / 2) / focal_length));//实际斜边
    }
    if (obj.type === 'ir') { //热成像
      obj.scale_ratio = irNum;//缩放比例

      // DFOV：61°
      let angle = (90 - (61 / 2)) * (Math.PI / 180);//角度
      new_hypotenuse = parseFloat(2 * (obj.height / Math.tan(angle)));
    }
    let _width = parseFloat((new_hypotenuse * 4) / 5 / zoom_ratio).toFixed(2);//幅宽
    let _height = parseFloat((new_hypotenuse * 3) / 5 / zoom_ratio).toFixed(2);//幅高
    var width = parseFloat((_width * (scale / _scale)).toFixed(2));
    var height = parseFloat((_height * (scale / _scale)).toFixed(2));
    let angle = Math.asin(3 / 5) * (180 / Math.PI);
    let len = [
      { len: parseFloat((new_hypotenuse / 2) * (scale / mapScale)).toFixed(2), angle: parseFloat(angle).toFixed(2) },
      { len: parseFloat((new_hypotenuse / 2) * (scale / mapScale)).toFixed(2), angle: parseFloat(angle).toFixed(2) },
      { len: 0, angle: parseFloat(90).toFixed(2) },
    ]
    //
    return [width, width, height, (height / 2 * -1), len, yaw_angle, leave_height, obj.scale_ratio]

  }

  //俯仰度 小于0并且大于-90时
  //相机的拍摄区域与飞行高度、无人机偏航角度、摄像头的俯仰角度有关，如果是变焦相机还与变焦有关
  const GetWidthNew2 = (data, _scale) => {
    let obj = {
      type: '',
      multiple: 5,
      pitch_angle: 90,//俯仰角度
      height: Number(data.flight_height) || 80,//飞行高度  || 到达高度
      scale_ratio: 1,//缩放比值
    }
    let yaw_angle = 0;
    let leave_height = obj.height;
    //根据动作 获取对应的高度、偏航角度、俯仰角度等信息
    //只有在“拍照”动作之前的动作才会在计算之内
    if (data.action && data.action.length >= 0) {
      let types = data.action.map((item) => { return item.type });
      let idx = types?.indexOf(5);
      for (let i = 0; i < data.action.length; i++) {
        let val = data.action[i];
        switch (val.type) {
          case 1://抬升高度
            leave_height = leave_height + Number(val.uplift_height);
            if (i < idx && idx >= 0) { obj.height = obj.height + Number(val.uplift_height); };
            break;
          case 2://下降高度
            leave_height = leave_height - Number(val.decline_height);
            if (i < idx && idx >= 0) { obj.height = obj.height - Number(val.decline_height); };
            break;
          case 3://偏航角
            if (i < idx && idx >= 0) { yaw_angle = val.yaw_angle } break;
          case 15://无人机偏航角
            if (i < idx && idx >= 0) { yaw_angle = val.UAV_angle } break;
          case 4://俯仰角
            if (i < idx && idx >= 0) { obj.pitch_angle = Number(val.pitch_angle) + 90; } break;
          case 7://变焦
            if (i < idx && idx >= 0) { obj.multiple = Number(val.zoom); } break;
          case 5:
            if (val.photograph_type.length > 0) { obj.type = val.photograph_type[0]; }; break;
          default: break;
        }
      }
      if (idx === -1) { //没有拍照，直接退出
        let len = [
          { len: 0, angle: 0 },
          { len: 0, angle: 0 },
          { len: 0, angle: 0 }
        ];
        return [0, 0, 0, 0, len, yaw_angle, leave_height, obj.scale_ratio]
      }
    } else {
      let len = [
        { len: 0, angle: 0 },
        { len: 0, angle: 0 },
        { len: 0, angle: 0 }
      ];
      return [0, 0, 0, 0, len, yaw_angle, leave_height, obj.scale_ratio]
    }
    if (obj.pitch_angle === 0) {
      let o = {
        type: obj.type,
        multiple: obj.multiple,
        height: obj.height,
        yaw_angle: yaw_angle,
        leave_height: leave_height,
      }
      return GetWidthNew(o, _scale)
    }

    if (obj.pitch_angle === 90) {
      let len = [
        { len: Math.floor(Math.sqrt(50000) * (scale / mapScale)), angle: 63.46 },
        { len: Math.floor(Math.sqrt(50000) * (scale / mapScale)), angle: 63.46 },
        { len: Math.floor(200 * (scale / mapScale)), angle: 90 },
      ]
      return [
        Math.floor(200 * (scale / mapScale)),
        Math.floor(200 * (scale / mapScale)),
        2,
        Math.floor(200 * (scale / mapScale)),
        len,
        yaw_angle,
        leave_height,
        obj.scale_ratio
      ]
    }
    let w = 0;//宽
    let h = 0;//高
    let focal_length = 0;//当前焦距
    let zoom_ratio = 1;//变焦比值
    // m30 相机
    if (obj.type === 'zoom') { //变焦
      obj.scale_ratio = zoomNum;//缩放比例

      //变焦镜头 1/2"CMOS
      //焦距范围 21 ~ 75 mm
      //等效焦距范围 113 ~ 405 mm
      //传感器宽高 	6.4 * 4.8  斜边 8  宽:斜 4:5 高:斜  3:5
      //最大倍数 = 变焦相机最大等效焦距÷广角相机等效焦距x数码变焦比值
      let min_focal = 21;//最小焦距 mm
      let max_focal = 75;//最大焦距 mm
      let equivalent_min_focal = 113;//最小等效焦距
      let max_equivalent_focal = 405;//最大等效焦距
      let widt_equivalent_focal = 24;//广角相机等效焦距
      let ratio = max_equivalent_focal / max_focal;//最大等效焦距 与 最大焦距的比值 约等于 3.58 
      let max_multiple = parseInt(max_focal / 4.5);//最大光学变焦倍数
      let widt_focal = 4.5;//广角相机焦距
      w = 6.4;
      h = 4.8;
      if (obj.multiple <= max_multiple) {
        focal_length = widt_focal * obj.multiple
      } else {
        zoom_ratio = Math.round(obj.multiple * widt_equivalent_focal / max_equivalent_focal);//变焦比值
        focal_length = (obj.multiple * 24 / zoom_ratio) / ratio;//当前焦距  (变焦相机等效焦距 = 倍数 * 广角相机等效焦距（24）/变焦比值) / ratio
      }
    }

    if (obj.type === 'wide') { //广角
      obj.scale_ratio = wideNum;//缩放比例

      //广角镜头 1/2"CMOS
      //焦距 4.5 mm
      //传感器宽高 	6.4 * 4.8  斜边 8  宽:斜 4:5 高:斜  3:5
      w = 6.4;
      h = 4.8;
      focal_length = 4.5;//当前焦距
    }

    if (obj.type === 'ir') { //热成像
      obj.scale_ratio = irNum;//缩放比例

      // DFOV：61°
      // 焦距：9.1 mm
      let tan = 0.589; // tan(61/2) 约等于
      focal_length = 9.1;
      let hypotenuse = 2 * tan * focal_length;//斜边 约等于 10.7198 
      w = 4 * hypotenuse / 5;
      h = 3 * hypotenuse / 5;
    }

    //开始计算相机拍摄区域
    let AH = obj.height / Math.cos((obj.pitch_angle) * (Math.PI / 180)); //邻边比斜边
    let j_DAI = 2 * Math.asin((h / 2) / (Math.sqrt(Math.pow((h / 2), 2) + Math.pow(focal_length, 2)))) * (180 / Math.PI); // 角DAI的角度
    let AD = obj.height / Math.cos(((j_DAI / 2) + obj.pitch_angle) * (Math.PI / 180));
    let j_BAC = 2 * Math.asin((w / 2) / Math.sqrt(Math.pow(Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)) / 2, 2) + Math.pow(focal_length, 2))) * (180 / Math.PI);

    let AC = AD / Math.cos((j_BAC / 2) * (Math.PI / 180));
    let DC = Math.sqrt(Math.pow(AC, 2) - Math.pow(AD, 2));
    let BC = 2 * DC;
    let DH = Math.sqrt(Math.pow(AD, 2) + Math.pow(AH, 2) - (Math.cos((j_DAI / 2) * (Math.PI / 180)) * 2 * AD * AH));
    let j_AHG = 90 - obj.pitch_angle;
    let j_HAI = j_DAI / 2;
    let j_AIH = 180 - j_AHG - j_HAI;
    let HI = AH * Math.sin(j_HAI * (Math.PI / 180)) / Math.sin(j_AIH * (Math.PI / 180));
    let FE = HI * BC / DH;
    let DI = DH + HI;
    let HG = Math.sqrt(Math.pow(AH, 2) - Math.pow(obj.height, 2));
    let DG2 = Math.pow(AD, 2) - Math.pow(obj.height, 2);
    let CG = Math.sqrt(Math.pow(DC, 2) + DG2);
    let j_DCG = Math.acos(DC / CG) * (180 / Math.PI);

    // let IA = (FE / 2) / Math.tan((j_BAC / 2) * (Math.PI / 180));

    let IG = obj.height * Math.tan((obj.pitch_angle - j_HAI) * (Math.PI / 180));
    let EG = Math.sqrt(Math.pow(IG, 2) + Math.pow(FE / 2, 2));
    let j_IEG = Math.acos((FE / 2) / EG) * (180 / Math.PI);
    let len = [
      { len: Math.floor(EG * (scale / mapScale)), angle: parseFloat(j_IEG).toFixed(2) },
      { len: Math.floor(CG * (scale / mapScale)), angle: parseFloat(j_DCG).toFixed(2) },
      { len: Math.floor(HG * (scale / mapScale)), angle: 90 },
    ]
    return [
      Math.floor(BC * (scale / mapScale) / zoom_ratio),//梯形上底
      Math.floor(FE * (scale / mapScale) / zoom_ratio),//梯形下底
      Math.floor(DI * (scale / mapScale) / zoom_ratio),//梯形高
      Math.floor((HG - HI) * (scale / mapScale)),//偏移
      len,//线 中心点到梯形4个点的距离
      yaw_angle,//旋转角度
      leave_height,//离开高度
      obj.scale_ratio,//缩放比值
    ]

  }

  //获取航点| 管线节点  1 管线节点  2 航点
  const GetRoutesPointFun = async (type, c) => {
    let res = await GetRoutesPoint({
      access_token: token,
      id: PID,//航线id
      type: type || 1
    });
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return c();
    }
    if (!res.data.length || res.data.length === 0) {
      message.warning('暂无管线节点');
      return c();
    }
    let arr = res.data.map((item) => {
      let d = transformGCJ2WGS({ lat: item.latitude, lon: item.longitude });
      return [Number(parseFloat(d.lon).toFixed(7)), Number(parseFloat(d.lat).toFixed(7))];
    })
    setLineData([...arr]);
    return c(arr[0])
  }

  //获取管线详细信息以及起飞点信息
  const GetRoutesInfoFun = async (point) => {
    let obj = {
      access_token: token,
      id: PID,
    }
    let res = await GetRoutesInfo(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    let val = res.data;
    let d
    if (val.latitude && val.longitude) {
      let p = transformGCJ2WGS({ lat: val.latitude, lon: val.longitude });
      d = [Number(parseFloat(p.lon).toFixed(7)), Number(parseFloat(p.lat).toFixed(7))]
    } else { //如果起飞点的经纬度为空，则显示管线的第一个点，没有则显示成都的经纬度
      if (point) {
        d = point;
      } else {
        d = [104.06, 30.67];
      }
    }
    setCenterPoint(d);
    let info = {
      id: val.id,
      point: d,
      flight_height: Number(val.takeoffaltitude) || 0,//飞行高度 || 到达高度
      return_height: Number(val.gohomealtitude) || 0,//返航高度
      uplift_speed: Number(val.safespeed) || 0,//抬升速度
      cruise_speed: Number(val.curspeed) || 0,//巡航速度
      waypoint_speed: Number(val.waypointspeed) || 0//航点速度
    }
    setLineTitle(res?.data?.Rtitle)
    setHomeMarker({ ...info })
  }

  //获取航点以及航点动作
  const GetRoutesPointActionFun = async () => {
    let obj = {
      access_token: token,
      id: PID,
      type: 1
    }
    let res = await GetRoutesPointAction(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    if (!res.data.length || res.data.length === 0) { //没有航点
      return;
    }
    let arr = [];
    let altitudes_list = [];
    for (let i = 0; i < res.data.length; i++) {
      let item = res.data[i];
      let d = transformGCJ2WGS({ lat: item.latitude, lon: item.longitude });
      let obj = {
        id: item.id,
        name: item.name,
        // point: [Number(parseFloat(d.lon).toFixed(7)), Number(parseFloat(d.lat).toFixed(7))],
        point: [Number(item.wgs84_longitude), Number(item.wgs84_latitude)],
        flight_height: Number(item.altitude) || 0,//飞行高度 || 到达高度
        yaw_angle: 0,//偏航角度
        src: item.pic,
        action: [],//动作
      }
      altitudes_list.push(Number(item.asl));//地表海拔 海拔(高程)

      if (item.aclist && item.aclist.length > 0) {

        obj.action = item.aclist.map((val) => {
          let obj_action = {
            type: val.type,
            uplift_height: '',//抬升高度
            decline_height: '',//下降高度
            UAV_angle: '',//无人机偏航角度
            yaw_angle: '',//云台偏航角度
            pitch_angle: '',//俯仰角度
            zoom: '',//变焦倍数
            photograph_type: [],//拍照类型
            readout_type: null,//读数 - 显表类型
            readout_unit: '',//读数 - 数据单位
            readout_range: ['', ''],//读数 - 合理范围
            temperature_range: ['', ''],//温度 - 合理范围
            photography_state: true,//摄像是否开启
            lighting_state: true,//照明辅助是否开启
          }
          switch (val.type) {
            case 1: obj_action.uplift_height = Number(val.value); break;
            case 2: obj_action.decline_height = Number(val.value); break;
            case 3: obj_action.yaw_angle = val.value; obj.yaw_angle = val.value; break;//云台偏航角
            case 4: obj_action.pitch_angle = val.value; break;
            case 5: obj_action.photograph_type = [(val.value)]; break;
            case 6: obj_action.photography_state = (Number(val.value) === 1 ? true : false); break;
            case 7: obj_action.zoom = val.value; break;
            case 8: obj_action.readout_range = val.value !== '' ? [val.value.split('-')[0], val.value.split('-')[1]] : ['', '']; break;
            case 9: obj_action.lighting_state = (Number(val.value) === 1 ? true : false); break;
            case 10: obj_action.temperature_range = val.value !== '' ? [val.value.split('-')[0], val.value.split('-')[1]] : ['', '']; break;
            case 15: obj_action.UAV_angle = val.value; obj.UAV_angle = val.value; break;//无人机偏航角
            default:
              break;
          }
          return obj_action
        })
      }
      let wh = GetWidthNew2(obj, mapScale)
      obj.width = Number(wh[0]);
      obj.height = Number(wh[1]);
      obj.z = Number(wh[2]);
      obj.offset = Number(wh[3]);//偏移
      obj.line = wh[4];
      obj.yaw_angle = Number(wh[5]);
      obj.leave_height = wh[6];
      obj.scale_ratio = wh[7];
      arr.push(obj);
    }
    setMarkers([...arr]);
  }


  // //下载标识桩模板 type:下载方式 1、下载 2、另存为
  const downloadStakeTemplate = (type = 1) => {
    let arrData = []
    arrData.push({ number: '序号', longitude: '经度', latitude: '纬度', })
    drawPipelineList.points.forEach((res, index) => {
      let obj = { number: index + 1, longitude: res[0], latitude: res[1] }
      arrData.push(obj)
    })
    // arrData.unshift()
    let option = {};
    option.fileName = `修改的(${lineTitle})管线`;
    if (type === 2) { //是否将excel转为文件
      option.saveAsBlob = true;
    }
    option.datas = [
      {
        // sheetData: [
        //   { number: '序号', longitude: '经度', latitude: '纬度', name: '名称' },
        //   { number: '1', longitude: '104.2108974', latitude: '30.8545486', name: '测试1' },
        //   { number: '2', longitude: '104.2098443', latitude: '30.8546942', name: '测试2' },
        //   { number: '3', longitude: '104.2084056', latitude: '30.8549502', name: '测试3' },
        // ],
        sheetData: arrData,
        sheetName: 'Sheet1',
        sheetFilter: ['number', 'longitude', 'latitude',],
        columnWidths: [10, 10, 10, 10],
      },
    ];
    let toExcel = new ExportJsonExcel(option); //new
    if (type === 1) {
      toExcel.saveExcel();
      let info = { ...downloadModel };
      info.isOpen = false;
      setDownloadModel(info);
    } else {
      let file = toExcel.saveExcel(); //保存
      saveAs(file, `修改的(${lineTitle})管线`);
    }

  }

  //另存为
  const saveAs = async (file, fileName) => {
    try {
      const opts = {
        types: [
          {
            description: '文件',
            accept: {
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls']
            },
          }
        ],
        suggestedName: fileName,
        excludeAcceptAllOption: true
      };
      const handle = await window.showSaveFilePicker(opts); // 打开保存文件对话框
      const writable = await handle.createWritable(); // 创建可写入的文件对象

      // 在这里写入文件内容
      await writable.write(file);
      await writable.close();
      message.success('文件保存成功');
      let info = { ...downloadModel };
      info.isOpen = false;
      setDownloadModel(info);
    } catch (error) {
      let text = JSON.stringify(error);
      if (text.indexOf('The user aborted a request') === -1) {//无法使用另存为
        message.warning('当前浏览器暂不支持另存为操作，请直接下载 或更换以下浏览器：谷歌、edge、360');
      }
    }
  }

  //关闭下载弹窗
  const closeDownloadModel = () => {
    let info = { ...downloadModel };
    info.isOpen = false;
    setDownloadModel(info);
  }


  const handleKeyDown = (event) => {
    if (event.ctrlKey) {
      console.log('Ctrl 键被按下了！');
      // 在这里执行你需要的操作
      isPressCtrl.current = !isPressCtrl.current
      setIsPressCtrlState(isPressCtrl.current)
    }
  };

  useEffect(() => { //页面加载完成执行   &&   监听按键
    let num = Math.floor(Math.random() * 3);
    setChooseMapKey(num);
    GetRoutesPointFun(1, (point) => {
      GetRoutesInfoFun(point);//获取起飞点信息
    });//获取管线节点
    GetRoutesPointActionFun();//获取航点以及动作



    // 添加键盘按下和释放的事件监听器
    document.addEventListener('keydown', handleKeyDown);

    // 清理函数，在组件卸载时调用
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };

    //eslint-disable-next-line
  }, [])

  //地图放大缩小执行
  useEffect(() => {
    if (markers && markers.length === 0) {
      return;
    }
    let arr = [...markers];
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i];
      let wh = GetWidthNew2(val, mapScale);
      val.width = Number(wh[0]);
      val.height = Number(wh[1]);
      val.z = Number(wh[2]);
      val.offset = Number(wh[3]);
      val.line = wh[4];
      val.yaw_angle = Number(wh[5]);
      val.leave_height = wh[6];
      val.scale_ratio = wh[7];
    }
    setMarkers([...arr]);
    //eslint-disable-next-line
  }, [mapScale])


  return (
    <div style={{ height: '100vh' }}>
      {/* 地图 */}
      <RMap
        // mapStyle="amap://styles/whitesmoke"
        features={[]}
        ref={mapRef}
        zoom={zoom}
        center={center_point}
        onZoomChange={(map) => {
          // zoom 最大20 最小2
          setZoom(map.getZoom());
          setmapScale(map.getScale())
        }}
        onClick={(e, value) => {
          if (drawPipelineList.isSelect === 1) {
            let dra = drawPipelineList
            dra.points.push([value.lnglat.lng, value.lnglat.lat])
            setDrawPipelineList({ ...dra })
          }
        }}
        onDragEnd={async (map) => { //地图拖动结束执行
          setCenterPoint(map.getCenter().toArray());
        }}
      >
        {/* 天地图图层 */}
        <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=vec_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />
        <TileLayer getTileUrl={'http://t0.tianditu.gov.cn/DataServer?T=img_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} opacity={opacity} />
        <TileLayer getTileUrl={'http://t4.tianditu.gov.cn/DataServer?T=cia_w&tk=' + mapkeys[choose_map_key] + '&x=[x]&y=[y]&l=[z]'} />

        <>{/* 起飞点 */}
          {
            HomeMarkerInfo && JSON.stringify(HomeMarkerInfo) !== '{}'
              ?
              (
                <AmapMarker
                  visiable="true"
                  draggable={false}
                  position={HomeMarkerInfo.point}
                  zIndex={99999}
                  offset={[0, 0]}
                >
                  <img alt='起飞点' style={{ width: "48px", height: "48px" }} src={home} />
                </AmapMarker>
              ) : null
          }

        </>

        <>{/* 航点 */}
          {
            markers && markers.length > 0
              ?
              (
                markers.map((val, idx) => {
                  return (
                    <AmapMarker
                      features={[]}
                      key={idx}
                      visiable={isPressCtrlState}
                      draggable={false}
                      position={val.point}
                      extData={idx}
                      offset={[0, 11]} //偏移量
                      onClick={() => { //点击执行
                        MarkerClick(idx);
                      }}
                      angle={val.yaw_angle}
                      // angle={0}
                      onDragStart={() => {
                        if (val.is_check) return;
                        MarkerClick(idx);
                      }}
                      onDragEnd={(m) => {
                        let lnglat = m.getPosition();
                        onWaypointDragEnd(m.getExtData(), lnglat);
                      }}
                    >
                      {/* 航点 */}
                      <div className='opa80' style={{ display: isPressCtrlState ? `none` : '', pointerEvents: 'none' }}>
                        <div className='pos_rel ' style={{ zIndex: 10, }}>
                          <img alt="航点选中" src={waypoint} style={{ width: '22px', height: '22px', display: val.src ? 'none' : '' }} />
                          <div
                            style={{
                              width: '22px',
                              height: '22px',
                              display: val.src ? '' : 'none'
                            }}
                          />
                        </div>
                        <div
                          className='pos_abs '
                          style={{
                            overflow: 'hidden',
                            display: val.src ? '' : 'none',
                            zIndex: 10,
                            width: val.width * val.scale_ratio + 'px',
                            height: val.z * val.scale_ratio + 'px',
                            top: (-((val.z) / 2) + 11) * val.scale_ratio + 'px',
                            // top: 11 + 'px',
                            left: (-((val.width) / 2) + 11) * val.scale_ratio + 'px',
                            // left: 11 + 'px',
                          }}>
                          <img
                            alt='拍照'
                            src={val.src}
                            style={{
                              width: val.width * val.scale_ratio + 'px',
                              height: val.z * val.scale_ratio + 'px',
                              zIndex: 9
                            }}
                          />
                        </div>
                      </div>
                    </AmapMarker>
                  )
                })
              )
              : ''
          }
        </>

        <>{/* 通过高德折线   绘制管线 */}
          {
            drawPipelineList.isSelect === 1//选择管线
              ? (
                <>{/* 点击选择的管线 */}
                  <AmapLabelsLayer zooms={[0, 20]} zIndex={1000} collision={zoom > 19 ? false : true}>
                    {
                      drawPipelineList.points.map((val, idx) => {
                        return (
                          <div key={idx}>
                            {/* 点位 */}
                            <AmapMarker
                              offset={[-8, -8]} //偏移量
                              zIndex={15}
                              key={idx}
                              visiable={true}
                              position={val}
                              icon={{
                                image: Frame,
                                size: [16, 16],
                                anchor: [8, 8]
                              }}
                              text={{ content: (idx + 1) + '', direction: 'right', offset: [0, -2], style: { fontSize: 14, fillColor: 'white', strokeColor: 'black', strokeWidth: 5 } }}
                            />

                            <>
                              {/* 管线 */}
                              {
                                idx > 0 &&
                                <BezierCurve
                                  zIndex={9}
                                  path={[drawPipelineList.points[idx - 1], drawPipelineList.points[idx]]}
                                  isOutline={true}
                                  outlineColor="#E6E8E6"
                                  borderWeight={2}
                                  strokeColor="#D32029"
                                  strokeOpacity={1}
                                  strokeWeight={4}
                                  strokeStyle="solid" // 线样式还支持 'dashed'
                                  lineJoin='round'
                                  lineCap='round'
                                // strokeStyle是dashed时有效
                                // strokeDasharray={[4, 4]}  //0 设置线段粗细  1 设置线段粗细间隔
                                // zIndex={50}
                                />
                              }
                            </>




                          </div>

                        )
                      })
                    }
                  </AmapLabelsLayer>
                </>

              )
              : (
                // 修饰 管线
                <>
                  {
                    false &&
                    <AmapLabelsLayer zooms={[0, 20]} zIndex={1000} collision={zoom > 19 ? false : true}>
                      {
                        drawPipelineList.points.map((val, idx) => {
                          return (
                            <div key={idx}>
                              {/* 点位 */}
                              <AmapLabelMarker
                                zIndex={5}
                                key={idx}
                                visiable={true}
                                position={[val[0], val[1] + 0.00003]}
                                icon={{
                                  image: '',
                                  size: [16, 16],
                                  anchor: [8, 8]
                                }}
                                text={{ content: (idx + 1) + '', direction: 'right', offset: [8, 1], style: { pointerEvents: 'none', fontSize: 14, fillColor: 'white', strokeColor: 'black', strokeWidth: 5 } }}
                              />
                            </div>

                          )
                        })
                      }
                    </AmapLabelsLayer>
                  }







                  <PolylineEditor
                    zIndex={10}
                    enabled={true}
                    showDir={true}
                    onChange={(poly) => {
                      let dra = drawPipelineList
                      if (poly.getPath().length) {
                        let path = poly.getPath();
                        let arr = []
                        path.forEach((res) => {
                          arr.push([res.lng, res.lat])
                        })
                        dra.points = arr
                      }
                      setDrawPipelineList({ ...dra })
                    }}
                  // text={{ direction: 'right', offset: [0, -2], style: { fontSize: 14, fillColor: 'white', strokeColor: 'black', strokeWidth: 5 } }}
                  >
                    <Polyline
                      path={drawPipelineList.points}
                      // strokeColor="#D32029"
                      strokeWeight={6}
                      strokeOpacity={0.2}
                      fillOpacity={0.4}
                      // fillColor={'#D32029'}
                      zIndex={50}
                      bubble={true}
                    />

                  </PolylineEditor>
                </>
              )
          }
        </>

        <>{/* 打点 */}
          <AmapLabelsLayer style={{ display: (dotList && dotList.length > 0) ? `` : `none` }} zooms={[0, 20]} collision={zoom > 19 ? false : true} >
            {
              dotList.map((val, idx) => {
                return (
                  <div key={idx}>
                    {/* 点位 */}
                    <AmapMarker
                      zIndex={15}
                      offset={[-11, -14]} //偏移量
                      key={idx}
                      visiable={true}
                      position={val.point}
                      onClick={() => {
                        if (dotIdx === (idx + 1)) {
                          let iss = isShowDot
                          iss = !iss
                          setIsShowDot(iss)
                        } else {
                          setDotIdx(idx + 1)
                          setIsShowDot(true)
                        }
                        form.setFieldsValue({
                          latitude: val.point[0],
                          longitude: val.point[1],
                        })
                      }}
                      icon={{
                        image: mapNode,
                        size: [22, 22],
                        anchor: [8, 8]
                      }}
                    >
                    </AmapMarker>

                    <>{/* 管线 */}
                      {
                        idx > 0 && false &&
                        <BezierCurve
                          zIndex={9}
                          path={[dotList[idx - 1].point, val.point]}
                          isOutline={true}
                          outlineColor="#E6E8E6"
                          borderWeight={2}
                          strokeColor="#D32029"
                          strokeOpacity={1}
                          strokeWeight={4}
                          strokeStyle="solid" // 线样式还支持 'dashed'
                          lineJoin='round'
                          lineCap='round'
                        // strokeStyle是dashed时有效
                        // strokeDasharray={[4, 4]}  //0 设置线段粗细  1 设置线段粗细间隔
                        // zIndex={50}
                        />
                      }
                    </>
                  </div>
                )
              })
            }

            <>{/* 编辑经纬度tips */}
              {
                // isShowDot &&
                <AmapMarker
                  zIndex={20}
                  features={[]}
                  // visiable={isPressCtrlState}
                  draggable={false}
                  position={dotList[dotIdx - 1]?.point}
                  // extData={idx}
                  offset={[0, 11]} //偏移量
                >
                  {/* 航点 */}
                  <div className='pos_rel' style={{ zIndex: 999 }}>
                    <div className='pos_abs w188 h104 radius8 pdTB6 pdRL8' style={{ top: '-132px', left: 0, backgroundColor: '#141414', pointerEvents: 'auto', display: isShowDot ? `` : `none` }}>
                      <>
                        <Form
                          form={form}
                          layout='inline'
                          name="userForm"
                          onFinish={(data) => {
                            let dot = dotList
                            dot[dotIdx - 1].point = [data.latitude, data.longitude]
                            setDotList([...dot])
                          }}
                          onFinishFailed={() => { console.log('晓不得啥玩意儿'); }}
                          autoComplete="off"
                        >
                          <Form.Item
                            label="纬度"
                            name="latitude"
                          >
                            <Input size='small' className='w130 h24' />
                          </Form.Item>

                          <Form.Item
                            label="经度"
                            name="longitude"
                          >
                            <Input size='small' className='w130 h24' />
                          </Form.Item>


                          <Form.Item>
                            <Button size='small' className='marR8' onClick={() => {
                              let arr = []
                              let num = 0
                              dotList.forEach((res, index) => {
                                if (dotIdx !== (index + 1)) {
                                  num += 1
                                  let obj = {
                                    idx: num,
                                    point: res.point
                                  }
                                  arr.push(obj)
                                }
                              })
                              setDotList(arr)
                              setIsShowDot(false)
                              setDotIdx(dotIdx > 1 ? (dotIdx - 1) : 1)
                            }}
                            >
                              删除
                            </Button>
                            <Button id='submitForm' type="primary" size='small' htmlType="submit">
                              确定
                            </Button>
                          </Form.Item>
                        </Form>
                      </>

                    </div>
                  </div>
                </AmapMarker>
              }
            </>
          </AmapLabelsLayer>


        </>


        <Scale position={{ bottom: '24px', left: '4px' }} /> {/* 比例尺 */}
        {/* <Toolbar position={{ bottom: '24px', right: '20px' }} /> 按钮缩放 */}

      </RMap>

      {/* 功能按钮 */}
      <div className="pos_abs flex-col" style={{ top: 0 }}>
        <div className='flex-row mar24 radius8 hidden'>
          <div className='flex jcc align_center'>
            <> {/* 导出 */}
              <div className='flex jcc align_center pointer'
                style={{ background: '#1F1F1F', borderRight: '1px solid rgba(255,255,255,0.1)' }}
                onClick={() => {
                  console.log('导出', drawPipelineList.points);
                  let info = { ...downloadModel }
                  info.isOpen = true;
                  info.title = '导出编辑好的管线';
                  info.content = '编辑的管线已准备好，点击按钮下载'
                  setDownloadModel(info);
                }}
              >
                <SaveOutlined className="mar12 w16 h16" style={{ color: 'white' }} />
              </div>
            </>

            <> {/* 绘制管线 */}
              <div className='flex jcc align_center pointer'
                style={{ background: '#1F1F1F', borderRight: '1px solid rgba(255,255,255,0.1)' }}
                onClick={() => {
                  let dra = drawPipelineList
                  if (dra.isSelect === 0) {
                    dra.isSelect = 1
                  } else if (dra.isSelect === 1) {
                    dra.isSelect = 2
                  } else if (dra.isSelect === 2) {
                    dra.isSelect = 1
                  }
                  setDrawPipelineList({ ...dra })
                }}
              >
                <NodeIndexOutlined className="mar12 w16 h16" style={{ color: drawPipelineList.isSelect === 1 ? '#1668DC' : 'white' }} />
              </div>
            </>

            <>{/* 打点 */}
              <div className='flex jcc align_center pointer'
                style={{ background: '#1F1F1F', borderRight: '1px solid rgba(255,255,255,0.1)' }}
                onClick={() => {
                  let obj = {
                    idx: dotList.length + 1,
                    isClick: false,
                    point: center_point
                  }
                  dotList.push(obj)
                  setDotList(dotList)
                  setDotIdx(dotList.length)
                  setIsShowDot(true)
                  form.setFieldsValue({
                    latitude: center_point[0],
                    longitude: center_point[1],
                  });
                }}
              >
                <EnvironmentOutlined className="mar12 w16 h16" style={{ color: 'white' }} />
              </div>
            </>

            <>{/* 切换 */}
              <div className='flex jcc align_center pointer'
                style={{ background: '#1F1F1F', borderRight: '1px solid rgba(255,255,255,0.1)' }}
                onClick={() => {
                  setOpacity(!opacity)
                }}
              >
                <BlockOutlined className="mar12 w16 h16" style={{ color: 'white' }} />
              </div>
            </>


            <> {/* 管线名称 */}
              {
                lineTitle &&
                <div className='h40' style={{ background: '#1F1F1F' }}>
                  <div className='pdTB8 pdRL12 lh24 fw600 fs16' style={{ color: 'white' }}>
                    {lineTitle}
                  </div>
                </div>
              }
            </>
          </div>

          <div className='flex-auto'></div>
        </div>
      </div>



      <Modal title="" width={416} open={downloadModel.isOpen}
        footer={false} closable={false} maskClosable={true}
        onCancel={() => { closeDownloadModel() }}
      >

        <div className='marB8 flex_row align_center'>
          <span className='fs22 marR16 col_primary'><ExclamationCircleOutlined /></span>
          <span style={{ fontWeight: 500, fontSize: 16, color: 'rgba(255, 255, 255, 0.85)' }}>
            {downloadModel.title}
          </span>
        </div>
        <div className='fs14 marL38' style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.85)' }}>
          {downloadModel.content}
        </div>

        <div className='marT24 tr'>
          <Button className='marR8' onClick={() => {
            closeDownloadModel()
          }}>取消</Button>

          <Button className='marR8' type="primary" onClick={() => {
            console.log('123', drawPipelineList.points);
            downloadStakeTemplate(1);
          }}>
            下载
          </Button>

          <Button type="primary" onClick={() => {
            downloadStakeTemplate(2);
          }}>
            另存为
          </Button>
        </div>
      </Modal>
    </div >
  );
};

export default NewGodMap;