// 封装本地储存
const local = {

  get (key) {
    let str = window.localStorage.getItem(key)
    return str
      ? str
      : ''
  },
  set (key, value) {
    window.localStorage.setItem(key, value)
  },
  remove (key) {
    window.localStorage.removeItem(key)
  },
  clear () {
    window.localStorage.clear()
  },
}

// 暴露出去
export default local
