// 风险处理员 - 组队
import { useState, useEffect, useRef } from 'react'
import { Card, Table, Input, Space, Button, Pagination, Modal, message, Transfer, Spin } from 'antd';
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import OperationBuilder from '@/components/TableBuilder/OperationBuilder';
import { setTeamFun, getTeamListFun, GetHandlerUserList, delTeamFun, teamAssignPersonnelFun } from "@/services/risk_handlers.js"; // 引入axios
import local from '@/utils/local'
import { async } from 'ailabel';

function Team() {
  const { confirm } = Modal;

  const token = local.get('cruise_m30_token');
  const companyId = local.get('cruise_m30_companyId');

  const [siftData, setSiftData] = useState({
    teamName: '',
    page: 1,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//总条数

  const searchInput = useRef(null);//搜索框数据

  const [inputTeamName, setInputTeamName] = useState('');//输入的组队名称
  const [modalEditInfo, setModalEditInfo] = useState({ //弹窗信息
    id: '',
    open: false,
    title: '',
  });
  const [modalAssignInfo, setModalAssignInfo] = useState({ // 分配人员 弹窗信息
    id: '',
    all: [],
  });
  const [modalAssignOpen, setModalAssignOpen] = useState(false);
  const [chooseUser, setChooseUser] = useState([]);//选择的用户
  const [modalLoading, setModalLoading] = useState(false);

  const handleSearch = (confirm, dataIndex) => {// 输入框搜索
    confirm()
  };

  const handleReset = (clearFilters, confirm) => { //重置
    clearFilters();
    confirm();
  };

  const getTxtSearch = (dataIndex) => ({//文本 搜索框
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      < div className='pd8' onKeyDown={(e) => e.stopPropagation()} >
        <div>
          <Input ref={searchInput}
            placeholder={`搜索 ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
            onPressEnter={() => handleSearch(confirm)}
          />
        </div>
        <Space className='pdT8 flex jcsb'>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜索
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
        </Space>
      </ div >
    ),
    filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : '' }} />),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  })

  const columns = [
    {
      title: '队名',
      width: 150,
      dataIndex: 'teamName',
      ...getTxtSearch('队名')
    },
    {
      title: '成员',
      dataIndex: 'user',
      render: (text, record) => {
        return <div className='opa45'>{text}</div>
      }
    },
    {
      title: '操作',
      dataIndex: '',
      width: 80,
      align: 'center',
      render: (_, record, index) => OperationBuilder(record, index, 'actions_drop', ButtonList, Operation)
    },
  ];

  //操作按钮
  const ButtonList = [
    {
      key: '0',
      type: 'TeamOutlined',
      label: "分配人员",
      purview: true,
    },
    {
      key: '1',
      type: 'SetUp',
      label: "编辑",
      purview: true,
    },
    {
      key: '2',
      type: 'Delete',
      label: '解散',
      purview: true,
    },
  ]

  //操作按钮 回调
  const Operation = (record, index, type, key) => {
    switch (key) {
      case '0': //分配人员
        setModalAssignOpen(true);
        getAllHandler(record);
        break;

      case '1': //编辑
        let info = { ...modalEditInfo };
        info.open = true;
        info.id = record.id;
        info.title = '编辑';
        setModalEditInfo(info);
        setInputTeamName(record.teamName);
        break;

      case '2': //解散
        confirm({
          title: '解散组队',
          icon: <ExclamationCircleOutlined />,
          content: '是否确认解散当前组队？请谨慎操作',
          okText: '解散',
          cancelText: '取消',
          async onOk() {
            let res = await delTeamFun({
              access_token: token,
              id: record.id
            });
            if (res.ret !== 200) {
              message.warning(res.msg || '数据跑丢了~');
              return;
            }
            message.success('解散成功');
            getTableData(siftData);
          },
          onCancel() {
          },
        });
        break;

      default:
        break;
    }

  };

  const onChange = (pagination, filters, sorter, extra) => {//表头搜索 change方法
    let sift = { ...siftData };
    sift.teamName = filters.teamName ? filters.teamName : '';
    setSiftData(sift);
    getTableData(sift);
  };

  //获取表格数据
  const getTableData = async (sift) => {
    let params = {
      access_token: token,
      CompanyID: companyId,
      groupname: sift.teamName,
    };
    setLoading(true);
    let res = await getTeamListFun(params);
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let arr = [];
    for (let i = 0; i < res.data.length; i++) {
      let val = res.data[i];
      let obj = {
        key: i,
        id: val.id,
        teamName: val.groupname,
        user: val.username ? val.username : '-',
        userId: val.userid ? val.userid.split(',') : [],
      };
      arr.push(obj);
    }
    setTableData(arr);
  }

  //新增 | 编辑队名
  const setTeamName = async (data) => {
    let params = {
      access_token: token,
      CompanyID: companyId,
      id: data.id,
      groupname: data.teamName,
    };
    setModalLoading(true);
    let res = await setTeamFun(params);
    setModalLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    if (data.id) {
      message.success('编辑成功');
    } else {
      message.success('新增成功');
    }
    closeEditModal();
    getTableData(siftData);
  }

  //关闭 新增|编辑弹窗
  const closeEditModal = () => {
    let info = { ...modalEditInfo };
    info.id = '';
    info.open = false;
    info.title = '';
    setModalEditInfo(info);
  }

  //关闭 分配弹窗
  const closeAssignModal = () => {
    let info = { ...modalAssignInfo };
    info.id = '';
    info.all = [];
    setModalAssignInfo(info);
    setModalAssignOpen(false);
    setChooseUser([]);
  }

  //获取所有的处理人员
  const getAllHandler = async (info) => {
    setModalLoading(true);
    let res = await GetHandlerUserList({
      access_token: token,
      page: 0
    });
    setModalLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }

    let arr = [];
    for (let i = 0; i < res.data.items.length; i++) {
      let val = res.data.items[i];
      let obj = {
        key: val.id.toString(),
        title: val.realname,
      };
      arr.push(obj);
    }
    let assign = { ...modalAssignInfo };
    assign.id = info.id;
    assign.all = arr;
    setModalAssignInfo(assign);
    setChooseUser(info.userId)
  }

  //分配人员
  const assignPersonnel = async () => {
    let params = {
      access_token: token,
      CompanyID: companyId,
      userid: chooseUser.length > 0 ? chooseUser.join(',') : '',
      id: modalAssignInfo.id
    }
    setModalLoading(true);
    let res = await teamAssignPersonnelFun(params);
    setModalLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    };
    message.success('分配成功');
    closeAssignModal();
    getTableData(siftData);
  }

  useEffect(() => {
    getTableData(siftData);
    // eslint-disable-next-line
  }, [])

  return (
    <div className='pd24'>
      <Card>
        <div className="flex_row align_center justify_end marB8">
          <Button type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              let info = { ...modalEditInfo };
              info.id = '';
              info.open = true;
              info.title = '新增';
              setModalEditInfo(info);
            }}
          >
            新增
          </Button>
        </div>
        <Table loading={loading} columns={columns} dataSource={tableData} onChange={onChange} pagination={false} />
        {/* <div className='flex justify_end marT8'>
          <Pagination
            total={total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条数据`}
            style={{ marginLeft: 'auto' }}
            current={siftData.page}
            onChange={(current, pageSize) => { //选择当前页后执行
              let sift = { ...siftData };
              sift.page = current;
              sift.pageSize = pageSize;
              setSiftData(sift);
            }}
          />
        </div> */}
      </Card>

      {/* 新增编辑 队名 */}
      <Modal title={modalEditInfo.title} width={360} open={modalEditInfo.open} destroyOnClose={true}
        footer={[
          <Button key="submit" type="primary" loading={modalLoading} onClick={() => {
            let obj = {
              id: modalEditInfo.id,
              teamName: inputTeamName
            }
            setTeamName(obj);
          }}>
            确定
          </Button>
        ]}
        onCancel={closeEditModal}>
        <Input placeholder='队名' value={inputTeamName} onChange={(e) => {
          setInputTeamName(e.target.value);
        }} />
      </Modal>

      <Modal title={'分配人员'} width={650} open={modalAssignOpen} destroyOnClose={true}
        footer={[
          <Button key="submit" type="primary" loading={modalLoading} onClick={async () => {
            assignPersonnel();
          }}>
            确定
          </Button>
        ]}
        onCancel={closeAssignModal}>
        <Transfer
          listStyle={{ height: '400px', width: '280px' }}
          dataSource={modalAssignInfo.all}
          showSearch
          // filterOption={(inputValue, option) => option.description.indexOf(inputValue) > -1}
          targetKeys={chooseUser}
          onChange={(newTargetKeys) => {
            setChooseUser(newTargetKeys);
          }}
          render={(item) => item.title}
        />
      </Modal>

    </div>
  )
}

export default Team;