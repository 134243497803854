// 巡检飞手
import { useState, useEffect } from 'react'
import { Button, Card, Table, Spin, Modal, message } from 'antd'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import OperationBuilder from '@/components/TableBuilder/OperationBuilder';
import HeaderBuilder from '@/components/TableBuilder/HeaderBuilder';
import SetPilots from './set_pilots.js';
import Jurisdiction from './Jurisdiction.js';
import { GetUserList, UserInfo_SetStatus } from "@/services/inspection_pilots"; // 引入Ajax
import local from '@/utils/local'
import SortOrFilter from '@/components/TableBuilder/SortOrFilter';
import { changeDataType } from "@/common/js/common.js" //引入公共方法
import './index.less'

const ButtonList = [
  {
    key: '0',
    type: 'SetUp',
    label: "修改",
    purview: true,
  },
  {
    key: '1',
    type: 'Authority',
    label: "权限",
    purview: true,
  },
  {
    key: 'divider',
    type: 'Divider',
    purview: true,
  },
  {
    key: '3',
    type: 'Delete',
    label: '删除',
    purview: true,
  },
]

export default function Inspector () {
  /**
   * data
   * */
  const { confirm } = Modal;
  const [spinningLoading, _spinningLoading] = useState(false);
  const [List, _List] = useState([]);
  const [total, _total] = useState(0);
  const [AddOrSetUpFlag, setAddOrSetUpFlag] = useState(false);
  const [AddOrSetUpVal, setAddOrSetUpVal] = useState({});
  const [JurisdictionFlag, setJurisdictionFlag] = useState(false);
  const [JurisdictionVal, setJurisdiction] = useState({});
  const [searchCriteria, _searchCriteria] = useState({
    realname: '',
    sex: '',
    order: '',
    username: '',
    phone: '',
    CompanyID: '',
    current: 1,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false); //删除loading
  useEffect(() => {
    onFinish(searchCriteria);
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  /**
   * methods
   * */
  // 巡检员列表
  const onFinish = async (sift_data) => {
    _spinningLoading(true)
    const res = await GetUserList({
      access_token: local.get('cruise_m30_token') || '',
      realname: sift_data.realname,
      sex: sift_data.sex,
      order: sift_data.order,
      username: sift_data.username,
      phone: sift_data.phone,
      CompanyID: sift_data.CompanyID,
      page: sift_data.current,
      perpage: sift_data.pageSize,
    })
    _spinningLoading(false)
    //console.log('巡检员数据');
    //console.log(res);
    if (res.ret == 200) {// eslint-disable-line
      if (res.data.items.length != 0) {// eslint-disable-line
        res.data.items.forEach((item, index) => {
          item.key = item.id;
          if (item.lasttime) {
            let date = changeDataType(item.lasttime);
            item.showDate = date.date;
            item.showTime = date.time;
          } else {
            item.showDate = '';
            item.showTime = '';
          }


        });
      }
      _List(res.data.items || []);
      _total(res.data.total || 0)
    }
  };
  // 右上角操作
  const TopOperation = () => {
    return <div>
      <Button type="primary" className='margL12' icon={<PlusOutlined />} onClick={() => {
        const Val = AddOrSetUpVal
        Val.id = ''
        Val.title = '新增'
        setAddOrSetUpVal(AddOrSetUpVal)
        setAddOrSetUpFlag(true)
      }}>新增</Button>
    </div>
  }
  // 分页、排序、筛选变化时触发
  const handleTableChange = (newPagination, filters, sorter) => {
    let searchBefore = { ...searchCriteria };
    // const searchAfter = SortOrFilter(newPagination, filters, sorter);
    // console.log(searchAfter);
    // // 分页
    // searchBefore.current = searchAfter.newPagination.current;
    // searchBefore.pageSize = searchAfter.newPagination.pageSize;
    // // 筛选 | 搜索
    // searchBefore.realname = searchAfter.filters && searchAfter.filters.realname ? searchAfter.filters.realname : ''
    // searchBefore.sex = searchAfter.filters && searchAfter.filters.sex_s ? searchAfter.filters.sex_s : ''
    // searchBefore.CompanyID = searchAfter.filters && searchAfter.filters.company_s ? searchAfter.filters.company_s : ''
    // searchBefore.username = searchAfter.filters && searchAfter.filters.username ? searchAfter.filters.username : ''
    // searchBefore.phone = searchAfter.filters && searchAfter.filters.phone ? searchAfter.filters.phone : ''

    // //排序
    // searchBefore.order = searchAfter.sorter || ''
    searchBefore.current = newPagination.current;
    searchBefore.pageSize = newPagination.pageSize;
    _searchCriteria({ ...searchBefore })
    onFinish(searchBefore) //加载列表
  };
  //操作回调
  const Operation = (record, index, type, key) => {
    switch (key) {
      case '0':
        const Val = AddOrSetUpVal
        Val.id = record.id
        Val.title = '修改'
        setAddOrSetUpVal(AddOrSetUpVal)
        setAddOrSetUpFlag(true)
        break;
      case '1':
        const Jurisdiction = JurisdictionVal
        Jurisdiction.id = record.id
        setAddOrSetUpVal(Jurisdiction)
        setJurisdictionFlag(true)
        break;
      case '3':
        confirm({
          title: `确定要删除吗?`,
          icon: <ExclamationCircleOutlined />,
          onOk () {
            return new Promise(async (resolve, reject) => {
              const request = await UserInfo_SetStatus({
                access_token: local.get('cruise_m30_token'),
                id: record.id,
                status: 0,
              });
              resolve();
              if (request.ret == 200) {// eslint-disable-line
                message.success(request.msg || '操作成功', 1.5);
                let obj = { ...searchCriteria };
                if (List.length === 1) {
                  if (obj.current !== 1) {
                    obj.current = obj.current - 1;
                  }
                }
                _searchCriteria({ ...obj });
                onFinish(obj) //加载列表
              }
            });
          },
          onCancel () { },
        });
        break;
      default:
        break;
    }
  }
  //弹窗回调
  const onAddOrSetUp = (Flag) => {
    setAddOrSetUpFlag(false)
    setAddOrSetUpVal({})
    if (Flag) onFinish(searchCriteria)
  }
  //权限弹窗回调
  const onJurisdiction = (Flag) => {
    setAddOrSetUpVal({})
    setJurisdictionFlag(false)
    if (Flag) onFinish(searchCriteria)
  }
  const columns = [
    {
      title: '姓名',
      // issearch: true,
      dataIndex: 'realname',
      // width:'250px',
      // render: (_, item, index) => {
      //     return (
      //         <div className='disFlex aliItemsCen'>
      //             <img src={item.headSculpture} style={{ width: 22, height: 22 }}></img>
      //             <span className='margL8'>{item.name}</span>
      //         </div>
      //     );
      // },
    },
    {
      title: '性别',
      dataIndex: 'sex_s',
      // width: '250px',
      // filters: [
      //     {
      //         text: '未知',
      //         value: '0',
      //     },
      //     {
      //         text: '男',
      //         value: '1',
      //     },
      //     {
      //         text: '女',
      //         value: '2',
      //     },
      // ],
    },
    // {
    //     title: '年龄',
    //     dataIndex: 'age',
    //     sorter: {
    //         compare: (a, b) => a.age - b.age,
    //         multiple: 1,
    //     },
    // },
    {
      title: '账号',
      dataIndex: 'phone',
      // width: '250px',
      // issearch: true,
    },
    // {
    //     title: 'ID',
    //     dataIndex: 'username',
    // },
    {
      title: '公司',
      dataIndex: 'company_s',
      // width: '250px',
      // filters: [
      //     {
      //         text: '有舟科技',
      //         value: '1',
      //     },
      // ],
    },
    {
      title: '登录时间',
      dataIndex: 'lasttime',
      // sorter: {
      //     multiple: 2,
      // },
      render: (text, record) => (
        <div>
          <span className='fSize14 opacity0_85 margR8'>{record.showDate}</span>
          <span className='fSize14 opacity0_45'>{record.showTime}</span>
        </div>
      )
    },
    {
      title: '操作',
      dataIndex: 'address',
      align: 'center',
      width: '100px',
      render: (_, record, index) => OperationBuilder(record, index, 'actions_drop', ButtonList, Operation),
    },
  ];
  return (
    <div className="padd_24 flex-col hei100 tablebackcol" style={{ overflowY: 'auto' }}>
      <Spin spinning={spinningLoading}>
        <div className='backcol paddRL24'>
          <div className='flex-row jusConCenSB aliItemsCen' style={{ height: '64px' }}>
            <span className='fSize16 fWei600'>巡检飞手</span>
            <span>{TopOperation()}</span>
          </div>
          <Table
            columns={HeaderBuilder(columns)}
            dataSource={List}
            pagination={{
              total: total,
              current: searchCriteria.current,
              pageSize: searchCriteria.pageSize,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              showQuickJumper: true,
            }}
            onChange={handleTableChange}
          />

        </div>
      </Spin>
      <SetPilots
        AddOrSetUpVal={AddOrSetUpVal}
        visible={AddOrSetUpFlag}
        Operation={onAddOrSetUp}
      >
      </SetPilots>
      <Jurisdiction
        JurisdictionVal={JurisdictionVal}
        visible={JurisdictionFlag}
        Operation={onJurisdiction}
      >
      </Jurisdiction>
    </div>
  )
}
