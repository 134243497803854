// 航线相关请求
import req from './index'

//获取管线
export const GetRoutesList = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesList_Get', params)
}

//新增管线
export const AddRoutes = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesInfo_Add', params)
}

//编辑管线
export const EditRoutes = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesInfo_Edit', params)
}

//删除管线
export const DelRoutes = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesInfo_Del', params)
}

//上传坐标
export const ImportPoint = (params) => {
  return req.file('api/admin.php?s=Admin.Routes.RoutesPoint_Import', params)
}

//上传航线
export const ImportAirway = (params) => {
  return req.file('api/admin.php?s=Admin.Routes.RoutesRoute_Import', params)
}

//定点类型 上传航线
export const ImportAirwayAPI = (params) => {
  return req.file('api/admin.php?s=Admin.Cruise.AccurateRetake_Import', params, 90000)
}

//上传标识桩
export const IdentificationStake = (params) => {
  return req.file('api/admin.php?s=Admin.Routes.RoutesMark_Import', params)
}

//获取管线详细信息 包括起飞点
export const GetRoutesInfo = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesInfo_Get', params)
}

//获取航点
export const GetRoutesPoint = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPoint_Get', params)
}

//编辑航点
export const SetRoutesPoint = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPoint_Edit', params)
}

//根据经纬度获取海拔
export const GetAltitude = (params) => {
  return req.get('api/admin.php?s=Admin.Routes.GetElev9', params)
}

//获取航点以及航点动作
export const GetRoutesPointAction = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPointAction_Get', params)
}

//编辑航点动作
export const SetRoutesPointAction = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPointActoin_Edit', params)
}

//编辑起飞点
export const SetTakeOff = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesTakeOff_Edit', params)
}

// 获取无人机点位
export const getUavpositionInfo = (params) => {
  return req.get('api/admin.php?service=Admin.Cruise.uavposition', params)
}

// 导出
export const exportWaypoints = (params) => {
  return req.get('api/admin.php?s=Admin.Routes.Routes_PointExport', params)
}
// 获取地图中展示编辑与否的权限
export const getLimitsAuthorityInfo = (params) => {
  return req.get('api/admin.php?s=Admin.Routes.GetMapLimitation', params)
}

// 批量添加单元段
export const batchAddUnitSegments = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPoint_Set', params)
}
// 锁定table单个数据
export const lockListData = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.Routes_LockSet', params)
}

// 获取航线总览数据
export const getRouteOverview = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.GetAll_RoutesList', params)
}

// 获取定点类型-风险设置列表
export const getRiskSetListAPI = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.AccurateRetakeList_Get', params)
}


//获取定点类型-风险开关信息
export const getRiskSwitchInfoFun = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.AccurateRetake_Info', params)
}

//设置定点类型-风险开关信息
export const setRiskSwitchInfoFun = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.AccurateRetake_Set', params)
}

//上传图片
export const submitPictureApi = (params) => {
  return req.file('api/admin.php?s=Admin.Cruise.UploadAccurateRetake_pic', params)
}
//下载航线文件
export const downloadRouteApi = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Wayfile_Export', params)
}