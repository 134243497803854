import React, { useState, useEffect } from 'react'
import { PlusOutlined, GlobalOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Select, Avatar, Image, PageHeader, Pagination, Spin, Table, DatePicker, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'
import { GetRoutesList } from '@/services/pipeline_gad_map'
import local from '@/utils/local'
import { changeDataType } from "@/common/js/common.js" //引入公共方法
import moment from 'moment'
import { basicPath } from '../../router/config';

const { Option } = Select;


function Sonline (props) {
  const navigate = useNavigate();
  const location = useLocation();
  const PID = location.state.id;//主管线id
  const name = location.state.name;//主管线名称
  const token = local.get('cruise_m30_token');

  const [current, setCurrent] = useState(1);//第几页
  const [data, setData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//总共多少条
  const [loading, setLoading] = useState(false);//是否显示加载动画

  const [param, setParam] = useState({
    PID: PID,
    access_token: token,
    page: 1,
    perpage: 10,
  })



  const columns = [ //表格头
    {
      title: '单元段名称',
      dataIndex: 'title',
    },
    {
      title: '总里程(km)',
      dataIndex: 'length',
      // width: '160px',
      // sorter: {
      // 	compare: (a, b) => a.chinese - b.chinese,
      // 	multiple: 3,
      // },
    },
    {
      title: '更新时间',
      dataIndex: 'time',
      width: '200px',
      render: (text, record) => (
        record.time
          ? (
            <div>
              <span className='fSize14 opacity0_85 margR8'>{record.showDate}</span>
              <span className='fSize14 opacity0_45'>{record.showTime}</span>
            </div>
          )
          : (
            <span className='fSize14 opacity0_45'>-</span>
          )
      )
    },
    {
      title: '上帝图',
      dataIndex: '',
      width: '100px',
      align: 'center',
      render: (text, record) => (
        <div className='coL1890FF curPointer' style={{ display: Number(record.length) > 0 ? '' : 'none' }} onClick={() => {
          toNewGodMap({ id: record.id });
        }}>
          <GlobalOutlined style={{ width: '16px', height: '16px' }} />
        </div>
      )
    }
  ];


  //获取表格数据
  const getData = async (list) => {
    setLoading(true);
    let obj = {
      access_token: list.access_token,
      PID: list.PID,
      page: list.page,
      perpage: list.perpage,
    }
    let res = await GetRoutesList(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了');
      return;
    }
    //console.log(res);
    for (let i = 0; i < res.data.items.length; i++) {
      let val = res.data.items[i];
      val.key = i;
      if (val.time) {
        let date = changeDataType(val.time);
        val.showDate = date.date;
        val.showTime = date.time;
      } else {
        val.showDate = '';
        val.showTime = '';
      }
    }
    setTotal(res.data.total);
    setData([...res.data.items]);
    setLoading(false);
  };

  //在函数组件中，组件加载完成后执行
  useEffect(() => {
    getData(param);
  }, [])// eslint-disable-line

  //跳转至最新的上帝管道图
  const toNewGodMap = (param) => {
    window.open(basicPath + '/pipeline_gad_map_show?' + param.id);
  }

  return (
    <div className="flex-col hei100 tablebackcol">
      <div className='flex_row paddTB16 paddRL24 align_center'>
        <span className='curPointer' style={{ width: '32px' }} onClick={() => { navigate(-1) }}><ArrowLeftOutlined style={{ width: '16px', height: '16px' }} /></span>
        <span className='fSize20'>{name}</span>
      </div>
      <div className="borbox" style={{ overflowY: 'auto', padding: '0 24px 24px 24px' }}>
        <div className="flex-col padd_24 backcol">
          <Spin spinning={loading}>
            <Table
              rowKey='id'
              columns={columns}
              // rowSelection={rowSelection}
              dataSource={data}
              pagination={false}
            />
          </Spin>
          <div className="min-h64 tt_a_r disFlex aliItemsCen">
            <Pagination
              total={total}
              showSizeChanger
              showQuickJumper
              showTotal={total => `共 ${total} 条数据`}
              style={{ marginLeft: 'auto' }}
              // pageSizeOptions={[5,10,20]}
              current={current}
              onChange={
                (current, pageSize) => { //选择当前页后执行
                  setCurrent(current);
                  let obj = param;
                  obj.page = current;
                  obj.perpage = pageSize;
                  setParam({ ...obj });
                  getData(obj);
                }
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Sonline
