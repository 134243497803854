//巡检地图
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './index.less'
import MapComponents from "./map_components";

export default function PatrolMap () {
  const navigate = useNavigate();
  const [transfer, _transfer] = useState({})
  const [viewpicture, _viewpicture] = useState({})
  const onBack = () => {
    navigate('/viewpicture', { state: { transfer: viewpicture } })
  }
  useEffect(() => {
    let text = window.location.search.substring(1);
    _transfer(JSON.parse(window.sessionStorage.getItem('patrol_map?' + text)))
    _viewpicture(JSON.parse(window.sessionStorage.getItem('viewpicture' + text)))


    console.log('进来了', JSON.parse(window.sessionStorage.getItem('patrol_map?' + text)));
  }, [])

  return (
    <div className="NodeMap hei100 disFlex flexDirCol" style={{ height: '100vh' }}>
      {/* <Header /> */}
      <div className='flex1 posRel'>
        {MapComponents(transfer)}
      </div>
    </div>
  )
}
