import React from 'react'
import img from '@/common/img/data_monitor/big_data.png' //引入图片的路径


function Data_monitor (props) {

  const rootRef = React.createRef();

  //执行浏览器全屏
  const requestFullScreen = () => {
    if (rootRef.current.requestFullscreen) {
      rootRef.current.requestFullscreen();
    } else if (rootRef.current.mozRequestFullScreen) {
      rootRef.current.mozRequestFullScreen();
    } else if (rootRef.current.webkitRequestFullScreen) {
      rootRef.current.webkitRequestFullScreen();
    }
  }

  return (
    <div className="hei100 border backColor14 posRel">
      <img alt='背景' ref={rootRef} src={img} style={{ width: '100%' }} ></img>
      <div className='posAbs curPointer'
        style={{ zIndex: '10', top: '1.5%', left: '3.5%', width: '2%', height: '4%' }}
        onClick={() => {
          requestFullScreen();
        }}
      >
      </div>
    </div>
  );
}
export default Data_monitor