// home的相关请求
import req from './index'

//登录
export const GetSignIn = (params) => {
  return req.post('api/admin.php?s=Admin.User.Admin_Login', params)
}

//修改密码
export const SetPassword = (params) => {
  return req.post('/api/platform.php?s=Platform.User.AlterPass', params)
}

//获取用户信息
export const GetUserInfo = (params) => {
  return req.post('api/admin.php?s=Admin.User.Admin_MyProfile', params)
}
//获取所有权限
export const getAllPermissions = (params) => {
  return req.post('api/admin.php?s=Admin.Limitation.GetUserMenu', params)
}