import req from './index'


//获取风险信息
export const getRiskIdentification = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.RisksSpecific_List', params)
}
//是否启用识别
export const isIdentify = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.RisksSpecific_Set', params)
}
//提交警报或相似度
export const subAlarmOrSimilarity = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.RisksSpecific_Set', params)
}
//获取 报警距离 相似度 信息   
export const getAlarmDistanceInfo = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Risk_typeList', params)
}
// 编辑警戒线数据
export const setCordonInfoFun = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Set_Cordon', params)
}