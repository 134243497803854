// 结构页面
import React, { useState, useRef, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Layout, Dropdown, Form, Input, message, Button, Drawer, Spin } from 'antd';
import Menu from './menu'
import Logo from '@/common/img/logo.svg'
import { MenuList } from './menu_list.js';
import { GetUserInfo, SetPassword } from '@/services/home'
import local from '@/utils/local'

function Structure () {
  const navigate = useNavigate();
  const isAvoidLogin = local.get('cruise_m30_isAvoidLogin') || 0;//是否为免密登录
  const form = useRef();
  const [isMenu, setIsMenu] = useState(false)//main是否显示
  const [collapsed, setCollapsed] = useState(false)
  const { Header, Sider, Content } = Layout;
  const token = local.get('cruise_m30_token');
  const [user_info, setUserInfo] = useState({});
  const [editPasswordList, setEditPasswordList] = useState({//修改密码  抽屉
    isOpen: false,
    loading: false,
    newPassword: '',//新密码
    confirmPassword: '',//确认新密码
  })

  const items = [
    {
      label: '修改密码',
      key: '1',
    },
    {
      label: '退出登录',
      key: '2',
    },
  ];

  const onFinish = async (values) => {//提交修改密码
    let edi = editPasswordList
    edi.loading = true
    setEditPasswordList({ ...edi })
    let res = await SetPassword({
      access_token: token,
      password: values.old_password,
      new_password: values.new_password
    })

    if (res.ret === 200) {
      if (res.data.is_alter) {
        message.success('修改密码成功，即将返回登陆页面');
        edi.isOpen = false
        edi.loading = false
        setEditPasswordList({ ...edi })
        setTimeout(() => {
          navigate('/login')
        }, 1500)
      } else {
        message.error('修改密码失败');
        edi.loading = false
        setEditPasswordList({ ...edi })
      }
    } else {

      edi.loading = false
      setEditPasswordList({ ...edi })
    }

  }

  const pwdValidator = (rule, value, callback) => {//校验  密码
    if (!value) {
      return callback('请再次输入新密码')
    } else if (value !== editPasswordList.newPassword) {
      return callback('两次密码不相同请重新输入')
    } else {
      return callback()
    }
  }

  const onHead = ({ key }) => {//点击操作
    if (key === '1') {
      // local.set('cruise_m30_cruiseMenuKeys', '/edit_password');
      // navigate('/edit_password', { replace: true });
      // return;
      let edi = editPasswordList
      edi.isOpen = true
      edi.newPassword = ''
      edi.confirmPassword = ''
      setEditPasswordList({ ...edi })
    }
    if (key === '2') {
      message.success('退出成功');
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 1500)
    }
  };



  //获取用户信息
  const GetUserInfoFun = async () => {
    const res = await GetUserInfo({
      access_token: token,
    });
    if (res.ret === 200) {
      local.set('cruise_m30_companyId', res.data.profile.CompanyID);
      setUserInfo(res.data.profile);
    }
  }

  useEffect(() => {
    GetUserInfoFun()
    // eslint-disable-next-line
  }, [])

  return (
    <Layout style={{ height: '100vh', overflow: 'hidden' }}>
      {/* 头部 */}
      <Header style={{ padding: '0', background: '#1f1f1f' }}>
        <div className='flex_row'>
          <div className='flex-row aliItemsCen'>
            <img
              src={user_info.logo_url}
              alt=""
              className="margL24"
              style={{
                display: user_info.logo_url && user_info.logo_url !== '' ? `` : `none`,
                height: '25px',
                marginRight: '0px'
              }} />
            <div className='fSize18 opa85 margL24' >
              {user_info.project_name}
            </div>
          </div>
          <div className='flex-auto'></div>

          <div className='fSize14 margL8 opa85' style={{ color: 'white' }}>{user_info.cominfo_name}</div>
          <div style={{ padding: '0 28px' }}>
            {
              isAvoidLogin ? <span className='opa85'>{user_info.username}</span> :
                <Dropdown menu={{
                  items,
                  onClick: onHead
                }}>
                  <span className='pointer opa85'>{user_info.username}</span>
                </Dropdown>
            }

          </div>
        </div>
      </Header>


      <Layout>
        {/* 菜单 */}
        <Sider>
          <Menu
            isShowMenu={(e) => { setIsMenu(e) }}
            collapsed={collapsed}
          // setCollapsed={setCollapsed}
          >
          </Menu>
        </Sider>

        {/* 内容 */}
        <Content style={{ overflowY: 'auto' }}>
          {
            isMenu
              ? (<Outlet />)
              : (
                <div className='wid100 hei100 flex flex_col jcc  align_center'>
                  <div className='fs32 fw600'>
                    欢迎访问管理系统
                  </div>
                  {/* <div className='fs18 fw400 pdT24'>
                    暂无相关权限，请联系管理员添加···
                  </div> */}
                </div>
              )
          }
        </Content>
      </Layout>


      {/* 抽屉 */}
      <Drawer
        width={256}
        title='修改密码'
        onClose={() => {
          let edi = editPasswordList
          edi.isOpen = false
          setEditPasswordList({ ...edi })
        }}
        open={editPasswordList.isOpen}
        extra={
          <Button
            type='primary'
            onClick={() => {
              document.getElementById('submit').click()
            }}
          >
            确定
          </Button>
        }
      >

        <Spin spinning={editPasswordList.loading}>
          <Form
            ref={form}
            name="basic"
            layout='vertical'
            style={{ color: '#fff !import' }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="原密码"
              name="old_password"
              rules={[
                {
                  required: true,
                  message: '请输入原密码',
                },
                {
                  min: 6, message: '密码至少6位'
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="新密码"
              name="new_password"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: '请输入新密码',
                },
                {
                  pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{12,}$/,
                  message: '密码不低于12位，且包含大小写英文、数字、符号'
                }
              ]}
            >
              <Input.Password
                value={editPasswordList.newPassword}
                onChange={(value) => {
                  let edi = editPasswordList
                  edi.newPassword = value.target.value
                  setEditPasswordList({ ...edi })
                }}
              />
            </Form.Item>

            <Form.Item
              label="确认密码"
              name="confirm_password"
              validateTrigger="onBlur"
              required
              rules={[
                {
                  validator: pwdValidator,
                }
              ]}
            >
              <Input.Password
                value={editPasswordList.confirmPassword}
                onChange={(value) => {
                  let edi = editPasswordList
                  edi.confirmPassword = value.target.value
                  setEditPasswordList({ ...edi })
                }} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button
                id='submit'
                type="primary"
                className='none'
                htmlType="submit"
              >
                提交
              </Button>
            </Form.Item>
          </Form>

        </Spin>
      </Drawer>
    </Layout>

  )
}
export default Structure