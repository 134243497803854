import local from '@/utils/local'
//table表格 翻页 排序 筛选 搜索回调
const SortOrFilter = (newPagination, filters, sorter) => {
  const issearch = JSON.stringify(local.get('cruise_m30_issearch'))
  let searchFlag = {
    filters: {},
    sorter: {},
  };
  let search = {
    newPagination: {},
    filters: {},
    sorter: {},
  };
  // 分页
  search.newPagination.current = newPagination.current;
  search.newPagination.pageSize = newPagination.pageSize;
  // 筛选 | 搜索
  for (const key in filters) {
    if (filters[key]) {
      search.filters[key] = filters[key].join(',')
    }
  }
  //排序
  let sorterList = []
  if (sorter.length > 0) {
    sorter.forEach((item, index) => {
      sorterList.push(item.field + ' ' + (item.order == 'ascend' ? 'ASC' : 'DESC')) // eslint-disable-line
      search.sorter = sorterList.join(',')
    })
  } else {
    if (sorter.column) {
      search.sorter = sorter.field + ' ' + (sorter.order == 'ascend' ? 'ASC' : 'DESC') // eslint-disable-line
    }
  }
  search.filters = isObjectEmpty(search.filters) ? null : search.filters
  search.sorter = isObjectEmpty(search.sorter) ? null : search.sorter
  //搜索


  // 判断重置分页
  searchFlag.filters = search.filters
  searchFlag.sorter = search.sorter
  local.set('cruise_m30_issearch', searchFlag)
  if (JSON.stringify(searchFlag) != issearch) { // eslint-disable-line
    search.newPagination.current = 1
  }
  return search
};

export default SortOrFilter

// 判断对象是否为空
const isObjectEmpty = (obj) => {
  for (var key in obj) {
    return false
  }
  return true
}
