// 航线相关请求
import req from './index'

//获取管线
export const GetRoutesList = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesListTop_Get', params)
}

//新增管线
export const AddRoutes = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesInfo_Add', params)
}

//编辑管线
export const EditRoutes = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesInfo_Edit', params)
}

//删除管线
export const DelRoutes = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesInfo_Del', params)
}

//上传坐标
export const ImportPoint = (params) => {
  return req.file('api/admin.php?s=Admin.Routes.RoutesPoint_Import', params)
}

//上传航线
export const ImportAirway = (params) => {
  return req.file('api/admin.php?s=Admin.Routes.RoutesRoute_Import', params)
}

//上传标识桩
export const IdentificationStake = (params) => {
  return req.file('api/admin.php?s=Admin.Routes.RoutesMark_Import', params)
}

//获取管线详细信息 包括起飞点
export const GetRoutesInfo = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesInfo_Get', params)
}

//获取航点
export const GetRoutesPoint = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPoint_Get', params)
}

//编辑航点
export const SetRoutesPoint = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPoint_Edit', params)
}

//根据经纬度获取海拔
export const GetAltitude = (params) => {
  return req.get('api/admin.php?s=Admin.Routes.GetElev9', params)
}

//获取航点以及航点动作
export const GetRoutesPointAction = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPointAction_Get', params)
}

//编辑航点动作
export const SetRoutesPointAction = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesPointActoin_Edit', params)
}

//编辑起飞点
export const SetTakeOff = (params) => {
  return req.post('api/admin.php?s=Admin.Routes.RoutesTakeOff_Edit', params)
}

// 获取无人机点位
export const getUavpositionInfo = (params) => {
  return req.get('api/admin.php?service=Admin.Cruise.uavposition', params)
}

// 导出
export const exportWaypoints = (params) => {
  return req.get('api/admin.php?s=Admin.Routes.Routes_PointExport', params)
}

