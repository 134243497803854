
// 人员列表
import React from 'react'
import { useState, useEffect } from 'react'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Pagination, Spin, Space, message } from 'antd';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom' //路由跳转使用 useNavigate react-router v6已经把usehistory替换成了useNavigate
import OperationBuilder from '@/components/TableBuilder/OperationBuilder';
import SortOrFilter from '@/components/TableBuilder/SortOrFilter';

import SetHandler from './set_handler.js';
import { GetHandlerUserList, SetState } from "@/services/risk_handlers.js"; // 引入axios
import { changeDataType } from "@/common/js/common.js" //引入公共方法
import local from '@/utils/local'

const { TextArea } = Input;

function Riskuser (props) {
  const navigate = useNavigate();
  const btnsize = 'default';
  const token = local.get('cruise_m30_token');

  const [search_user, SetSearchUser] = useState('');//搜索姓名
  const [search_phone, SetSearchPhone] = useState('');//搜索电话
  const [search_sector, SetSearchSector] = useState('');//搜索部门
  const [search_ID, SetSearchID] = useState('');//搜索账号

  const [AddOrSetUpVal, setAddOrSetUpVal] = useState({});//传递标题和id
  const [AddOrSetUpFlag, setAddOrSetUpFlag] = useState(false);

  const [current, setCurrent] = useState(1);//第几页
  const [data, setData] = useState([]);//表格数据
  const [chooseData, setChooseData] = useState([]);//表格选择的数据
  const [total, setTotal] = useState(0);//总共多少条
  const [loading, setLoading] = useState(false);

  const [param, setParam] = useState({
    access_token: token,
    realname: '',
    department: '',
    username: '',
    phone: '',
    pid: 0,
    order: '',
    page: current,
    perpage: 10,
  })

  const [showModle, setShowModel] = useState(false);
  const [modelTitle, setModelTitle] = useState('');

  const [title, setTitle] = useState('');
  const [cycle, setCycle] = useState('');
  const [id, setId] = useState('');

  const [des, setDes] = useState('');


  const [sex, SetSex] = useState([])
  //搜索姓名
  const SearchName = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, }}>
        <Input
          placeholder='请输入姓名'
          value={search_user}
          onChange={(e) => { SetSearchUser(e.target.value) }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              let obj = { ...param };
              obj.realname = search_user;
              setParam({ ...obj });
              getData(obj, () => {
                setTimeout(() => {
                  setLoading(false);
                }, 500)
              })
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>

          <Button
            onClick={() => {
              let obj = { ...param };
              obj.realname = '';
              setParam({ ...obj });
              SetSearchUser('')
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  //搜索电话
  const SearchPhone = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8, }}>
        <Input
          placeholder='请输入电话号码'
          value={search_phone}
          onChange={(e) => { SetSearchPhone(e.target.value) }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              let obj = { ...param };
              obj.phone = search_user;
              setParam({ ...obj });
              getData(obj, () => {
                setTimeout(() => {
                  setLoading(false);
                }, 500)
              })
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>

          <Button
            onClick={() => {
              let obj = { ...param };
              obj.phone = '';
              setParam({ ...obj });
              SetSearchPhone('')
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  //搜索部门
  const SearchSector = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8, }}>
        <Input
          placeholder='请输入部门'
          value={search_sector}
          onChange={(e) => { SetSearchSector(e.target.value) }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              let obj = { ...param };
              obj.department = search_sector;
              setParam({ ...obj });
              getData(obj, () => {
                setTimeout(() => {
                  setLoading(false);
                }, 500)
              })
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>

          <Button
            onClick={() => {
              let obj = { ...param };
              obj.department = '';
              setParam({ ...obj });
              SetSearchSector('')
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  //搜索ID/账号
  const SearchID = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, }}>
        <Input
          placeholder='请输入ID'
          value={search_ID}
          onChange={(e) => { SetSearchID(e.target.value) }}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              let obj = { ...param };
              obj.username = search_ID;
              setParam({ ...obj });
              getData(obj, () => {
                setTimeout(() => {
                  setLoading(false);
                }, 500)
              })
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>

          <Button
            onClick={() => {
              let obj = { ...param };
              obj.username = '';
              setParam({ ...obj })
              SetSearchID('')
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  const columns = [ //表格头
    // {
    //   title: 'ID',
    //   dataIndex: 'username',
    //   width: '200',
    //   ...SearchID(),
    // },
    {
      title: '姓名',
      dataIndex: 'realname',
      // width:'250px',
      // ...SearchName(),
    },
    {
      title: '性别',
      dataIndex: 'sex_s',
      // width: '100px',
      // filters: sex,
      // filterMultiple: false
    },
    // {
    //   title: '年龄',
    //   dataIndex: 'age',
    //   width: '60',
    //   sorter: {
    //     compare: (a, b) => a.math - b.math,
    //     multiple: 2,
    //   },
    // },
    {
      title: '电话',
      dataIndex: 'phone',
      // width:'250px',
      // ...SearchPhone(),
    },
    {
      title: '部门',
      dataIndex: 'department',
      // width:'250px',
      // ...SearchSector(),
    },
    {
      title: '登录时间',
      dataIndex: 'lasttime',
      render: (text, record) => (
        <div>
          <span className='fSize14 opacity0_85 margR8'>{record.showDate}</span>
          <span className='fSize14 opacity0_45'>{record.showTime}</span>
        </div>
      )
      // sorter: {
      //   compare: (a, b) => a.math - b.math,
      //   multiple: 2,
      // },
    },
    {
      title: '操作',
      dataIndex: 'op',
      width: '100px',
      align: 'center',
      render: (_, record, index) => OperationBuilder(record, index, 'actions_drop', ButtonList, Operation)
    },
  ];
  //操作按钮
  const ButtonList = [
    {
      key: '0',
      type: 'SetUp',
      label: "修改",
      purview: true,
    },
    {
      key: 'divider',
      type: 'Divider',
      purview: true,
    },
    {
      key: '3',
      type: 'Delete',
      label: '删除',
      purview: true,
    },
  ]
  //操作按钮 回调
  const Operation = (record, index, type, key) => {
    //console.log('回调');
    //console.log(record);
    //console.log(index);
    //console.log(type);
    //console.log(key);
    switch (key) {
      case '0':
        let obj = {
          id: record.id,
          title: '修改'
        }
        setAddOrSetUpVal(obj);
        setAddOrSetUpFlag(true);
        break;
      case '3':
        delData(record.id);
        break;
      default:
        break;
    }

  };


  //获取表格数据
  const getData = async (sift_data, callback) => {
    setLoading(true);
    let res = await GetHandlerUserList(sift_data);
    //console.log('获取表格数据');
    //console.log(res);
    if (res.ret === 200) { // eslint-disable-line
      for (let i = 0; i < res.data.items.length; i++) {
        let val = res.data.items[i];
        val.key = i;
        if (val.lasttime) {
          let date = changeDataType(val.lasttime);
          val.showDate = date.date;
          val.showTime = date.time;
        } else {
          val.showDate = '';
          val.showTime = '';
        }

      }
      setTotal(res.data.total);
      setData(res.data.items);
      let sexs = res.data.sjzd.sex.map((item, idx) => {
        return { value: item.value, text: item.label }
      })
      SetSex(sexs);
      callback();
    } else {
      //console.log(res.msg);
    }
  };

  //删除表格数据
  const delData = async (id) => {
    // //console.log('删除');
    // //console.log(param);
    let res = await SetState({
      access_token: token,
      id: id,
      status: 0
    });
    if (res.ret == 200) { // eslint-disable-line
      message.success('删除成功');
      let obj = { ...param };
      if (data.length === 1) {
        if (obj.page !== 1) {
          obj.page = obj.page - 1;
        }
      }
      setParam({ ...obj });
      getData(obj, () => {
        setTimeout(() => {
          setLoading(false);
        }, 500)
      });
    }
  };

  //弹窗回调
  const onAddOrSetUp = (Flag) => {
    setAddOrSetUpFlag(false)
    setAddOrSetUpVal({})
    if (Flag) {
      getData(param, () => {
        setTimeout(() => {
          setLoading(false);
        }, 500)
      })
    }
  }


  //在函数组件中，组件加载完成后执行
  useEffect(() => {
    getData(param, () => {
      setTimeout(() => {
        setLoading(false);
      }, 500)
    });
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="hei100 padd_24 borbox tablebackcol">
      <div className="flex-col paddRL24  backcol">
        <div className="h64 flex-row aliItemsCen min-h64">
          <div className="fSize16 fWei600">人员列表</div>
          <div className="flex-auto"></div>
          <Button type="primary"
            icon={<PlusOutlined />}
            size={btnsize}
            onClick={
              () => {
                let obj = {
                  id: '',
                  title: '新增'
                }
                setAddOrSetUpVal(obj);
                setAddOrSetUpFlag(true);
              }
            }
          >
            新增
          </Button>
        </div>
        <div className="flex-auto borbox" style={{ overflowY: 'auto' }}>
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              onChange={(pagination, filters, sorter) => {
                //console.log('zhix');
                //console.log(pagination, filters, sorter);
                setCurrent(1);
                let val = SortOrFilter(pagination, filters, sorter);
                let obj = { ...param };
                obj.order = val.sorter;
                //console.log(obj.order);
                obj.page = 1;
                if (filters.sex_s && filters.sex_s.length > 0) {
                  obj.sex = filters.sex_s[0];
                } else {
                  obj.sex = ''
                }
                setParam({ ...obj });
                getData(obj, () => {
                  setTimeout(() => {
                    setLoading(false);
                  }, 500)
                });
              }}
            />
          </Spin>
          <div className=" tt_a_r disFlex aliItemsCen" style={{ minHeight: '32px', margin: '16px 0' }}>
            <Pagination
              total={total}
              showSizeChanger
              showQuickJumper
              showTotal={total => `共 ${total} 条数据`}
              style={{ marginLeft: 'auto' }}
              current={current}
              onChange={
                (current, pageSize) => { //选择当前页后执行
                  let obj = { ...param };
                  obj.page = current;
                  setCurrent(current);
                  obj.perpage = pageSize;
                  // obj.order='';
                  setParam({ ...obj });
                  getData(obj, () => {
                    setTimeout(() => {
                      setLoading(false);
                    }, 500)
                  });
                }
              }
            />
          </div>
        </div>

        <SetHandler
          AddOrSetUpVal={AddOrSetUpVal}
          visible={AddOrSetUpFlag}
          Operation={onAddOrSetUp}
        >
        </SetHandler>
      </div>
    </div>
  );
}
export default Riskuser