// 短信通知相关请求
import req from './index'

// 获取短信列表
export const getSMSAPI = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Sms_templateList', params)
}
// 是否启用
export const isEnableAPI = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Enable_Sms_template', params)
}
// 新增、编辑短信
export const setSMSAPI = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Sms_templateSet', params)
}
// 获取编辑的短信
export const getSMSInfoAPI = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Sms_templateInfo', params)
}

