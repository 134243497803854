
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons';
import { message, Pagination, Spin, Button, Input, Space } from 'antd';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom' //路由跳转使用 useNavigate react-router v6已经把usehistory替换成了useNavigate
import { GetRoutesList } from '@/services/pipeline_gad_map.js'
import { changeDataType } from "@/common/js/common.js" //引入公共方法
import local from '@/utils/local'

function Pipelinediagram (props) {
  const location = useLocation();
  const navigate = useNavigate();
  const token = local.get('cruise_m30_token');

  const [data, setData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//总共多少条
  const [loading, setLoading] = useState(false);

  const [siftData, setSiftData] = useState({
    access_token: token,
    urltype: 1,
    title: '',
    page: 1,
    perpage: 10,
  });

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`筛选 ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜索
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    )
  });

  const columns = [ //表格头
    {
      title: '管线名称',
      dataIndex: 'title',
      render: (text, record) => (
        <span style={{ color: '#1890FF', cursor: 'pointer' }}
          onClick={
            () => {
              navigate('/pipeline_gad_map_info', { state: { id: record.id, name: text } })
            }
          }
        >
          {text}
        </span>
      ),
      ...getColumnSearchProps('管线名称')
    },
    {
      title: '总里程(km)',
      dataIndex: 'length',
      width: '160px',
    },
    {
      title: '更新时间',
      dataIndex: 'time',
      width: '200px',
      render: (text, record) => (
        record.time
          ? (
            <div>
              <span className='fSize14 opacity0_85 margR8'>{record.showDate}</span>
              <span className='fSize14 opacity0_45'>{record.showTime}</span>
            </div>
          )
          : (
            <span className='fSize14 opacity0_45'>-</span>
          )
      )
    },
  ];


  //获取表格数据
  const getTableData = async (info) => {
    setLoading(true);
    let obj = {
      access_token: token,
      title: info.title,
      type: info.urlType,
      page: info.page,
      perpage: info.perpage,
    }
    let res = await GetRoutesList(obj);
    if (res.ret !== 200) {
      message.warning(res.msg || '系统出错了!');
      return;
    }
    //console.log('获取表格数据');
    //console.log(res);
    for (let i = 0; i < res.data.items.length; i++) {
      let val = res.data.items[i];
      if (val.time) {
        let date = changeDataType(val.time);
        val.showDate = date.date;
        val.showTime = date.time;
      } else {
        val.showDate = '';
        val.showTime = '';
      }
    }
    setTotal(res.data.total);
    setData([...res.data.items]);
    setLoading(false);
  };

  //在函数组件中，组件加载完成后执行
  useEffect(() => {

    let sif = siftData
    switch (location.pathname) {
      case '/conduit_pipeline_gad_map':
        sif.urlType = 1
        break;

      case '/powerGrid_pipeline_gad_map':
        sif.urlType = 7
        break;

      default:
        break;
    }
    setSiftData({ ...sif })
    getTableData(sif);
  }, [location.pathname])// eslint-disable-line

  return (
    <div className="hei100 padd_24 borbox tablebackcol" style={{ overflowY: 'auto' }}>
      <div className="flex-col paddRL24 paddT24 backcol">
        <div className="borbox">
          <Spin spinning={loading}>
            <Table
              columns={columns}
              // rowSelection={rowSelection}
              dataSource={data}
              pagination={false}
              rowKey="id"
              onChange={(pagination, filters, sorter) => {
                console.log(filters);
                let sift = { ...siftData };
                for (let key in filters) {
                  sift[key] = filters[key] && filters[key].length > 0 ? filters[key][0] : '';
                }
                sift.page = 1;
                setSiftData({ ...sift });
                getTableData(sift);
              }}
            />
          </Spin>
        </div>
        <div className="min-h64 tt_a_r disFlex aliItemsCen">
          <Pagination
            total={total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条数据`}
            style={{ marginLeft: 'auto' }}
            current={siftData.page}
            onChange={
              (current, pageSize) => { //选择当前页后执行
                let obj = { ...siftData };
                obj.page = current;
                obj.perpage = pageSize;
                setSiftData({ ...obj });
                getTableData(obj);
              }
            }
          />
        </div>
      </div>
    </div>
  );
}
export default Pipelinediagram