// 巡检结果的相关请求
import req from './index'



//风险处理
export const RiskDisposeList_Get = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.New_RiskDisposeList_Get', params)
}
//巡检图像
export const InsImageList_Get = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.InsImageList_Get', params)
}
//巡检报告
export const InsReportList_Get = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.InsReportList_Get', params)
}

//巡检报告-获取报告文件
export const GetReport = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.InsReportCreate_Get', params)
}

//巡检图像--详情
export const GetPointListTab = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.InsImageList_PointListTab', params)
}

//风险处理-节点数据列表
export const GetPointList = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.InsImageList_PointList', params)
}


//风险处理-详情
export const GetPoint = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.RiskDisposePicList_Get', params)
}

//风险处理-详情 新
export const GetPointNew = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.RiskDisposePic_Get', params)
}

//巡检图像-节点处理详情信息
export const GetPointInfo = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.InsImageList_PointInfo', params)
}

//风险处理-获取用户信息
export const GetUserInfo = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.RiskDisposeAssignUser_Get', params)
}

//风险处理-分配节点处理人员
export const AllocationUser = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.RiskDisposeAssignUser_Set', params)
}

//风险处理-忽略风险点
export const Ignore = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.RiskDisposeIgnore_Set', params)
}

// 常规风险  报警距离   获取信息
export const getAlarmDistanceInfo = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Risk_typeList', params)
}
// 常规风险  报警距离   设置修改信息
export const setAlarmDistanceInfo = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.RiskType_Set', params)
}

// 上传图片
export const uploadImageFun = (params) => {
  return req.file('api/admin.php?s=Admin.Cruise.InsPointRiskDisposePic_Upload', params)
}

// 阶段填报
export const stageFillFun = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.InsPointRisk_Dispose', params)
}




