// 短信通知
import local from '@/utils/local'
import { useNavigate, useLocation, } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Card, Button, Table, Tag, Drawer, Space, Input, message, Form, Switch, Popconfirm, Select, Spin } from 'antd'
import { PlusOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { getSMSAPI, isEnableAPI, setSMSAPI, getSMSInfoAPI } from '@/services/SMS_notification.js'


function SMSNotification () {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数
  const token = local.get('cruise_m30_token');

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false)//加载中···


  const [tableData, setTableData] = useState([])//表格数据
  const [dictionary, setDictionary] = useState({
    state: [],//状态
  })

  const [SMSDrawer, setSMSDrawer] = useState({//抽屉
    title: "",
    id: "",
    isOpen: false,
    loading: false,
  })

  const getAllData = async () => {
    setLoading(true)
    let res = await getSMSAPI({ access_token: token })
    setLoading(false)
    if (res.ret !== 200) { message.warning(res.msg || '数据跑丢了'); return; }
    let dic = dictionary
    dic.state = res.data.sjzd.state
    setDictionary({ ...dic })
    setTableData([...res.data.list])
  }


  const columns = [//表头
    {
      title: '模板名称',
      dataIndex: 'name',
      render: (text, record) => (
        <div>
          {text}
        </div>
      )
    },
    {
      title: '状态',
      dataIndex: 'state',
      render: (text, record) => {
        switch (Number(text)) {
          case 0:
            return (
              <Tag icon={<ExclamationCircleOutlined />} color="warning">{dictionary.state.find(res => res.value === text)?.label}</Tag>
            )
          case 1:
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">{dictionary.state.find(res => res.value === text)?.label}</Tag>
            )
          case 2:
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">{dictionary.state.find(res => res.value === text)?.label}</Tag>
            )
          default:
            return <div className='opa25'>-</div>
        }
      }
    },
    // {
    //   title: '使用次数',
    //   dataIndex: 'ins_status',
    //   render: (text, record) => (
    //     <div>
    //       {text}
    //     </div>
    //   )

    // },
    {
      title: '启用',
      dataIndex: 'is_enable',
      render: (text, record) => (
        <div>
          <Popconfirm
            disabled={record.state !== 1}
            placement="top"
            cancelText="取消"
            okText={text ? `禁用` : `启用`}
            title={`是否${text ? '禁用' : '启用'}`}
            onConfirm={async () => {
              let obj = {
                id: record.id,
                access_token: token,
                is_enable: text ? 0 : 1
              }
              let res = await isEnableAPI(obj)
              if (res.ret !== 200) { message.warning(res.msg || '数据跑丢了'); return; }
              message.success(`${text ? '禁用' : '启用'}成功！`)
              let tab = tableData
              tab.find(res => res.id === record.id).is_enable = text ? 0 : 1
              setTableData([...tab])
            }}
          >
            <Switch
              size='small'
              checked={text}
              disabled={record.state !== 1}
            />
          </Popconfirm>
        </div>
      )

    },
    {
      title: '备注',
      dataIndex: 'remarks',
      render: (text, record) => (
        <div >
          {text
            ? (
              <div className='opa45'>{text}</div>
            )
            : (
              <div className='opa25'>-</div>
            )
          }
        </div>
      )

    },
    {
      title: '操作',
      width: 60,
      dataIndex: 'id',
      render: (text, record) => (
        <span
          className='pointer'
          style={{ color: '#177DDC' }}
          onClick={async () => {
            form.resetFields()
            let sms = SMSDrawer
            sms.id = record.id
            sms.title = '编辑'
            sms.isOpen = true
            sms.loading = true
            setSMSDrawer({ ...sms })
            let res = await getSMSInfoAPI({ access_token: token, id: record.id })
            sms.loading = false
            setSMSDrawer({ ...sms })
            if (res.ret !== 200) {
              message.warning(res.msg || '数据跑丢了');
              return;
            }
            form.setFieldsValue({
              mbmc: res.data.name,
              zt: res.data.state,
              bz: res.data.remarks,
            })
          }}
        >
          编辑
        </span>
      )
    },
  ]


  const onFinish = async (data) => {//表单提交
    let sms = SMSDrawer
    sms.loading = true
    setSMSDrawer({ ...sms })
    let obj = {
      access_token: token,
      id: sms.id,
      name: data.mbmc,
      state: data.zt,
      remarks: data.bz,
    }
    let res = await setSMSAPI(obj)
    if (res.ret !== 200) {
      sms.loading = false
      setSMSDrawer({ ...sms })
      message.warning(res.msg || '数据跑丢了');
      return;
    }
    sms.loading = false
    sms.isOpen = false
    setSMSDrawer({ ...sms })
    message.success(`${sms.id ? '编辑' : '新增'}成功`)
    getAllData()
  }

  useEffect(() => {
    getAllData()
    // eslint-disable-next-line
  }, [])

  return (
    <Card className='mar24'>
      <div className='flex jcr marB16'>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            let sms = SMSDrawer
            sms.id = ''
            sms.title = '新增'
            sms.isOpen = true
            setSMSDrawer({ ...sms })
            form.setFieldsValue({
              zt: 0
            })
          }}
        >
          新增
        </Button>
      </div>

      {/* 表格 */}
      <Table
        rowKey='id'
        loading={loading}
        columns={columns}
        dataSource={tableData}
      />

      {/* 短信抽屉 */}
      <Drawer
        width={256}
        title={SMSDrawer.title}
        open={SMSDrawer.isOpen}
        onClose={() => {
          let sms = SMSDrawer
          sms.id = ''
          sms.title = ''
          sms.isOpen = false
          sms.loading = false
          setSMSDrawer({ ...sms })
          form.resetFields()
        }}
        extra={
          <Space>
            <Button type="primary" disabled={SMSDrawer.loading} onClick={() => { document.getElementById('submitForm').click() }}>提交</Button>
          </Space>
        }
      >
        <Spin spinning={SMSDrawer.loading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="mbmc"
              label="模板名称"
              rules={[
                {
                  required: true,
                  message: '请填写模板名称',
                },
              ]}
            >
              <Input />
            </Form.Item>


            <Form.Item
              name="zt"
              label="状态"
            >
              <Select options={dictionary.state} />
            </Form.Item>


            <Form.Item
              name="bz"
              label="备注"
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item >
              <Button id='submitForm' className='none' type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </Card>
  )
}
export default SMSNotification;