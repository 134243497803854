import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Badge } from 'antd';
import { useNavigate } from 'react-router-dom' //路由跳转使用 useNavigate react-router v6已经把usehistory替换成了useNavigate

// key:
// 1: 采集读数巡检处理数   
// 2: 常规巡检待处理数     
// 3: 高危巡检待处理数
// 4: 异常排查数
function TextInfo (props) {
  const { label, type, count } = props;

  const keyWord = [
    {
      key: '1',
      text: '采集读数巡检处理数',
      count: count
    },
    {
      key: '2',
      text: '管道风险',
      count: count
    },
    {
      key: '3',
      text: '高危风险',
      count: count
    },
    {
      key: '4',
      text: '异常排查',
      count: count
    },
    {
      key: '7',
      text: '电网风险',
      count: count
    },
    {
      key: '8',
      text: '电塔风险',
      count: count
    },
    {
      key: '9',
      text: '场站风险',
      count: count
    },
  ]

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', }}>
      <div>{keyWord.find((item) => item.key === type)?.text}</div>
      <div style={{ display: keyWord.find((item) => item.key === type).count ? `block` : `none` }}>
        <Badge count={keyWord.find((item) => item.key === type)?.count} />
      </div>
    </div >
  );
}
export default TextInfo