import req from './index'

//获取异常列表
export const GetAnomalous = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.FaultCheckList_Get', params)
}

//获取节点图片排查数据
export const GetAnomalousImage = (params) => {
  return req.get('api/admin.php?s=Admin.Cruise.FaultCheckInfo_Get', params)
}

//提交排查数据
export const SubCheckImg = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.FaultCheckInfo_Set', params)
}

//获取画线数据
export const GetImageLineData = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.ImageLineData_Get', params)
}

//获取压缩后的图片
export const ImageLineCompressData = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.ImageLineCompress_Get', params)
}

//保存画线图片
export const SubmageLine = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.ImageLineData_Set', params)
}

//保存画线图片
export const ImportInsPic = (params) => {
  return req.file('api/admin.php?s=Admin.Cruise.FaultCheck_ImportInsPic', params)
}

//忽略AI识别区域坐标
export const IgnoreAIAxis = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.RiskWorkOff_Set', params)
}


//新增|删除 识别区域坐标
export const SetAIAxis = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.RiskAbnormal_Point', params)
}

//新增|删除 识别区域坐标
export const setAxisNew = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.RiskAbnormal_Point_new', params)
}

//删除 识别区域坐标
export const DelAIAxis = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.RiskAbnormal_List_set', params)
}

//获取风险选项
export const GetRiskOptions = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Risk_typeList', params)
}

//获取航点范围 
export const GetPointRangeFun = (params) => {
  return req.post('api/admin.php?s=Admin.Pipe.getRoutesPipeLine', params)
}

//发送短信
export const SendMessageFun = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.SendMessage', params)
}

//发送短信
export const newGetRiskOptionsFun = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.Risks_specific', params)
}

//新 获取异常类型
export const setIdentifyTypeAPI = (params) => {
  return req.post('api/admin.php?s=Admin.Cruise.SetAiStatistics', params)
}



